import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header";
import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";
import { UserStore } from "../../store/UserStore";
import ArrowDownward from '@material-ui/icons/ArrowDownward';

var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const sortIcon = <ArrowDownward />;


class crearEmpresas extends Reflux.Component<any, any> {
    public formRef: any = null;
    lastFilters = {};
    state = {
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipocliente: "",
        empresaDetailTotal: [],
        empresaSeleccionado: false,
        idUser: '',
        municipios: [{ key: "", value: 0 }],
        empresaDetail: {
            id: "",
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: ""
        },
        formEmpresa: {
            id: "",
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: ""
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataUsuario: false,
        updateDataUsuario: false,
        saveUsuarioButtonDisabled: true,
        updateUsuarioButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        inputDisabled: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreProductoDisabled: false,
        inputCifDisabled: false,
        inputIdDisabled: false,
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        apellido1TitularError: false,
        emailError: false,
        nombreTitularError: false,
        NIFError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        ibanError: false,
        alert: null,
        createResult: [],
        createResult2: [],

    };




    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);

    }


    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    }



    componentDidMount() {
        this.getMunicipiosLista();
    }

    //////////////////////////////////////////////////////////////////////////////////////7


    async getMunicipiosLista() {


        const userID = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")
        var user: any = [];


        var dataFilters = {
            'userId': userID
        }

        const valores = JSON.stringify(dataFilters)

        const headers = {
            
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/diccionario/municipiosgeo";

        await axios.get(direccionFile, { headers, params: { valores } })
            .then((result) => {


                var municipio = result.data.data;

                this.setState({
                    municipios: municipio
                });
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                  });
              });

    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };


    //////////////////////////////////////////////////////////////////////////////////////7


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
     </ReactBSAlert>
            )
        });
    };


    warningAlertRepetido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Empresa Repetida"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    LA empresa ya se encuentra registrada.
     </ReactBSAlert>
            )
        });
    };



    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
     </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear la empresa"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
            </ReactBSAlert>
            )
        });
    };


    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Empresa guardada correctamente
        </ReactBSAlert>
            )
        });
    };



    ///////////////////////////////////////////////////////////////////////////////////////////


    onChangeFieldUsuario = (event) => {
        event.persist()
        var formData = { ...this.state.formEmpresa };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        var municipio = "";
        var autonomia = "";
        var provincia = "";

        const nameFilters = ['idEmpresa', 'nif', 'tipo', 'tipoServicio', 'numOrden', 'empresa', 'direccion', 'codigoPostal', 'municipio', 'provincia', 'telefonoAtt', 'telefonoUrgencias', 'ambito', 'fechaAlta', 'fechaBaja', 'web', 'estado', 'correoContacto', 'nombreContacto', 'telefonoContacto', 'fechAltaDatos', 'usuario']
        const optionsFilters = ['user_group_id', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles'];

        if (nameFilters.indexOf(event.target.name) != -1) {
            this.setState({ saveDataUsuario: true });
            this.setState({
                saveUsuarioButtonDisabled: false,
                updateUsuarioButtonDisabled: false
            })
        }
        if (event.target.name == 'empresa' && event.target.value.length > 0) {

            this.setState({ saveDataUsuario: true });
            this.setState({
                saveUsuarioButtonDisabled: false,
                updateUsuarioButtonDisabled: false
            })
        }
        if (event.target.name == 'apellidos' && event.target.value.length > 0) {

            this.setState({ saveDataUsuario: true });
            this.setState({
                saveUsuarioButtonDisabled: false,
                updateUsuarioButtonDisabled: false
            })
        }


        if (event.target.name === 'nif' && event.target.value.length == 0) {
            this.setState({ NIFClienteError: true })
        } else if (event.target.name === 'nif' && event.target.value.length > 0) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                this.setState({
                    saveDataUsuario: true,
                    NIFClienteError: false
                });
                this.setState({
                    saveUsuarioButtonDisabled: true,
                    updateUsuarioButtonDisabled: false
                })
            } else {
                this.setState({
                    NIFClienteError: true,
                    saveDataUsuario: false,
                    updateUsuarioButtonDisabled: true
                })
            }
        }


        if (event.target.name === 'correoContacto') {


            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);

            if (result === true) {
                this.setState({
                    emailError: false,
                })
            } else {
                this.setState({
                    emailError: true
                })
            }
        };






        if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
            value = null

        }

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        };




        if (name == 'municipio') {

            let indexMunicipio = this.state.municipios.findIndex(object =>
                object.key === value
            ); // 👉️ 1

            if (indexMunicipio !== -1) {
                municipio = this.state.municipios[indexMunicipio].value
                autonomia = this.state.municipios[indexMunicipio].autonomia
                provincia = this.state.municipios[indexMunicipio].provincia
            };
            formData['codigoINE'] = value
            formData['municipio'] = municipio
            formData['provincia'] = provincia

            this.setState({
                saveDataCliente: true
            })

            this.setState({ formEmpresa: formData });

        } else {


            /*    if (name == 'nombreMunicipioPS') {
                    const municipio: any = this.state.dicMunicipios
                    const resultado = municipio.find(municipio => municipio ?.Nombre === value);
                    const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
                    formData['nombreProvinciaPS'] = resultado.DescProvincia
                    formData['AutonomiaTitular'] = resultado.DescAutonomia
                }
            */

            formData[name] = value;
            //if (value === "" || value === null) {
            //    formData[name] = 0;
            //
            //}
            this.setState({
                saveDataCliente: true
            })
            this.setState({ formEmpresa: formData });
        }

    };

    //////////////////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    ///////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        const filters = {};
        this.setState({
            inputCifDisabled: false,
            inputNombreProductoDisabled: false,
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            empresaSeleccionado: false,
            empresaDetailTotal: [],
            formEmpresa: {
                id: "",
                idEmpresa: "",
                nif: "",
                tipo: "",
                tipoServicio: "",
                numOrden: "",
                empresa: "",
                direccion: "",
                codigoPostal: "",
                municipio: "",
                provincia: "",
                telefonoAtt: "",
                telefonoUrgencias: "",
                ambito: "",
                fechaAlta: "",
                fechaBaja: "",
                web: "",
                estado: "a",
                correoContacto: "",
                nombreContacto: "",
                telefonoContacto: "",
                fechAltaDatos: "",
                usuario: ""
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveUsuarioButtonDisabled: true,
            updateUsuarioButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            saveDataUsuario: false

        });

        this.formRef.current.reset();

    };

    ///////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {

        if (this.state.saveDataUsuario && (this.state.formEmpresa.empresa && this.state.formEmpresa.nif)) {

            this.confirmAlertSave()

        } else {
            this.warningAlertFiltros()
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {

        //const fechaActivacion = this.state.fechaActivacion;
        const uuidUser = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")

        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }


        const codigoINE = this.state.municipioBuscar ? this.state.municipioBuscar : ""
        var iduser = datosUser.userId ? datosUser.userId : ""
        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""




        if (this.state.saveDataUsuario == true) {

            var updatedAt = '';

            var createdAt = this.state.formEmpresa.createdAt;

            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var updatedAt = this.state.formEmpresa.updatedAt ? this.state.formEmpresa.updatedAt : null;

            if (!updatedAt) {
                updatedAt = ''
            };

            var empresaDataUpdate = {}

            empresaDataUpdate = {
                'id': "",
                'idEmpresa': "",
                'nif': this.state.formEmpresa.nif,
                'tipo': this.state.formEmpresa.tipo,
                'tipoServicio': this.state.formEmpresa.tipoServicio,
                'numOrden': this.state.formEmpresa.numOrden,
                'empresa': this.state.formEmpresa.empresa?.toUpperCase() || '',
                'direccion': this.state.formEmpresa.direccion?.toUpperCase() || '',
                'codigoPostal': this.state.formEmpresa.codigoPostal,
                'municipio': this.state.formEmpresa.municipio?.toUpperCase() || '',
                'provincia': this.state.formEmpresa.provincia?.toUpperCase() || '',
                'telefonoAtt': this.state.formEmpresa.telefonoAtt,
                'telefonoUrgencias': this.state.formEmpresa.telefonoUrgencias,
                'ambito': this.state.formEmpresa.ambito?.toUpperCase() || '',
                'fechaAlta': this.state.formEmpresa.fechaAlta,
                'fechaBaja': this.state.formEmpresa.fechaBaja,
                'web': this.state.formEmpresa.web,
                'estado': this.state.formEmpresa.estado,
                'correoContacto': this.state.formEmpresa.correoContacto,
                'nombreContacto': this.state.formEmpresa.nombreContacto?.toUpperCase() || '',
                'telefonoContacto': this.state.formEmpresa.telefonoContacto,
                'fechAltaDatos': this.state.formEmpresa.fechAltaDatos,
                'usuario': iduser
            };


            const headers = {
                
                'Content-Type': 'application/json',
            };
            let direccionFile = "https://api.nextniro.com/api/v1/gestion/empresacreate";


            axios.post(direccionFile, empresaDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    console.log('Actualización correcta', result)
                    console.log('Datpos del registro: ', result.data)
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        empresaSeleccionado: false,
                        empresaDetailTotal: [],
                        saveDataUsuario: false,
                        saveUsuarioButtonDisabled: true,
                        updateUsuarioButtonDisabled: true,
                        createdAt: '',
                        updatedAt: '',
                        toggledClearRows: !this.state.toggledClearRows,
                        formEmpresa: {
                            id: "",
                            idEmpresa: "",
                            nif: "",
                            tipo: "",
                            tipoServicio: "",
                            numOrden: "",
                            empresa: "",
                            direccion: "",
                            codigoPostal: "",
                            municipio: "",
                            provincia: "",
                            telefonoAtt: "",
                            telefonoUrgencias: "",
                            ambito: "",
                            fechaAlta: "",
                            fechaBaja: "",
                            web: "",
                            estado: "a",
                            correoContacto: "",
                            nombreContacto: "",
                            telefonoContacto: "",
                            fechAltaDatos: "",
                            usuario: ""
                        }
                    });
                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                      });
                  });
        }
    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formEmpresa };


        const name = id;
        var valueDay = formattedValue

        if (name == 'createdAt') {
            this.setState({ createdAt: value });

        }

        if (name == 'updatedAt') {
            this.setState({ updatedAt: value });
        }

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formData });
    };



    /////////////////////////////////////////////////////////////////////////////////

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }



    render() {

        const formEmpresa: any = this.state.formEmpresa;


        //const listItems = createResult.map((d) => <li key="{d.idUser}">{d.idUser}</li>);

        if (this.state.userGroupId == 9999) {
            return (
                <>
                    <Header title={'Alta Empresa'} />
                    <Container className=" mt--7" fluid>

                        <Card className="mb-4">
                            <CardHeader>
                                <Button
                                    onClick={this.showFiltersToggle}
                                    size="sm"
                                    className="float-right"
                                >
                                    <i className={"ni " + this.getFilterIcon()} />
                                </Button>
                                <h3 className="mb-0">Filtrar</h3>
                            </CardHeader>
                            <Collapse isOpen={this.state.showFilters}>
                                <CardBody>
                                    <Form innerRef={this.formRef}>
                                        <Row>

                                            <Col className="text-right">
                                                <Button onClick={this.clearForm}>
                                                    <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>

                                            </Col>
                                        </Row>

                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>

                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={this.props.history.goBack}
                                                >
                                                    Atrás
                                            </button>
                                            </Col>

                                            <Col className="col-auto d-flex">
                                                <Card className="p-3 bg-light">
                                                    <FormGroup>

                                                        <Button disabled={this.state.saveUsuarioButtonDisabled} id="saveRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                            onClick={() => { this.updateHandler(this.state.saveDataUsuario) }}
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-person-plus-fill" style={{ fontSize: 25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Añadir</span>
                                                            <UncontrolledTooltip delay={0} placement="top" target="saveRepreProptooltip">
                                                                AÑADIR EMPRESA AL REGISTRO
                                                    </UncontrolledTooltip>
                                                        </Button>


                                                    </FormGroup>
                                                </Card>
                                            </Col>

                                        </Row>
                                        <div>
                                            <CardTitle>DATOS GENERALES EMPRESA</CardTitle>
                                        </div>

                                        <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="id">
                                                    ID
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="id"
                                                    placeholder=""
                                                    name="nombre"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.id}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="empresa">
                                                    Razón Social
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="empresa"
                                                    placeholder=""
                                                    name="empresa"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.empresa}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="numOrden">
                                                    N Orden
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="numOrden"
                                                    placeholder=""
                                                    name="numOrden"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.numOrden}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="nif">
                                                    CIF
                                                </Label>
                                                <Input
                                                    id="nif"
                                                    placeholder=""
                                                    name="nif"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.nif}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                                {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="direccion">
                                                    Dirección
                                                </Label>
                                                <Input
                                                    id="direccion"
                                                    placeholder=""
                                                    name="direccion"
                                                    maxLength={150}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.direccion}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="codigoPostal">
                                                C. Postal
                                            </Label>
                                            <Input
                                                id="codigoPostal"
                                                placeholder=""
                                                name="codigoPostal"
                                                maxLength={150}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.codigoPostal}
                                                type="text"
                                                disabled={this.state.inputDisabled}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                                <Label className="form-control-label" htmlFor="ayuntamientoRef">
                                                    Municipio
                                                </Label>
                                                <Input type="select" defaultValue={'DEFAULT'} name="municipio" id="municipio" onChange={this.onChangeFieldUsuario}>
                                                    <option value="">Selecciona un municipio... </option>
                                                    {this.state.municipios.map((item, key) => (
                                                        <option key={key} value={item.key}>{item.value}</option>
                                                    ))
                                                    }

                                                </Input>
                                            </Col>

                                    <Col>
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="provincia">
                                                Provincia
                                            </Label>
                                            <Input
                                                id="provincia"
                                                placeholder=""
                                                name="provincia"
                                                maxLength={150}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.provincia}
                                                type="text"
                                                disabled={this.state.inputDisabled}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                <Col md="2">
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="nombreContacto">
                                                Nombre Contacto
                                            </Label>
                                            <Input
                                                className="text-primary"
                                                id="nombreContacto"
                                                placeholder=""
                                                name="nombreContacto"
                                                maxLength={80}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.nombreContacto}
                                                type="text"
                                                disabled={this.state.inputDisabled}
                                            />
                                        </FormGroup>
                                    </Col>


                                    <Col md="2">
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="telefonoContacto">
                                                Teléfono Contacto
                                            </Label>
                                            <Input
                                                className="text-primary"
                                                id="telefonoContacto"
                                                placeholder=""
                                                name="telefonoContacto"
                                                maxLength={20}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.telefonoContacto}
                                                type="text"
                                                disabled={this.state.inputDisabled}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="correoContacto">
                                                Correo Contacto
                                            </Label>
                                            <Input
                                                id="correoContacto"
                                                placeholder=""
                                                name="correoContacto"
                                                maxLength={80}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.correoContacto}
                                                type="text"
                                                disabled={this.state.inputDisabled}

                                            />
                                        </FormGroup>
                                    </Col>
                               
                                </Row>
                                    
                                    </CardBody>
                                </Card>

                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>OTROS DATOS</CardTitle>
                                        </div>

                                        <Row>
                                    <Col md="2" lg="2">
                                        <FormGroup>
                                            <Label className="form-control-label" for="tipo">Tipo Empresa</Label>
                                            <Input type="select" name="tipo" id="tipo"
                                                disabled={this.state.inputDisabled}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.tipo}>
                                                <option selected>Selecciona...</option>
                                                <option value="COM"> Comercializadora</option>
                                                <option value="DIS"> Distribuidora</option>
                                             </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md="2" lg="2">
                                        <FormGroup>
                                            <Label className="form-control-label" for="tipoverde">Tipo Servicio</Label>
                                            <Input type="select" name="tipoServicio" id="tipoServicio"
                                                disabled={this.state.inputDisabled}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.tipoServicio}>
                                                <option selected>Selecciona...</option>
                                                <option value="ELEC">Electricidad</option>
                                                <option value="GAS">Gas</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>




                                </Row>

                                    </CardBody>
                                </Card>
                                {this.state.alert}

                            </div>
                        </Row>
                    </Container>

                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"} />
                </>
            );
        }
    }
}

export default withApollo(crearEmpresas);
