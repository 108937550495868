//import Password from './Password'
//import { Mailer } from 'nodemailer-react'
import React from "react";
import { withRouter } from 'react-router-dom';
import { ResponsiveBar, Bar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import Reflux from "reflux";
import { Card, Container, CardTitle, CardBody, Input, Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import LoadingOverlay from "react-loading-overlay";
import { UserStore } from "../../store/UserStore";
import { removeToken } from "../../utils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../assets/scss/documento.scss";
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv";
import axios from 'axios';
import Header from "../../components/Headers/Header";


const columnsDatosAnualIngresos = [
    {
        selector: row => row['year'], name: 'Año', sortable: false, width: '100px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        selector: row => row['tasaTotal'], name: 'Total Tasas', sortable: false, width: '180px', center: true, format: (row => `${Number(row['tasaTotal'] ? row['tasaTotal'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    },
    {
        selector: row => row['tasasTotalesElectricidad'], name: 'Tasas Elec.', sortable: false, width: '180px', center: true, format: (row => `${Number(row['tasasTotalesElectricidad'] ? row['tasasTotalesElectricidad'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    },
    {
        selector: row => row['tasasTotalesGas'], name: 'Tasas Gas', sortable: false, width: '180px', center: true, format: (row => `${Number(row['tasasTotalesGas'] ? row['tasasTotalesGas'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    },
    {
        selector: row => row['energiaElectricidad'], name: 'Mw Electricidad', sortable: false, width: '180px', center: true, format: (row => `${Number(row['energiaElectricidad'] ? row['energiaElectricidad'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
    {
        selector: row => row['costeTasasDistribuidoraElectricidad'], name: 'Tasas Distr. Elec.', sortable: false, width: '180px', center: true, format: (row => `${Number(row['costeTasasDistribuidoraElectricidad'] ? row['costeTasasDistribuidoraElectricidad'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    },
    {
        selector: row => row['costeTasasComercializadoraElectricidad'], name: 'Tasas Comerc. Elec.', sortable: false, width: '180px', center: true, format: (row => `${Number(row['costeTasasComercializadoraElectricidad'] ? row['costeTasasComercializadoraElectricidad'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    },
    {
        selector: row => row['energiaGas'], name: 'Mw Gas', sortable: false, width: '180px', center: true, format: (row => `${Number(row['energiaGas'] ? row['energiaGas'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
    {
        selector: row => row['costeTasasDistribuidoraGas'], name: 'Tasas Distr. Gas', sortable: false, width: '180px', center: true, format: (row => `${Number(row['costeTasasDistribuidoraGas'] ? row['costeTasasDistribuidoraGas'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    },
    {
        selector: row => row['costeTasasComercializadoraGas'], name: 'Tasas Comerc. Gas', sortable: false, width: '180px', center: true, format: (row => `${Number(row['costeTasasComercializadoraGas'] ? row['costeTasasComercializadoraGas'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    }
];

const notify = () => toast.success(
    "Correo enviado correctamente",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyError = () => toast.error(
    "Existe un problema con el servicio de correos",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyEnvio = () => toast.info(
    "Preparando el fichero para su envío por correo",
    {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const comercialProductoFields = {
    periodo: "Periodo",
    precioPotencia: "Precio potencia €/kW día",
    precioEnergia: "Precio Energía €/kWh"
};

const comercialProductoExpandableFields = {};

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;',
            fontSize: '14px'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '14px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0
    dataWithArc.forEach(datum => {
        total += datum.value
    })

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '28px',
                fontWeight: 500,
            }}
        >
            {total.toLocaleString('es-ES', {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                useGrouping: true
                                            }) } €
        </text>
    )
}


class informeResumenMunicipioConsultorActual extends Reflux.Component {
    public container: any = null;
    public fileInput: any = null;
    public pdfExportComponent: any = null;

    public formProductoRef: any = null;
    public state: any = {
        id: "",
        compania: "",
        municipioBuscar: "00000",
        tabSelected: 2019,
        tabSelectedString: "2019",
        añosActual: 2019,
        outputDatosAnalisis: [{ id: "", value: 0 }],
        outputDatosTarifa: [{ id: "", value: 0 }],
        consumosAnuales: [{
            year: 2019,
            data: [
                {
                    codigoTarifaATR: 1,
                    P1: 0,
                    P2: 0,
                    P3: 0,
                    P4: 0,
                    P5: 0,
                    P6: 0
                },
            ]
        }],
        ingresosComercializadoras: [
            {
                year: 2019,
                porcentajeComercializadoraEnergia: [
                    {
                        comercializadora: "Sin datos",
                        cups: 0,
                        porcentaje: 0,
                        energiaMW: 0,
                        costeComercializadoraEnergia: 0,
                        tasaComercializadora: 0
                    }
                ]
            }],
        consumosAnualesIngresos: [
            {
                year: 2019,
                porcentajeComercializadoraEnergia: [
                    {
                        comercializadora: "Sin datos",
                        cups: 0,
                        porcentaje: 0,
                        energiaMW: 0,
                        costeComercializadoraEnergia: 0,
                        tasaComercializadora: 0
                    }
                ]
            }],
        resumenEnergia: [
            {
                id: 2019,
                value: 0
            }],
        resumenIngresos: [
            {
                year: 2019,
                value: 0

            }],
        consumosPotenciaAnuales: [{
            year: 2019,
            data: [
                {
                    codigoTarifaATR: 1,
                    P1: 0,
                    P2: 0,
                    P3: 0,
                    P4: 0,
                    P5: 0,
                    P6: 0
                },
            ]
        }],
        consumosAnualesPotenciaIngresos: [
            {
                year: 2019,
                costePotencia: [
                    {
                        P1: 0,
                        P2: 0,
                        P3: 0,
                        P4: 0,
                        P5: 0,
                        P6: 0
                    }
                ]
            }],
        resumenPotencia: [
            {
                year: 2019,
                costeEnergiaTotal: 0,
                costeEnergiaTotalP1: 0,
                costeEnergiaTotalP2: 0,
                costeEnergiaTotalP3: 0,
                costeEnergiaTotalP4: 0,
                costeEnergiaTotalP5: 0,
                costeEnergiaTotalP6: 0
            }],
        tasasResumenDistribuidoraMunicipio: {
            dataAyuntamientoConsumos: [
                {
                    consumosanuales: [{
                        year: 2019,
                        data: [
                            {
                                codigoTarifaATR: 1,
                                P1: 0,
                                P2: 0,
                                P3: 0,
                                P4: 0,
                                P5: 0,
                                P6: 0
                            },
                        ]
                    }],
                    datosPrecioEnergia: [
                        {
                            year: 2019,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2019,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2020,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2021,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2022,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        }
                    ],
                    costeEnergiaResumen: [
                        {
                            year: 2019,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        },
                        {
                            year: 2019,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        },
                        {
                            year: 2020,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        },
                        {
                            year: 2021,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        },
                        {
                            year: 2022,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        }
                    ],
                    potenciasanuales: [{
                        year: 2019,
                        data: [
                            {
                                codigoTarifaATR: 1,
                                P1: 0,
                                P2: 0,
                                P3: 0,
                                P4: 0,
                                P5: 0,
                                P6: 0
                            },
                        ]
                    }],
                    datosPrecioPotencia: [
                        {
                            year: 2019,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2019,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2020,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2021,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2022,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        tasasResumenMunicipio:
        {
            distribuidora: "",
            municipio: "",
            provincia: "",
            autonomia: "",
            consumoenergiatotal: 0,
            cups: 0,
            datosPerfil: [
                {
                    id: "",
                    consumoTotalPerfil: 0,
                    cups: 0,
                    porcentaje: 0
                }
            ],
            datosTarifa: [
                {
                    id: "",
                    consumoTotalTarifa: 0,
                    cups: 0,
                    porcentaje: 0
                }
            ],
            datosAnalisis: [
                {
                    tarifa: "",
                    cups: "0",
                    energiatarifa: "",
                    menor5mw: "",
                    energiamenor5mw: "",
                    menor10mw: "",
                    energiamenor10mw: "",
                    menor20mw: "",
                    energiamenor20mw: "",
                    menor9999MW: "",
                    energiamenor9999MW: "",
                    sindatos: "",
                    energiasindatos: ""
                }
            ]
        },
        outputDatosAnalisisPotencia: [{ id: "", value: 0 }],
        municipios: [{ key: "", value: 0 }],
        // --- Data
        isLoading: false,
        correoContacto: '',
        nombreFichero: '',
        datafile: "",
        uploadedFileContents: null,
        control: 0,
    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.container = React.createRef<HTMLDivElement>();
        this.logout = this.logout.bind(this);
        this.toggleNavs = this.toggleNavs.bind(this);
    }


    componentDidMount() {

        this.formProductoRef = React.createRef();

        var now = new Date();
        var añoActual = now.getFullYear();
        const user = localStorage.getItem("user");
        if (user) {
            const parsed = JSON.parse(user);
            this.setState({
                user: parsed,
                añosActual: añoActual
            });
        }

        this.setState({
            ...this.state,
        });

        this.setState({
            control: 1
        }
        );
    }

    toggleNavs = (tab) => {
        //event.preventDefault();

        if (this.state.activeTab !== tab) {
            this.setState({
                tabSelected: tab,
                tabSelectedString: tab.toString(),
            });
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    fetchDataMunicipio = async () => {

        this.setState({
            isLoading: true,
        })

        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")
        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))
        const user: any = localStorage.getItem("user");
        const userID = localStorage.getItem("uuidUser")

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        const municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        var tasasResumen: any = []

        var dataFilters = {
            'codigoINE': codigoINE,
            //'codigoINE': '04069',
            'usuario': userID
        };

        const valores = JSON.stringify(dataFilters)

        const headers = {
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/tasas/createinfobaseresumenmixto/actual";

        axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {
                var tasasResumenDistribuidoraMunicipio = result.data.dataAyuntamientoIngresosResumen;
                var resumenIngresos = tasasResumenDistribuidoraMunicipio[0].costesTasaAnual
                var xMax = Math.max.apply(null, resumenIngresos.map(function (o) { return parseInt(o.year); }));

                var outputDatosAnalisis: any = [];

                var outputDatosTarifa: any = [];
                for (var iAnalisis = 0; iAnalisis < 2; ++iAnalisis) {
                    var id = ''
                    var valor = 0
                    if(iAnalisis== 0){
                        id = 'Tasas Totales Electricidad'
                        valor = resumenIngresos[0].tasasTotalesElectricidad
                    } else if(iAnalisis == 1){
                        id = 'Tasas Totales Gas'
                        valor = resumenIngresos[0].tasasTotalesGas
                    }

                    outputDatosAnalisis.push({ id: id, value: valor })
                }

                for (var iEnergia = 0; iEnergia < 2; ++iEnergia) {
                    var id = ''
                    var valor = 0
                    if(iEnergia== 0){
                        id = 'Total Energía Electricidad'
                        valor = resumenIngresos[0].energiaElectricidad
                    } else if(iEnergia == 1){
                        id = 'Total Energia Gas'
                        valor = resumenIngresos[0].energiaGas
                    }

                    outputDatosTarifa.push({ id: id, value: valor })
                }

                this.setState({
                    tasasResumenDistribuidoraMunicipio: tasasResumenDistribuidoraMunicipio,
                    resumenIngresos: resumenIngresos,
                    outputDatosAnalisis: outputDatosAnalisis,
                    outputDatosTarifa: outputDatosTarifa,
                    tabSelected: xMax,
                    tabSelectedString: xMax.toString(),
                    isLoading: false,
                })

                //this.clearForm();
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    /////////////////////////////////////////////////////////////////////////////////

    onChangeField = (event) => {

        event.persist()


        const name = event.target.name;
        let value = event.target.value;
        // var setFilters = this.state.setFilters;

        //setFilters[name] = value;
        if (event.target.type === "select-one" && value === "0") {
            //delete setFilters[name];
        }

        if (value === "" || value === null) {
            //delete setFilters[name];
        }

        this.setState({ municipioBuscar: value });
    };

    //////////////////////////////////////////////////////////////

    renderRates() {
        const styleFullWith = { "width": "100%" };

        var indexyear = this.state.resumenIngresos.map(object => object.year).indexOf(this.state.tabSelected)
        var resumenIngresos: any = []
        var resumenIngresosAño: any = []

        resumenIngresosAño.push({ ...this.state.resumenIngresos[indexyear] })

        var costeTasasComercializadoraElectricidad = 0
        var costeTasasComercializadoraGas = 0
        var costeTasasDistribuidoraElectricidad = 0
        var costeTasasDistribuidoraGas = 0
        var energiaElectricidad = 0
        var energiaGas = 0
        var tasaTotal = 0
        var tasasTotalesElectricidad = 0
        var tasasTotalesGas = 0
        var year = 0

        if (indexyear !== -1) {
            let id = this.state.resumenIngresos[indexyear].year
            let value = this.state.resumenIngresos[indexyear].tasaTotal ? this.state.resumenIngresos[indexyear].tasaTotal : 0
            costeTasasComercializadoraElectricidad = this.state.resumenIngresos[indexyear].costeTasasComercializadoraElectricidad ? this.state.resumenIngresos[indexyear].costeTasasComercializadoraElectricidad : 0
            costeTasasDistribuidoraElectricidad = this.state.resumenIngresos[indexyear].costeTasasDistribuidoraElectricidad ? this.state.resumenIngresos[indexyear].costeTasasDistribuidoraElectricidad : 0
            energiaElectricidad = (this.state.resumenIngresos[indexyear].energiaElectricidad ? this.state.resumenIngresos[indexyear].energiaElectricidad : 0) / 1000

            costeTasasComercializadoraGas = this.state.resumenIngresos[indexyear].costeTasasComercializadoraGas ? this.state.resumenIngresos[indexyear].costeTasasComercializadoraGas : 0
            costeTasasDistribuidoraGas = this.state.resumenIngresos[indexyear].costeTasasDistribuidoraGas ? this.state.resumenIngresos[indexyear].costeTasasDistribuidoraGas : 0
            energiaGas = (this.state.resumenIngresos[indexyear].energiaGas ? this.state.resumenIngresos[indexyear].energiaGas : 0) / 1000
            tasaTotal = this.state.resumenIngresos[indexyear].tasaTotal ? this.state.resumenIngresos[indexyear].tasaTotal : 0

            tasasTotalesElectricidad = this.state.resumenIngresos[indexyear].tasasTotalesElectricidad ? this.state.resumenIngresos[indexyear].tasasTotalesElectricidad : 0
            tasasTotalesGas = this.state.resumenIngresos[indexyear].tasasTotalesGas ? this.state.resumenIngresos[indexyear].tasasTotalesGas : 0

            resumenIngresos.push({ id: id, value: value })
        }

        return (
            <React.Fragment> 
                <Container className=" mt-3" fluid>
                           

                <Row>

                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardTitle className="info-title"><h5>Electricidad</h5></CardTitle>
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            TASAS
                                        </CardTitle>
                                        <span className="h3 font-weight-bold mb-0">
                                            {costeTasasComercializadoraElectricidad.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €

                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                            <i className="bi bi-currency-euro" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    <span className="text-nowrap">Comercializadora</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardTitle className="info-title"><h5>Electricidad</h5></CardTitle>
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            TASAS
                                        </CardTitle>
                                        <span className="h3 font-weight-bold mb-0">
                                            {costeTasasDistribuidoraElectricidad.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €
                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                            <i className="bi bi-currency-euro" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Distribuidora</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardTitle className="info-title"><h5>Electricidad</h5></CardTitle>
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            ENERGIA
                                        </CardTitle>
                                        <span className="h3 font-weight-bold mb-0">

                                            {energiaElectricidad.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} Gw

                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                            <i className="bi bi-lightning-charge" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Energia Distribuida (Gw) </span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            INGRESOS TOTALES
                                        </CardTitle>
                                        <span className="h3 font-weight-bold mb-0">
                                            {tasaTotal.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €

                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                            <i className="bi bi-currency-euro" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Total suma tasas</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>

                <Row>


                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardTitle className="info-title"><h5>Gas</h5></CardTitle>
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            TASAS
                                        </CardTitle>
                                        <span className="h3 font-weight-bold mb-0">
                                            {costeTasasComercializadoraGas.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €

                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                            <i className="bi bi-currency-euro" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    <span className="text-nowrap">Comercializadora</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardTitle className="info-title"><h5>Gas</h5></CardTitle>
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            TASAS
                                        </CardTitle>
                                        <span className="h3 font-weight-bold mb-0">
                                            {costeTasasDistribuidoraGas.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €
                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                            <i className="bi bi-currency-euro" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Distribuidora</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardTitle className="info-title"><h5>Gas</h5></CardTitle>
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            ENERGIA
                                        </CardTitle>
                                        <span className="h3 font-weight-bold mb-0">

                                            {energiaGas.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} Gw

                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                            <i className="bi bi-lightning-charge" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Energia Distribuida (Gw) </span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </Container>

            </React.Fragment>
        )
    };


    //////////////////////////////////////////////////////////////

    render() {
        const isLoading = this.state.isLoading;

        const styleFullWith = { "width": "100%" };

        const resumenIngresos = this.state.resumenIngresos;

        const ratesHtml = this.renderRates();

        const municipio = this.state.municipioBuscar

        const nombreFichero = municipio+"_listadoResumenIngresosDetalle.csv"
        //              fileName={`Report for ${cups} ${new Date().getFullYear()}`}
     
        var dataEnergiaChart: any = [];

        for (var index = 0; index < this.state.resumenIngresos.length; ++index) {
            let id = this.state.resumenIngresos[index].year;
            let value = this.state.resumenIngresos[index].energia / 1000;
            value = value ? value : 0
            dataEnergiaChart.push({ id: id, value: value })
        }

        var dataEnergiacsv: any = [];

        for (var index = 0; index < this.state.resumenIngresos.length; ++index) {

            let año = this.state.resumenIngresos[index].year;
            let electricidadEnergiaGw = this.state.resumenIngresos[index].energiaElectricidad / 1000;
            let gasEnergiaGw = this.state.resumenIngresos[index].energiaGas / 1000;

            let tasaTotal = this.state.resumenIngresos[index].tasaTotal ? this.state.resumenIngresos[index].tasaTotal.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            }) : "0"
            let tasasTotalesElectricidad = this.state.resumenIngresos[index].tasasTotalesElectricidad ? this.state.resumenIngresos[index].tasasTotalesElectricidad.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            }) : 0
            let tasasTotalesGas = this.state.resumenIngresos[index].tasasTotalesGas ? this.state.resumenIngresos[index].tasasTotalesGas.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            }) : 0

            let importeTasasComercializadoraElectricidad = this.state.resumenIngresos[index].costeTasasComercializadoraElectricidad ? this.state.resumenIngresos[index].costeTasasComercializadoraElectricidad.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            }) : "0"
            let importeTasasComercializadoraGas = this.state.resumenIngresos[index].costeTasasComercializadoraGas ? this.state.resumenIngresos[index].costeTasasComercializadoraGas.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            }) : "0"
            let importeTasasDistribuidoraElectricidad = this.state.resumenIngresos[index].costeTasasDistribuidoraElectricidad ? this.state.resumenIngresos[index].costeTasasDistribuidoraElectricidad.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            }) : 0
            let importeTasasDistribuidoraGas = this.state.resumenIngresos[index].costeTasasDistribuidoraGas ? this.state.resumenIngresos[index].costeTasasDistribuidoraGas.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            }) : 0
            let electricidadEnergiaGwString: any = electricidadEnergiaGw ? electricidadEnergiaGw.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            }) : "0"
            let gasEnergiaGwString: any = gasEnergiaGw ? gasEnergiaGw.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            }) : "0"
            dataEnergiacsv.push({ año: año, tasaTotal: tasaTotal, tasasTotalGas: tasasTotalesGas, tasasTotalElectricidad: tasasTotalesElectricidad, electricidadEnergiaGw: electricidadEnergiaGwString, importeTasasComercializadoraElectricidad: importeTasasComercializadoraElectricidad, importeTasasDistribuidoraElectricidad: importeTasasDistribuidoraElectricidad, gasEnergiaGw: gasEnergiaGwString, importeTasasComercializadoraGas: importeTasasComercializadoraGas, importeTasasDistribuidoraGas: importeTasasDistribuidoraGas })

        };

        var now = new Date();
        var añoActual = now.getFullYear();

        var outputDatosAnalisis=  this.state.outputDatosAnalisis
        var outputDatosTarifa = this.state.outputDatosTarifa

        console.log('valor de this.state: ', this.state)

        return (
            <>
                <Header title={'Información Resumen Tasas Electricidad y Gas. Año: '+añoActual} />
                <Container className=" mt--7" fluid>
                    <Card className="mb-4">
                        <CardBody>
                            <Row>

                                <Col className="text-right" md="2">
                                    <Button
                                        color="primary"
                                        onClick={this.fetchDataMunicipio}
                                    >
                                        <i className={"bi bi-search"} /> Analizar
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
                <LoadingOverlay
                        active={isLoading}
                        spinner
                        text="Cargando...">


                    {ratesHtml}

                    <div>

                    <Container className=" mt-3" fluid>

                            <Row className="card-deck" style={styleFullWith}>
                                <Card className="mt-3 mb-3 p-3">
                                    <Col md="12" xl="12">
                                        <CardTitle className="info-title"><h2>Ingresos Aproximados Tasas Año Actual</h2></CardTitle>
                                        <Card className="card-stats" style={{ height: 400 }}>
                                        <ResponsivePie
                                                data={outputDatosAnalisis}
                                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                colors={{ scheme: 'nivo' }}
                                                innerRadius={0.5}
                                                padAngle={0.7}
                                                cornerRadius={3}
                                                activeOuterRadiusOffset={8}
                                                borderWidth={1}
                                                borderColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            0.2
                                                        ]
                                                    ]
                                                }}
                                                arcLinkLabelsSkipAngle={10}
                                                arcLinkLabelsTextColor="#333333"
                                                arcLinkLabelsThickness={2}
                                                arcLinkLabelsColor={{ from: 'color' }}
                                                arcLabelsSkipAngle={10}
                                                arcLabelsTextColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            2
                                                        ]
                                                    ]
                                                }}
                                                layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
                                                valueFormat={value =>
                                                    `${Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                        useGrouping: true
                                                    })} €`
                                                }
                                                legends={[
                                                    {
                                                        anchor: 'bottom',
                                                        direction: 'row',
                                                        justify: false,
                                                        translateX: 36,
                                                        translateY: 56,
                                                        itemsSpacing: 0,
                                                        itemWidth: 200,
                                                        itemHeight: 18,
                                                        itemTextColor: '#999',
                                                        itemDirection: 'left-to-right',
                                                        itemOpacity: 1,
                                                        symbolSize: 18,
                                                        symbolShape: 'circle',
                                                        effects: [
                                                            {
                                                                on: 'hover',
                                                                style: {
                                                                    itemTextColor: '#000'
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]}
                                            />
                                        </Card>
                                    </Col>
                                </Card>
                            </Row>

            

                            <Row className="card-deck" style={styleFullWith}>
                                <Card className="mt-3 mb-3 p-3">
                                    <Col md="12" xl="12">
                                        <CardTitle className="info-title"><h2>Energia Comercializada En El Municipio (Mw)</h2></CardTitle>
                                        <Card className="card-stats" style={{ height: 400 }}>
                                        <ResponsiveBar
                                                layout="horizontal"
                                                margin={{ top: 26, right: 120, bottom: 26, left: 200 }}
                                                data={outputDatosTarifa}
                                                indexBy="id"
                                                keys={['value']}
                                                colors={{ scheme: 'nivo' }}
                                                colorBy="indexValue"
                                                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                                                enableGridX
                                                enableGridY={false}
                                                axisTop={{
                                                    format: '~s',
                                                }}

                                                valueFormat={value =>
                                                    `${(Number(value)/1000).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                        useGrouping: true
                                                    })} Gw`
                                                }

                                                axisBottom={null}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                padding={0.3}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
                                            />
                                        </Card>
                                    </Col>
                                </Card>
                            </Row>
                        </Container>

                    </div>
                    </LoadingOverlay>

                <div>

                        <Container className=" mt-3" fluid>
                            <Row>
                                <Col md="6" xl="12">
                                    <Card className="mt- mb-3">
                                        <CardBody>
                                            <Row className="">
                                                <h2 className="col">
                                                    Datos Análisis Ingreso Previsto Tasa - Año actual
                                                </h2>
                                            </Row>
                                            <Row>
                                                <DataTable
                                                    columns={columnsDatosAnualIngresos}
                                                    data={resumenIngresos}
                                                    fixedHeader={true}
                                                    striped
                                                    pointerOnHover
                                                    customStyles={customStyles}
                                                    noDataComponent={"Sin datos para mostrar"}
                                                    subHeader
                                                    subHeaderComponent={
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <CSVLink
                                                                data={dataEnergiacsv}
                                                                filename={nombreFichero}
                                                                className="btn btn-default btn-sm btn-circle"
                                                                color="default"
                                                                target="_blank"
                                                                separator={";"}
                                                            >
                                                                <span className="btn-inner--icon">
                                                                    <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text">Fichero CSV</span>

                                                            </CSVLink>
                                                        </div>
                                                    }
                                                />
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </Container>
                   
                </div>
              
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

export default withRouter(informeResumenMunicipioConsultorActual);
