import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import Reflux from "reflux";
import { UserStore } from "../store/UserStore";
import { removeToken } from "../utils";
import { ThunderboltFilled, FireFilled } from "@ant-design/icons";
import listaSubmin from "../assets/img/icons/custom/lista_submin.svg";
import listaSubminGas from "../assets/img/icons/custom/lista_submin_gas.svg";
import listaEmpresas from "../assets/img/icons/custom/lista_empresas.svg";
import listadoPS from "../assets/img/icons/custom/listadoPS.svg";
import propuestaMulti from "../assets/img/icons/custom/propuestaMulti.svg";
import gestionPropuestas from "../assets/img/icons/custom/gestion_propuestas.svg";
import gestionContratos from "../assets/img/icons/custom/gestion_contratos.svg";
import HeaderDash from "../components/Headers/HeaderDash";
//import Header from "../components/Headers/StatisticHeader";
//import GetUserData from "../components/User/GetUserData";
//import {
//  GET_COMERCIAL_RATE,
//  GET_COMERCIAL_RATE_CHART,
//} from "../queries/comercial/rate";
//import numberWithPoints from "../utils/numberWithPoints";
//import { chartOptions } from "../variables/charts";

class Index extends React.Component {
  state = {
    UserStore: {},
    companyIdUser: "",
    userName: "",
    companyNameUser: "",
    userGroupId: 1000,
    userId: "",
    idCard: "",
    first_name1: "",
    last_name1: "",
    municipio: "",
    isLoading2: false,
    tasasYear: 0,
    isLoading: false,
    dataResumenTasas: [],
    events: [
      {
        title: null,
        start: null,
        end: null,
        className: null,
        id: null,
      },
    ],
    alert: null,
    isLoadingData: true
  };

  constructor(props) {
    super(props);
    this.store = UserStore;
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {

    const userID = localStorage.getItem("uuidUser");
    const token = localStorage.getItem("token");

    console.log('valor de userID: ', userID, ' token: ', token)

    const companyNameGlobal = localStorage.getItem("ayuntamiento")
    const companyIdUser = localStorage.getItem("codigoINE");

    const userGroupId = localStorage.getItem("user_group_id");
    const userId = localStorage.getItem("id");
    const userName = localStorage.getItem("nombre") + " " + localStorage.getItem("apellidos");
    const idCard = localStorage.getItem("email");

    let companyNameUser = localStorage.getItem("ayuntamiento");

    if (userGroupId === "1000") {
      this.setState({
        activeUser: true,
      });
    }
/*
    apellidos    :     "DEMO"
    app_logo    :     ""
    app_logo_dir    :     ""
    attempts_login    :     0
    ayuntamiento    :     "Campo de Criptana"
    codigoINE    :     "13028"
    companyId    :     0
    createdAt    :     "2023-07-18T19:10:31"
    detalles    :     true
    email    :     "demo@nextniro.com"
    esActiva    :     true
    esAdmin    :     false
    esComercial    :     false
    esConsultor    :     false
    esFuncionario    :     true
    id    :     14
    idEmpresa    :     "1001"
    informes    :     true
    locked_up    :     null
    modifiedAt    :     "2023-07-19T08:43:15"
    nif    :     "B86074903"
    nombre    :     "DEMO"
    password    :     "$2b$10$szVYjJ8io3BhE8jFE1M5SuXQ3BP/bcBMZxunew1MM/CqAOldwjzrO"
    profile_photo    :     ""
    profile_photo_dir    :     ""
    query_limit    :     0
    reset_token    :     ""
    reset_token_expire    :     ""
    telefono    :     ""
    userId    :     "USU0000142023-1001"
    userUuid    :     "cec10be2-9c82-4174-a8fb-06bf7fae7353"
    user_group_id    :     "4000"
*/


    this.setState({
      companyNameGlobal,
      companyIdUser,
      userGroupId,
      userId,
      userName,
      companyNameUser,
      idCard,
    });

   // this.getUserData();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  getUserData() {
    const userID = localStorage.getItem("uuidUser");
    const token = localStorage.getItem("token");
    var user: any = [];
    var dataFilters = {
      userId: userID,
    };
    const valores = JSON.stringify(dataFilters);
    const headers = {
      // 'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    let direccionFile = "https://api.nextniro.com/api/v1/auth/usuario";

    axios
      .get(direccionFile, { headers, params: { valores } })
      .then((result) => {
        user = result.data;

        this.setState({
          first_name1: user.user.nombre,
          last_name1: user.user.apellidos,
          municipio: user.user.ayuntamiento,
        });
      })
      .catch((error) => {
        if (!error.response) {
          // network error
          this.setState({
            loading: false,
            loginError: false,
            networkError: true,
            sessionError: false,
            userBlockedError: false,
          });
        } else {
          // http status code
          const code = error.response.status;
          // response data
          const response = error.response.data;
          if (code == "403") {
            this.setState({
              loading: false,
              loginError: true,
              networkError: false,
              sessionError: false,
              userBlockedError: false,
            });
          }
          if (code == "401") {
            this.logout();
          }
        }
      });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  logout = async () => {
    removeToken();
    localStorage.removeItem("lastFilters");
    localStorage.removeItem("user");
    localStorage.removeItem("lastFiltersEmpre");
    localStorage.removeItem("lastFiltersMulti");
    localStorage.removeItem("lastFiltersMultiGas");
    localStorage.removeItem("lastFiltersGas");
    this.props.history.push(`/auth/login`);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    console.log('entra en render')

    return (
      <>
        <HeaderDash title={'Accesos directos'} />

        <Container className="mt--7" fluid>
          <Card className="mb-4">
          <CardBody>

<Row>
<Col className="px-1 py-1" sm="4" md={true}>
                  <Link
                    to="/admin/resumeningresosestudio"
                    id="resumeningresosestudio"
                  >
                    <Card className="card-stats mb-4 mb-xl-0 h-100 btn p-1">
                      <CardBody className="p-0">
                        <Row>
                          <ThunderboltFilled
                            style={{ fontSize: "14px", color: "#ffffff" }}
                            className="col-auto ml-auto mr-3 p-1 align-self-end badge rounded-circle bg-yellow "
                          />
                        </Row>
                        <Row>
                          <Col className="col col-8 m-auto text-center ">
                            <h3>
                              <img src={listaSubmin} />
                            </h3>
                          </Col>
                        </Row>
                        <Row className="m-auto">
                          <div className="col">
                            <CardTitle className="text-center mb-0">
                              Tasas - Resumen electricidad
                            </CardTitle>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
  
<Col className="px-1 py-1" sm="4" md={true}>
                  <Link to={{ pathname: "empresa/" }} id="empresa">
                    <Card className="card-stats mb-4 mb-xl-0 h-100 btn p-1">
                      <CardBody className="p-0">
                        <Row>
                          <FireFilled
                            style={{ fontSize: "14px", color: "#ffffff" }}
                            className="col-auto ml-auto mr-0 align-self-end badge rounded-circle bg-blue p-1"
                          />
                          <ThunderboltFilled
                            style={{ fontSize: "14px", color: "#ffffff" }}
                            className="col-auto mr-3 align-self-end badge rounded-circle bg-yellow p-1"
                          />
                        </Row>
                        <Row>
                          <Col className="col col-8 m-auto text-center ">
                            <h1>
                              <img src={listaEmpresas} />
                            </h1>
                          </Col>
                        </Row>
                        <Row className="m-auto">
                          <div className="col">
                            <CardTitle className="text-center mb-0">
                              Listado empresas servicios
                            </CardTitle>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
<Col className="px-1 py-1" sm="4" md={true}>
                  <Link
                    to={{ pathname: "consultortasaspre/" }}
                    id="tasas-estudio-pre"
                  >
                    <Card className="card-stats mb-4 mb-xl-0 h-100 btn p-1">
                      <CardBody className="p-0">
                        <Row>
                          <FireFilled
                            style={{ fontSize: "14px", color: "#ffffff" }}
                            className="col-auto ml-auto mr-0 align-self-end badge rounded-circle bg-blue p-1"
                          />
                          <ThunderboltFilled
                            style={{ fontSize: "14px", color: "#ffffff" }}
                            className="col-auto mr-3 align-self-end badge rounded-circle bg-yellow p-1"
                          />
                        </Row>
                        <Row>
                          <Col className="col col-8 m-auto text-center ">
                            <h1>
                              <img src={listadoPS} />
                            </h1>
                          </Col>
                        </Row>
                        <Row className="m-auto">
                          <div className="col">
                            <CardTitle className="text-center mb-0">
                              Estudio Preliminar
                            </CardTitle>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col className="px-1 py-1" sm="4" md={true}>
                  <Link
                    to={{ pathname: "seguimientoexpedientescomunicaciones/" }}
                    id="gestio-contrato"
                  >
                    <Card className="card-stats mb-4 mb-xl-0 h-100 btn p-1">
                      <CardBody className="p-0">
                        <Row>
                          <ThunderboltFilled
                            style={{ fontSize: "14px", color: "#ffffff" }}
                            className="col-auto ml-auto mr-3 align-self-end badge rounded-circle bg-yellow p-1"
                          />
                        </Row>
                        <Row>
                          <Col className="col col-8 m-auto text-center">
                            <h1>
                              <img src={gestionContratos} />
                            </h1>
                          </Col>
                        </Row>
                        <Row className="m-auto">
                          <div className="col">
                            <CardTitle className="text-center mb-0">
                              Seguimiento Exp.
                            </CardTitle>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                </Row>
                </CardBody>


          </Card>

        </Container>
      </>
    );
  }
}


export default Index;
