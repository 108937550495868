//import Password from './Password'
//import { Mailer } from 'nodemailer-react'
import React from "react";
import { ResponsiveBar } from '@nivo/bar'
import Reflux from "reflux";
import { withApollo } from "react-apollo";
import { Card, Container, CardTitle, CardBody, Input, Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink, } from "reactstrap";
import classnames from "classnames";
import LoadingOverlay from "react-loading-overlay";
import { UserStore } from "../../store/UserStore";
import { removeToken } from "../../utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../assets/scss/documento.scss";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Header from "../../components/Headers/Header";

const columnsDatosConsumosAnualGas = [
    {
        selector: row => row['tarifaATR'], name: 'TARIFA', sortable: false, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['cupsTarifa'], name: 'CUPS', sortable: false, width: '100px', center: true },
    { selector: row => row['P1'], name: 'P1', sortable: false, width: '100px', center: true,  format: (row => `${(Number(row['P1'] ? row['P1'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`)},
];


const columnsDatosConsumosAnual = [
    {
        selector: row => row['tarifaATR'], name: 'TARIFA', sortable: false, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['P1'], name: 'P1', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P1'] ? row['P1'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P2'], name: 'P2', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P2'] ? row['P2'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P3'], name: 'P3', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P3'] ? row['P3'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P4'], name: 'P4', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P4'] ? row['P4'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P5'], name: 'P5', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P5'] ? row['P5'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P6'], name: 'P6', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P6'] ? row['P6'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
];

const columnsDatosConsumosAnualIngresos = [
    {
        selector: row => row['tarifaDesc'], name: 'TARIFA', sortable: false, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['P1'], name: 'P1', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P1'] ? row['P1'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P2'], name: 'P2', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P2'] ? row['P2'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P3'], name: 'P3', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P3'] ? row['P3'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P4'], name: 'P4', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P4'] ? row['P4'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P5'], name: 'P5', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P5'] ? row['P5'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
    { selector: row => row['P6'], name: 'P6', sortable: false, width: '100px', center: true, format: (row => `${(Number(row['P6'] ? row['P6'] : 0)/1000).toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    })} Mw`) },
];


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



class informePreliminarMunicipioDistribuidoraConsultor extends Reflux.Component {
    public container: any = null;


    public formProductoRef: any = null;
    public state: any = {
        id: "",
        compania: "",
        municipioBuscar: "00000",
        tabSelected: 2019,
        tabSelectedString: "2019",
        consumosAnuales: [{
            year: 2019,
            data: [
                {
                    codigoTarifaATR: 1,
                    P1: 0,
                    P2: 0,
                    P3: 0,
                    P4: 0,
                    P5: 0,
                    P6: 0
                },
            ]
        }],
        consumosAnualesGas: [{
            year: 2019,
            data: [
                {
                    codigoTarifaATR: "0",
                    P1: 0
                },
            ]
        }],
        consumosAnualesIngresos: [
            {
                year: 2019,
                costeEnergia: [
                    {
                        tarifa: 1,
                        P1: 0,
                        P2: 0,
                        P3: 0,
                        P4: 0,
                        P5: 0,
                        P6: 0
                    }
                ]
            }],
            consumosAnualesIngresosGas: [
                {
                    year: 2019,
                    costeEnergiaGas: [
                        {
                            tarifa :"1",
                            alquiler: 0,
                            cups: 0,
                            peajesFijos:0,
                            peajesVariables: 0
                        }
                    ]
                }],
        resumenEnergia: [
            {
                year: 2019,
                costeEnergiaTotal: 0,
                costeEnergiaTotalP1: 0,
                costeEnergiaTotalP2: 0,
                costeEnergiaTotalP3: 0,
                costeEnergiaTotalP4: 0,
                costeEnergiaTotalP5: 0,
                costeEnergiaTotalP6: 0
            }],
            resumenEnergiaGas: [
                {
                    year: 2019,
                    costeEnergiaTotalGas: 0
                }],
        consumosPotenciaAnuales: [{
            year: 2019,
            data: [
                {
                    codigoTarifaATR: 1,
                    P1: 0,
                    P2: 0,
                    P3: 0,
                    P4: 0,
                    P5: 0,
                    P6: 0
                },
            ]
        }],
        consumosAnualesPotenciaIngresos: [
            {
                year: 2019,
                costePotencia: [
                    {
                        P1: 0,
                        P2: 0,
                        P3: 0,
                        P4: 0,
                        P5: 0,
                        P6: 0
                    }
                ]
            }],
        resumenPotencia: [
            {
                year: 2019,
                costeEnergiaTotal: 0,
                costeEnergiaTotalP1: 0,
                costeEnergiaTotalP2: 0,
                costeEnergiaTotalP3: 0,
                costeEnergiaTotalP4: 0,
                costeEnergiaTotalP5: 0,
                costeEnergiaTotalP6: 0
            }],
        tasasResumenDistribuidoraMunicipio: {
            "dataAyuntamientoConsumos": [
                {
                    consumosanuales: [{
                        year: 2019,
                        data: [
                            {
                                codigoTarifaATR: 1,
                                P1: 0,
                                P2: 0,
                                P3: 0,
                                P4: 0,
                                P5: 0,
                                P6: 0
                            },
                        ]
                    }],
                    datosPrecioEnergia: [
                        {
                            year: 2018,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2019,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2020,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2021,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2022,
                            costeEnergia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        }
                    ],
                    costeEnergiaResumen: [
                        {
                            year: 2019,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        },
                        {
                            year: 2020,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        },
                        {
                            year: 2021,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        },
                        {
                            year: 2022,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        }, {
                            year: 2023,
                            costeEnergiaTotal: 0,
                            costeEnergiaTotalP1: 0,
                            costeEnergiaTotalP2: 0,
                            costeEnergiaTotalP3: 0,
                            costeEnergiaTotalP4: 0,
                            costeEnergiaTotalP5: 0,
                            costeEnergiaTotalP6: 0
                        },

                    ],
                    costeEnergiaResumenGas: [
                        {
                            year: 2019,
                            costeEnergiaTotalGas: 0,
                            costeEnergiaTotalGasPeajesFijos: 0,
                            costeEnergiaTotalGasPeajesVariables: 0
                        },
                        {
                            year: 2020,
                            costeEnergiaTotalGas: 0,
                            costeEnergiaTotalGasPeajesFijos: 0,
                            costeEnergiaTotalGasPeajesVariables: 0
                        },
                        {
                            year: 2021,
                            costeEnergiaTotalGas: 0,
                            costeEnergiaTotalGasPeajesFijos: 0,
                            costeEnergiaTotalGasPeajesVariables: 0
                        },
                        {
                            year: 2022,
                            costeEnergiaTotalGas: 0,
                            costeEnergiaTotalGasPeajesFijos: 0,
                            costeEnergiaTotalGasPeajesVariables: 0
                        }, {
                            year: 2023,
                            costeEnergiaTotalGas: 0,
                            costeEnergiaTotalGasPeajesFijos: 0,
                            costeEnergiaTotalGasPeajesVariables: 0
                        }
                    ],
                    potenciasanuales: [{
                        year: 2019,
                        data: [
                            {
                                codigoTarifaATR: 1,
                                P1: 0,
                                P2: 0,
                                P3: 0,
                                P4: 0,
                                P5: 0,
                                P6: 0
                            },
                        ]
                    }],
                    datosPrecioPotencia: [
                        {
                            year: 2018,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2019,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2020,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2021,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        },
                        {
                            year: 2022,
                            costePotencia: [
                                {
                                    P1: 0,
                                    P2: 0,
                                    P3: 0,
                                    P4: 0,
                                    P5: 0,
                                    P6: 0
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        tasasResumenMunicipio:
        {
            distribuidora: "",
            municipio: "",
            provincia: "",
            autonomia: "",
            consumoenergiatotal: 0,
            cups: 0,
            datosPerfil: [
                {
                    id: "",
                    consumoTotalPerfil: 0,
                    cups: 0,
                    porcentaje: 0
                }
            ],
            datosTarifa: [
                {
                    id: "",
                    consumoTotalTarifa: 0,
                    cups: 0,
                    porcentaje: 0
                }
            ],
            datosAnalisis: [
                {
                    tarifa: "",
                    cups: "0",
                    energiatarifa: "",
                    menor5mw: "",
                    energiamenor5mw: "",
                    menor10mw: "",
                    energiamenor10mw: "",
                    menor20mw: "",
                    energiamenor20mw: "",
                    menor9999MW: "",
                    energiamenor9999MW: "",
                    sindatos: "",
                    energiasindatos: ""
                }
            ]
        },
        outputDatosAnalisis: [{ id: "", value: 0 }],
        outputDatosAnalisisPotencia: [{ id: "", value: 0 }],
        outputDatosTarifa: [{ id: "", value: 0 }],
        municipios: [{ key: "", value: 0 }],
        // --- Data
        isLoading: false
    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.container = React.createRef<HTMLDivElement>();

        this.logout = this.logout.bind(this);
        this.toggleNavs = this.toggleNavs.bind(this);
    }


    componentDidMount() {

        this.formProductoRef = React.createRef();

        const user = localStorage.getItem("user");

        if (user) {
            const parsed = JSON.parse(user);
            this.setState({
                user: parsed,
            });
        }

        this.setState({
            ...this.state,
        });

        this.setState({

            control: 1
        }
        );

    }

    toggleNavs = (tab) => {
        //event.preventDefault();
        if (this.state.activeTab !== tab) {
            this.setState({
                tabSelected: tab,
                tabSelectedString: tab.toString(),
            });
        }

    };


    ////////////////////////////////////////////////////////////////////////////////////////////////

    fetchDataMunicipio = async () => {


        this.setState({
            isLoading: true,
        })

        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")


        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");
        const userID = localStorage.getItem("uuidUser")

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        const municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        var tasasResumen: any = []

        var dataFilters = {
            'codigoINE': codigoINE,
            'usuario': userID
        }

        const valores = JSON.stringify(dataFilters)

        const headers = {

            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/tasas/createinfobaseconsumosmixto";


        axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {

                var tasasResumenDistribuidoraMunicipio = result.data.dataAyuntamientoConsumos;
                var consumosAnuales = tasasResumenDistribuidoraMunicipio[0].consumosanuales
                var consumosAnualesGas = tasasResumenDistribuidoraMunicipio[0].consumosanualesgas
                var consumosAnualesIngresos = tasasResumenDistribuidoraMunicipio[0].datosPrecioEnergia
                var consumosAnualesIngresosGas = tasasResumenDistribuidoraMunicipio[0].datosPrecioEnergiaGas
                var resumenEnergia = tasasResumenDistribuidoraMunicipio[0].costeEnergiaResumen
                var resumenEnergiaGas =    tasasResumenDistribuidoraMunicipio[0].costeEnergiaResumenGas
                var consumosPotenciaAnuales = tasasResumenDistribuidoraMunicipio[0].potenciasanuales
                var consumosAnualesPotenciaIngresos = tasasResumenDistribuidoraMunicipio[0].datosPrecioPotencia

                var xMax = Math.max.apply(null, consumosAnuales.map(function (o) { return parseInt(o.year); }));

                var resumenPotencia = tasasResumenDistribuidoraMunicipio[0].costePotenciaResumen

                var outputDatosAnalisis: any = [];

                var outputDatosTarifa: any = [];
                for (var i = 0; i < resumenEnergia.length; ++i) {
                    outputDatosAnalisis.push({ id: resumenEnergia[i].year, value: resumenEnergia[i].costeEnergiaTotal })
                }

                var outputDatosAnalisisPotencia: any = [];


                for (var i = 0; i < resumenPotencia.length; ++i) {
                    outputDatosAnalisisPotencia.push({ id: resumenPotencia[i].year, value: resumenPotencia[i].costePotenciaTotal })
                }

                this.setState({
                    tasasResumenDistribuidoraMunicipio: tasasResumenDistribuidoraMunicipio,
                    consumosAnuales: consumosAnuales,
                    consumosAnualesGas: consumosAnualesGas,
                    consumosAnualesIngresos: consumosAnualesIngresos,
                    consumosAnualesIngresosGas: consumosAnualesIngresosGas,
                    resumenEnergia: resumenEnergia,
                    resumenEnergiaGas: resumenEnergiaGas,
                    consumosPotenciaAnuales: consumosPotenciaAnuales,
                    consumosAnualesPotenciaIngresos: consumosAnualesPotenciaIngresos,
                    resumenPotencia: resumenPotencia,
                    tabSelected: xMax,
                    tabSelectedString: xMax.toString(),
                    outputDatosAnalisis: outputDatosAnalisis,
                    outputDatosAnalisisPotencia: outputDatosAnalisisPotencia,
                    isLoading: false,
                })

                //this.clearForm();
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    /////////////////////////////////////////////////////////////////////////////////

    onChangeField = (event) => {

        event.persist()


        const name = event.target.name;
        let value = event.target.value;
        // var setFilters = this.state.setFilters;

        //setFilters[name] = value;
        if (event.target.type === "select-one" && value === "0") {
            //delete setFilters[name];
        }

        if (value === "" || value === null) {
            //delete setFilters[name];
        }

        this.setState({ municipioBuscar: value });
    };

    //////////////////////////////////////////////////////////////

    renderRates() {


        var indexyear = this.state.consumosAnuales.map(object => object.year).indexOf(this.state.tabSelected)


        const styleFullWith = { "width": "100%" };

        var datosConsumos: any = []
        if (indexyear !== -1) {
            datosConsumos = this.state.consumosAnuales[indexyear]
            datosConsumos = datosConsumos.data
        }

        indexyear = this.state.consumosAnualesGas.map(object => object.year).indexOf(this.state.tabSelected)

        var datosConsumosGas: any = []
        if (indexyear !== -1) {
            datosConsumosGas = this.state.consumosAnualesGas[indexyear]
            datosConsumosGas = datosConsumosGas.data
        }

        console.log('valor de datosConsumoGas ----> ', datosConsumosGas)



        indexyear = this.state.consumosAnualesIngresos.map(object => object.year).indexOf(this.state.tabSelected)

        var consumosAnualesIngresos: any = this.state.consumosAnualesIngresos[indexyear]
        consumosAnualesIngresos = consumosAnualesIngresos.costeEnergia

        indexyear = this.state.consumosAnualesIngresosGas.map(object => object.year).indexOf(this.state.tabSelected)

        var consumosAnualesIngresosGas: any = this.state.consumosAnualesIngresosGas[indexyear]
        consumosAnualesIngresosGas = consumosAnualesIngresosGas.costeEnergiaGas


        indexyear = this.state.resumenEnergia.map(object => object.year).indexOf(this.state.tabSelected)

        var resumenEnergia: any = {}
        if (indexyear !== -1) {
            resumenEnergia = this.state.resumenEnergia[indexyear]
        }


        indexyear = this.state.resumenEnergiaGas.map(object => object.year).indexOf(this.state.tabSelected)

        var resumenEnergiaGas: any = {}
        if (indexyear !== -1) {
            resumenEnergiaGas = this.state.resumenEnergiaGas[indexyear]
        }

        indexyear = this.state.consumosPotenciaAnuales.map(object => object.year).indexOf(this.state.tabSelected)


        var costeAlquiler: any = [];
        var alquilerTotal = 0;


        for (var index = 0; index < consumosAnualesIngresos.length; ++index) {

            var tarifa = consumosAnualesIngresos[index].tarifa;
            var cups = consumosAnualesIngresos[index].cups;
            var alquiler = consumosAnualesIngresos[index].alquiler;
            alquilerTotal = alquilerTotal + alquiler

            costeAlquiler.push({ tarifa: tarifa, cups: cups, alquilerCoste: alquiler })

        };


        var costeAlquilerGas: any = [];
        var alquilerTotalGas = 0;


        for (var index = 0; index < consumosAnualesIngresosGas.length; ++index) {

            var tarifa = consumosAnualesIngresosGas[index].tarifa;
            var cups = consumosAnualesIngresosGas[index].cups;
            var alquiler = consumosAnualesIngresosGas[index].alquiler;
            alquilerTotalGas = alquilerTotalGas + alquiler

            costeAlquilerGas.push({ tarifa: tarifa, cups: cups, alquilerCosteGas: alquiler })

        };



        var consumosPotenciaAnuales: any = []

        if (indexyear !== -1) {
            consumosPotenciaAnuales = this.state.consumosPotenciaAnuales[indexyear]
            consumosPotenciaAnuales = consumosPotenciaAnuales.data
        }


        indexyear = this.state.consumosAnualesPotenciaIngresos.map(object => object.year).indexOf(this.state.tabSelected)

        var consumosAnualesPotenciaIngresos: any = []

        if (indexyear !== -1) {
            consumosAnualesPotenciaIngresos = this.state.consumosAnualesPotenciaIngresos[indexyear]
            consumosAnualesPotenciaIngresos = consumosAnualesPotenciaIngresos.costePotencia
        }

        var resumenPotencia: any = {}
        if (indexyear !== -1) {
            resumenPotencia = this.state.resumenPotencia[indexyear]
        }

        var ingresosEnergiaTotal = (resumenEnergia.costeEnergiaTotal ? resumenEnergia.costeEnergiaTotal : 0) * 0.015
        var ingresosPotenciaTotal = (resumenPotencia.costePotenciaTotal ? resumenPotencia.costePotenciaTotal : 0) * 0.015
        var ingresosAlquilerTotal = alquilerTotal ? alquilerTotal * 0.015 : 0
        var total = ingresosEnergiaTotal + ingresosPotenciaTotal + ingresosAlquilerTotal

        var ingresosEnergiaTotalGas = (resumenEnergiaGas.costeEnergiaTotalGas ? resumenEnergiaGas.costeEnergiaTotalGas : 0) * 0.015
        var ingresosAlquilerTotalGas = alquilerTotalGas ? alquilerTotalGas * 0.015 : 0
        var totalGas = ingresosEnergiaTotalGas +  ingresosAlquilerTotalGas




        return (
            <React.Fragment> <TabPane tabId={this.state.tabSelectedString}>

<Row className="card-deck" style={styleFullWith}>

<Card className="mt-3 mb-3 p-3 card-document">
    <CardTitle className="mt-4 info-title"><h3>Ingresos Previstos Anual Electricidad {this.state.tabSelectedString} </h3></CardTitle>
    <Row className="mt-4">
        <Col className="col-auto pt-2 pb-2 mr-2">
            <h4>Ingresos Peajes:</h4>
        </Col>

        <Col className="col-auto d-flex bg-units radius-pot text-center align-items-center">
            <h4 className="mt-2 mb-2 justify-content-center align-self-center text-center">{ingresosEnergiaTotal.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €</h4>
        </Col>

        <Col className="col-auto pt-2 pb-2 ml-4">
            <h4>Ingresos Potencia: </h4>
        </Col>

        <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
            <h4 className="mt-2 mb-2 justify-content-center align-self-center">{ingresosPotenciaTotal.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €</h4>
        </Col>


        <Col className="col-auto pt-2 pb-2 ml-6">
            <h4>Ingresos  Alquiler: </h4>
        </Col>

        <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
            <h4 className="mt-2 mb-2 justify-content-center align-self-center">{ingresosAlquilerTotal.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €</h4>
        </Col>

        <Col className="col-auto pt-2 pb-2 ml-4">
            <h4>Total electricidad: </h4>
        </Col>

        <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
            <h4 className="mt-2 mb-2 justify-content-center align-self-center">{total.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €</h4>
        </Col>

    </Row>

    <CardTitle className="mt-4 info-title"><h3>Ingresos Previstos Anual Gas {this.state.tabSelectedString}</h3></CardTitle>

    <Row className="mt-4">
        <Col className="col-auto pt-2 pb-2 mr-2">
            <h4>Ingresos Peajes:</h4>
        </Col>

        <Col className="col-auto d-flex bg-units radius-pot text-center align-items-center">
            <h4 className="mt-2 mb-2 justify-content-center align-self-center text-center">{ingresosEnergiaTotalGas.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €</h4>
        </Col>

        <Col className="col-auto pt-2 pb-2 ml-6">
            <h4>Ingresos  Alquiler: </h4>
        </Col>

        <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
            <h4 className="mt-2 mb-2 justify-content-center align-self-center">{ingresosAlquilerTotalGas.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €</h4>
        </Col>

        <Col className="col-auto pt-2 pb-2 ml-4">
            <h4>Total Gas: </h4>
        </Col>

        <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
            <h4 className="mt-2 mb-2 justify-content-center align-self-center">{totalGas.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €</h4>
        </Col>
    </Row>
</Card>
</Row>
                <Row>
                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h3 className="col">
                                        Datos análisis Consumos Electricidad Energía/Tarifa
                                    </h3>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnual}
                                        data={datosConsumos}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h3 className="col">
                                        Datos análisis Ingresos Electricidad Energía/Tarifa
                                    </h3>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnualIngresos}
                                        data={consumosAnualesIngresos}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h3 className="col">
                                        Datos análisis Consumos Electricidad Potencia/Tarifa
                                    </h3>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnual}
                                        data={consumosPotenciaAnuales}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h3 className="col">
                                        Datos análisis Ingresos Electricidad Potencia/Tarifa
                                    </h3>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnualIngresos}
                                        data={consumosAnualesPotenciaIngresos}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h3 className="col">
                                        Datos análisis Ingresos Gas Energía/Tarifa
                                    </h3>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnualGas}
                                        data={datosConsumosGas}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </TabPane>
            </React.Fragment>
        )
        //columnsDatosConsumosAnual
    };

    //////////////////////////////////////////////////////////////

    render() {
        const isLoading = this.state.isLoading;
        const styleFullWith = { "width": "100%" };
        const outputDatosAnalisis = this.state.outputDatosAnalisis;
        const outputDatosAnalisisPotencia = this.state.outputDatosAnalisisPotencia;
        const consumosAnuales = this.state.consumosAnuales;
        const ratesHtml = this.renderRates();
        var data: any = {}
        var dataAhorro: any = {}
        let options = {}

        return (
            <>
                <Header title={'Información Preliminar Tasas Distribuidora'} />
                <Container className=" mt--7" fluid>
                    <Card className="mb-4">
                        <CardBody>
                            <Row>

                                <Col className="text-right" md="2">

                                    <Button
                                        color="primary"
                                        onClick={this.fetchDataMunicipio}
                                    >
                                        <i className={"bi bi-search"} /> Analizar
                                    </Button>
                                </Col>



                            </Row>

                        </CardBody>
                    </Card>

                </Container>


                <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="section-tabs"
                    tabs
                    role="tablist"
                >
                    {

                        consumosAnuales.map(item => <NavItem key={item.year}>
                            <NavLink
                                key={item.year}
                                aria-selected={this.state.tabSelected === item.year}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabSelected === item.year,
                                })}
                                onClick={() => this.toggleNavs(item.year)}
                                href="#"
                                role="tab"
                            >
                                {item.year}
                            </NavLink>
                        </NavItem>)

                    }
                </Nav>
                <LoadingOverlay
                        active={isLoading}
                        spinner
                        text="Cargando...">

                <TabContent activeTab={this.state.tabSelectedString}>


                    {ratesHtml}


                </TabContent>


                <div>

                        <Container className=" mt-3" fluid>

                            <Row className="card-deck" style={styleFullWith}>
                                <Card className="mt-3 mb-3 p-3 card-document">


                                    <Col md="6" xl="12">
                                        <CardTitle className="info-title"><h2>Ingresos Anuales Previstos por año por peajes  </h2></CardTitle>

                                        <Card className="card-stats" style={{ height: 400 }}>

                                            <ResponsiveBar
                                                layout="horizontal"
                                                margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                                                data={outputDatosAnalisis}
                                                indexBy="id"
                                                keys={['value']}
                                                colors={{ scheme: 'nivo' }}
                                                colorBy="indexValue"
                                                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                                                enableGridX
                                                enableGridY={false}
                                                axisTop={{
                                                    format: '~s',
                                                }}

                                                axisBottom={null}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                valueFormat={value =>
                                                    `${Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 2,
                                                        useGrouping: true
                                                    })} €`
                                                }
                                                padding={0.3}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}

                                            />



                                        </Card>
                                    </Col>
                                </Card>
                                <Card className="mt-3 mb-3 p-3 card-document">


                                    <Col md="6" xl="12">
                                        <CardTitle className="info-title"><h2>Ingresos Anuales Previstos por año por potencia</h2></CardTitle>

                                        <Card className="card-stats" style={{ height: 400 }}>

                                            <ResponsiveBar
                                                layout="horizontal"
                                                margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                                                data={outputDatosAnalisisPotencia}
                                                indexBy="id"
                                                keys={['value']}
                                                colors={{ scheme: 'nivo' }}
                                                colorBy="indexValue"
                                                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                                                enableGridX
                                                enableGridY={false}
                                                axisTop={{
                                                    format: '~s',
                                                }}

                                                axisBottom={null}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                valueFormat={value =>
                                                    `${Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 2,
                                                        useGrouping: true
                                                    })} €`
                                                }
                                                padding={0.3}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}

                                            />



                                        </Card>
                                    </Col>
                                </Card>
                            </Row>

                        </Container>
                </div>
                </LoadingOverlay>

                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

export default withApollo(informePreliminarMunicipioDistribuidoraConsultor);
