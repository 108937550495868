import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../routes";
import { withApollo } from 'react-apollo';
import Page from "../components/Page/Page";

class Print extends React.Component<any, any> {
	public state = {
		brandName: '',
	}

	componentDidUpdate(e)
	{
		document.documentElement.scrollTop = 0;
	}

	getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.layout === "/print") {
				return (
					<Route
						path={prop.layout + prop.path}
						key={key}
						render={props => (
							<Page {...props}
								component={prop.component}
								title={'TASATOOL | ' + prop.name}
								name={prop.name}
								onSetBrandText={this.onSetBrandText}
							/>
						)}
					/>
				);
			} else {
				return null;
			}
		});
	};

	onSetBrandText = (title) => {
		this.setState({ brandName: title });
	};

	render() {
		return (
			<>
				<Switch>{this.getRoutes(routes)}</Switch>
			</>
		);
	}
}

export default withApollo(Print);
