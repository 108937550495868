import axios from "axios";
//import { message } from 'antd'
import Swal from 'sweetalert2'
import { withRouter } from 'react-router-dom';
import { axiosPublic } from "./axiosPublic";

axios.defaults.baseURL = "https://api.nextniro.com/api";

axios.interceptors.request.use(
  async (config) => {
    const session = localStorage.getItem("token");

    console.log("Config en interceptors -----> ", config);

    if (session) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${session}`,
      };
    }
    console.log("Request was sent");
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error?.config;

    console.log("error en interceptors response ----> ", error);
    console.log("config en interceptors error.response ----> ", error.response);
   // console.log(
   //   "config en interceptors error.response.status ----> ",
   //   error?.response?.status
    //);
    console.log("config en interceptots ------> ", originalConfig);

    if (!error?.response){

      console.log('ENTRA EN !ERROR.response')

      new Swal({
        icon: 'error',
        title: "Sin conexión de red",
        text: "Existe un problema en la red. Si persite en problema contacte con el equipo de soporte",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Continuar",
    }).then((result) => {
      if (result.isConfirmed) {
        return Promise.reject(error);
      }
    })

    } else if (originalConfig.url !== "https://api.nextniro.com/api/v1/auth/login" && error.response) {
      console.log('ENTRA EN ERROR.response 401 403 405 503')
      // if (error?.response?.status === 401 && !config?.sent) { !originalConfig._retry
      if (error?.response?.status === 401) {
        //config.sent = true;
        originalConfig._retry = true;

        const session = localStorage.getItem("token");
        const idUser = localStorage.getItem("uuidUser");

        var dataFilters = {
          idUser: idUser,
          token: session,
        };

        const valores = JSON.stringify(dataFilters);

        try {
          const response = await axiosPublic.get("/v1/auth/refresh", {
            params: { valores },
          });

          const session = response.data;

          console.log("valor de response: ", response);

          console.log("VALOR DE SESSION en refreshToken-------> ", session);

          if (!session.token) {
            localStorage.removeItem("token");
          }

          if (session.token) {
            console.log("data ----> ", session.token);
            localStorage.removeItem("token");
            var token = session.token;
            localStorage.setItem("token", token);
            originalConfig.headers = {
              ...originalConfig.headers,
              authorization: `Bearer ${session.token}`,
            };
          }
          return axios(originalConfig);
        } catch (_error) {
          localStorage.removeItem("token");
          return Promise.reject(_error);
        }
      } else if (error?.response?.status === 403){
        new Swal({
          icon: 'error',
          title: "Sin permisos de acceso",
          text: "No tienes permisos para acceder a la página solicitada",
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Continuar",
      }).then((result) => {
        if (result.isConfirmed) {
          return Promise.reject(error);
        }
      })
      } else if (error?.response?.status === 503){
        new Swal({
          icon: 'error',
          title: "Problemas en el servidor",
          text: "En estos momentos existe un problema con el servidor. Si persite el problema contacte con el equipo de soporte",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Continuar",
      }).then((result) => {
        if (result.isConfirmed) {
          return Promise.reject(error);
        }
      })
      } else if (error?.response?.status === 500){
        new Swal({
          icon: 'error',
          title: "Problemas en la Base de datos",
          text: "En estos momentos existe un problema con el servidor. Si persite el problema contacte con el equipo de soporte",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Continuar",
      }).then((result) => {
        if (result.isConfirmed) {
          return Promise.reject(error);
        }
      })
      } 
     }
    return Promise.reject(error);
  }
);

export default withRouter(axios);
