import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import logo from "../assets/img/theme/logoTasaToolayuntamientoDemo.png";

// core components
import AuthNavbar from "../components/Navbars/AuthNavbar";


import routes from "../routes";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div id="login-header" className="main-content">
        <img src={logo}  className="ml-6 py-lg-2 d-lg-block" />
          <div className="header py-7 py-lg-6">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                </Row>
              </div>
            </Container>

          </div>
          {/* Page content */}
          <Container className="mt--3 pb-5">
            <Row className="justify-content-end">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Auth;
