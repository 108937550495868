import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header";
import { withRouter } from "react-router-dom"
//import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";
import { UserStore } from "../../store/UserStore";

var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

class crearContestacionEmpresas extends Reflux.Component<any, any> {
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contestacionDetailTotal: [],
        contestacionSeleccionada: false,
        id: '',
        municipios: [{ id: "Sin valores", valor: 0, email: "" }],
        contestacionDetail: {
            municipio: "",
            codigoINE: "",
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            area: "",
            cargo: "",
            cif: "",
            empresa: "",
            telefonoFijo: "",
            telefonoMovil: "",
            email: "",
            createdAt: "",
            id: "",
            uuidId: "",
            esActiva: false,
            esAprobada: false,
            esEnEstudio: false,
            esFinalizada: false,
            esContestada: false,
            expediente: "",
            fechaContestacionEmpresa: null,
            fechaRevision: null,
            fechaRespuestaAyuntamiento: null,
            fechaFinalizacionExpediente: null,
            idUser: null,
            estado: null
        },
        formContestacion: {
            municipio: "",
            codigoINE: "",
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            area: "",
            cargo: "",
            cif: "",
            empresa: "",
            telefonoFijo: "",
            telefonoMovil: "",
            email: "",
            createdAt: "",
            id: "",
            uuidId: "",
            esActiva: false,
            esAprobada: false,
            esEnEstudio: false,
            esFinalizada: false,
            esContestada: false,
            expediente: "",
            fechaContestacionEmpresa: null,
            fechaRevision: null,
            fechaRespuestaAyuntamiento: null,
            fechaFinalizacionExpediente: null,
            idUser: null,
            estado: null
        },
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        saveDataContestacion: false,
        saveContestacionButtonDisabled: true,
        updateContestacionButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: false,
        createdAt: '',
        fechaContestacionEmpresa: null,
        fechaRevision: null,
        fechaRespuestaAyuntamiento: null,
        fechaFinalizacionExpediente: null,
        fechaPresentacion: null,
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        alert: null,
        empresas: [{ id: "Sin valores", valor: 0 }],
        expedientes: null,
        expedientesList: [{ id: "Sin valores", anualidad: 0 }],
        expedienteSeleccionado: [{}],
        inputEmpresaDisabled: true,
        inputExpedientesDisabled: true,
        inputMunicipioDisabled: false
    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);
    };

    componentDidMount() {
        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))
        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id

        const user: any = localStorage.getItem("user");

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        let municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
        let codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        

        var formData = { ...this.state.formContestacion };

        formData['municipio'] = municipio;
        formData['codigoINE'] = codigoINE;

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            formContestacion: formData
        });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    async getContestacionLista() {

        const userID = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""


        var dataFilters = {
            'nif': this.state.formContestacion.cif?.toUpperCase() || "",
            'municipioIne': codigoINE,
        }


        const valores = JSON.stringify(dataFilters)

        const headers = {
            
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/tasas/expedienteslistmunicipio";

        await axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {
                if (result.data.rows.length > 0) {

                    let data = result.data.rows
                    let empresasList: any = []
                    for (let index = 0; index < data.length; index++) {
                        var id = data[index].nif;
                        var value = data[index].empresa;
                        empresasList.push({ id: id, valor: value })
                    };

                    empresasList.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)

                    this.setState({
                        expedientes: result.data.rows,
                        empresas: empresasList
                    });

                } else {
                    this.warningAlertNIF()
                };
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                  });
              });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {
        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.history.push(`/auth/login`);
    };


    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertRepetido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Contestación Repetida"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    LA contestación ya se encuentra registrada.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };
    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea dar de alta la contestación?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onSave()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Contestacióm guardada correctamente
                </ReactBSAlert>
            )
        });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldContestacion = (event) => {
        event.persist()
        var formData = { ...this.state.formContestacion };
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        var municipio = "";

        const nameFilters = ['port', 'host', 'usermail', 'secure', 'pass', 'nombreRepresentante', 'apellido1Representante', 'apellido2Representante', 'area', 'cargo', 'cif', 'tipoVia', 'direccion', 'numFinca', 'portal', 'escalera', 'piso', 'puerta', 'codigoPostal', 'telefonoFijo', 'telefonoMovil', 'email', 'createdAt', 'id', 'uuidId', 'esActiva', 'esAprobada', 'esEnEspera', 'esFinalizada', 'esFirmada', 'esPresentada', 'expediente', 'fechaContestacionEmpresa', 'fechaRevision', 'fechaRespuestaAyuntamiento', 'fechaFinalizacionExpediente', 'fechaPresentacion'];

        const optionsFilters = ['esActiva', 'esAprobada', 'esEnEstudio', 'esFinalizada', 'esContestada'];

        if (nameFilters.indexOf(event.target.name) != -1) {
            this.setState({ saveDataContestacion: true });
            this.setState({
                saveContestacionButtonDisabled: true,
                updateContestacionButtonDisabled: false
            })
        }
        if (event.target.name == 'nombreRepresentante' && event.target.value.length > 0) {

            this.setState({ saveDataContestacion: true });
            this.setState({
                saveContestacionButtonDisabled: true,
                updateContestacionButtonDisabled: false
            })
        }
        if (event.target.name == 'apellido1Representante' && event.target.value.length > 0) {

            this.setState({ saveDataContestacion: true });
            this.setState({
                saveContestacionButtonDisabled: true,
                updateContestacionButtonDisabled: false
            })
        };

        if (event.target.name === 'cif' && event.target.value.length == 0) {
            this.setState({ NIFClienteError: true })
        } else if (event.target.name === 'cif' && event.target.value.length > 0) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                this.setState({
                    saveDataContestacion: true,
                    NIFClienteError: false
                });
                this.setState({
                    saveContestacionButtonDisabled: true,
                    updateContestacionButtonDisabled: false
                })
            } else {
                this.setState({
                    NIFClienteError: true,
                    saveDataContestacion: false,
                    updateContestacionButtonDisabled: true
                })
            }
        };

        if (event.target.name === 'email') {
            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);
            if (result === true) {
                this.setState({
                    saveDataContestacion: true,
                    emailError: false,
                })
            } else {
                this.setState({
                    saveDataContestacion: false,
                    emailError: true
                })
            }
        };

        if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
            value = null
        };

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        };

        if (name == 'municipio') {
            let indexMunicipio = this.state.municipios.findIndex(object =>
                object.id === value
            ); // 👉️ 1
            if (indexMunicipio !== -1) {
                municipio = this.state.municipios[indexMunicipio].valor

            };
            formData['codigoINE'] = value
            formData['municipio'] = municipio
            this.setState({
                saveDataContestacion: true,
                formContestacion: formData,
                inputEmpresaDisabled: false,
            }, this.getContestacionLista)
        } else if (name != 'empresa' && name != 'expedienteList'){

            formData[name] = value;
            this.setState({
                saveDataContestacion: true
            })
            this.setState({ formContestacion: formData });
        }

        var empresa = ""
        var expediente: any = []

        if (name == 'empresa' && value) {
            let indexEmpresa = this.state.empresas.findIndex(object =>
                object.id === value
            ); // 👉️ 1

            if (indexEmpresa !== -1) {
                empresa = this.state.empresas[indexEmpresa].valor

            };

            formData['cif'] = value
            formData['empresa'] = empresa

            const resultsExpedientes = this.state.expedientes.filter(element => {
                // 👇️ using AND (&&) operator
                return element.nif === value;
            });

            this.setState({
                saveDataContestacion: true,
                formContestacion: formData,
                expedientesList: resultsExpedientes,
                inputExpedientesDisabled: false,
                inputMunicipioDisabled: true,
            }, this.getContestacionExpedienteLista)
        } if (name == 'empresa' && !value) {
            let municipio=  formData['municipio'];
            let codigoINE = formData['codigoINE'];
            this.setState({
                saveDataContestacion: false,
                formContestacion: {
                    municipio: municipio,
                    codigoINE: codigoINE,
                    nombreRepresentante: "",
                    apellido1Representante: "",
                    apellido2Representante: "",
                    area: "",
                    cargo: "",
                    cif: "",
                    empresa: "",
                    telefonoFijo: "",
                    telefonoMovil: "",
                    email: "",
                    createdAt: "",
                    id: "",
                    uuidId: "",
                    esActiva: false,
                    esAprobada: false,
                    esEnEstudio: false,
                    esFinalizada: false,
                    esContestada: false,
                    expediente: "",
                    fechaContestacionEmpresa: null,
                    fechaRevision: null,
                    fechaRespuestaAyuntamiento: null,
                    fechaFinalizacionExpediente: null,
                    idUser: null,
                    estado: null
                },
                expedientesList: [{}],
                inputExpedientesDisabled: true,
                inputEmpresaDisabled: true,
                inputMunicipioDisabled: false,
                updateContestacionButtonDisabled: true
            })
        } if (name == 'expedienteList' && value) {
            console.log('entra en expediente list con valor')
            let indexExpediente = this.state.expedientes.findIndex(object =>
                object.id === value
            ); // 👉️ 1

            if (indexExpediente !== -1) {
                expediente = { ...this.state.expedientes[indexExpediente] }
            };

            formData['expediente'] = value
            this.setState({
                saveDataContestacion: true,
                formContestacion: formData,
                expedienteSeleccionado: expediente,
                inputEmpresaDisabled: true
            }, this.getContestacionExpedienteLista)
        } else if (name == 'expedienteList' && !value) {
            formData[name] = value;
            formData['expediente'] = "";
            let municipio=  formData['municipio'];
            let codigoINE = formData['codigoINE'];
            let cif = formData['cif'];
            let empresa = formData['empresa'];
            this.setState({
                saveDataContestacion: false,
                formContestacion: {
                    municipio: municipio,
                    codigoINE: codigoINE,
                    nombreRepresentante: "",
                    apellido1Representante: "",
                    apellido2Representante: "",
                    area: "",
                    cargo: "",
                    cif: cif,
                    empresa: empresa,
                    telefonoFijo: "",
                    telefonoMovil: "",
                    email: "",
                    createdAt: "",
                    id: "",
                    uuidId: "",
                    esActiva: false,
                    esAprobada: false,
                    esEnEstudio: false,
                    esFinalizada: false,
                    esContestada: false,
                    expediente: "",
                    fechaContestacionEmpresa: null,
                    fechaRevision: null,
                    fechaRespuestaAyuntamiento: null,
                    fechaFinalizacionExpediente: null,
                    idUser: null,
                    estado: null
                },
                expedienteSeleccionado: [{}],
                inputExpedientesDisabled: true,
                inputEmpresaDisabled: false,
                updateContestacionButtonDisabled: true

            });
        } 
    };

    ///////////////////////////////////////////////////////////////////////////////////////

    getContestacionExpedienteLista = () => {

        console.log('VALOR DE THIS STATE EN GETCONTESTACIONEXPEDIEnteLISTA ---> ', this.state)

    };


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;
        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        this.setState({
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            contestacionSeleccionada: false,
            contestacionDetailTotal: [],
            formContestacion: {
                municipio: "",
                codigoINE: "",
                nombreRepresentante: "",
                apellido1Representante: "",
                apellido2Representante: "",
                area: "",
                cargo: "",
                cif: "",
                empresa: "",
                telefonoFijo: "",
                telefonoMovil: "",
                email: "",
                createdAt: "",
                id: "",
                uuidId: "",
                esActiva: false,
                esAprobada: false,
                esEnEstudio: false,
                esFinalizada: false,
                esContestada: false,
                expediente: "",
                fechaContestacionEmpresa: null,
                fechaRevision: null,
                fechaRespuestaAyuntamiento: null,
                fechaFinalizacionExpediente: null,
                idUser: null,
                estado: null
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveContestacionButtonDisabled: true,
            updateContestacionButtonDisabled: true,
            createdAt: '',
            fechaContestacionEmpresa: null,
            fechaRevision: null,
            fechaRespuestaAyuntamiento: null,
            fechaFinalizacionExpediente: null,
            fechaPresentacion: null
        });
        this.formRef.current.reset();
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    saveHandler(props) {

        if (this.state.saveDataContestacion && (this.state.formContestacion.nombreRepresentante && this.state.formContestacion.cif)) {

            this.confirmAlertSave()

        } else {
            this.warningAlertFiltros()
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onSave = async () => {

        const token = localStorage.getItem("token")
        const user: any = localStorage.getItem("user");
        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }
        var iduser = datosUser.userId ? datosUser.userId : ""
        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""

        if (this.state.saveDataContestacion == true) {
            var createdAt = this.state.formContestacion.createdAt;
            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var fechaContestacionEmpresa = this.state.formContestacion.fechaContestacionEmpresa ? this.state.formContestacion.fechaContestacionEmpresa : null;
            if (!fechaContestacionEmpresa) {
                fechaContestacionEmpresa = null
            };

            var fechaRevision = this.state.formContestacion.fechaRevision ? this.state.formContestacion.fechaRevision : null;
            if (!fechaRevision) {
                fechaRevision = null
            };

            var fechaRespuestaAyuntamiento = this.state.formContestacion.fechaRespuestaAyuntamiento ? this.state.formContestacion.fechaRespuestaAyuntamiento : null;
            if (!fechaRespuestaAyuntamiento) {
                fechaRespuestaAyuntamiento = null
            };

            var fechaFinalizacionExpediente = this.state.formContestacion.fechaFinalizacionExpediente ? this.state.formContestacion.fechaFinalizacionExpediente : null;
            if (!fechaFinalizacionExpediente) {
                fechaFinalizacionExpediente = null
            };


            var contestacionDataSave = {}

            contestacionDataSave = {
                    'datosContestacion': this.state.formContestacion,
                    'datosExpediente': this.state.expedienteSeleccionado,
                    'idUser': iduser 
/*
                'municipio': this.state.formContestacion.municipio,
                'codigoINE': this.state.formContestacion.codigoINE,
                'nombreRepresentante': this.state.formContestacion.nombreRepresentante?.toUpperCase() || '',
                'apellido1Representante': this.state.formContestacion.apellido1Representante?.toUpperCase() || '',
                'apellido2Representante': this.state.formContestacion.apellido2Representante?.toUpperCase() || '',
                'area': this.state.formContestacion.area?.toUpperCase() || '',
                'cargo': this.state.formContestacion.cargo?.toUpperCase() || '',
                'cif': this.state.formContestacion.cif,
                'empresa': this.state.formContestacion.empresa,
                'telefonoFijo': this.state.formContestacion.telefonoFijo,
                'telefonoMovil': this.state.formContestacion.telefonoMovil,
                'email': this.state.formContestacion.email,
                'createdAt': createdAt,
                'id': this.state.formContestacion.id,
                'uuidId': this.state.formContestacion.uuidId,
                'esActiva': this.state.formContestacion.esActiva,
                'esAprobada': this.state.formContestacion.esAprobada,
                'esEnEstudio': this.state.formContestacion.esEnEstudio,
                'esFinalizada': this.state.formContestacion.esFinalizada,
                'esContestada': this.state.formContestacion.esContestada,
                'expediente': this.state.formContestacion.expediente,
                'fechaContestacionEmpresa': fechaContestacionEmpresa,
                'fechaRevision': fechaRevision,
                'fechaRespuestaAyuntamiento': fechaRespuestaAyuntamiento,
                'fechaFinalizacionExpediente': fechaFinalizacionExpediente,
                'idUser': iduser,
                'estado': this.state.formContestacion.estado*/
            };

            const headers = {
                
                'Content-Type': 'application/json',
            };
            let direccionFile = "https://api.nextniro.com/api/v1/tasas/contestacioncreate";

            axios.post(direccionFile, contestacionDataSave, { headers, responseType: 'json' })
                .then((result) => {

                    console.log('Actualización correcta', result)
                    console.log('Datos del registro: ', result.data)
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        contestacionSeleccionada: false,
                        contestacionDetailTotal: [],
                        toggledClearRows: !this.state.toggledClearRows,
                        saveContestacionButtonDisabled: true,
                        updateContestacionButtonDisabled: true,
                        createdAt: '',
                        updatedAt: '',
                        fechaContestacionEmpresa: null,
                        fechaRevision: null,
                        fechaRespuestaAyuntamiento: null,
                        fechaFinalizacionExpediente: null,
                        fechaPresentacion: null,
                        expedienteSeleccionado: [{}],
                        inputExpedientesDisabled: true,
                        inputEmpresaDisabled: true,
                        inputMunicipioDisabled:false,
                        empresas: [{ id: "Sin valores", valor: 0 }],
                        expedientes: null,
                        expedientesList: [{ id: "Sin valores", anualidad: 0 }],
                        formContestacion: {
                            municipio: "",
                            codigoINE: "",
                            nombreRepresentante: "",
                            apellido1Representante: "",
                            apellido2Representante: "",
                            area: "",
                            cargo: "",
                            cif: "",
                            empresa: "",
                            telefonoFijo: "",
                            telefonoMovil: "",
                            email: "",
                            createdAt: "",
                            id: "",
                            uuidId: "",
                            esActiva: false,
                            esAprobada: false,
                            esEnEstudio: false,
                            esFinalizada: false,
                            esContestada: false,
                            expediente: "",
                            fechaContestacionEmpresa: null,
                            fechaRevision: null,
                            fechaRespuestaAyuntamiento: null,
                            fechaFinalizacionExpediente: null,
                            idUser: null,
                            estado: null
                        }
                    });
                    //this.clearForm();
                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                      });
                  });
        }
    };

    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formContestacion };
        const name = id;

        if (name == 'fechaContestacionEmpresa') {
            this.setState({ fechaContestacionEmpresa: value });
        }

        if (name == 'fechaRevision') {
            this.setState({ fechaRevision: value });
        }

        if (name == 'fechaRespuestaAyuntamiento') {
            this.setState({ fechaRespuestaAyuntamiento: value });
        }

        if (name == 'fechaFinalizacionExpediente') {
            this.setState({ fechaFinalizacionExpediente: value });
        }

        if (name == 'createdAt') {
            this.setState({ createdAt: value });
        }

        formData[name] = value
        this.setState({ formContestacion: formData });
    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {
        // eslint-disable-next-line no-console

        if (state.selectedCount === 1) {
            var existeContestacion = ''
            var cliente = state.selectedRows[0].id;

            this.setState({ contestacionDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt

            existeContestacion = state.selectedRows[0].id

            if (existeFechaAlta) {
                this.setState({
                    inputDisabled: false,
                    updateContestacionButtonDisabled: true
                })
            };

            if (existeContestacion) {
                this.setState({ id: this.state.contestacionDetail.id });
                this.setState({
                    formContestacion: {
                        municipio: state.selectedRows[0].municipio,
                        codigoINE: state.selectedRows[0].codigoINE,
                        nombreRepresentante: state.selectedRows[0].nombreRepresentante,
                        apellido1Representante: state.selectedRows[0].apellido1Representante,
                        apellido2Representante: state.selectedRows[0].apellido2Representante,
                        area: state.selectedRows[0].area,
                        cargo: state.selectedRows[0].cargo,
                        cif: state.selectedRows[0].cif,
                        empresa: state.selectedRows[0].empresa,
                        telefonoFijo: state.selectedRows[0].telefonoFijo,
                        telefonoMovil: state.selectedRows[0].telefonoMovil,
                        email: state.selectedRows[0].email,
                        createdAt: state.selectedRows[0].createdAt,
                        id: state.selectedRows[0].id,
                        uuidId: state.selectedRows[0].uuidId,
                        esActiva: state.selectedRows[0].esActiva,
                        esAprobada: state.selectedRows[0].esAprobada,
                        esEnEstudio: state.selectedRows[0].esEnEspera,
                        esFinalizada: state.selectedRows[0].esFinalizada,
                        esContestada: state.selectedRows[0].esContestada,
                        expediente: state.selectedRows[0].expediente,
                        fechaContestacionEmpresa: state.selectedRows[0].fechaContestacionEmpresa,
                        fechaRevision: state.selectedRows[0].fechaRevision,
                        fechaRespuestaAyuntamiento: state.selectedRows[0].fechaRespuestaAyuntamiento,
                        fechaFinalizacionExpediente: state.selectedRows[0].fechaFinalizacionExpediente,
                        idUser: state.selectedRows[0].idUser,
                        estado: state.selectedRows[0].estado
                    },
                    contestacionSeleccionada: true,
                    inputDisabled: false
                });

            } else {

                this.setState({
                    contestacionSeleccionada: false,
                    formContestacion: {
                        municipio: "",
                        codigoINE: "",
                        nombreRepresentante: "",
                        apellido1Representante: "",
                        apellido2Representante: "",
                        area: "",
                        cargo: "",
                        cif: "",
                        empresa: "",
                        telefonoFijo: "",
                        telefonoMovil: "",
                        email: "",
                        createdAt: "",
                        id: "",
                        uuidId: "",
                        esActiva: false,
                        esAprobada: false,
                        esEnEstudio: false,
                        esFinalizada: false,
                        esContestada: false,
                        expediente: "",
                        fechaContestacionEmpresa: null,
                        fechaRevision: null,
                        fechaRespuestaAyuntamiento: null,
                        fechaFinalizacionExpediente: null,
                        idUser: null,
                        estado: null
                    },
                    fechaContestacionEmpresa: null,
                    fechaRevision: null,
                    fechaRespuestaAyuntamiento: null,
                    fechaFinalizacionExpediente: null,
                    fechaPresentacion: null,
                    createdAt: null
                });
            }
        } else {

            this.setState({
                contestacionSeleccionada: false,
                inputDisabled: false,
                updateContestacionButtonDisabled: true,
                fechaContestacionEmpresa: null,
                fechaRevision: null,
                fechaRespuestaAyuntamiento: null,
                fechaFinalizacionExpediente: null,
                fechaPresentacion: null,
                formContestacion: {
                    municipio: "",
                    codigoINE: "",
                    nombreRepresentante: "",
                    apellido1Representante: "",
                    apellido2Representante: "",
                    area: "",
                    cargo: "",
                    cif: "",
                    empresa: "",
                    telefonoFijo: "",
                    telefonoMovil: "",
                    email: "",
                    createdAt: "",
                    id: "",
                    uuidId: "",
                    esActiva: false,
                    esAprobada: false,
                    esEnEstudio: false,
                    esFinalizada: false,
                    esContestada: false,
                    expediente: "",
                    fechaContestacionEmpresa: null,
                    fechaRevision: null,
                    fechaRespuestaAyuntamiento: null,
                    fechaFinalizacionExpediente: null,
                    idUser: null,
                    estado: null
                }
            });
        };
    };

    ///////////////////////////////////////////////////////////////////7

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };

    render() {

        console.log('this.state en render() ----> ', this.state)
        const formContestacion: any = this.state.formContestacion;
        const createdAt = this.state.createdAt

        const fechaContestacionEmpresa = this.state.fechaContestacionEmpresa
        const fechaRevision = this.state.fechaRevision
        const fechaRespuestaAyuntamiento = this.state.fechaRespuestaAyuntamiento
        const fechaFinalizacionExpediente = this.state.fechaFinalizacionExpediente


        return (
            <>
                <Header title={'Alta Contestación a Requerimiento'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                            </Button>

                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>
                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                                <FormGroup>
                                                    <Button disabled={this.state.updateContestacionButtonDisabled} id="saveAytoProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.saveHandler(this.state.saveDataContestacion) }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-lines-fill" style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Añadir</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="saveAytoProptooltip">
                                                            AÑADIR REGISTRO
                                                        </UncontrolledTooltip>
                                                    </Button>
                                                </FormGroup>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <div>
                                        <CardTitle>DATOS GENERALES CONTESTACION</CardTitle>
                                    </div>
                                    <Row>
                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="municipio">
                                                    MUNICIPIO
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="INE"
                                                    placeholder=""
                                                    name="municipio"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.municipio}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="codigoINE">
                                                    INE
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="INE"
                                                    placeholder=""
                                                    name="codigoINE"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.codigoINE}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Label className="form-control-label" htmlFor="ayuntamientoRef">
                                                EMPRESA
                                            </Label>
                                            <Input type="select" defaultValue={'DEFAULT'} name="empresa" id="empresa" onChange={this.onChangeFieldContestacion} disabled={this.state.inputEmpresaDisabled}>
                                                <option value="">Selecciona una empresa... </option>
                                                {this.state.empresas.map((item, key) => (
                                                    <option key={key} value={item.id}>{item.valor}</option>
                                                ))
                                                }
                                            </Input>
                                        </Col>

                                        <Col md="3">
                                            <Label className="form-control-label" htmlFor="ayuntamientoRef">
                                                EXPEDIENTES
                                            </Label>
                                            <Input type="select" defaultValue={'DEFAULT'} name="expedienteList" id="expedienteList" onChange={this.onChangeFieldContestacion} disabled={this.state.inputExpedientesDisabled}>
                                                <option value="">Selecciona un expediente... </option>
                                                {this.state.expedientesList.map((item, key) => (
                                                    <option key={key} value={item.id}>Exp.años {String(item.anualidad)}</option>
                                                ))
                                                }
                                            </Input>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="expediente">
                                                    Expediente
                                                </Label>
                                                <Input
                                                    id="expediente"
                                                    placeholder=""
                                                    name="expediente"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.expediente}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="createdAt">
                                                    Fecha Alta Registro
                                                </Label>
                                                <DatePicker id="createdAt"
                                                    value={createdAt}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "createdAt")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>DATOS DE CONTACTO</CardTitle>
                                    </div>
                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombreRepresentante">
                                                    Nombre Contacto
                                                </Label>
                                                <Input
                                                    id="nombreRepresentante"
                                                    placeholder=""
                                                    name="nombreRepresentante"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.nombreRepresentante}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="apellido1Representante">
                                                    Primer Apellido
                                                </Label>
                                                <Input
                                                    id="apellido1Representante"
                                                    placeholder=""
                                                    name="apellido1Representante"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.apellido1Representante}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="apellido2Representante">
                                                    Segundo Apellido
                                                </Label>
                                                <Input
                                                    id="apellido2Representante"
                                                    placeholder=""
                                                    name="apellido2Representante"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.apellido2Representante}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="area">
                                                    Area
                                                </Label>
                                                <Input
                                                    id="area"
                                                    placeholder=""
                                                    name="area"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.area}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="cargo">
                                                    Cargo
                                                </Label>
                                                <Input
                                                    id="cargo"
                                                    placeholder=""
                                                    name="cargo"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.cargo}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="telefonoFijo">
                                                    Teléfono Contacto
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="telefonoFijo"
                                                    placeholder=""
                                                    name="telefonoFijo"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.telefonoFijo}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="telefonoMovil">
                                                    Teléfono Móvil
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="telefonoMovil"
                                                    placeholder=""
                                                    name="telefonoMovil"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.telefonoMovil}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="email">
                                                    Correo Contacto
                                                </Label>
                                                <Input
                                                    id="email"
                                                    placeholder=""
                                                    name="email"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.email}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>OTROS DATOS</CardTitle>
                                    </div>
                                    <Row>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="esEnEstudio">En Estudio</Label>
                                                <Input type="select" name="esEnEstudio" id="esEnEstudio"
                                                    disabled={this.state.inputDisabled}

                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.esEnEstudio}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="En Respuesta"> Respuesta</Label>
                                                <Input type="select" name="esContestada" id="esContestada"
                                                    disabled={this.state.inputDisabled}

                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.esContestada}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="esActiva">Activa</Label>
                                                <Input type="select" name="esActiva" id="esActiva"
                                                    disabled={this.state.inputDisabled}

                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.esActiva}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="esAprobada">Aprobada</Label>
                                                <Input type="select" name="esAprobada" id="esAprobada"
                                                    disabled={this.state.inputDisabled}

                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.esAprobada}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="esFinalizada">Finalizada</Label>
                                                <Input type="select" name="esFinalizada" id="esFinalizada"
                                                    disabled={this.state.inputDisabled}

                                                    onChange={this.onChangeFieldContestacion}
                                                    value={formContestacion.esFinalizada}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaContestacionEmpresa">
                                                    Fecha Contestación
                                                </Label>
                                                <DatePicker id="fechaContestacionEmpresa"
                                                    value={fechaContestacionEmpresa}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "fechaContestacionEmpresa")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaRevision">
                                                    Fecha Revisión
                                                </Label>
                                                <DatePicker id="fechaRevision"
                                                    value={fechaRevision}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "fechaRevision")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaRespuestaAyuntamiento">
                                                    Fecha Resp. Ayto
                                                </Label>
                                                <DatePicker id="fechaRespuestaAyuntamiento"
                                                    value={fechaRespuestaAyuntamiento}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "fechaRespuestaAyuntamiento")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaFinalizacionExpediente">
                                                    Fecha Fin Exped.
                                                </Label>
                                                <DatePicker id="fechaFinalizacionExpediente"
                                                    value={fechaFinalizacionExpediente}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "fechaFinalizacionExpediente")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {this.state.alert}
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(crearContestacionEmpresas);