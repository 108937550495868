import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import ReactBSAlert from "react-bootstrap-sweetalert";
//import FormData from 'form-data';
// reactstrap components
import {
    Card, Container, Row, CardTitle, CardBody, 
    Input, FormText, FormGroup, Label,
    Form, Col, Button,
    UncontrolledTooltip
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
// core components
import Header from "../../components/Headers/Header";
import { withApollo } from "react-apollo";
import { UserStore } from "../../store/UserStore";
import { removeToken } from "../../utils";
//import memoize from 'memoize-one';


class cargaDatosLiquidaciones extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public inputEl: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        municipioBuscar:"",
        idDistribuidora:"",
        municipios: [{ id: "", valor: 0 }],
        alert: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        page: 1,
        limit: 10,
        isLoadingSips: true,
        isLoadingConsumos: true,
        isLoadingConsumosTotales: true,
        changeLogoLoading: false,
        updateProfileLoading: false,
        files: [],
        events: [],
        filePreviews: {},
        mibgasPrice: [],
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        saveData: false,
        saveDataRepre: false,
        saveProductButtonDisabled: true,
        contratoButtonDisabled: false,
        saveXMLButtonDisabled: true,
        idComercializadoraActualContrato: '',
        nombreComercializadoraActualContrato: '',
        cups: '',
        CIF: '',
        consumoAnualCup: '',
        idRepresentante: '',
        representanteDetail: [],
        inputDisabled: false,
        imgCollection: [],
        saveButtonDisabled: true,
        ultimaRemesa: "",
        nombreMunicipio:""

    };


    constructor(props) {
        super(props);
        this.store = UserStore;
        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileInput = React.createRef();
        this.inputEl = React.createRef();
        this.logout = this.logout.bind(this);
        this.confirmAlertExit = this.confirmAlertExit.bind(this);


    }

    componentWillReceiveProps() {

        console.log(this.props)
        console.log(this.state)

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALOR DE USUARIO ----------------->', usuario)



        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId

        });
    }


////////////////////////////////////////////////////////////////////////////////////

    componentDidMount() {

        const user = localStorage.getItem("user");

        if (user) {
            const parsed = JSON.parse(user);
            this.setState({
                user: parsed,
            });
        }

        this.setState({
            ...this.state,
        });

    };

    /////////////////////////////////////////////////////////////////////

    export = () => { };

/////////////////////////////////////////////////////////////////////////////////////////////////////

hideAlert = () => {
    this.setState({
        alert: null
    });
};

confirmAlertExit= () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Vuelva a iniciar sesión"
                onConfirm={() => this.logout()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Salir"
                btnSize=""
                focusConfirmBtn
            >
            Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
        </ReactBSAlert>
        )
    });
};

faltaDatos= () => {
this.setState({
    alert: (
        <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Existen campos obligatorios sin información"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
        >
            Revise los datos introducidos en los campos de municipio y/o distribuidora
</ReactBSAlert>
    )
});
};

/////////////////////////////////////////////////////////////////////////////////////////////////

    onFileChange(e) {
        e.persist()

        this.setState(
            ({ imgCollection }) => ({
                imgCollection: e.target.files
            }), () => {
                this.setState(({ imgCollection }) => ({
                    imgCollection: e.target.files
                }))
            })

        //this.setState({ imgCollection: e.target.files })
        console.log('VALOR DE SELECCION DE FILES -----> ', e.target.files)

        let tamañoFiles = e.target.files.length;
        console.log('VALOR DE SELECCION DE FILES logitud -----> ', tamañoFiles)

        if (tamañoFiles > 0) {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: false
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: false
                    }))
                })

        } else {
            this.setState(
                ({ saveButtonDisabled }) => ({
                    saveButtonDisabled: true
                }), () => {
                    this.setState(({ saveButtonDisabled }) => ({
                        saveButtonDisabled: true
                    }))
                })
        }

    };

    //////////////////////////////////////////////////////////////////////////////////////////////////

    onSubmit(e) {

        e.preventDefault()

       if(!this.state.municipioBuscar){

        this.faltaDatos()

       } else {

        var formData = new FormData();
        for (const key of Object.keys(this.state.imgCollection)) {
            formData.append('file', this.state.imgCollection[key])
        }

        this.setState({

            isLoading: true,
        })
        var ultimaRemesa = ""

        const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
        const token = localStorage.getItem("token")

        //var user: any = [];

        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }


        const municipio = this.state.nombreMunicipio
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        

        formData.append('municipio', municipio);
        formData.append('codigoIne', codigoINE)
        formData.append('usuario', userID);

        const headers = {
            
            'Content-Type': 'multipart/form-data',
        };

        let direccionFile = "https://api.nextniro.com/api/v1/tasas/uploadLiquidacion";

        //let direccionFileb = "http://78.47.44.246:4000/api/upload-images";
        //    let direccionFileList = "https://datapi.psgestion.es/files?id="+this.state.companyIdUser;
        console.log(direccionFile)
        axios.post(direccionFile, formData, { headers }
        ).then(result => {


            this.setState({

                isLoading: false,
            })

            this.successAlert();
            //ultimaRemesa = result.data.idRemesa
            //this.setState({ultimaRemesa: result.data.idRemesa})
            //this.setState({mibgasPrice: result.data})
            //this.setState({imgCollection:[]})
            //this.setState({saveButtonDisabled: true})
            this.inputEl.current.value = ''
        }).catch((error) => {
            this.setState({
                isLoading: false,
              });
          });

    };
};

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    /////////////////////////////////////////////////////////////////////////////////


    readUploadedFileAsText = (inputFile) => {

        console.log('DENTRO DE LA FUNCION READUPLODADED-----> ', inputFile)
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            //temporaryFileReader.readAsText(inputFile);
            temporaryFileReader.readAsDataURL(inputFile);
        });
    };


    /////////////////////////////////////////////////////////////////////////////

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Datos cargados correctamente
                </ReactBSAlert>
            )
        });
    };

    ////////////////////////////////////////////////////////////////////////////


    onChangeField = (event) => {

        event.persist()

        console.log('event value ', event.target.value)
        console.log('event name ', event.target.name)

        let value = event.target.value;
        let name = event.target.name
        var municipio = ""

        if (event.target.type === "select-one" && value === "0") {
            //delete setFilters[name];
        }

        if (value === "" || value === null) {
            //delete setFilters[name];
        }

        if (name == 'municipio') {

            let indexMunicipio = this.state.municipios.findIndex(object =>
                object.id === value
            ); // 👉️ 1

            if (indexMunicipio !== -1) {
                municipio = this.state.municipios[indexMunicipio].valor
            };

        } 



        this.setState({ municipioBuscar: value,
                        nombreMunicipio: municipio });
    };

//////////////////////////////////////////////////////////////////////////////////////

    render() {
        const isLoading = this.state.isLoading;
        const disableExport =
            this.state.isLoadingSips ||
            this.state.isLoadingConsumos ||
            this.state.isLoadingConsumosTotales ||
            this.state.isLoadingSips;
        const disableExportClass = disableExport ? " disabled" : "";

        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)



        return (
            <>
                <Header title={'Carga de Datos Liquidaciones Ayuntamiento'} />
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>



                                    </Row>
                                </CardBody>
                            </Card>



                            <Card className="mt-3 shadow">
                                <CardBody>
                                    <CardTitle>CARGA DE FICHEROS DE LIQUIDACIONES AYUNTAMIENTO (xlsx)</CardTitle>
                                    <Row>

                                        <Col md="12" lg="5" className ="pt-3" >
                                        <FormGroup>
                                            <Form onSubmit={this.onSubmit}>
                                                <input type="file" name="files" className="custom-file-input" onChange={this.onFileChange} ref={this.inputEl} multiple />

                                                <button disabled={this.state.saveButtonDisabled} id="gpsProptooltip" type="submit" color="#2dce89" className="btn btn-primary btn-sm btn-circle" >
                                                    <span className="btn-inner--icon">
                                                        <i className={"bi bi-cloud-arrow-up"} style={{ fontSize: 25 }}></i>
                                                    </span>
                                                    <span className="btn-inner--text">Subir Ficheros</span>
                                                    <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                                        SUBIR FICHEROS XLSX AL SERVIDOR
                                                    </UncontrolledTooltip>
                                                </button>
                                            </Form>
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <FormText color="muted">
                                        Seleccionar los fichero xlsx  para subir al servidor y procesar
                                    </FormText>
                                    <LoadingOverlay
                                        active={isLoading}
                                        spinner
                                        text="Cargando...">
                                        <Row className="card-deck">
                                            <Card className="mt-3 mb-3 p-3 card-document">
                                            </Card>
                                        </Row>


                                    </LoadingOverlay>
                                </CardBody>
                            </Card>
                            {this.state.alert}
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}


export default withApollo(cargaDatosLiquidaciones);
