import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header";
import { withRouter } from "react-router-dom"
//import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";
import { UserStore } from "../../store/UserStore";

var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

class crearAyuntamiento extends Reflux.Component<any, any> {
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        ayuntamientoDetailTotal: [],
        ayuntamientoSeleccionado: false,
        id: '',
        municipios: [{ key: "", value: 0 }],
        ayuntamientoDetail: {
            municipio: "",
            codigoINE: "",
            nombreContacto: "",
            apellido1Contacto: "",
            apellido2Contacto: "",
            area: "",
            cargo: "",
            cif: "",
            tipoVia: "",
            direccion: "",
            numFinca: "",
            portal: "",
            escalera: "",
            piso: "",
            puerta: "",
            codigoPostal: "",
            provincia: "",
            comunidadAutonoma: "",
            telefonoFijo: "",
            telefonoMovil: "",
            email: "",
            createdAt: "",
            id: "",
            uuidId: "",
            esActiva: false,
            esAprobada: false,
            esEnEspera: false,
            esFinalizada: false,
            esFirmada: false,
            esPresentada: false,
            expediente: "",
            fechaActivacion: null,
            fechaAprobacion: null,
            fechaFirma: null,
            fechaFinalizacion: null,
            fechaPresentacion: null,
            idUser: null,
            idEmpresa: null,
            host: null,
            port: null,
            secure: null,
            usermail: null,
            pass: null

        },
        formAyuntamiento: {
            municipio: "",
            codigoINE: "",
            nombreContacto: "",
            apellido1Contacto: "",
            apellido2Contacto: "",
            area: "",
            cargo: "",
            cif: "",
            tipoVia: "",
            direccion: "",
            numFinca: "",
            portal: "",
            escalera: "",
            piso: "",
            puerta: "",
            codigoPostal: "",
            provincia: "",
            comunidadAutonoma: "",
            telefonoFijo: "",
            telefonoMovil: "",
            email: "",
            createdAt: "",
            id: "",
            uuidId: "",
            esActiva: false,
            esAprobada: false,
            esEnEspera: false,
            esFinalizada: false,
            esFirmada: false,
            esPresentada: false,
            expediente: "",
            fechaActivacion: null,
            fechaAprobacion: null,
            fechaFirma: null,
            fechaFinalizacion: null,
            fechaPresentacion: null,
            idUser: null,
            idEmpresa: null,
            host: null,
            port: null,
            secure: null,
            usermail: null,
            pass: null
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        saveDataCliente: false,
        saveAyuntamientoButtonDisabled: true,
        updateAyuntamientoButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: false,
        createdAt: '',
        fechaActivacion: null,
        fechaAprobacion: null,
        fechaFirma: null,
        fechaFinalizacion: null,
        fechaPresentacion: null,
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        alert: null

    };


    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);
    };

    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId

        });
    };

    componentDidMount() {
        this.getMunicipiosLista();
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////

    async getMunicipiosLista() {

        const userID = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")

        var dataFilters = {
            'userId': userID
        }

        const valores = JSON.stringify(dataFilters)

        const headers = {
            
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/diccionario/municipiosgeo";

        await axios.get(direccionFile, { headers, params: { valores } })
            .then((result) => {
                var municipio = result.data.data;
                this.setState({
                    municipios: municipio
                });
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                  });
              });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {
        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        //this.props.client.cache.reset();
        //this.props.client.resetStore();
        this.props.history.push(`/auth/login`);
    };


    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertRepetido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Ayuntamiento Repetido"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El ayuntamiento ya se encuentra registrado.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };
    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el ayuntamiento?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Ayuntamiento guardado correctamente
                </ReactBSAlert>
            )
        });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldAyuntamiento = (event) => {
        event.persist()
        var formData = { ...this.state.formAyuntamiento };
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        var municipio = "";
        var autonomia = "";
        var provincia = "";

        const nameFilters = ['port','host','usermail','secure','pass','nombreContacto', 'apellido1Contacto', 'apellido2Contacto', 'area', 'cargo', 'cif', 'tipoVia', 'direccion', 'numFinca', 'portal', 'escalera', 'piso', 'puerta', 'codigoPostal', 'telefonoFijo', 'telefonoMovil', 'email', 'createdAt', 'id', 'uuidId', 'esActiva', 'esAprobada', 'esEnEspera', 'esFinalizada', 'esFirmada', 'esPresentada', 'expediente', 'fechaActivacion', 'fechaAprobacion', 'fechaFirma', 'fechaFinalizacion', 'fechaPresentacion'];

        const optionsFilters = ['secure', 'esActiva', 'esAprobada', 'esEnEspera', 'esFinalizada', 'esFirmada', 'esPresentada'];

        if (nameFilters.indexOf(event.target.name) != -1) {
            this.setState({ saveDataCliente: true });
            this.setState({
                saveAyuntamientoButtonDisabled: true,
                updateAyuntamientoButtonDisabled: false
            })
        }
        if (event.target.name == 'nombreContacto' && event.target.value.length > 0) {

            this.setState({ saveDataCliente: true });
            this.setState({
                saveAyuntamientoButtonDisabled: true,
                updateAyuntamientoButtonDisabled: false
            })
        }
        if (event.target.name == 'apellido1Contacto' && event.target.value.length > 0) {

            this.setState({ saveDataCliente: true });
            this.setState({
                saveAyuntamientoButtonDisabled: true,
                updateAyuntamientoButtonDisabled: false
            })
        }

        if (event.target.name === 'cif' && event.target.value.length == 0) {
            this.setState({ NIFClienteError: true })
        } else if (event.target.name === 'cif' && event.target.value.length > 0) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                this.setState({
                    saveDataCliente: true,
                    NIFClienteError: false
                });
                this.setState({
                    saveAyuntamientoButtonDisabled: true,
                    updateAyuntamientoButtonDisabled: false
                })
            } else {
                this.setState({
                    NIFClienteError: true,
                    saveDataCliente: false,
                    updateAyuntamientoButtonDisabled: true
                })
            }
        };

        if (event.target.name === 'email') {
            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);
            if (result === true) {
                this.setState({
                    saveDataCliente: true,
                    emailError: false,
                })
            } else {
                this.setState({
                    saveDataCliente: false,
                    emailError: true
                })
            }
        };

        if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
            value = null
        };

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        };

        if (name == 'municipio') {
            let indexMunicipio = this.state.municipios.findIndex(object =>
                object.key === value
            ); // 👉️ 1
            if (indexMunicipio !== -1) {
                municipio = this.state.municipios[indexMunicipio].value
                autonomia = this.state.municipios[indexMunicipio].autonomia
                provincia = this.state.municipios[indexMunicipio].provincia
            };
            formData['codigoINE'] = value
            formData['municipio'] = municipio
            formData['comunidadAutonoma'] = autonomia
            formData['provincia'] = provincia
            this.setState({
                saveDataCliente: true
            })
            this.setState({ formAyuntamiento: formData });
        } else {
            formData[name] = value;
            this.setState({
                saveDataCliente: true
            })
            this.setState({ formAyuntamiento: formData });
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;
        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        this.setState({
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            ayuntamientoSeleccionado: false,
            ayuntamientoDetailTotal: [],
            formAyuntamiento: {
                municipio: "",
                codigoINE: "",
                nombreContacto: "",
                apellido1Contacto: "",
                apellido2Contacto: "",
                area: "",
                cargo: "",
                cif: "",
                tipoVia: "",
                direccion: "",
                numFinca: "",
                portal: "",
                escalera: "",
                piso: "",
                puerta: "",
                codigoPostal: "",
                provincia: "",
                comunidadAutonoma: "",
                telefonoFijo: "",
                telefonoMovil: "",
                email: "",
                createdAt: "",
                id: "",
                uuidId: "",
                esActiva: false,
                esAprobada: false,
                esEnEspera: false,
                esFinalizada: false,
                esFirmada: false,
                esPresentada: false,
                expediente: "",
                fechaActivacion: null,
                fechaAprobacion: null,
                fechaFirma: null,
                fechaFinalizacion: null,
                fechaPresentacion: null,
                idUser: null,
                idEmpresa: null,
                host: null,
                port: null,
                secure: null,
                usermail: null,
                pass: null

            },
            toggledClearRows: !this.state.toggledClearRows,
            saveAyuntamientoButtonDisabled: true,
            updateAyuntamientoButtonDisabled: true,
            createdAt: '',
            fechaActivacion: null,
            fechaAprobacion: null,
            fechaFirma: null,
            fechaFinalizacion: null,
            fechaPresentacion: null
        });
        this.formRef.current.reset();
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {

        if (this.state.saveDataCliente && (this.state.formAyuntamiento.nombreContacto && this.state.formAyuntamiento.cif)) {

            this.confirmAlertSave()

        } else {
            this.warningAlertFiltros()
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {

        const token = localStorage.getItem("token")
        const user: any = localStorage.getItem("user");
        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }
        var iduser = datosUser.userId ? datosUser.userId : ""
        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""

        if (this.state.saveDataCliente == true) {
            var createdAt = this.state.formAyuntamiento.createdAt;
            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var fechaActivacion = this.state.formAyuntamiento.fechaActivacion ? this.state.formAyuntamiento.fechaActivacion : null;
            if (!fechaActivacion) {
                fechaActivacion = ''
            };

            var fechaAprobacion = this.state.formAyuntamiento.fechaAprobacion ? this.state.formAyuntamiento.fechaAprobacion : null;
            if (!fechaAprobacion) {
                fechaAprobacion = ''
            };

            var fechaFirma = this.state.formAyuntamiento.fechaFirma ? this.state.formAyuntamiento.fechaFirma : null;
            if (!fechaFirma) {
                fechaFirma = ''
            };

            var fechaFinalizacion = this.state.formAyuntamiento.fechaFinalizacion ? this.state.formAyuntamiento.fechaFinalizacion : null;
            if (!fechaFinalizacion) {
                fechaFinalizacion = ''
            };

            var fechaPresentacion = this.state.formAyuntamiento.fechaPresentacion ? this.state.formAyuntamiento.fechaPresentacion : null;
            if (!fechaPresentacion) {
                fechaPresentacion = ''
            };

            var ayuntamientoDataUpdate = {}

            ayuntamientoDataUpdate = {

                'municipio': this.state.formAyuntamiento.municipio,
                'codigoINE': this.state.formAyuntamiento.codigoINE,
                'nombreContacto': this.state.formAyuntamiento.nombreContacto?.toUpperCase() || '',
                'apellido1Contacto': this.state.formAyuntamiento.apellido1Contacto?.toUpperCase() || '',
                'apellido2Contacto': this.state.formAyuntamiento.apellido2Contacto?.toUpperCase() || '',
                'area': this.state.formAyuntamiento.area?.toUpperCase() || '',
                'cargo': this.state.formAyuntamiento.cargo?.toUpperCase() || '',
                'cif': this.state.formAyuntamiento.cif,
                'tipoVia': this.state.formAyuntamiento.tipoVia?.toUpperCase() || '',
                'direccion': this.state.formAyuntamiento.direccion?.toUpperCase() || '',
                'numFinca': this.state.formAyuntamiento.numFinca,
                'portal': this.state.formAyuntamiento.portal,
                'escalera': this.state.formAyuntamiento.escalera,
                'piso': this.state.formAyuntamiento.piso,
                'puerta': this.state.formAyuntamiento.puerta,
                'codigoPostal': this.state.formAyuntamiento.codigoPostal,
                'provincia': this.state.formAyuntamiento.provincia,
                'comunidadAutonoma': this.state.formAyuntamiento.comunidadAutonoma,
                'telefonoFijo': this.state.formAyuntamiento.telefonoFijo,
                'telefonoMovil': this.state.formAyuntamiento.telefonoMovil,
                'email': this.state.formAyuntamiento.email,
                'createdAt': createdAt,
                'id': this.state.formAyuntamiento.id,
                'uuidId': this.state.formAyuntamiento.uuidId,
                'esActiva': this.state.formAyuntamiento.esActiva,
                'esAprobada': this.state.formAyuntamiento.esAprobada,
                'esEnEspera': this.state.formAyuntamiento.esEnEspera,
                'esFinalizada': this.state.formAyuntamiento.esFinalizada,
                'esFirmada': this.state.formAyuntamiento.esFirmada,
                'esPresentada': this.state.formAyuntamiento.esPresentada,
                'expediente': this.state.formAyuntamiento.expediente,
                'fechaActivacion': fechaActivacion,
                'fechaAprobacion': fechaAprobacion,
                'fechaFirma': fechaFirma,
                'fechaFinalizacion': fechaFinalizacion,
                'fechaPresentacion': fechaPresentacion,
                'idUser': iduser,
                'idEmpresa': idEmpresa,
                'host': this.state.formAyuntamiento.host ? this.state.formAyuntamiento.host : null,
                'port': this.state.formAyuntamiento.port ? this.state.formAyuntamiento.port : null,
                'secure': this.state.formAyuntamiento.secure ? this.state.formAyuntamiento.secure : false,
                'usermail': this.state.formAyuntamiento.usermail ? this.state.formAyuntamiento.usermail : null,
                'pass': this.state.formAyuntamiento.pass ? this.state.formAyuntamiento.pass : null
            };

            const headers = {
                
                'Content-Type': 'application/json',
            };
            let direccionFile = "https://api.nextniro.com/api/v1/gestion/ayuntamientocreate";

            axios.post(direccionFile, ayuntamientoDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    console.log('Actualización correcta', result)
                    console.log('Datpos del registro: ', result.data)
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        ayuntamientoSeleccionado: false,
                        ayuntamientoDetailTotal: [],
                        toggledClearRows: !this.state.toggledClearRows,
                        saveAyuntamientoButtonDisabled: true,
                        updateAyuntamientoButtonDisabled: true,
                        createdAt: '',
                        updatedAt: '',
                        fechaActivacion: null,
                        fechaAprobacion: null,
                        fechaFirma: null,
                        fechaFinalizacion: null,
                        fechaPresentacion: null,
                        formAyuntamiento: {
                            municipio: "",
                            codigoINE: "",
                            nombreContacto: "",
                            apellido1Contacto: "",
                            apellido2Contacto: "",
                            area: "",
                            cargo: "",
                            cif: "",
                            tipoVia: "",
                            direccion: "",
                            numFinca: "",
                            portal: "",
                            escalera: "",
                            piso: "",
                            puerta: "",
                            codigoPostal: "",
                            provincia: "",
                            comunidadAutonoma: "",
                            telefonoFijo: "",
                            telefonoMovil: "",
                            email: "",
                            createdAt: "",
                            id: "",
                            uuidId: "",
                            esActiva: false,
                            esAprobada: false,
                            esEnEspera: false,
                            esFinalizada: false,
                            esFirmada: false,
                            esPresentada: false,
                            expediente: "",
                            fechaActivacion: null,
                            fechaAprobacion: null,
                            fechaFirma: null,
                            fechaFinalizacion: null,
                            fechaPresentacion: null,
                            idUser: null,
                            idEmpresa: null,
                            host: null,
                            port: null,
                            secure: null,
                            usermail: null,
                            pass: null
                        }
                    });
                    //this.clearForm();
                }).catch((error) => {
                    console.log(error);
                    if (error?.response) {
                        // http status code
                        const code = error.response.status
                        // response data
                        if (code == "500") {
                            this.hideAlert()

                            this.warningAlertRepetido()

                            this.setState({
                                isLoading: false,
                            });
                        }
                    }
                });
        }
    };


    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formAyuntamiento };
        const name = id;

        if (name == 'fechaActivacion') {
            this.setState({ fechaActivacion: value });
        }

        if (name == 'fechaAprobacion') {
            this.setState({ fechaAprobacion: value });
        }

        if (name == 'fechaFirma') {
            this.setState({ fechaFirma: value });
        }

        if (name == 'fechaFinalizacion') {
            this.setState({ fechaFinalizacion: value });
        }

        if (name == 'fechaPresentacion') {
            this.setState({ fechaPresentacion: value });
        }

        if (name == 'createdAt') {
            this.setState({ createdAt: value });
        }

        formData[name] = value
        this.setState({ formData });
    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {
        // eslint-disable-next-line no-console

        if (state.selectedCount === 1) {
            var existeCliente = ''
            var cliente = state.selectedRows[0].id;

            this.setState({ ayuntamientoDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt

            existeCliente = state.selectedRows[0].id

            if (existeFechaAlta) {
                this.setState({
                    inputDisabled: false,
                    updateAyuntamientoButtonDisabled: true
                })
            };


            if (existeCliente) {
                this.setState({ id: this.state.ayuntamientoDetail.id });
                this.setState({
                    formAyuntamiento: {
                        municipio: state.selectedRows[0].municipio,
                        codigoINE: state.selectedRows[0].codigoINE,
                        nombreContacto: state.selectedRows[0].nombreContacto?.toUpperCase() || '',
                        apellido1Contacto: state.selectedRows[0].apellido1Contacto?.toUpperCase() || '',
                        apellido2Contacto: state.selectedRows[0].apellido2Contacto?.toUpperCase() || '',
                        area: state.selectedRows[0].area?.toUpperCase() || '',
                        cargo: state.selectedRows[0].cargo?.toUpperCase() || '',
                        cif: state.selectedRows[0].cif,
                        tipoVia: state.selectedRows[0].tipoVia,
                        direccion: state.selectedRows[0].direccion?.toUpperCase() || '',
                        numFinca: state.selectedRows[0].numFinca,
                        portal: state.selectedRows[0].portal,
                        escalera: state.selectedRows[0].escalera,
                        piso: state.selectedRows[0].piso,
                        puerta: state.selectedRows[0].puerta,
                        codigoPostal: state.selectedRows[0].codigoPostal,
                        provincia: state.selectedRows[0].provincia,
                        comunidadAutonoma: state.selectedRows[0].comunidadAutonoma,
                        telefonoFijo: state.selectedRows[0].telefonoFijo,
                        telefonoMovil: state.selectedRows[0].telefonoMovil,
                        email: state.selectedRows[0].email,
                        createdAt: state.selectedRows[0].createdAt,
                        id: state.selectedRows[0].id,
                        uuidId: state.selectedRows[0].uuidId,
                        esActiva: state.selectedRows[0].esActiva,
                        esAprobada: state.selectedRows[0].esAprobada,
                        esEnEspera: state.selectedRows[0].esEnEspera,
                        esFinalizada: state.selectedRows[0].esFinalizada,
                        esFirmada: state.selectedRows[0].esFirmada,
                        esPresentada: state.selectedRows[0].esPresentada,
                        expediente: state.selectedRows[0].expediente,
                        fechaActivacion: state.selectedRows[0].fechaActivacion,
                        fechaAprobacion: state.selectedRows[0].fechaAprobacion,
                        fechaFirma: state.selectedRows[0].fechaFirma,
                        fechaFinalizacion: state.selectedRows[0].fechaFinalizacion,
                        fechaPresentacion: state.selectedRows[0].fechaPresentacion,
                        idUser: state.selectedRows[0].idUser,
                        idEmpresa: state.selectedRows[0].idEmpresa,
                        host: state.selectedRows[0].host,
                        port: state.selectedRows[0].port,
                        secure: state.selectedRows[0].secure,
                        usermail: state.selectedRows[0].usermail,
                        pass: state.selectedRows[0].pass
                    },
                    ayuntamientoSeleccionado: true,
                    inputDisabled: false
                });

            } else {

                this.setState({
                    ayuntamientoSeleccionado: false,
                    formAyuntamiento: {
                        municipio: "",
                        codigoINE: "",
                        nombreContacto: "",
                        apellido1Contacto: "",
                        apellido2Contacto: "",
                        area: "",
                        cargo: "",
                        cif: "",
                        tipoVia: "",
                        direccion: "",
                        numFinca: "",
                        portal: "",
                        escalera: "",
                        piso: "",
                        puerta: "",
                        codigoPostal: "",
                        provincia: "",
                        comunidadAutonoma: "",
                        telefonoFijo: "",
                        telefonoMovil: "",
                        email: "",
                        createdAt: "",
                        id: "",
                        uuidId: "",
                        esActiva: false,
                        esAprobada: false,
                        esEnEspera: false,
                        esFinalizada: false,
                        esFirmada: false,
                        esPresentada: false,
                        expediente: "",
                        fechaActivacion: null,
                        fechaAprobacion: null,
                        fechaFirma: null,
                        fechaFinalizacion: null,
                        fechaPresentacion: null,
                        idUser: null,
                        idEmpresa: null,
                        host: null,
                        port: null,
                        secure: null,
                        usermail: null,
                        pass: null
                    },
                    fechaActivacion: null,
                    fechaAprobacion: null,
                    fechaFirma: null,
                    fechaFinalizacion: null,
                    fechaPresentacion: null,
                    createdAt: null
                });
            }
        } else {

            this.setState({
                ayuntamientoSeleccionado: false,
                inputDisabled: false,
                updateAyuntamientoButtonDisabled: true,
                fechaActivacion: null,
                fechaAprobacion: null,
                fechaFirma: null,
                fechaFinalizacion: null,
                fechaPresentacion: null,
                formAyuntamiento: {
                    municipio: "",
                    codigoINE: "",
                    nombreContacto: "",
                    apellido1Contacto: "",
                    apellido2Contacto: "",
                    area: "",
                    cargo: "",
                    cif: "",
                    tipoVia: "",
                    direccion: "",
                    numFinca: "",
                    portal: "",
                    escalera: "",
                    piso: "",
                    puerta: "",
                    codigoPostal: "",
                    provincia: "",
                    comunidadAutonoma: "",
                    telefonoFijo: "",
                    telefonoMovil: "",
                    email: "",
                    createdAt: "",
                    id: "",
                    uuidId: "",
                    esActiva: false,
                    esAprobada: false,
                    esEnEspera: false,
                    esFinalizada: false,
                    esFirmada: false,
                    esPresentada: false,
                    expediente: "",
                    fechaActivacion: null,
                    fechaAprobacion: null,
                    fechaFirma: null,
                    fechaFinalizacion: null,
                    fechaPresentacion: null,
                    idUser: null,
                    idEmpresa: null,
                    host: null,
                    port: null,
                    secure: null,
                    usermail: null,
                    pass: null
                }
            });
        };
    };

    ///////////////////////////////////////////////////////////////////7

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };

    render() {

        const formAyuntamiento: any = this.state.formAyuntamiento;
        const createdAt = this.state.createdAt

        const fechaActivacion = this.state.fechaActivacion
        const fechaAprobacion = this.state.fechaAprobacion
        const fechaFirma = this.state.fechaFirma
        const fechaFinalizacion = this.state.fechaFinalizacion
        const fechaPresentacion = this.state.fechaPresentacion

        if (this.state.userGroupId == 9999) {
            return (
                <>
                    <Header title={'Alta Ayuntamiento'} />
                    <Container className=" mt--7" fluid>

                        <Card className="mb-4">
                            <CardHeader>
                                <Button
                                    onClick={this.showFiltersToggle}
                                    size="sm"
                                    className="float-right"
                                >
                                    <i className={"ni " + this.getFilterIcon()} />
                                </Button>
                                <h3 className="mb-0">Filtrar</h3>
                            </CardHeader>
                            <Collapse isOpen={this.state.showFilters}>
                                <CardBody>
                                    <Form innerRef={this.formRef}>
                                        <Row>
                                            <Col className="text-right">
                                                <Button onClick={this.clearForm}>
                                                    <i className={"ni ni-fat-remove"} /> Limpiar
                                                </Button>

                                            </Col>
                                        </Row>

                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={this.props.history.goBack}
                                                >
                                                    Atrás
                                                </button>
                                            </Col>
                                            <Col className="col-auto d-flex">
                                                <Card className="p-3 bg-light">
                                                    <FormGroup>
                                                        <Button disabled={this.state.updateAyuntamientoButtonDisabled} id="saveAytoProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                            onClick={() => { this.updateHandler(this.state.saveDataCliente) }}
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-person-lines-fill" style={{ fontSize: 25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Añadir</span>
                                                            <UncontrolledTooltip delay={0} placement="top" target="saveAytoProptooltip">
                                                                AÑADIR AYúNTAMIENTO AL REGISTRO
                                                            </UncontrolledTooltip>
                                                        </Button>
                                                    </FormGroup>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <div>
                                            <CardTitle>DATOS GENERALES AYUNTAMIENTO</CardTitle>
                                        </div>
                                        <Row>
                                            <Col md="3">
                                                <Label className="form-control-label" htmlFor="ayuntamientoRef">
                                                    MUNICIPIO
                                                </Label>
                                                <Input type="select" defaultValue={'DEFAULT'} name="municipio" id="municipio" onChange={this.onChangeFieldAyuntamiento}>
                                                    <option value="">Selecciona un municipio... </option>
                                                    {this.state.municipios.map((item, key) => (
                                                        <option key={key} value={item.key}>{item.value}</option>
                                                    ))
                                                    }
                                                </Input>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="codigoINE">
                                                        INE
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="INE"
                                                        placeholder=""
                                                        name="codigoINE"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.codigoINE}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="cif">
                                                        CIF AYTO
                                                    </Label>
                                                    <Input
                                                        id="cif"
                                                        placeholder=""
                                                        name="cif"
                                                        maxLength={16}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.cif}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                    {this.state.NIFClienteError ? <FormText color="red">Comprobar CIF</FormText> : ''}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="tipoVia">
                                                        Tipo Via
                                                    </Label>
                                                    <Input
                                                        id="tipoViar"
                                                        placeholder=""
                                                        name="tipoVia"
                                                        maxLength={12}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.tipoVia}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="direccion">
                                                        Dirección
                                                    </Label>
                                                    <Input
                                                        id="direccion"
                                                        placeholder=""
                                                        name="direccion"
                                                        maxLength={60}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.direccion}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="numFinca">
                                                        Nº Finca
                                                    </Label>
                                                    <Input
                                                        id="numFinca"
                                                        placeholder=""
                                                        name="numFinca"
                                                        maxLength={10}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.numFinca}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="portal">
                                                        Portal
                                                    </Label>
                                                    <Input
                                                        id="portal"
                                                        placeholder=""
                                                        name="portal"
                                                        maxLength={10}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.portal}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="escalera">
                                                        ESC.
                                                    </Label>
                                                    <Input
                                                        id="escalera"
                                                        placeholder=""
                                                        name="escalera"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.escalera}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="piso">
                                                        Piso
                                                    </Label>
                                                    <Input
                                                        id="piso"
                                                        placeholder=""
                                                        name="piso"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.piso}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="puerta">
                                                        Puerta
                                                    </Label>
                                                    <Input
                                                        id="puerta"
                                                        placeholder=""
                                                        name="puerta"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.puerta}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="codigoPostal">
                                                        C.P.
                                                    </Label>
                                                    <Input
                                                        id="codigoPostal"
                                                        placeholder=""
                                                        name="codigoPostal"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.codigoPostal}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                    {this.state.CodigoPostalTitularError ? <FormText color="red">Por favor compruebe el código postal del titular</FormText> : ''}
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="provincia">
                                                        Provincia
                                                    </Label>
                                                    <Input
                                                        id="provincia"
                                                        placeholder=""
                                                        name="provincia"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.provincia}
                                                        type="text"
                                                        disabled={true}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="comunidadAutonoma">
                                                        C. Autónoma
                                                    </Label>
                                                    <Input
                                                        id="comunidadAutonoma"
                                                        placeholder=""
                                                        name="comunidadAutonoma"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.comunidadAutonoma}
                                                        type="text"
                                                        disabled={true}

                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="createdAt">
                                                        Fecha Alta Registro
                                                    </Label>

                                                    <DatePicker id="createdAt"
                                                        value={createdAt}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "createdAt")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="fechaPresentacion">
                                                        Fecha Presentacion
                                                    </Label>

                                                    <DatePicker id="fechaPresentacion"
                                                        value={fechaPresentacion}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "fechaPresentacion")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>DATOS DE CONTACTO</CardTitle>
                                        </div>
                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreContacto">
                                                        Nombre Contacto
                                                    </Label>
                                                    <Input
                                                        id="nombreContacto"
                                                        placeholder=""
                                                        name="nombreContacto"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.nombreContacto}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido1Contacto">
                                                        Primer Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido1Contacto"
                                                        placeholder=""
                                                        name="apellido1Contacto"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.apellido1Contacto}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="apellido2Contacto">
                                                        Segundo Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido2Contacto"
                                                        placeholder=""
                                                        name="apellido2Contacto"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.apellido2Contacto}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="area">
                                                        Area
                                                    </Label>
                                                    <Input
                                                        id="area"
                                                        placeholder=""
                                                        name="area"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.area}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="cargo">
                                                        Cargo
                                                    </Label>
                                                    <Input
                                                        id="cargo"
                                                        placeholder=""
                                                        name="cargo"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.cargo}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="telefonoFijo">
                                                        Teléfono Contacto
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoFijo"
                                                        placeholder=""
                                                        name="telefonoFijo"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.telefonoFijo}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="telefonoMovil">
                                                        Teléfono Móvil
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoMovil"
                                                        placeholder=""
                                                        name="telefonoMovil"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.telefonoMovil}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="email">
                                                        Correo Contacto
                                                    </Label>
                                                    <Input
                                                        id="email"
                                                        placeholder=""
                                                        name="email"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.email}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>DATOS SERVIDOR CORREO</CardTitle>
                                        </div>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="host">
                                                        Servidor Correo
                                                    </Label>
                                                    <Input
                                                        id="host"
                                                        placeholder=""
                                                        name="host"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.host}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="port">
                                                        Puerto
                                                    </Label>
                                                    <Input
                                                        id="port"
                                                        placeholder=""
                                                        name="port"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.port}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="secure">Seguro</Label>
                                                    <Input type="select" name="secure" id="secure"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.secure}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="usermail">
                                                        Usuario
                                                    </Label>
                                                    <Input
                                                        id="usermail"
                                                        placeholder=""
                                                        name="usermail"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.usermail}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="pass">
                                                        Clave
                                                    </Label>
                                                    <Input
                                                        id="pass"
                                                        placeholder=""
                                                        name="pass"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.pass}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>OTROS DATOS</CardTitle>
                                        </div>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="expediente">
                                                        Expediente
                                                    </Label>
                                                    <Input
                                                        id="expediente"
                                                        placeholder=""
                                                        name="expediente"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.expediente}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esPresentada">Presentada</Label>
                                                    <Input type="select" name="esPresentada" id="esPresentada"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esPresentada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esEnEspera">En Espera</Label>
                                                    <Input type="select" name="esEnEspera" id="esEnEspera"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esEnEspera}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esActiva">Activo</Label>
                                                    <Input type="select" name="esActiva" id="esActiva"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esActiva}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esAprobada">Aprobada</Label>
                                                    <Input type="select" name="esAprobada" id="esAprobada"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esAprobada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esFinalizada">Finalizada</Label>
                                                    <Input type="select" name="esFinalizada" id="esFinalizada"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esFinalizada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esFirmada">Firmada</Label>
                                                    <Input type="select" name="esFirmada" id="esFirmada"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esFirmada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaPresentacion">
                                                        Fecha Presentacion
                                                    </Label>
                                                    <DatePicker id="fechaPresentacion"
                                                        value={fechaPresentacion}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "fechaPresentacion")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                {this.state.alert}
                            </div>
                        </Row>
                    </Container>
                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"} />
                </>
            );
        }
    }
}

export default withRouter(crearAyuntamiento);
