import Reflux from "reflux";
import { UserStore, UserStoreActions } from "../../store/UserStore";
import { removeToken } from "../../utils";
import { withApollo } from "react-apollo";
//import { GET_USER_DATA } from "../../queries/users/users.queries";
import {store} from 'state-pool'
import axios from 'axios';
import qs from 'qs';

class GetUserData extends Reflux.Component {
  constructor(props) {
    super(props);
    this.store = UserStore;
    this.logout = this.logout.bind(this);
  }
  async componentDidMount() {
    const userFromStorage = localStorage.getItem("user");
    const userID = localStorage.getItem("uuidUser")
    const token = localStorage.getItem("token")

    if (userFromStorage) {
      try {
        const parsed = JSON.parse(userFromStorage);

        UserStoreActions.update(parsed);
      } catch (e) {}
    }

    var dataFilters = {
        'userId': userID
}

const valores = JSON.stringify(dataFilters)

    const headers = {
         'Content-Type': 'application/json',
    };
    let direccionFile = "https://api.nextniro.com/api/v1/auth/usuario";

    await axios.get(direccionFile, { headers, params: { valores}})
        .then((result) => {

            //console.log(JSON.stringify(result.data));

                const user = result.data.user;

                store.setState("userGlobal",user)
                localStorage.setItem("user", JSON.stringify(user));
                UserStoreActions.update(user);
        })
        .catch((error) => {
            //console.log(error);
            //console.log('mensaje error --->', error.message)
            //console.log('Failure')
            if (!error.response) {
               // network error
               this.setState({
                   loading: false,
                   loginError: false,
                   networkError: true,
                   sessionError: false,
                   userBlockedError: false
               });
             } else {
               // http status code
               const code = error.response.status
               // response data
               const response = error.response.data
               //console.log('VAlor de code ---> ', code)
               //console.log('valor de response -----> ', response)
               //console.log ('VAlor total -----> ', error)
               if(code == "403"){

                   this.setState({
                       loading: false,
                       loginError: true,
                       networkError: false,
                       sessionError: false,
                       userBlockedError: false
                   });
                                }


                                if (code == "401") {

                                    this.logout()
                                }

             }

            //this.warningAlert()
        });

  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  logout = async () => {

      removeToken();
      localStorage.removeItem("lastFilters");
      localStorage.removeItem("user");
      localStorage.removeItem("lastFiltersEmpre");
      localStorage.removeItem("lastFiltersMulti");
      localStorage.removeItem('lastFiltersMultiGas');
      localStorage.removeItem('lastFiltersGas');
      this.props.client.cache.reset();
      this.props.client.resetStore();
      this.props.history.push(`/auth/login`);

  };

  /////////////////////////////////////////////////////////////////////////////////



  render() {
    return null;
  }
}

export default withApollo(GetUserData);
