import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import ReactBSAlert from "react-bootstrap-sweetalert";
//import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
//import { ResponsiveRadialBar } from '@nivo/radial-bar'
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    CardHeader, Collapse, TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";

import { CSVLink } from "react-csv";
import ReactExport from "react-data-export";
import classnames from "classnames";
import LoadingOverlay from "react-loading-overlay";
// core components
import Header from "../../components/Headers/Header";

//import { withApollo } from "react-apollo";

import { withRouter } from 'react-router-dom';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserStore } from "../../store/UserStore";
import { removeToken } from "../../utils";
import DataTable from 'react-data-table-component';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const sortIcon = <ArrowDownward />;

const columnsDatosGlobal = [

    { selector: row => row.year, name: 'AÑO', sortable: true, width: '100px' },
    {
        selector: row => row.diferencia, name: 'INGR VS PREV', sortable: true, width: '160px', center: true, format: (row => `${Number(row.diferencia ? row.diferencia : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`),
        conditionalCellStyles: [
            {
                when: row => row.diferencia < 0,
                style: {
                    backgroundColor: 'rgba(242, 38, 19, 0.9)',
                    color: 'white',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
            {
                when: row => row.diferencia > 0,
                style: {
                    backgroundColor: 'rgba(63, 195, 128, 0.9)',
                    color: 'white',
                    '&:hover': {
                        cursor: 'not-allowed',
                    },
                },
            },
        ]
    },
    { selector: row => row.ingresosTasasDistribuidora, name: 'INGR. DIST', sortable: true, width: '130px', format: (row => `${Number(row.ingresosTasasDistribuidora ? row.ingresosTasasDistribuidora : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },
    { selector: row => row.ingresosTasasComercializadora, name: 'INGR. COM', sortable: true, width: '130px', format: (row => `${Number(row.ingresosTasasComercializadora ? row.ingresosTasasComercializadora : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },
    { selector: row => row.ingresosOtrasTasas, name: 'INGR. OTRAS', sortable: true, width: '130px', center: true, format: (row => `${Number(row.ingresosOtrasTasas ? row.ingresosOtrasTasas : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },
    { selector: row => row.ingresosTasasTotales, name: 'TOTAL INGR.', sortable: true, width: '130px', format: (row => `${Number(row.ingresosTasasTotales ? row.ingresosTasasTotales : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },
    { selector: row => row.previsionTasasDistribuidoraElectricidad, name: 'PREV. DIST ELEC', sortable: true, width: '130px', center: true, format: (row => `${Number(row.previsionTasasDistribuidoraElectricidad ? row.previsionTasasDistribuidoraElectricidad : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },
    { selector: row => row.previsionTasasComercializadoraElectricidad, name: 'PREV. COM ELEC', sortable: true, width: '130px', center: true, format: (row => `${Number(row.previsionTasasComercializadoraElectricidad ? row.previsionTasasComercializadoraElectricidad : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },
    { selector: row => row.previsionTasasDistribuidoraGas, name: 'PREV. DIST GAS', sortable: true, width: '130px', center: true, format: (row => `${Number(row.previsionTasasDistribuidoraGas ? row.previsionTasasDistribuidoraGas : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },
    { selector: row => row.previsionTasasComercializadoraGas, name: 'PREV. COM GAS', sortable: true, width: '130px', center: true, format: (row => `${Number(row.previsionTasasComercializadoraGas ? row.previsionTasasComercializadoraGas : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },
    { selector: row => row.previsionOtrasTasas, name: 'PREV. OTRAS', sortable: true, width: '130px', center: true, format: (row => `${Number(row.previsionOtrasTasas ? row.previsionOtrasTasas : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },
    { selector: row => row.previsionTasasTotales, name: 'TOTAL PREV.', sortable: true, width: '130px', center: true, format: (row => `${Number(row.previsionTasasTotales ? row.previsionTasasTotales : 0).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })} €`) },

];

const columnsDatosClientesAgrupados = [

    { selector: row => row.nombreEmpresaEnergia, name: 'DISTRIB/COMERCIALIZ', sortable: true, width: '220px', format: (row => capitalize(row.nombreEmpresaEnergia)) },
    { selector: row => row.numDocumentoEmpresaEnergia, name: 'CIF', sortable: true, width: '120px', center: true, format: (row => capitalize(row.numDocumentoEmpresaEnergia)) },
    {
        selector: row => row.importe, name: 'IMPORTE', sortable: true, width: '120px', center: true, format: (row => `${Number(row.importe ? row.importe : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} €`)
    },
    { selector: row => row.fecFactura, name: 'FECHA FACTURA', sortable: true, width: '140px', center: true, format: (row => moment(row.fecFactura).isValid() ? moment(row.fecFactura).format('DD-MM-YYYY') : "") },
    { selector: row => row.idFactura, name: 'ID FACTURA', sortable: true, width: '140px', center: true },
];

const columnsDatosConsumosAnual = [
    {
        selector: row => row.liquidacion, name: 'AÑO', sortable: false, width: '100px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        selector: row => row.importe, name: 'INGRESOS', sortable: false, width: '160px', center: true, format: (row => `${Number(row.importe ? row.importe : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} €`)
    },
];
const columnsDatosConsumosAnualIngresos = [
    {
        selector: row => row['id'], name: 'AÑO', sortable: false, width: '100px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        selector: row => row['value'], name: 'INGRESOS', sortable: false, width: '160px', center: true, format: (row => `${Number(row['value'] ? row['value'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} €`)
    }
];


const columnsDatosConsumosAnualComercializadora = [

    {
        selector: row => row.nombreEmpresaEnergia, name: 'DISTRIB/COMERCIALIZ', sortable: true, width: '220px', format: (row => capitalize(row.nombreEmpresaEnergia)), style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row.numDocumentoEmpresaEnergia, name: 'CIF', sortable: true, width: '120px', center: true, format: (row => capitalize(row.numDocumentoEmpresaEnergia)) },
    { selector: row => row.facturas, name: 'Nº FACTURAS', sortable: true, width: '120px', center: true },
    {
        selector: row => row['importe'], name: 'INGRESOS', sortable: true, width: '160px', center: true, format: (row => `${Number(row['importe'] ? row['importe'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} €`)
    },

];

const columnsDatosNotificadosComercializadora = [

    {
        selector: row => row.comercializadora, name: 'DISTRIB/COMERCIALIZ', sortable: true, width: '220px', format: (row => capitalize(row.comercializadora)), style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row.totalFacturas, name: 'Nº FACTURAS', sortable: true, width: '120px', center: true, format: (row => Number(row.totalFacturas)) },
    {
        selector: row => row.importeTasasTotal, name: 'IMPORTE NOTIFICADO', sortable: true, width: '160px', center: true, format: (row => `${Number(row.importeTasasTotal ? row.importeTasasTotal : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} €`)
    },

];

const columnsDatosNotificadosComercializadoraPorcentaje = [

    {
        selector: row => row.comercializadora, name: 'COMERCIALIZ', sortable: true, width: '220px', format: (row => capitalize(row.comercializadora)), style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        selector: row => row.porcentaje, name: '%', sortable: true, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        selector: row => row.energiaDistribucion, name: 'ENERGIA', sortable: true, width: '160px', center: true, format: (row => `${Number(row.energiaDistribucion ? row.energiaDistribucion : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} `)
    },
    {
        selector: row => row.importeTotal, name: 'INGRESO DIST. NOTIFICADO', sortable: true, width: '160px', center: true, format: (row => `${Number(row.importeTotal ? row.importeTotal : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} €`)
    },
    {
        selector: row => row.importeTasasTotalDistribuidora, name: 'TASAS DISTRIBUIDORA', sortable: true, width: '160px', center: true, format: (row => `${Number(row.importeTasasTotalDistribuidora ? row.importeTasasTotalDistribuidora : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} €`)
    },
    {
        selector: row => row.importeTotalComercializadora, name: 'INGRESO COMER. NOTIFICADO', sortable: true, width: '160px', center: true, format: (row => `${Number(row.importeTotalComercializadora ? row.importeTotalComercializadora : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} €`)
    },
    {
        selector: row => row.importeTasasTotalComercializadora, name: 'TASAS COMERCIALIZADORA', sortable: true, width: '160px', center: true, format: (row => `${Number(row.importeTasasTotalComercializadora ? row.importeTasasTotalComercializadora : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2, useGrouping: true
        })} €`)
    },
];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);


class analisisRealvsPrevisto extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            razonSocial: "",
            remesaSearch: "",
            cifClienteSearch: "",
            cifEmisorSearch: "",
            tipoEstadoSearch: "",
            facturaDesde: "",
            facturaHasta: "",
            municipioBuscar: ""
        },
        UserStore: {},
        resumenIngresos: [{
            liquidacion: 2018,
            importe: 0
        }],
        tasasrecaudadasporempresa: [{
            liquidacion: 2018,
            data: [{
                totalFacturas: 0,
                importe: 0,
                comercializadora: ""
            }]
        }],
        tasasrecaudadasporempresaporcentaje:[{
            year: 2018,
            data: [{
                comercializadora: 'SIN DATOS',
                importeTotal: 0,
                importeTasasTotalComercializadora: 0,
                porcentaje: 0,
                importeTasasTotalDistribuidora: 0,
                ingresosComercializadora: 0,
                energiaDistribucion: 0,
                importeTotalComercializadora: 0
            }]
        }],
        tasasNotificadasDistribuidora: [{
            year: 2018,
            data: [{
                totalFacturas: "0",
                importeTasasTotal: 0,
                nombreEmpresaEnergia: "",
                numDocumentoEmpresaEnergia: ""
            }]

        }],
        resumenIngresosAgrupados: [{
            costeTasasComercializadora: 0,
            costeTasasDistribuidora: 0,
            costesOtrasTasas: 0,
            tasasTotales: 0,
            year: 2018
        }],
        ingresosPrevistosAnual: [{
            costeTasasComercializadora: 0,
            costeTasasDistribuidora: 0,
            energia: 0,
            tasasTotales: 0,
            year: 2018
        }],
        tabSelected: 2018,
        tabSelectedString: "2018",
        resumenGlobal: [{
            year: 2018,
            ingresosTasasComercializadora: 0.0,
            ingresosTasasDistribuidora: 0,
            ingresosOtrasTasas: 0,
            ingresosTasasTotales: 0,
            previsionTasasComercializadora: 0,
            previsionTasasDistribuidora: 0,
            previsionOtrasTasas: 0,
            previsionTasasTotales: 0,
            diferencia: 0
        }],
        outputDataResumenTasasChart: [{ id: 2018, ingresos: 0, previsto: 0, diferencia: 0, color: "" }],
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipocliente: "",
        facturaDetailTotal: [],
        facturaSeleccionada: false,
        idCliente: '',
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        buscarButtonDisabled: true,
        CIFClienteError: false,
        show: false,
        imgCollection: [],
        facturaButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        cifErrorEmisor: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        facturaDesde: '',
        facturaHasta: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        emailError: false,
        nombreTitularError: false,
        NIFError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        municipios: [{ id: "", valor: 0 }],
        cnaeAux: "",
        alert: null
    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.container = React.createRef<HTMLDivElement>();
        this.logout = this.logout.bind(this);
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.toggleNavs = this.toggleNavs.bind(this);
    }



    componentDidMount() {
        const user = localStorage.getItem("user");
        if (user) {
            const parsed = JSON.parse(user);
            this.setState({
                user: parsed,
            });
        }
        this.setState({
            ...this.state,
        });
    };

    UNSAFE_componentWillReceiveProps() {
        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))
        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;
        const userGroupId = usuario.user_group_id
        const userId = usuario.id

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };
        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId
        });
    };

    //////////////////////////////////////////////////////////////////////////////

    toggleNavs = (tab) => {

        if (this.state.activeTab !== tab) {
            this.setState({
                tabSelected: tab,
                tabSelectedString: tab.toString(),
            });
        }

    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    warningAlertNoData = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };


    faltaDatos = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos en los campos de municipio y/o distribuidora
                </ReactBSAlert>
            )
        });
    };

    /////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    /////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        var filtersData = { ...this.state.filters };
        filters[name] = value;

        if (event.target.name === 'municipio') {
            this.setState({
                buscarButtonDisabled: false
            })
        };
        filtersData[name] = value;
        this.setState({ filters: filtersData });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;
        this.setState({ showFilters });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////    

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        const filters = {};
        this.setState({
            filters: {
                remesaSearch: '',
                cifClienteSearch: '',
                idSearch: ''
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            facturaSeleccionada: false,
            facturaDetailTotal: [],
            toggledClearRows: !this.state.toggledClearRows,
            createdAt: '',
            updatedAt: ''
        });

        this.formRef.current.reset();

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    buscarFacturas = async () => {

        this.setState({
            isLoading: true,
        })

        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }
        
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""

        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""

        var dataFilters = {
            'codigoIne': codigoINE
        };

        const headers = {

            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/tasas/facturacionanalisisayuntamiento";

        axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {

                if (result.data.length > 0) {
                    let data = result.data[0]
                    var xMax = Math.max.apply(null, data.resumenIngresos.map(function (o) { return parseInt(o.liquidacion); }));
                    var outputDataResumenTasasChart: any = []
                    for (var i = 0; i < data.resumenGlobal.length; ++i) {
                        var color = ["#2ec7c9", "#b6a2de", "#5ab1ef", "#ffb980", "#d87a80", "#8d98b3", "#e5cf0d", "#97b552", "#95706d", "#dc69aa", "#07a2a4", "#9a7fd1", "#588dd5", "#f5994e", "#c05050", "#59678c", "#c9ab00", "#7eb00a", "#6f5553", "#c14089"];
                        var rand = color[(Math.random() * color.length) | 0]
                        outputDataResumenTasasChart.push({ id: data.resumenGlobal[i].year, ingresos: data.resumenGlobal[i].ingresosTasasTotales, ingresosColor: "hsl(320, 70%, 50%)", previsto: data.resumenGlobal[i].previsionTasasTotales, previstoColor: "hsl(322, 70%, 50%)", diferencia: data.resumenGlobal[i].diferencia, diferenciaColor: "hsl(237, 70%, 50%)" });
                    }
                    this.setState({ resumenGlobal: data.resumenGlobal, tasasrecaudadasporempresaporcentaje: data.dataAgregadoDeclaracionTasasPorcentajeFinal    ,tasasrecaudadasporempresa: data.tasasrecaudadasporempresa, ingresosPrevistosAnual: data.ingresosPrevistosAnual, resumenIngresosAgrupados: data.resumenIngresosAgrupados, tasasNotificadasDistribuidora: data.dataAgregadoDeclaracionTasas , outputDataResumenTasasChart: outputDataResumenTasasChart, tabSelected: xMax, tabSelectedString: xMax.toString(), isLoading: false });
                } else {
                    this.warningAlertNoData()
                };
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            reftp?.scrollIntoView({ behavior: "smooth" })

        } else {

            this.setState({
                show: false
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChangeDateFilter = (value, formattedValue, id) => {
        var formData = { ...this.state.filters };
        const name = id;
        var valueDay = formattedValue
        if (name == 'facturaDesde') {
            this.setState({ facturaDesde: value });
            this.setState({
                buscarButtonDisabled: false,
            })
        }

        if (name == 'facturaHasta') {
            this.setState({ facturaHasta: value });
            this.setState({
                buscarButtonDisabled: false,
            })
        }

        formData[name] = valueDay;
        this.setState({ filters: formData });
    }

    ////////////////////////////////////////////////////////////////////////////////////////

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };

    renderRates() {

        var indexyear = this.state.resumenIngresos.map(object => object.liquidacion).indexOf(this.state.tabSelected)
        const styleFullWith = { "width": "100%" };

        var datosResumenIngresos: any = []
        if (indexyear !== -1) {
            datosResumenIngresos = this.state.resumenIngresos[indexyear]
            datosResumenIngresos = datosResumenIngresos
        }

        indexyear = this.state.resumenIngresos.map(object => object.liquidacion).indexOf(this.state.tabSelected)
        var resumenIngresos: any = []
        if (indexyear !== -1) {
            //resumenEnergia = this.state.resumenEnergia[indexyear]
            let id = this.state.resumenIngresos[indexyear].liquidacion
            let value = this.state.resumenIngresos[indexyear].importe
            resumenIngresos.push({ id: id, value: value })
        }

        indexyear = this.state.tasasrecaudadasporempresa.map(object => object.liquidacion).indexOf(this.state.tabSelected)

        var tasasrecaudadasporempresa: any = []
        if (indexyear !== -1) {
            tasasrecaudadasporempresa = this.state.tasasrecaudadasporempresa[indexyear]
            tasasrecaudadasporempresa = tasasrecaudadasporempresa.data
        }


        indexyear = this.state.tasasrecaudadasporempresaporcentaje.map(object => object.year).indexOf(this.state.tabSelected)

        var tasasrecaudadasporempresaporcentaje: any = []
        if (indexyear !== -1) {
            tasasrecaudadasporempresaporcentaje = this.state.tasasrecaudadasporempresaporcentaje[indexyear]
            tasasrecaudadasporempresaporcentaje = tasasrecaudadasporempresaporcentaje.data
        }



        indexyear = this.state.tasasNotificadasDistribuidora.map(object => Number(object.year)).indexOf(this.state.tabSelected)
        var tasasNotificadasDistribuidora: any = []

        if (indexyear !== -1) {
            tasasNotificadasDistribuidora = this.state.tasasNotificadasDistribuidora[indexyear]
            tasasNotificadasDistribuidora = tasasNotificadasDistribuidora.data
        }

        var ingresosTotales = 0
        var previstosTotales = 0
        var diferencia = 0

        indexyear = this.state.resumenGlobal.map(object => object.year).indexOf(this.state.tabSelected)

        if (indexyear !== -1) {
            //resumenEnergia = this.state.resumenEnergia[indexyear]
            ingresosTotales = this.state.resumenGlobal[indexyear].ingresosTasasTotales
            previstosTotales = this.state.resumenGlobal[indexyear].previsionTasasTotales
            diferencia = this.state.resumenGlobal[indexyear].diferencia

        }

        var energia = 0;
        var ingresos = 0;

        var datosvarios: any = []
        datosvarios.push({ energia: energia, ingresos: ingresos })

        return (
            <React.Fragment> <TabPane tabId={this.state.tabSelectedString}>

                <Row>
                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Ingresos Recibidos Agrupados Por Empresas  {this.state.tabSelectedString}
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnualComercializadora}
                                        data={tasasrecaudadasporempresa}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                        pagination
                                        paginationPerPage={12}
                                        subHeader
                                        subHeaderComponent={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <CSVLink
                                                    data={tasasrecaudadasporempresa}
                                                    filename={"relacionEmpresasIngresosRecibidosAño.csv"}
                                                    className="btn btn-default btn-sm btn-circle"
                                                    color="default"
                                                    target="_blank"
                                                    separator={";"}
                                                >

                                                    <span className="btn-inner--icon">
                                                        <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                    </span>
                                                    <span className="btn-inner--text">Fichero CSV</span>

                                                </CSVLink>
                                            </div>
                                        }
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col> 

                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Importe Tasas Notificados Agrupados Por Empresas  {this.state.tabSelectedString}
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosNotificadosComercializadora}
                                        data={tasasNotificadasDistribuidora}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                        pagination
                                        paginationPerPage={12}
                                        subHeader
                                        subHeaderComponent={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <Col className="col-auto pt-2 pb-2 ml-4">
                            <ExcelFile filename="Resumen_comercializadoras_ingresos_ayuntamiento" element={<Button color="primary"><i className={"bi bi-file-earmark-spreadsheet"} /> Bajar Excel</Button>}>
                                        <ExcelSheet data={tasasNotificadasDistribuidora} name="Tasas Anual Com">
                                            <ExcelColumn label="COMERCIALIZADORA" value="comercializadora" />
                                            <ExcelColumn label="NUMERO FACTURAS" value="totalFacturas" />
                                            <ExcelColumn label="IMPORTE TASAS" value={(col)=> col.importeTasasTotal.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} />
                                        </ExcelSheet>
                                    </ExcelFile>
                            </Col>
                                            </div>
                                        }
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col> 
                </Row>
                <Row>

                <Col sm="9">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Importe Tasas Notificados Agrupados Por Empresas  {this.state.tabSelectedString}
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosNotificadosComercializadoraPorcentaje}
                                        data={tasasrecaudadasporempresaporcentaje}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                        pagination
                                        paginationPerPage={12}
                                        subHeader
                                        subHeaderComponent={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <Col className="col-auto pt-2 pb-2 ml-4">
                            <ExcelFile filename="Resumen_comercializadoras_ingresos_ayuntamiento" element={<Button color="primary"><i className={"bi bi-file-earmark-spreadsheet"} /> Bajar Excel</Button>}>
                                        <ExcelSheet data={tasasrecaudadasporempresaporcentaje} name="Tasas Anual Com">
                                            <ExcelColumn label="COMERCIALIZADORA" value="comercializadora" />
                                            <ExcelColumn label="NUMERO FACTURAS" value="totalFacturas" />
                                            <ExcelColumn label="IMPORTE TASAS" value={(col)=> col.importeTasasTotal.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} />
                                        </ExcelSheet>
                                    </ExcelFile>
                            </Col>
                                            </div>
                                        }
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col> 
                </Row>
                <Row>
                <Col md="3" xl="3">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            INGRESOS TOTALES
                                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0">
                                            {ingresosTotales.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €

                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                            <i className="bi bi-currency-euro" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Total suma tasas</span>
                                </p>
                            </CardBody>
                        </Card>
                        </Col>
                        <Col md="3" xl="3">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            INGRESOS PREVISTOS TOTALES
                                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0">
                                            {previstosTotales.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €

                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                            <i className="bi bi-currency-euro" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Total suma tasas</span>
                                </p>
                            </CardBody>
                        </Card>
                        </Col>
                        <Col md="3" xl="3">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            DIFERENCIA                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0">
                                            {diferencia.toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                useGrouping: true
                                            })} €

                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                            <i className="bi bi-currency-euro" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Total suma tasas</span>
                                </p>
                            </CardBody>
                        </Card>


                    </Col>
                </Row>
                {/*<Row className="card-deck" style={styleFullWith}>

                    <Card className="mt-3 mb-3 p-3 card-document">
                        <CardTitle className="mt-4 info-title"><h2>Ingresos Registrados Ayuntamiento</h2></CardTitle>
                        <Row className="mt-4">
                            <Col className="col-auto pt-2 pb-2 mr-2">
                                <h2>Ingresos:</h2>
                            </Col>

                            <Col className="col-auto d-flex bg-units radius-pot text-center align-items-center">
                                <h3 className="mt-2 mb-2 justify-content-center align-self-center text-center">{energia.toLocaleString('es-ES', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} €</h3>
                            </Col>

                            <Col className="col-auto pt-2 pb-2 ml-4">
                                <ExcelFile filename="Resumen_comercializadoras_ingresos_ayuntamiento" element={<Button color="primary"><i className={"bi bi-search"} /> Bajar Excel</Button>}>
                                    <ExcelSheet data={tasasrecaudadasporempresa} name="Tasas Anual Com">
                                        <ExcelColumn label="COMERCIALIZADORA" value="nombreEmpresaEnergia" />
                                        <ExcelColumn label="CIF" value="numDocumentoEmpresaEnergia" />
                                        <ExcelColumn label="NUMERO FACTURAS" value="facturas" />
                                        <ExcelColumn label="IMPORTE" value="importe" />
                                    </ExcelSheet>
                                </ExcelFile>
                            </Col>

                        </Row>

                    </Card>
                            </Row>*/}
            </TabPane>
            </React.Fragment>
        )
    };




    render() {

        const isLoading = this.state.isLoading;
        const styleFullWith = { "width": "100%" };
        const ratesHtml = this.renderRates();
        const consumosAnuales = this.state.tasasrecaudadasporempresa;

        const chartIngresosvsPrevisto = this.state.outputDataResumenTasasChart;

        console.log('valor de this.state: ', this.state)

        return (
            <>
                <Header title={'Análisis Ingresos Tasas Vs Recaudación Prevista'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarFacturas}
                                                >
                                                <i className={"bi bi-search"} /> Analizar
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>
                                        <FormGroup>
                                            <Col>

                                            </Col>
                                        </FormGroup>

                                    </Row>
                                    <div>
                                        <LoadingOverlay
                                            active={isLoading}
                                            spinner
                                            text="Cargando...">



                                            <Card className="shadow">

                                                <DataTable
                                                    title="RESUMEN INGRESOS VS PREVISTOS"
                                                    columns={columnsDatosGlobal}
                                                    data={this.state.resumenGlobal}
                                                    //selectableRowsComponent={radio} // Pass the function only
                                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                    noDataComponent={"Sin datos para mostrar"}
                                                    clearSelectedRows={this.state.toggledClearRows}
                                                    highlightOnHover
                                                    pagination
                                                    paginationPerPage={12}
                                                    striped
                                                    pointerOnHover
                                                    selectableRowsHighlight
                                                    customStyles={customStyles}
                                                    subHeader
                                                    subHeaderComponent={
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                                            <CSVLink
                                                                data={this.state.resumenGlobal}
                                                                filename={"analisisIngresovsPrevisto.csv"}
                                                                className="btn btn-default btn-sm btn-circle"
                                                                color="default"
                                                                target="_blank"
                                                                separator={";"}
                                                            >

                                                                <span className="btn-inner--icon">
                                                                    <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                                </span>
                                                                <span className="btn-inner--text">Fichero CSV</span>

                                                            </CSVLink>
                                                        </div>

                                                    }
                                                    progressComponent={<Circular />}
                                                />
                                            </Card>
                                        </LoadingOverlay>
                                    </div>
                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>
                    {this.state.alert}
                </Container>
                <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="section-tabs"
                    tabs
                    role="tablist"
                >
                    {
                        consumosAnuales.map(item => <NavItem key={item.liquidacion}>
                            <NavLink
                                key={item.liquidacion}
                                aria-selected={this.state.tabSelected === item.liquidacion}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabSelected === item.liquidacion,
                                })}
                                onClick={() => this.toggleNavs(item.liquidacion)}
                                href="#"
                                role="tab"
                            >
                                {item.liquidacion}
                            </NavLink>
                        </NavItem>)

                    }
                </Nav>

                <TabContent activeTab={this.state.tabSelectedString}>

                    {ratesHtml}

                </TabContent>

                <Row>
                    <Container fluid>
                        <Row className="margin-reset w-100">

                            <Col md="12" xl="12">
                                <Card className="card-stats" style={{ height: 400 }}>
                                    <ResponsiveBar
                                        data={chartIngresosvsPrevisto}
                                        //colors={{ datum: 'data.color' }}
                                        colors={{ scheme: 'nivo' }}
                                        keys={[
                                            'ingresos',
                                            'previsto',
                                            'diferencia'
                                        ]}
                                        indexBy="id"
                                        groupMode="grouped"
                                        margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
                                        padding={0.3}
                                        valueScale={{ type: 'linear' }}
                                        indexScale={{ type: 'band', round: true }}
                                        borderColor={{
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    1.6
                                                ]
                                            ]
                                        }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Años',
                                            legendPosition: 'middle',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 100,
                                            tickRotation: 0,
                                            legend: 'Ingresos Vs Previsto €/año',
                                            legendPosition: 'middle',
                                            legendOffset: -20
                                        }}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor={{
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    1.6
                                                ]
                                            ]
                                        }}
                                        valueFormat={value =>
                                            `${Number(value).toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                useGrouping: true
                                            })} €`
                                        }
                                        legends={[

                                        ]}
                                        tooltip={({ label, value }) => (
                                            <div
                                                style={{
                                                    padding: 12,
                                                    background: '#8d98b3',

                                                }}
                                            >
                                                <strong>
                                                    {label}: {Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 2,
                                                        useGrouping: true
                                                    })} €
                                                </strong>
                                            </div>
                                        )}
                                        role="application"
                                        ariaLabel="Gráfico resumen tasas de forma anual"
                                        barAriaLabel={function (e) { return "Tasas Año " + e.year + ": " + e.value }}
                                    />

                                </Card>
                            </Col>


                        </Row>
                    </Container>
                </Row>

                {this.state.alert}
            </>
        );
    }
}

export default withRouter(analisisRealvsPrevisto);
