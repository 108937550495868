const TOKEN_KEY = 'token';
const UUID_KEY = 'uuidUser'

export const setToken = (token, uuidUser) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(UUID_KEY, uuidUser)
}

export const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}
