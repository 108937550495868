import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link
} from 'react-router-dom';
import { CSVLink } from "react-csv";
// core components
import Header from "../../components/Headers/Header";
import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";

import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';



var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const sortIcon = <ArrowDownward />;
const estado = (s) => {
    if (s !== true) return <i className="fas fa-exclamation-circle fa-2x" style={{ color: "#ff1900" }} ></i>
    return <i className="far fa-check-circle fa-2x" style={{ color: "#7ee06e" }} ></i>
}

const columnsDatosEmpresas = [
    { selector: row => row.nif, name: 'NIF', sortable: true, width: '150px', format: (row => capitalize(row.nif)) },
    { selector: row => row.empresa, name: 'EMPRESA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.empresa)) },
    { selector: row => row.tipo, name: 'TIPO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipo)) },
    { selector: row => row.tipoServicio, name: 'SERVICIO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipoServicio)) },
    { selector: row => row.numOrden, name: 'ORDEN', sortable: true, width: '100px', center: true, format: (row => capitalize(row.numOrden)) },
    { selector: row => row.correoContacto, name: 'CORREO', sortable: true, width: '150px', center: true, format: (row => capitalize(row.correoContacto)) },
    { selector: row => row.nombreContacto, name: 'CONTACTO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.nombreContacto)) },
    { selector: row => row.telefonoContacto, name: 'TELEFONO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.telefonoContacto)) }
];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);


class Empresas extends Reflux.Component<any, any> {
    public formRef: any = null;
    lastFilters = {};
    state = {
        filters: {
            apellidosUsuariosSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            usuariosDesde: ""
        },
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        empresaDetailTotal: [],
        empresaSeleccionado: false,
        passwordModificado: false,
        idUser: '',
        empresaDetail: {
            id: "",
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: ""
        },
        formEmpresa: {
            id: "",
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: ""
        },
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataEmpresa: false,
        updateDataEmpresa: false,
        updateEmpresaButtonDisabled: true,
        saveEmpreButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        usuariosDesde: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        IBANError: false,
        alert: null

    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);
    }


    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))
        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;
        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };
        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);
    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el usuario?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos del usuario?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Empresa actualizada correctamente
                </ReactBSAlert>
            )
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        var filtersData = { ...this.state.filters };
        filters[name] = value;
        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/

        if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputIdDisabled: false
            })
        } else if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length > 0) {

            this.setState({
                inputIdDisabled: true,
                buscarButtonDisabled: false

            })

        }


        if (event.target.name === 'NIFSearch' && event.target.value.length == 0) {

            this.setState({

                NIFError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'NIFSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonDisabled: false,
                })

            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonDisabled: true,
                })
            }
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null
            this.setState({
                buscarButtonDisabled: true
            })

        } else if (event.target.name === 'tipoEstadoSearch' && event.target.value !== 'Selecciona...') {
            this.setState({
                buscarButtonDisabled: false
            })

        };

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldUsuario = (event) => {
        event.persist()

        var formData = { ...this.state.formEmpresa };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        const nameFilters = ['idEmpresa', 'nif', 'tipo', 'tipoServicio', 'numOrden', 'empresa', 'direccion', 'codigoPostal', 'municipio', 'provincia', 'telefonoAtt', 'telefonoUrgencias', 'ambito', 'fechaAlta', 'fechaBaja', 'web', 'estado', 'correoContacto', 'nombreContacto', 'telefonoContacto', 'fechAltaDatos', 'usuario']
        const optionsFilters = ['user_group_id', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles'];

        var municipio = ""

        if (this.state.empresaSeleccionado) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ saveDataEmpresa: true });
                this.setState({
                    saveEmpreButtonDisabled: false,
                    updateEmpresaButtonDisabled: false
                })
            }
            if (event.target.name == 'empresa' && event.target.value.length > 0) {

                this.setState({ saveDataEmpresa: true });
                this.setState({
                    saveEmpreButtonDisabled: false,
                    updateEmpresaButtonDisabled: false
                })
            }


            if (event.target.name === 'nif' && event.target.value.length == 0) {
                this.setState({ NIFClienteError: true })
            } else if (event.target.name === 'nif' && event.target.value.length > 0) {
                const result = validateSpanishId(event.target.value)
                if (result === true) {
                    this.setState({
                        saveDataEmpresa: true,
                        NIFClienteError: false
                    });
                    this.setState({
                        saveEmpreButtonDisabled: true,
                        updateEmpresaButtonDisabled: false
                    })
                } else {
                    this.setState({
                        NIFClienteError: true,
                        saveDataEmpresa: false,
                        updateEmpresaButtonDisabled: true
                    })
                }
            }


            if (event.target.name === 'correoContacto') {

                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                const result = pattern.test(event.target.value);

                if (result === true) {
                    this.setState({
                        emailError: false,
                    })
                } else {
                    this.setState({
                        emailError: true
                    })
                }
            };

            if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
                value = null
            }

            if (value === "true" || value == "false") {
                value = JSON.parse(value);
            };

                /*    if (name == 'nombreMunicipioPS') {
                        const municipio: any = this.state.dicMunicipios
                        const resultado = municipio.find(municipio => municipio ?.Nombre === value);
                        const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
                        formData['nombreProvinciaPS'] = resultado.DescProvincia
                        formData['AutonomiaTitular'] = resultado.DescAutonomia
                    }
                */

                formData[name] = value;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //
                //}
                this.setState({
                    saveDataEmpresa: true
                })
                this.setState({ formEmpresa: formData });
            

        }


    };


    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        this.setState({
            filters: {
                apellidosUsuariosSearch: "",
                NIFSearch: "",
                tipoEstadoSearch: "",
                usuariosDesde: ""
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            empresaSeleccionado: false,
            empresaDetailTotal: [],
            passwordModificado: false,
            formEmpresa: {
                id: "",
                idEmpresa: "",
                nif: "",
                tipo: "",
                tipoServicio: "",
                numOrden: "",
                empresa: "",
                direccion: "",
                codigoPostal: "",
                municipio: "",
                provincia: "",
                telefonoAtt: "",
                telefonoUrgencias: "",
                ambito: "",
                fechaAlta: "",
                fechaBaja: "",
                web: "",
                estado: "a",
                correoContacto: "",
                nombreContacto: "",
                telefonoContacto: "",
                fechAltaDatos: "",
                usuario: ""
            },
            toggledClearRows: !this.state.toggledClearRows,
            updateEmpresaButtonDisabled: true,
            saveEmpreButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            saveDataEmpresa: false
        });

        this.formRef.current.reset();

    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarEmpresa = async () => {
        this.setState({
            isLoading: true,
        })

        const token = localStorage.getItem("token")
        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }

        var dataFilters = {
            'empresa': this.state.filters.apellidosUsuariosSearch?.toUpperCase() || "",
            'nif': this.state.filters.NIFSearch?.toUpperCase() || "",
            'tipoServicio': this.state.filters.tipoEstadoSearch.toUpperCase() || ""
        };

        const headers = {
            
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/gestion/empresalist";

        axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {
                //console.log(JSON.stringify(result.data));
                //console.log('resultado del listados')
                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    this.setState({ empresaDetailTotal: result.data.rows });

                } else {
                    this.warningAlertNIF()
                };
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                  });
              });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {

        if (this.state.saveDataEmpresa && (this.state.formEmpresa.empresa && this.state.formEmpresa.nif)) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {

        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        if (this.state.saveDataEmpresa == true) {

            var empresaDataUpdate = {
                'id': this.state.formEmpresa.id,
                'idEmpresa': this.state.formEmpresa.idEmpresa,
                'nif': this.state.formEmpresa.nif,
                'tipo': this.state.formEmpresa.tipo,
                'tipoServicio': this.state.formEmpresa.tipoServicio,
                'numOrden': this.state.formEmpresa.numOrden,
                'empresa': this.state.formEmpresa.empresa?.toUpperCase() || '',
                'direccion': this.state.formEmpresa.direccion?.toUpperCase() || '',
                'codigoPostal': this.state.formEmpresa.codigoPostal,
                'municipio': this.state.formEmpresa.municipio?.toUpperCase() || '',
                'provincia': this.state.formEmpresa.provincia?.toUpperCase() || '',
                'telefonoAtt': this.state.formEmpresa.telefonoAtt,
                'telefonoUrgencias': this.state.formEmpresa.telefonoUrgencias,
                'ambito': this.state.formEmpresa.ambito?.toUpperCase() || '',
                'fechaAlta': this.state.formEmpresa.fechaAlta,
                'fechaBaja': this.state.formEmpresa.fechaBaja,
                'web': this.state.formEmpresa.web,
                'estado': this.state.formEmpresa.estado,
                'correoContacto': this.state.formEmpresa.correoContacto,
                'nombreContacto': this.state.formEmpresa.nombreContacto?.toUpperCase() || '',
                'telefonoContacto': this.state.formEmpresa.telefonoContacto,
                'fechAltaDatos': this.state.formEmpresa.fechAltaDatos,
                'usuario': this.state.formEmpresa.usuario
            }
        
        const headers = {
            
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/gestion/empresaupdate";


        axios.post(direccionFile, empresaDataUpdate, { headers, responseType: 'json' })
            .then((result) => {

                console.log('Actualización correcta', result)
                console.log('Datpos del registro: ', result.data)
                this.successAlert();
                this.setState({
                    inputDisabled: false,
                    empresaSeleccionado: false,
                    empresaDetailTotal: [],
                    saveDataEmpresa: false,
                    saveEmpreButtonDisabled: true,
                    updateEmpresaButtonDisabled: true,
                    passwordModificado: false,
                    createdAt: '',
                    updatedAt: '',
                    toggledClearRows: !this.state.toggledClearRows,
                    formEmpresa: {
                        id: "",
                        idEmpresa: "",
                        nif: "",
                        tipo: "",
                        tipoServicio: "",
                        numOrden: "",
                        empresa: "",
                        direccion: "",
                        codigoPostal: "",
                        municipio: "",
                        provincia: "",
                        telefonoAtt: "",
                        telefonoUrgencias: "",
                        ambito: "",
                        fechaAlta: "",
                        fechaBaja: "",
                        web: "",
                        estado: "a",
                        correoContacto: "",
                        nombreContacto: "",
                        telefonoContacto: "",
                        fechAltaDatos: "",
                        usuario: ""
                    }

                });

            }).catch((error) => {
                this.setState({
                    isLoading: false,
                  });
              });

        await this.buscarEmpresa();
        };
    }



//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

handleChange = state => {
    // eslint-disable-next-line no-console

    if (state.selectedCount === 1) {
        var existeEmpresa = ''
        var empresa = state.selectedRows[0].idEmpresa;
        this.setState({ empresaDetail: state.selectedRows[0] });
        let existeFechaAlta = state.selectedRows[0].createdAt
        let existeFechaBaja = state.selectedRows[0].updatedAt
        existeEmpresa = state.selectedRows[0].idEmpresa

        if (existeFechaAlta && existeFechaBaja) {

            this.setState({
                inputDisabled: true,
                updateEmpresaButtonDisabled: false
            })
        } else if (!existeFechaAlta && !existeFechaBaja) {
            this.setState({
                inputDisabled: false,
                updateEmpresaButtonDisabled: true
            })
        }



        if (existeEmpresa) {
            this.setState({ id: this.state.empresaDetail.id });
            this.setState({
                formEmpresa: {
                    id: state.selectedRows[0].id,
                    idEmpresa: state.selectedRows[0].idEmpresa,
                    nif: state.selectedRows[0].nif,
                    tipo: state.selectedRows[0].tipo,
                    tipoServicio: state.selectedRows[0].tipoServicio,
                    numOrden: state.selectedRows[0].numOrden,
                    empresa: state.selectedRows[0].empresa?.toUpperCase() || '',
                    direccion: state.selectedRows[0].direccion?.toUpperCase() || '',
                    codigoPostal: state.selectedRows[0].codigoPostal,
                    municipio: state.selectedRows[0].municipio?.toUpperCase() || '',
                    provincia: state.selectedRows[0].provincia?.toUpperCase() || '',
                    telefonoAtt: state.selectedRows[0].telefonoAtt,
                    telefonoUrgencias: state.selectedRows[0].telefonoUrgencias,
                    ambito: state.selectedRows[0].ambito?.toUpperCase() || '',
                    fechaAlta: state.selectedRows[0].fechaAlta,
                    fechaBaja: state.selectedRows[0].fechaBaja,
                    web: state.selectedRows[0].web,
                    estado: state.selectedRows[0].estado,
                    correoContacto: state.selectedRows[0].correoContacto,
                    nombreContacto: state.selectedRows[0].nombreContacto?.toUpperCase() || '',
                    telefonoContacto: state.selectedRows[0].telefonoContacto,
                    fechAltaDatos: state.selectedRows[0].fechAltaDatos,
                    usuario: state.selectedRows[0].usuario
                },
                empresaSeleccionado: true,
                inputDisabled: false,
                createdAt: state.selectedRows[0].createdAt
            });

        } else {
            this.setState({
                empresaSeleccionado: false,
                formEmpresa: {
                    id: "",
                    idEmpresa: "",
                    nif: "",
                    tipo: "",
                    tipoServicio: "",
                    numOrden: "",
                    empresa: "",
                    direccion: "",
                    codigoPostal: "",
                    municipio: "",
                    provincia: "",
                    telefonoAtt: "",
                    telefonoUrgencias: "",
                    ambito: "",
                    fechaAlta: "",
                    fechaBaja: "",
                    web: "",
                    estado: "a",
                    correoContacto: "",
                    nombreContacto: "",
                    telefonoContacto: "",
                    fechAltaDatos: "",
                    usuario: ""
                },
                createdAt: null,
                updatedAt: null,
                passwordModificado: false,
            });
        }
    } else {

        this.setState({
            empresaSeleccionado: false,
            inputDisabled: false,
            updateEmpresaButtonDisabled: true,
            saveEmpreButtonDisabled: true,
            passwordModificado: false,
            createdAt: null,
            updatedAt: null,
            formEmpresa: {
                id: "",
                idEmpresa: "",
                nif: "",
                tipo: "",
                tipoServicio: "",
                numOrden: "",
                empresa: "",
                direccion: "",
                codigoPostal: "",
                municipio: "",
                provincia: "",
                telefonoAtt: "",
                telefonoUrgencias: "",
                ambito: "",
                fechaAlta: "",
                fechaBaja: "",
                web: "",
                estado: "a",
                correoContacto: "",
                nombreContacto: "",
                telefonoContacto: "",
                fechAltaDatos: "",
                usuario: ""
            }
        });



    };

}



handleClearRows = () => {
    this.setState({ toggledClearRows: !this.state.toggledClearRows })
}



render() {

    var formEmpresa = this.state.formEmpresa
        return (
            <>
                <Header title={'Información Empresas'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    RAZON SOCIAL/NOMBRE
                                                </Label>
                                                <Input
                                                    id="apellidosUsuariosSearch"
                                                    placeholder="Ingresar Razón Social de la Empresa"
                                                    name="apellidosUsuariosSearch"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.apellidosUsuariosSearch}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NIFSearch">
                                                    NIF
                                                </Label>
                                                <Input
                                                    id="NIFSearch"
                                                    placeholder="Ingresar CIF empresa"
                                                    name="NIFSearch"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.NIFSearch}
                                                    type="text"

                                                />
                                                {this.state.NIFError ? <FormText color="red">Comprobar CIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoEstadoProducto">ESTADO</Label>
                                                <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.tipoEstadoSearch}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="ELEC">ELECTRICIDAD</option>
                                                    <option value="GAS">GAS</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarEmpresa}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE EMPRESAS"
                                            columns={columnsDatosEmpresas}
                                            data={this.state.empresaDetailTotal}
                                            selectableRows
                                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                            selectableRowsSingle
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            onSelectedRowsChange={this.handleChange}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            contextMessage={{ singular: 'empresa', plural: 'empresa', message: 'seleccionada' }}
                                            subHeader
                                            subHeaderComponent={

                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    <CSVLink
                                                        data={this.state.empresaDetailTotal}
                                                        filename={"empresasRegistradas.csv"}
                                                        className="btn btn-default btn-sm btn-circle"
                                                        color="default"
                                                        target="_blank"
                                                        separator={";"}
                                                    >

                                                        <span className="btn-inner--icon">
                                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Fichero CSV</span>

                                                    </CSVLink>
                                                </div>
                                            }
                                            progressComponent={<Circular />}
                                        />
                                    </Card>
                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>




                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>

                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                                <FormGroup>

                                                    <Button disabled={this.state.updateEmpresaButtonDisabled} id="UpdateRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.updateHandler(this.state.saveDataEmpresa) }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-lines-fill" style={{ fontSize: 30 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Actualizar</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="UpdateRepreProptooltip">
                                                            MODIFICAR EMPRESA EXISTENTE
                                                        </UncontrolledTooltip>
                                                    </Button>

                                                    <Link
                                                        color="info" type="button" className="btn btn-primary btn-sm btn-circle"
                                                        data-toggle="modal"
                                                        to={{ pathname: "nueva-empresa" }}
                                                        id="newElecProduct"
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-plus-fill" style={{ fontSize: 30 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Nuevo</span>

                                                        <UncontrolledTooltip delay={0} placement="top" target="newElecProduct">
                                                            CREAR NUEVA EMPRESA
                                                        </UncontrolledTooltip>
                                                    </Link>

                                                </FormGroup>

                                            </Card>
                                        </Col>

                                    </Row>
                                    <div>
                                        <CardTitle>DATOS GENERALES EMPRESA</CardTitle>
                                    </div>

                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="id">
                                                    ID
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="id"
                                                    placeholder=""
                                                    name="nombre"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.id}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="empresa">
                                                    Razón Social
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="empresa"
                                                    placeholder=""
                                                    name="empresa"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.empresa}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="numOrden">
                                                    N Orden
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="numOrden"
                                                    placeholder=""
                                                    name="numOrden"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.numOrden}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="nif">
                                                    CIF
                                                </Label>
                                                <Input
                                                    id="nif"
                                                    placeholder=""
                                                    name="nif"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.nif}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                                {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="direccion">
                                                    Dirección
                                                </Label>
                                                <Input
                                                    id="direccion"
                                                    placeholder=""
                                                    name="direccion"
                                                    maxLength={150}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.direccion}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="codigoPostal">
                                                C. Postal
                                            </Label>
                                            <Input
                                                id="codigoPostal"
                                                placeholder=""
                                                name="codigoPostal"
                                                maxLength={150}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.codigoPostal}
                                                type="text"
                                                disabled={this.state.inputDisabled}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="municipio">
                                                Municipio
                                            </Label>
                                            <Input
                                                id="municipio"
                                                placeholder=""
                                                name="municipio"
                                                maxLength={150}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.municipio}
                                                type="text"
                                                disabled={this.state.inputDisabled}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="provincia">
                                                Provincia
                                            </Label>
                                            <Input
                                                id="provincia"
                                                placeholder=""
                                                name="provincia"
                                                maxLength={150}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.provincia}
                                                type="text"
                                                disabled={this.state.inputDisabled}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>



                                <Row>
                                <Col md="2">
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="nombreContacto">
                                                Nombre Contacto
                                            </Label>
                                            <Input
                                                className="text-primary"
                                                id="nombreContacto"
                                                placeholder=""
                                                name="nombreContacto"
                                                maxLength={80}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.nombreContacto}
                                                type="text"
                                                disabled={this.state.inputDisabled}
                                            />
                                        </FormGroup>
                                    </Col>


                                    <Col md="2">
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="telefonoContacto">
                                                Teléfono Contacto
                                            </Label>
                                            <Input
                                                className="text-primary"
                                                id="telefonoContacto"
                                                placeholder=""
                                                name="telefonoContacto"
                                                maxLength={20}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.telefonoContacto}
                                                type="text"
                                                disabled={this.state.inputDisabled}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup>

                                            <Label className="form-control-label" htmlFor="correoContacto">
                                                Correo Contacto
                                            </Label>
                                            <Input
                                                id="correoContacto"
                                                placeholder=""
                                                name="correoContacto"
                                                maxLength={80}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.correoContacto}
                                                type="text"
                                                disabled={this.state.inputDisabled}

                                            />
                                        </FormGroup>
                                    </Col>
                               
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className="shadow">
                            <CardBody>
                                <div>
                                    <CardTitle>OTROS DATOS</CardTitle>
                                </div>

                                <Row>
                                    <Col md="2" lg="2">
                                        <FormGroup>
                                            <Label className="form-control-label" for="tipo">Tipo Empresa</Label>
                                            <Input type="select" name="tipo" id="tipo"
                                                disabled={this.state.inputDisabled}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.tipo}>
                                                <option selected>Selecciona...</option>
                                                <option value="COM"> Comercializadora</option>
                                                <option value="DIS"> Distribuidora</option>
                                             </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md="2" lg="2">
                                        <FormGroup>
                                            <Label className="form-control-label" for="tipoverde">Tipo Servicio</Label>
                                            <Input type="select" name="tipoServicio" id="tipoServicio"
                                                disabled={this.state.inputDisabled}
                                                onChange={this.onChangeFieldUsuario}
                                                value={formEmpresa.tipoServicio}>
                                                <option selected>Selecciona...</option>
                                                <option value="ELEC">Electricidad</option>
                                                <option value="GAS">Gas</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>




                                </Row>
                            </CardBody>
                        </Card>
                        {this.state.alert}

                    </div>
                </Row>
            </Container>

            </>
            );
}
};
export default withApollo(Empresas);
