import Index from "./views/Index";
import Login from "./views/Auth/Login";
import Profile from "./views/users/Profile";
import Recover from "./views/Auth/Recover";
import Reset from "./views/Auth/Reset";

//import { HomeFilled } from '@ant-design/icons';

import informePreliminarMunicipio from "./views/tasasPre/informePreliminar"
import informePreliminarMunicipioConsultor from "./views/tasasConsultor/informePreliminarConsultor"
import informePreliminarMunicipioDistribuidoraConsultor from "./views/tasasConsultor/informePreliminarDistribuidoraConsultor"
import informePreliminarMunicipioComercializadoraConsultor from "./views/tasasConsultor/informePreliminarComercializadoraConsultor"
import cargaElectrico from "./views/gestionDatos/cargaElectricidad";
import cargaDatosLiquidaciones from "./views/gestionDatos/cargaDatosLiquidaciones";
import cargaEmpresaServicios from "./views/gestionDatos/cargaEmpresas";
import informeResumenMunicipioConsultor from "./views/tasasConsultor/informePreliminarResumenConsultor";
import informeResumenMunicipioConsultorActual from "./views/tasasConsultor/informePreliminarResumenConsultorActual";

import Ayuntamiento from "./views/adminTasasBackend/ayuntamiento";
import crearAyuntamiento from "./views/adminTasasBackend/crearAyuntamiento";

import Usuarios from "./views/adminTasasBackend/usuarios";
import crearUsuarios from "./views/adminTasasBackend/crearUsuarios";

import Empresas from "./views/adminTasasBackend/empresas";
import crearEmpresas from "./views/adminTasasBackend/crearEmpresas";

import gestionFicheros from "./views/adminTasasBackend/gestionFicheros";

import facturasCliente from "./views/gestionTasas/facturaCliente"

import declaracionesEmpresas from "./views/gestionTasas/declaracionesEmpresaServicios"

import analisisRealvsPrevisto from "./views/gestionTasas/analisisRealvsPrevisto"
import gestionComunicacionEmpresas from "./views/gestionTasas/gestionComunicacionEmpresas"
import seguimientoGestionComunicacionEmpresas from "./views/gestionTasas/seguimientogestionComunicacionEmpresas"

import gestionContestacionEmpresas from "./views/gestionTasas/gestionContestacionEmpresas"
import crearContestacionEmpresas from "./views/gestionTasas/creargestionComunicacionEmpresas"

import planificacionTareas from "./views/planificacion/calendarioProcesos"

var routes = [
  {
    path: "/index",
    name: "Inicio",
    icon: "bi bi-app-indicator",
    component: Index,
    layout: "/admin",
    key: 'index',
  },
  {
    path: "/user-profile",
    name: "Perfil",
    icon: "pe-7s-graph1",
    component: Profile,
    invisible: true,
    layout: "/admin",
    key: 'user-profile'
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25",
    component: Login,
    layout: "/auth",
    key: 'login'
  },
  {
    path: "/recover",
    name: "Recover",
    icon: "ni ni-key-25",
    component: Recover,
    layout: "/auth",
    key: 'recover'
  },
  {
    path: "/reset",
    name: "Reset",
    icon: "ni ni-key-25",
    component: Reset,
    layout: "/auth",
    key: 'reset'
  },
  {
    collapse: true,
    name: "Tasas Preliminar",
    miniName: "TP",
    state: "MultiLinea",
    hasSubmenu: true,
    icon: "bi bi-lightning-charge-fill text-yellow",
    layout: "/admin",
    key: 'tasasPre',
    views: [
      /*     {
             path: "/tasaspre",
             name: "Estudio Preliminar",
             icon: "bi bi-lightning-charge-fill text-yellow",
             component: informePreliminarMunicipio,
             layout: "/admin",
             key: 'tasas-preliminar',
           },*/
      {
        path: "/consultortasaspre",
        name: "Estudio Preliminar Consultor",
        icon: "bi bi-lightning-charge-fill text-yellow",
        component: informePreliminarMunicipioConsultor,
        layout: "/admin",
        key: 'consultor-tasas-preliminar',
      }
    ]
  },
  {
    collapse: true,
    name: "Tasas Estudios",
    miniName: "CTP",
    state: "MultiLineaTE",
    hasSubmenu: true,
    icon: "bi bi-lightning-charge-fill text-yellow",
    layout: "/admin",
    key: 'consultortasasPre',
    views: [
      {
        path: "/distribuidoraingresosestudio",
        name: "Distribuidora. Estudio Ingresos",
        icon: "bi bi-lightning-charge-fill text-yellow",
        component: informePreliminarMunicipioDistribuidoraConsultor,
        layout: "/admin",
        key: 'distribuidora-administrador-tasas-preliminar',
      },
      {
        path: "/comercializadoraingresosestudio",
        name: "Comercializadora. Estudio Ingresos",
        icon: "bi bi-lightning-charge-fill text-yellow",
        component: informePreliminarMunicipioComercializadoraConsultor,
        layout: "/admin",
        key: 'comercializadora-administrador-tasas-preliminar',
      },
      {
        path: "/resumeningresosestudio",
        name: "Resumen. Estudio Ingresos",
        icon: "bi bi-lightning-charge-fill text-yellow",
        component: informeResumenMunicipioConsultor,
        layout: "/admin",
        key: 'resument-administrador-tasas-preliminar',
      },
      {
        path: "/encurso/resumeningresosestudio",
        name: "Año En Curso. Estudio Ingresos",
        icon: "bi bi-lightning-charge-fill text-yellow",
        component: informeResumenMunicipioConsultorActual,
        layout: "/admin",
        key: 'actual-resumen-ayto-tasas-preliminar',
      }
    ]
  },
  {
    path: "/nuevo-ayuntamiento/",
    name: "Nuevo Ayuntamiento",
    icon: "iconClassName",
    component: crearAyuntamiento,
    invisible: true,
    layout: "/admin",
    key: 'crear-Ayuntamiento'
  },
  {
    path: "/nuevo-usuario/",
    name: "Nuevo Usuario",
    icon: "iconClassName",
    component: crearUsuarios,
    invisible: true,
    layout: "/admin",
    key: 'crear-Usuarios'
  },
  {
    path: "/nueva-empresa/",
    name: "Nueva Empresa",
    icon: "iconClassName",
    component: crearEmpresas,
    invisible: true,
    layout: "/admin",
    key: 'crear-Empresa'
  },
  {
    path: "/nueva-contestacion/",
    name: "Nueva Contestación",
    icon: "iconClassName",
    component: crearContestacionEmpresas,
    invisible: true,
    layout: "/admin",
    key: 'crear-Contestacion'
  },
  {
    collapse: true,
    name: "Gestión tasas",
    miniName: "GT",
    state: "multiCollapseTasas",
    hasSubmenu: true,
    icon: "bi bi-intersect",
    layout: "/admin",
    key: 'TasasAdmin',
    views: [
      {
        path: "/liquidaciones",
        name: "Liquidaciones empresas",
        icon: "bi bi-file-earmark-ruled",
        component: facturasCliente,
        layout: "/admin",
        key: 'liquidacionaes',
      },
      {
        path: "/declaraciones",
        name: "Declaraciones empresas",
        icon: "bi bi-file-earmark-ruled",
        component: declaracionesEmpresas,
        layout: "/admin",
        key: 'declaraciones',
      },
      {
        path: "/analisisvs",
        name: "Análisis Ingresos Vs Previstos",
        icon: "bi bi-bar-chart-line",
        component: analisisRealvsPrevisto,
        layout: "/admin",
        key: 'analisisRealvsPrevisto',
      },
      {
        path: "/inicioexpedientestasas",
        name: "Inicio expedientes tasas",
        icon: "bi bi-file-post",
        component: gestionComunicacionEmpresas,
        layout: "/admin",
        key: 'inicioComunicacionEmpresas',
      },
      {
        path: "/seguimientoexpedientescomunicaciones",
        name: "Seguimiento expedientes tasas",
        icon: "bi bi-folder-symlink",
        component: seguimientoGestionComunicacionEmpresas,
        layout: "/admin",
        key: 'seguimientoComunicacionEmpresas',
      },
      {
        path: "/gestioncontestaciones",
        name: "Seguimiento de repuestas a requerimientos",
        icon: "bi bi-folder-check",
        component: gestionContestacionEmpresas,
        layout: "/admin",
        key: 'gestionContestacionEmpresasRequerimientos',
      }
    ],
},
{
  collapse: true,
  name: "Carga Datos",
  miniName: "GD",
  state: "multiCollapseContratacion",
  hasSubmenu: true,
  icon: "bi bi-database-up",
  layout: "/admin",
  key: 'GestionDatosAdmin',
  views: [
    {
      path: "/electricidadcargadatos",
      name: "Energia - Carga de datos Distri/Comer",
      icon: "bi bi-cloud-upload",
      component: cargaElectrico,
      layout: "/admin",
      key: 'electricidadcargaficheros',
    },
    {
      path: "/liquidacionescargadatos",
      name: "Liquidaciones - Carga de datos",
      icon: "bi bi-cloud-upload",
      component: cargaDatosLiquidaciones,
      layout: "/admin",
      key: 'liquidacioncargaficheros',
    },
    {
      path: "/empresascargadatos",
      name: "Empresas de servicios - Carga de datos",
      icon: "bi bi-cloud-upload",
      component: cargaEmpresaServicios,
      layout: "/admin",
      key: 'empresascargaficheros',
    },
    {
      path: "/ficherosAdmin",
      name: "Gestión de Documentos",
      icon: "bi bi-cloud-upload",
      component: gestionFicheros,
      layout: "/admin",
      key: 'ficherosCargaBackend',
    }
  ]
},

  {
    collapse: true,
    name: "Calendario",
    miniName: "CT",
    state: "multiCollapseCalendar",
    hasSubmenu: true,
    icon: "bi bi-calendar",
    layout: "/admin",
    key: 'CalendarioAdmin',
    views: [
      {
        path: "/tareascalendario",
        name: "Calendario",
        icon: "bi bi-calendar-event", 
        component: planificacionTareas,
        layout: "/admin",
        key: 'tareasCalendario',
      }
    ]
  },
  {
    collapse: true,
    name: "Administración",
    miniName: "ADT",
    state: "multiCollapseConsulta",
    hasSubmenu: true,
    icon: "bi bi-search",
    layout: "/admin",
    key: 'gestionAdministracion',
    views: [
      {
        path: "/ayuntamiento",
        name: "ayuntamiento",
        icon: "bi bi-file-earmark-ruled",
        component: Ayuntamiento,
        layout: "/admin",
        key: 'ayuntamiento'
      },
      {
        path: "/usuario",
        name: "usuario",
        icon: "bi bi-person-circle",
        component: Usuarios,
        layout: "/admin",
        key: 'usuario'
      },
      {
        path: "/empresa",
        name: "empresa",
        icon: "bi bi-buildings",
        component: Empresas,
        layout: "/admin",
        key: 'empresa'
      }
    ]
  },
];
export default routes;
