import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Alert,
} from "reactstrap";
// Apollo
import { withApollo } from "react-apollo";
import { RECOVER } from "../../queries/users/users.queries";

class Recover extends React.Component<any, any> {
  state = {
    token: null,
    email: "",
    type: localStorage.getItem("type"),
    passRecovered: false,
    passRecoveredError: false,
    loading: false,
  };

  constructor(props) {
    super(props);

    this.updateEmail = this.updateEmail.bind(this);
    this.recover = this.recover.bind(this);
  }

  public componentDidMount() {
    document.title = "TASATOOL | RECOVER PASSWORD";
  }

  public render() {
    return (
      <>
        <Col lg="5" md="7">
          {this._renderAlert()}
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent text-center">
              Recuperar contraseña
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={this.updateEmail}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={this.state.loading}
                    onClick={(e) => this.recover(e)}
                  >
                    Recuperar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }

  private _renderAlert() {
    if (this.state.passRecovered) {
      return <Alert color="success">Se ha solicitado nueva contraseña, si el usuario esta en el sistema se le enviara un email con los pasos a seguir.</Alert>;
    }

    if (this.state.passRecoveredError) {
      return <Alert color="danger">{this.state.passRecoveredError}</Alert>;
    }

    return null;
  }

  private async recover(event) {
    await this.setState({
      loading: true,
      passRecovered: false,
      passRecoveredError: false
    });
    const email = this.state.email;
    try {
      const queryUserResult = await this.props.client.mutate({
        mutation: RECOVER,
        variables: { email },
      });
      await this.setState({ loading: false });
      if (queryUserResult.data.recoverPassword) {
        this.setState({
          passRecovered: true,
          passRecoveredError: false
        });
      }
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        const error = e.graphQLErrors[0];
        await this.setState({
          passRecoveredError: error.message,
          passRecovered: false,
          loading: false
        });
      } else {
        await this.setState({
          passRecoveredError: 'Unknow error',
          passRecovered: false,
          loading: false
        });
      }
    }
  }

  private updateEmail(evt) {
    this.setState({
      email: evt.target.value,
    });
  }
}

export default withApollo(Recover);
