import React from "react"
import { withRouter } from "react-router-dom"

/* 
 * Component which serves the purpose of a "root route component". 
 */
class Page extends React.Component<any> {
  /**
   * Here, we define a react lifecycle method that gets executed each time 
   * our component is mounted to the DOM, which is exactly what we want in this case
   */
  componentDidMount() {
    document.title = this.props.title
     this.props.onSetBrandText(this.props.name);
  }

  /**
   * Here, we use a component prop to render 
   * a component, as specified in route configuration
   */
  render() {
    const PageComponent = this.props.component
    
    return (
      <PageComponent {...this.props} />
    )
  }
}

export default withRouter(Page)