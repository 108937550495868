
//import Password from './Password'
//import { Mailer } from 'nodemailer-react'
import React from "react";
import ReactExport from "react-data-export";
import { ResponsiveBar } from '@nivo/bar'
import Reflux from "reflux";
import { withApollo } from "react-apollo";
import { Card, Container, CardTitle, CardBody, Input, Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import LoadingOverlay from "react-loading-overlay";
import { UserStore } from "../../store/UserStore";
import { removeToken } from "../../utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../assets/scss/documento.scss";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Header from "../../components/Headers/Header";
import ReactBSAlert from "react-bootstrap-sweetalert";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columnsDatosConsumosAnual = [
    {
        selector: row => row['cupsTotalDistribuidoraSips'], name: 'CUPS', sortable: false, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    }, {
        selector: row => row['total'], name: 'Total Energía', sortable: false, width: '150px', center: true, format: (row => `${Number(row['total'] ? row['total'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
    {
        selector: row => row['P1'], name: 'P1', sortable: false, width: '100px', center: true, format: (row => `${Number(row['P1'] ? row['P1'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
    {
        selector: row => row['P2'], name: 'P2', sortable: false, width: '100px', center: true, format: (row => `${Number(row['P2'] ? row['P2'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
    {
        selector: row => row['P3'], name: 'P3', sortable: false, width: '100px', center: true, format: (row => `${Number(row['P3'] ? row['P3'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
    {
        selector: row => row['P4'], name: 'P4', sortable: false, width: '100px', center: true, format: (row => `${Number(row['P4'] ? row['P4'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
    {
        selector: row => row['P5'], name: 'P5', sortable: false, width: '100px', center: true, format: (row => `${Number(row['P5'] ? row['P5'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
    {
        selector: row => row['P6'], name: 'P6', sortable: false, width: '100px', center: true, format: (row => `${Number(row['P6'] ? row['P6'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
];


const columnsDatosConsumosAnualIngresos = [
    {
        selector: row => row['comercializadora'], name: 'Comercializadora', sortable: false, width: '250px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['cups'], name: 'CUPS', sortable: false, width: '100px', center: true },
    {
        selector: row => row['porcentaje'], name: '%', sortable: false, width: '100px', center: true, format: (row => `${Number(row['porcentaje'] ? row['porcentaje'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} `)
    },
    {
        selector: row => row['energiaMW'], name: 'Energia Mw', sortable: false, width: '120px', center: true, format: (row => `${Number(row['energiaMW'] ? row['energiaMW'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },
    {
        selector: row => row['costeComercializadoraEnergia'], name: 'Ingresos', sortable: false, width: '100px', center: true, format: (row => `${Number(row['costeComercializadoraEnergia'] ? row['costeComercializadoraEnergia'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    },
    {
        selector: row => row['tasaComercializadora'], name: 'TASA AYTO', sortable: false, width: '130px', center: true, format: (row => `${Number(row['tasaComercializadora'] ? row['tasaComercializadora'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    }
];

const columnsDatosConsumosAnualIngresosEnergia = [
    {
        selector: row => row['id'], name: 'Año', sortable: false, width: '100px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        selector: row => row['value'], name: 'Energia Total Mw', sortable: false, width: '160px', center: true, format: (row => `${Number(row['value'] ? row['value'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} Mw`)
    },

];
const columnsDatosConsumosAnualIngresos2 = [
    {
        selector: row => row['id'], name: 'Año', sortable: false, width: '100px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        selector: row => row['value'], name: 'Ingresos Previsto Tasa', sortable: false, width: '160px', center: true, format: (row => `${Number(row['value'] ? row['value'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        })} €`)
    },

];


const notify = () => toast.success(
    "Correo enviado correctamente",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyError = () => toast.error(
    "Existe un problema con el servicio de correos",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyEnvio = () => toast.info(
    "Preparando el fichero para su envío por correo",
    {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const comercialProductoFields = {
    periodo: "Periodo",
    precioPotencia: "Precio potencia €/kW día",
    precioEnergia: "Precio Energía €/kWh"
};

const comercialProductoExpandableFields = {};

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



//const container = React.useRef<HTMLDivElement>(null);
//  const pdfExportComponent = React.useRef<PDFExport>(null);
/*  const exportPDFWithMethod = () => {
    let element = container.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  };
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
*/



class informePreliminarMunicipioComercializadoraConsultor extends Reflux.Component {
    public container: any = null;
    public fileInput: any = null;
    public pdfExportComponent: any = null;

    public formProductoRef: any = null;
    public state: any = {
        id: "",
        compania: "",
        municipioBuscar: "00000",
        tabSelected: 2018,
        tabSelectedString: "2018",
        tabSelectedEnergia: "ELECTRICIDAD",
        tabSelectedStringEnergia: "ELECTRICIDAD",
        tipoEnergia: [{ tipo: "ELECTRICIDAD" },
        { tipo: "GAS" }],
        tasasComercializadoraAnual: [],
        consumosAnuales: [{
            year: 2019,
            data: [
                {
                    codigoTarifaATR: 1,
                    P1: 0,
                    P2: 0,
                    P3: 0,
                    P4: 0,
                    P5: 0,
                    P6: 0
                },
            ]
        }],
        consumosAnualesGas: [{
            year: 2019,
            data: [
                {
                    codigoTarifaATR: 'R1',
                    total: 0,
                },
            ]
        }],
        ingresosComercializadoras: [
            {
                year: 2019,
                porcentajeComercializadoraEnergia: [
                    {
                        comercializadora: "Sin datos",
                        cups: 0,
                        porcentaje: 0,
                        energiaMW: 0,
                        costeComercializadoraEnergia: 0,
                        tasaComercializadora: 0
                    }
                ]
            }],
        ingresosComercializadorasGas: [
            {
                year: 2019,
                porcentajeComercializadoraEnergiaGas: [
                    {
                        comercializadora: "Sin datos",
                        cups: 0,
                        porcentaje: 0,
                        energiaMW: 0,
                        costeComercializadoraEnergia: 0,
                        tasaComercializadora: 0
                    }
                ]
            }],
        consumosAnualesIngresos: [
            {
                year: 2019,
                porcentajeComercializadoraEnergia: [
                    {
                        comercializadora: "Sin datos",
                        cups: 0,
                        porcentaje: 0,
                        energiaMW: 0,
                        costeComercializadoraEnergia: 0,
                        tasaComercializadora: 0
                    }
                ]
            }],
        consumosAnualesIngresosGas: [
            {
                year: 2018,
                porcentajeComercializadoraEnergiaGas: [
                    {
                        comercializadora: "Sin datos",
                        cups: 0,
                        porcentaje: 0,
                        energiaMW: 0,
                        costeComercializadoraEnergia: 0,
                        tasaComercializadora: 0
                    }
                ]
            }],
        resumenEnergia: [
            {
                id: 2019,
                value: 0
            }],
        resumenEnergiaGas: [
            {
                id: 2019,
                value: 0
            }],
        resumenIngresos: [
            {
                id: 2018,
                value: 0

            }],
        resumenIngresosGas: [
            {
                id: 2019,
                value: 0

            }],
        consumosPotenciaAnuales: [{
            year: 2019,
            data: [
                {
                    codigoTarifaATR: 1,
                    P1: 0,
                    P2: 0,
                    P3: 0,
                    P4: 0,
                    P5: 0,
                    P6: 0
                },
            ]
        }],
        consumosAnualesPotenciaIngresos: [
            {
                year: 2019,
                costePotencia: [
                    {
                        P1: 0,
                        P2: 0,
                        P3: 0,
                        P4: 0,
                        P5: 0,
                        P6: 0
                    }
                ]
            }],
        resumenPotencia: [
            {
                year: 2019,
                costeEnergiaTotal: 0,
                costeEnergiaTotalP1: 0,
                costeEnergiaTotalP2: 0,
                costeEnergiaTotalP3: 0,
                costeEnergiaTotalP4: 0,
                costeEnergiaTotalP5: 0,
                costeEnergiaTotalP6: 0
            }],
        tasasResumenDistribuidoraMunicipio: {
            dataAyuntamientoConsumosComercializadora: [
                {
                    consumosanuales: [{
                        year: 2019,
                        data: [
                            {
                                codigoTarifaATR: 1,
                                P1: 0,
                                P2: 0,
                                P3: 0,
                                P4: 0,
                                P5: 0,
                                P6: 0
                            },
                        ]
                    }],
                    consumosanualesgas: [{
                        year: 2019,
                        data: [
                            {
                                codigoTarifaATR: 1,
                                total: 0,
                            },
                        ]
                    }],
                    ingresosComercializadoras: [
                        {
                            year: 2019,
                            porcentajeComercializadoraEnergia: [
                                {
                                    comercializadora: "Sin datos",
                                    cups: 0,
                                    porcentaje: 0,
                                    energiaMW: 0,
                                    costeComercializadoraEnergia: 0,
                                    tasaComercializadora: 0
                                }
                            ]
                        }],
                    ingresosComercializadorasGas: [
                        {
                            year: 2019,
                            porcentajeComercializadoraEnergiaGas: [
                                {
                                    comercializadora: "Sin datos",
                                    cups: 0,
                                    porcentaje: 0,
                                    energiaMW: 0,
                                    costeComercializadoraEnergia: 0,
                                    tasaComercializadora: 0
                                }
                            ]
                        }],
                    consumosAnualesIngresos: [
                        {
                            year: 2019,
                            porcentajeComercializadoraEnergia: [
                                {
                                    comercializadora: "Sin datos",
                                    cups: 0,
                                    porcentaje: 0,
                                    energiaMW: 0,
                                    costeComercializadoraEnergia: 0,
                                    tasaComercializadora: 0
                                }
                            ]
                        }],
                    consumosAnualesIngresosGas: [
                        {
                            year: 2018,
                            porcentajeComercializadoraEnergiaGas: [
                                {
                                    comercializadora: "Sin datos",
                                    cups: 0,
                                    porcentaje: 0,
                                    energiaMW: 0,
                                    costeComercializadoraEnergia: 0,
                                    tasaComercializadora: 0
                                }
                            ]
                        }],
                    resumenEnergia: [
                        {
                            id: 2019,
                            value: 0
                        }],
                    resumenEnergiaGas: [
                        {
                            id: 2019,
                            value: 0
                        }],
                    resumenIngresos: [
                        {
                            id: 2018,
                            value: 0

                        }],
                    resumenIngresosGas: [
                        {
                            id: 2019,
                            value: 0
                        }]
                }
            ]
        },
        tasasResumenMunicipio:
        {
            distribuidora: "",
            municipio: "",
            provincia: "",
            autonomia: "",
            consumoenergiatotal: 0,
            cups: 0,
            datosPerfil: [
                {
                    id: "",
                    consumoTotalPerfil: 0,
                    cups: 0,
                    porcentaje: 0
                }
            ],
            datosTarifa: [
                {
                    id: "",
                    consumoTotalTarifa: 0,
                    cups: 0,
                    porcentaje: 0
                }
            ],
            datosAnalisis: [
                {
                    tarifa: "",
                    cups: "0",
                    energiatarifa: "",
                    menor5mw: "",
                    energiamenor5mw: "",
                    menor10mw: "",
                    energiamenor10mw: "",
                    menor20mw: "",
                    energiamenor20mw: "",
                    menor9999MW: "",
                    energiamenor9999MW: "",
                    sindatos: "",
                    energiasindatos: ""
                }
            ]
        },
        outputDatosAnalisis: [{ id: "", value: 0 }],
        outputDatosAnalisisPotencia: [{ id: "", value: 0 }],
        outputDatosTarifa: [{ id: "", value: 0 }],
        municipios: [{ id: "", valor: 0 }],
        // --- Data
        isLoading: false,
        correoContacto: '',
        nombreFichero: '',
        datafile: "",
        uploadedFileContents: null,
        control: 0,
        alert: null
    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.container = React.createRef<HTMLDivElement>();
        this.logout = this.logout.bind(this);
        this.toggleNavs = this.toggleNavs.bind(this);
        this.toggleNavsEnergia = this.toggleNavsEnergia.bind(this);
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
    }


    componentDidMount() {

        this.formProductoRef = React.createRef();

        const user = localStorage.getItem("user");

        if (user) {
            const parsed = JSON.parse(user);
            this.setState({
                user: parsed,
            });
        }

        this.setState({
            ...this.state,
        });

        this.setState({
            control: 1
        }
        );
    };

    toggleNavs = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                tabSelected: tab,
                tabSelectedString: tab.toString(),
            });
        }
    };

    toggleNavsEnergia = (tab) => {
        if (this.state.activeTabEnergia !== tab) {
            this.setState({
                tabSelectedEnergia: tab,
                tabSelectedStringEnergia: tab.toString(),
            });
        }

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    fetchDataMunicipio = async () => {

        this.setState({
            isLoading: true,
        });
        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")
        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))
        const user: any = localStorage.getItem("user");
        const userID = localStorage.getItem("uuidUser")

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        const municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        var tasasResumen: any = []

        var dataFilters = {
            'codigoINE': codigoINE,
            //'codigoINE': '04069',
            'usuario': userID
        };
        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/tasas/createinfobaseconsumoscomercializadoramixto";

        axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {

                ///////// datos de electricidad /////////

                var tasasResumenDistribuidoraMunicipio = result.data.dataAyuntamientoConsumosComercializadora;
                var consumosAnuales = tasasResumenDistribuidoraMunicipio[0].consumosanuales
                var ingresosComercializadoras = tasasResumenDistribuidoraMunicipio[0].distribucionEnergiaComercializadoraAnual
                var energiaComercializadoras = tasasResumenDistribuidoraMunicipio[0].distribucionComercializadoraAnual
                var resumenIngresos = tasasResumenDistribuidoraMunicipio[0].resumenIngresos
                var resumenEnergia = tasasResumenDistribuidoraMunicipio[0].resumenEnergia
                var tasasComercializadoraAnual = tasasResumenDistribuidoraMunicipio[0].tasasComercializadoraAnuales
                var xMax = Math.max.apply(null, consumosAnuales.map(function (o) { return parseInt(o.year); }));

                ///////// datos de gas //////////

                if (tasasResumenDistribuidoraMunicipio[0].consumosanualesgas.length) {
                    var consumosAnualesGas = tasasResumenDistribuidoraMunicipio[0].consumosanualesgas
                    var energiaComercializadorasGas = tasasResumenDistribuidoraMunicipio[0].distribucionComercializadoraAnualGas
                    var ingresosComercializadorasGas = tasasResumenDistribuidoraMunicipio[0].distribucionEnergiaComercializadoraAnualGas
                    var resumenIngresosGas = tasasResumenDistribuidoraMunicipio[0].resumenIngresosGas
                    var resumenEnergiaGas = tasasResumenDistribuidoraMunicipio[0].resumenEnergiaGas
                    var tasasComercializadoraAnualGas = tasasResumenDistribuidoraMunicipio[0].tasasComercializadoraAnualesGas

                    this.setState({
                        tasasResumenDistribuidoraMunicipio: tasasResumenDistribuidoraMunicipio,
                        consumosAnuales: consumosAnuales,
                        ingresosComercializadoras: ingresosComercializadoras,
                        energiaComercializadoras: energiaComercializadoras,
                        resumenIngresos: resumenIngresos,
                        resumenEnergia: resumenEnergia,
                        tabSelected: xMax,
                        tabSelectedString: xMax.toString(),
                        tasasComercializadoraAnual: tasasComercializadoraAnual,
                        consumosAnualesGas: consumosAnualesGas,
                        energiaComercializadorasGas: energiaComercializadorasGas,
                        ingresosComercializadorasGas: ingresosComercializadorasGas,
                        resumenIngresosGas: resumenIngresosGas,
                        resumenEnergiaGas: resumenEnergiaGas,
                        tasasComercializadoraAnualGas: tasasComercializadoraAnualGas,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        tasasResumenDistribuidoraMunicipio: tasasResumenDistribuidoraMunicipio,
                        consumosAnuales: consumosAnuales,
                        ingresosComercializadoras: ingresosComercializadoras,
                        energiaComercializadoras: energiaComercializadoras,
                        resumenIngresos: resumenIngresos,
                        resumenEnergia: resumenEnergia,
                        tabSelected: xMax,
                        tabSelectedString: xMax.toString(),
                        tasasComercializadoraAnual: tasasComercializadoraAnual,
                        isLoading: false
                    })
                }
                //this.clearForm();
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };


    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    /////////////////////////////////////////////////////////////////////////////////

    onChangeField = (event) => {

        event.persist()


        const name = event.target.name;
        let value = event.target.value;
        // var setFilters = this.state.setFilters;

        //setFilters[name] = value;
        if (event.target.type === "select-one" && value === "0") {
            //delete setFilters[name];
        }

        if (value === "" || value === null) {
            //delete setFilters[name];
        }

        this.setState({ municipioBuscar: value });
    };

    //////////////////////////////////////////////////////////////

    infoEnergia() {

        var tipoEnergiaSelected = this.state.tabSelectedEnergia
        const styleFullWith = { "width": "100%" };

        var datosConsumos: any = []
        var consumosAnualesIngresos: any = []
        var resumenEnergia: any = []
        var resumenIngresos: any = []
        var datosvarios: any = []
        var energia = 0;
        var ingresos = 0;
        var ingresosEnergiaTotal = 0;

        if (tipoEnergiaSelected == "ELECTRICIDAD") {

            var indexyear = this.state.consumosAnuales.map(object => object.year).indexOf(this.state.tabSelected)

            datosConsumos = []
            if (indexyear !== -1) {
                datosConsumos = this.state.consumosAnuales[indexyear]
                datosConsumos = datosConsumos.data
            }

            indexyear = this.state.ingresosComercializadoras.map(object => object.year).indexOf(this.state.tabSelected)
            consumosAnualesIngresos = []
            if (indexyear !== -1) {
                consumosAnualesIngresos = this.state.ingresosComercializadoras[indexyear]
                consumosAnualesIngresos = consumosAnualesIngresos.porcentajeComercializadoraEnergia
            }

            indexyear = this.state.resumenEnergia.map(object => object.id).indexOf(this.state.tabSelected)
            resumenEnergia = []
            if (indexyear !== -1) {
                //resumenEnergia = this.state.resumenEnergia[indexyear]
                let id = this.state.resumenEnergia[indexyear].id
                let value = this.state.resumenEnergia[indexyear].value
                resumenEnergia.push({ id: id, value: value })
            }

            indexyear = this.state.resumenIngresos.map(object => object.id).indexOf(this.state.tabSelected)

            resumenIngresos = []
            if (indexyear !== -1) {
                let id = this.state.resumenIngresos[indexyear].id
                let value = this.state.resumenIngresos[indexyear].value
                resumenIngresos.push({ id: id, value: value })
            }

            var energia = 0;
            var ingresos = 0;

            for (var index = 0; index < resumenEnergia.length; ++index) {
                energia = resumenEnergia[index].value;
            }

            for (var index = 0; index < resumenIngresos.length; ++index) {
                ingresos = resumenIngresos[index].value;
            }

            datosvarios = []
            datosvarios.push({ energia: energia, ingresos: ingresos })

            ingresosEnergiaTotal = ingresos

            energia = energia


        } else {
            /////////////////////////// GAS //////////////////////////////////////

         /*   consumosAnualesGas: consumosAnualesGas,
            energiaComercializadorasGas: energiaComercializadorasGas,
            ingresosComercializadorasGas: ingresosComercializadorasGas,
            resumenIngresosGas: resumenIngresosGas,
            resumenEnergiaGas: resumenEnergiaGas,
            tasasComercializadoraAnualGas: tasasComercializadoraAnualGas,
            isLoading: false */

            var indexyear = this.state.consumosAnualesGas.map(object => object.year).indexOf(this.state.tabSelected)

            datosConsumos = []
            if (indexyear !== -1) {
                datosConsumos = this.state.consumosAnualesGas[indexyear]
                datosConsumos = datosConsumos.data
            }

            indexyear = this.state.ingresosComercializadoras.map(object => object.year).indexOf(this.state.tabSelected)
            consumosAnualesIngresos = []
            if (indexyear !== -1) {
                consumosAnualesIngresos = this.state.ingresosComercializadorasGas[indexyear]
                consumosAnualesIngresos = consumosAnualesIngresos.porcentajeComercializadoraEnergiaGas
            }

            indexyear = this.state.resumenEnergiaGas.map(object => object.id).indexOf(this.state.tabSelected)
            resumenEnergia = []
            if (indexyear !== -1) {
                //resumenEnergia = this.state.resumenEnergia[indexyear]
                let id = this.state.resumenEnergiaGas[indexyear].id
                let value = this.state.resumenEnergiaGas[indexyear].value
                resumenEnergia.push({ id: id, value: value })
            }

            indexyear = this.state.resumenIngresosGas.map(object => object.id).indexOf(this.state.tabSelected)

            resumenIngresos = []
            if (indexyear !== -1) {
                let id = this.state.resumenIngresosGas[indexyear].id
                let value = this.state.resumenIngresosGas[indexyear].value
                resumenIngresos.push({ id: id, value: value })
            }

            var energiaGas = 0;
            var ingresosGas = 0;

            for (var index = 0; index < resumenEnergia.length; ++index) {

                energiaGas = resumenEnergia[index].value;
            }

            for (var index = 0; index < resumenIngresos.length; ++index) {

                ingresosGas = resumenIngresos[index].value;
            }

            datosvarios = []
            datosvarios.push({ energia: energiaGas, ingresos: ingresosGas })

            ingresosEnergiaTotal = ingresosGas

            energia = energiaGas
        }
        return (
            <React.Fragment>

                <Row>
                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Datos Energía Anual CUPS + {this.state.tabSelectedStringEnergia}
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnual}
                                        data={datosConsumos}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>

                    <Col sm="3">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Resumen Energía Mw
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnualIngresosEnergia}
                                        data={resumenEnergia}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>

                    <Col sm="3">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Resumen Ingresos
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnualIngresos2}
                                        data={resumenIngresos}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col sm="8">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Datos análisis Ingresos por Comercializadora
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnualIngresos}
                                        data={consumosAnualesIngresos}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>

                    <Col sm="3">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Resumen Tasas Anualizado
                                    </h2>
                                </Row>
                                <Row>

                                    <ExcelFile filename="Resumen_comercializadoras_provisional" element={<Button color="primary"><i className={"bi bi-search"} /> Bajar Excel</Button>}>
                                        <ExcelSheet data={this.state.tasasComercializadoraAnual} name="Tasas Anual Com">
                                            <ExcelColumn label="COMERCIALIZADORA" value="comercializadora" />
                                            <ExcelColumn label="2018" value="value1" />
                                            <ExcelColumn label="2019" value="value2" />
                                            <ExcelColumn label="2020" value="value3" />
                                            <ExcelColumn label="2021" value="value4" />
                                            <ExcelColumn label="2022" value="value5" />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////

    renderRates() {
        const infoEnergiaHtml2 = this.infoEnergia();
        var indexyear = this.state.consumosAnuales.map(object => object.year).indexOf(this.state.tabSelected)
        const styleFullWith = { "width": "100%" };

        var datosConsumos: any = []
        if (indexyear !== -1) {
            datosConsumos = this.state.consumosAnuales[indexyear]
            datosConsumos = datosConsumos.data
        }

        indexyear = this.state.ingresosComercializadoras.map(object => object.year).indexOf(this.state.tabSelected)

        var consumosAnualesIngresos: any = []
        if (indexyear !== -1) {
            consumosAnualesIngresos = this.state.ingresosComercializadoras[indexyear]
            consumosAnualesIngresos = consumosAnualesIngresos.porcentajeComercializadoraEnergia
        }


        indexyear = this.state.resumenEnergia.map(object => object.id).indexOf(this.state.tabSelected)
        var resumenEnergia: any = []
        if (indexyear !== -1) {
            //resumenEnergia = this.state.resumenEnergia[indexyear]
            let id = this.state.resumenEnergia[indexyear].id
            let value = this.state.resumenEnergia[indexyear].value
            resumenEnergia.push({ id: id, value: value })


        }

        indexyear = this.state.resumenIngresos.map(object => object.id).indexOf(this.state.tabSelected)

        var resumenIngresos: any = []
        if (indexyear !== -1) {
            let id = this.state.resumenIngresos[indexyear].id
            let value = this.state.resumenIngresos[indexyear].value
            resumenIngresos.push({ id: id, value: value })
        }

        var energia = 0;
        var ingresos = 0;

        for (var index = 0; index < resumenEnergia.length; ++index) {

            energia = resumenEnergia[index].value;
        }

        for (var index = 0; index < resumenIngresos.length; ++index) {

            ingresos = resumenIngresos[index].value;
        }

        var datosvarios: any = []
        datosvarios.push({ energia: energia, ingresos: ingresos })

        var ingresosEnergiaTotal = ingresos

        var energia = energia
        var total = 0

        /////////////////////////// GAS //////////////////////////////////////

        indexyear = this.state.resumenEnergiaGas.map(object => object.id).indexOf(this.state.tabSelected)
        var resumenEnergiaGas: any = []
        if (indexyear !== -1) {
            //resumenEnergia = this.state.resumenEnergia[indexyear]
            let id = this.state.resumenEnergiaGas[indexyear].id
            let value = this.state.resumenEnergiaGas[indexyear].value
            resumenEnergiaGas.push({ id: id, value: value })


        }

        indexyear = this.state.resumenIngresosGas.map(object => object.id).indexOf(this.state.tabSelected)

        var resumenIngresosGas: any = []
        if (indexyear !== -1) {
            let id = this.state.resumenIngresosGas[indexyear].id
            let value = this.state.resumenIngresosGas[indexyear].value
            resumenIngresosGas.push({ id: id, value: value })
        }

        var energiaGas = 0;
        var ingresosGas = 0;

        for (var index = 0; index < resumenEnergiaGas.length; ++index) {

            energiaGas = resumenEnergiaGas[index].value;
        }

        for (var index = 0; index < resumenIngresosGas.length; ++index) {

            ingresosGas = resumenIngresosGas[index].value;
        }

        var datosvariosGas: any = []
        datosvariosGas.push({ energia: energiaGas, ingresos: ingresosGas })

        var ingresosEnergiaTotalGas = ingresosGas

        var energiaGas = energiaGas



        return (
            <React.Fragment> <TabPane tabId={this.state.tabSelectedString}>
                <Row className="card-deck" style={styleFullWith}>

                    <Card className="mt-3 mb-3 p-3 card-document">
                        <CardTitle className="mt-4 info-title"><h3>Ingresos Previstos Anual</h3></CardTitle>
                        <Row className="mt-4">
                            <Col className="col-auto pt-2 pb-2 mr-2">
                                <h4>Electricidad Comercializada Estimada Mw:</h4>
                            </Col>

                            <Col className="col-auto d-flex bg-units radius-pot text-center align-items-center">
                                <h4 className="mt-2 mb-2 justify-content-center align-self-center text-center">{energia.toLocaleString('es-ES', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    useGrouping: true
                                })} Mw</h4>
                            </Col>

                            <Col className="col-auto pt-2 pb-2 ml-4">
                                <h4>Ingresos Previstos Electricidad: </h4>
                            </Col>

                            <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                                <h4 className="mt-2 mb-2 justify-content-center align-self-center">{ingresosEnergiaTotal.toLocaleString('es-ES', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    useGrouping: true
                                })} €</h4>
                            </Col>

                        </Row>
                        <Row className="mt-4">
                            <Col className="col-auto pt-2 pb-2 mr-2">
                                <h4>Gas Comercializado Estimada Mwh:</h4>
                            </Col>

                            <Col className="col-auto d-flex bg-units radius-pot text-center align-items-center">
                                <h4 className="mt-2 mb-2 justify-content-center align-self-center text-center">{energiaGas.toLocaleString('es-ES', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    useGrouping: true
                                })} Mw</h4>
                            </Col>

                            <Col className="col-auto pt-2 pb-2 ml-4">
                                <h4>Ingresos Previstos Gas: </h4>
                            </Col>

                            <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                                <h4 className="mt-2 mb-2 justify-content-center align-self-center">{ingresosEnergiaTotalGas.toLocaleString('es-ES', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    useGrouping: true
                                })} €</h4>
                            </Col>

                        </Row>


                    </Card>
                </Row>

                <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="section-tabs"
                    tabs
                    role="tablist"
                >
                    {
                        this.state.tipoEnergia.map(item => <NavItem key={item.tipo}>
                            <NavLink
                                key={item.tipo}
                                aria-selected={this.state.tabSelectedEnergia === item.tipo}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabSelectedEnergia === item.tipo,
                                })}
                                onClick={() => this.toggleNavsEnergia(item.tipo)}
                                href="#"
                                role="tab"
                            >
                                {item.tipo}
                            </NavLink>
                        </NavItem>)
                    }
                </Nav>


                <TabContent activeTabEnergia={this.state.tabSelectedString}>
                    {infoEnergiaHtml2}
                </TabContent>


            </TabPane>
            </React.Fragment>
        )

        //columnsDatosConsumosAnual
    };

    //////////////////////////////////////////////////////////////

    render() {
        const isLoading = this.state.isLoading;
        const styleFullWith = { "width": "100%" };
        const outputDatosAnalisis = this.state.resumenIngresos;
        const outputDatosAnalisisGas = this.state.resumenIngresosGas;
        const outputDatosAnalisisPotencia = this.state.resumenEnergia;
        const outputDatosAnalisisPotenciaGas = this.state.resumenEnergiaGas;

        const consumosAnuales = this.state.consumosAnuales;

        const ratesHtml = this.renderRates();

        const infoEnergiaHtml = this.infoEnergia();

        const nombreFichero = this.state.nombreFichero
        //              fileName={`Report for ${cups} ${new Date().getFullYear()}`}

        return (
            <>
                <Header title={'Información Preliminar Tasas Comercializadora'} />
                <Container className=" mt--7" fluid>
                    <Card className="mb-4">
                        <CardBody>
                            <Row>
                                 <Col className="text-right" md="2">
                                    <Button
                                        color="primary"
                                        onClick={this.fetchDataMunicipio}
                                    >
                                        <i className={"bi bi-search"} /> Analizar
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
                <div>
                    <LoadingOverlay
                        active={isLoading}
                        spinner
                        text="Cargando...">
                        <Container className=" mt-3" fluid>
                            <Row className="card-deck" style={styleFullWith}>
                                <Card className="mt-3 mb-3 p-3 card-document">
                                    <Col md="6" xl="12">
                                        <CardTitle className="info-title"><h3>Ingresos Previstos Anuales Tasas Electricidad</h3></CardTitle>
                                        <Card className="card-stats" style={{ height: 180 }}>
                                            <ResponsiveBar
                                                layout="horizontal"
                                                margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                                                data={outputDatosAnalisis}
                                                indexBy="id"
                                                keys={['value']}
                                                colors={{ scheme: 'nivo' }}
                                                colorBy="indexValue"
                                                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                                                enableGridX
                                                enableGridY={false}
                                                axisTop={{
                                                    format: '~s',
                                                }}

                                                axisBottom={null}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                valueFormat={value =>
                                                    `${Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                        useGrouping: true
                                                    })} €`
                                                }
                                                padding={0.3}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}

                                            />



                                        </Card>
                                    </Col>
                                </Card>
                                <Card className="mt-3 mb-3 p-3 card-document">

                                    <Col md="6" xl="12">
                                        <CardTitle className="info-title"><h3>Ingresos Previstos Anuales Tasas Gas</h3></CardTitle>

                                        <Card className="card-stats" style={{ height: 180 }}>

                                            <ResponsiveBar
                                                layout="horizontal"
                                                margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                                                data={outputDatosAnalisisGas}
                                                indexBy="id"
                                                keys={['value']}
                                                colors={{ scheme: 'nivo' }}
                                                colorBy="indexValue"
                                                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                                                enableGridX
                                                enableGridY={false}
                                                axisTop={{
                                                    format: '~s',
                                                }}

                                                axisBottom={null}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                valueFormat={value =>
                                                    `${Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                        useGrouping: true
                                                    })} €`
                                                }
                                                padding={0.3}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}

                                            />



                                        </Card>
                                    </Col>


                                </Card>
                            </Row>
                        </Container>
                    </LoadingOverlay>
                    {this.state.alert}
                </div>

                <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="section-tabs"
                    tabs
                    role="tablist"
                >
                    {
                        consumosAnuales.map(item => <NavItem key={item.year}>
                            <NavLink
                                key={item.year}
                                aria-selected={this.state.tabSelected === item.year}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabSelected === item.year,
                                })}
                                onClick={() => this.toggleNavs(item.year)}
                                href="#"
                                role="tab"
                            >
                                {item.year}
                            </NavLink>
                        </NavItem>)
                    }
                </Nav>

                <TabContent activeTab={this.state.tabSelectedString}>
                    {ratesHtml}
                </TabContent>

                <div>
                    <LoadingOverlay
                        active={isLoading}
                        spinner
                        text="Cargando...">
                        <Container className=" mt-3" fluid>

                            <Row className="card-deck" style={styleFullWith}>
                                <Card className="mt-3 mb-3 p-3 card-document">

                                    <Col md="6" xl="12">
                                        <CardTitle className="info-title"><h3>Electricidad Comercializada En Municipio (Mw)</h3></CardTitle>
                                        <Card className="card-stats" style={{ height: 300 }}>
                                            <ResponsiveBar
                                                layout="horizontal"
                                                margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                                                data={outputDatosAnalisisPotencia}
                                                indexBy="id"
                                                keys={['value']}
                                                colors={{ scheme: 'nivo' }}
                                                colorBy="indexValue"
                                                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                                                enableGridX
                                                enableGridY={false}
                                                axisTop={{
                                                    format: '~s',
                                                }}
                                                axisBottom={null}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                valueFormat={value =>
                                                    `${Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 1,
                                                        maximumFractionDigits: 2,
                                                        useGrouping: true
                                                    })} Mw`
                                                }
                                                padding={0.3}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
                                            />
                                        </Card>
                                    </Col>
                                </Card>

                                <Card className="mt-3 mb-3 p-3 card-document">

                                    <Col md="6" xl="12">
                                        <CardTitle className="info-title"><h3>Gas Comercializado En Municipio (Mw)</h3></CardTitle>
                                        <Card className="card-stats" style={{ height: 300 }}>
                                            <ResponsiveBar
                                                layout="horizontal"
                                                margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                                                data={outputDatosAnalisisPotenciaGas}
                                                indexBy="id"
                                                keys={['value']}
                                                colors={{ scheme: 'nivo' }}
                                                colorBy="indexValue"
                                                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                                                enableGridX
                                                enableGridY={false}
                                                axisTop={{
                                                    format: '~s',
                                                }}
                                                axisBottom={null}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                valueFormat={value =>
                                                    `${Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 1,
                                                        maximumFractionDigits: 2,
                                                        useGrouping: true
                                                    })} Mw`
                                                }
                                                padding={0.3}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
                                            />
                                        </Card>
                                    </Col>
                                </Card>
                            </Row>

                        </Container>
                    </LoadingOverlay>
                    {this.state.alert}
                </div>
            </>
        );
    }
}

export default withApollo(informePreliminarMunicipioComercializadoraConsultor);
