import Environment from '../../constants/environment';
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId, spainIdType } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";

import { UserStore } from "../../store/UserStore";

import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import { saveAs } from 'file-saver';

var DatePicker = require("reactstrap-date-picker");

const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const sortIcon = <ArrowDownward />;


class crearUsuarios extends Reflux.Component<any, any> {
    public formRef: any = null;
    lastFilters = {};
    state = {
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipocliente: "",
        usuarioDetailTotal: [],
        usuarioSeleccionado: false,
        idUser: '',
        municipios: [{ key: "", value: 0 }],
        usuarioDetail: {
            id: 0,
            userId: "",
            nombre: "",
            apellidos: "",
            nif: "",
            email: "",
            password: "",
            user_group_id: 0,
            query_limit: 0,
            profile_photo: "",
            profile_photo_dir: "",
            app_logo: "",
            app_logo_dir: "",
            ayuntamiento: "",
            codigoINE: "",
            userUuid: "",
            telefono: "",
            locked_up: null,
            attempts_login: 0,
            reset_token: "",
            reset_token_expire: "",
            companyId: 0,
            esActiva: false,
            esAdmin: false,
            esComercial: false,
            esConsultor: false,
            esFuncionario: false,
            informes: false,
            detalles: false,
            createdAt: null,
            modifiedAt: null,
            idEmpresa: "1001"
        },
        formUsuario: {
            id: 0,
            userId: "",
            nombre: "",
            apellidos: "",
            nif: "",
            email: "",
            password: "",
            user_group_id: 0,
            query_limit: 0,
            profile_photo: "",
            profile_photo_dir: "",
            app_logo: "",
            app_logo_dir: "",
            ayuntamiento: "",
            codigoINE: "",
            userUuid: "",
            telefono: "",
            locked_up: null,
            attempts_login: 0,
            reset_token: "",
            reset_token_expire: "",
            companyId: 0,
            esActiva: false,
            esAdmin: false,
            esComercial: false,
            esConsultor: false,
            esFuncionario: false,
            informes: false,
            detalles: false,
            createdAt: null,
            modifiedAt: null,
            idEmpresa: "1001"
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataUsuario: false,
        updateDataUsuario: false,
        saveUsuarioButtonDisabled: true,
        updateUsuarioButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        inputDisabled: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreProductoDisabled: false,
        inputCifDisabled: false,
        inputIdDisabled: false,
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        apellido1TitularError: false,
        emailError: false,
        nombreTitularError: false,
        NIFError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        ibanError: false,
        alert: null,
        createResult: [],
        createResult2: [],

    };




    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);

    }


    componentDidMount() {
        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas

        var formData = { ...this.state.formUsuario };

        const codigoINE = usuario.codigoINE
        const ayuntamiento = usuario.ayuntamiento
        formData['codigoINE'] = codigoINE
        formData['ayuntamiento'] = ayuntamiento
        

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas,
            formUsuario: formData

        });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };


    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
     </ReactBSAlert>
            )
        });
    };

    warningAlertRepetido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Usuario Repetido"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El usuario ya se encuentra registrado.
     </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
     </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el usuario"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
            </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos del usuario?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Cliente guardado correctamente
        </ReactBSAlert>
            )
        });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldUsuario = (event) => {
        event.persist()
        var formData = { ...this.state.formUsuario };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        var municipio = ""

        const nameFilters = ['id', 'userId', 'nombre', 'apellidos', 'nif', 'email', 'password', 'user_group_id', 'query_limit', 'profile_photo', 'profile_photo_dir', 'app_logo', 'app_logo_dir', 'ayuntamiento', 'codigoINE', 'userUuid', 'telefono', 'locked_up', 'attempts_login', 'reset_token', 'reset_token_expire', 'companyId', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles', 'createdAt', 'modifiedAt', 'idEmpresa'];

        const optionsFilters = ['user_group_id', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles'];

        if (nameFilters.indexOf(event.target.name) != -1) {
            this.setState({ saveDataUsuario: true });
            this.setState({
                saveUsuarioButtonDisabled: false,
                updateUsuarioButtonDisabled: false
            })
        }
        if (event.target.name == 'nombre' && event.target.value.length > 0) {

            this.setState({ saveDataUsuario: true });
            this.setState({
                saveUsuarioButtonDisabled: false,
                updateUsuarioButtonDisabled: false
            })
        }
        if (event.target.name == 'apellidos' && event.target.value.length > 0) {

            this.setState({ saveDataUsuario: true });
            this.setState({
                saveUsuarioButtonDisabled: false,
                updateUsuarioButtonDisabled: false
            })
        }


        if (event.target.name === 'nif' && event.target.value.length == 0) {
            this.setState({ NIFClienteError: true })
        } else if (event.target.name === 'nif' && event.target.value.length > 0) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                this.setState({
                    saveDataUsuario: true,
                    NIFClienteError: false
                });
                this.setState({
                    saveUsuarioButtonDisabled: true,
                    updateUsuarioButtonDisabled: false
                })
            } else {
                this.setState({
                    NIFClienteError: true,
                    saveDataUsuario: false,
                    updateUsuarioButtonDisabled: true
                })
            }
        }

        if (event.target.name === 'email') {
            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);

            if (result === true) {
                this.setState({
                    emailError: false,
                })
            } else {
                this.setState({
                    emailError: true
                })
            }
        };

        if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
            value = null
        }

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        };

        if (name == 'ayuntamiento') {

            let indexMunicipio = this.state.municipios.findIndex(object =>
                object.key === value
            ); // 👉️ 1

            if (indexMunicipio !== -1) {
                municipio = this.state.municipios[indexMunicipio].value
            };

            formData['codigoINE'] = value
            formData['ayuntamiento'] = municipio
            this.setState({
                saveDataCliente: true
            })

            this.setState({ formUsuario: formData });

        } else {

            /*    if (name == 'nombreMunicipioPS') {
                    const municipio: any = this.state.dicMunicipios
                    const resultado = municipio.find(municipio => municipio ?.Nombre === value);
                    const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
                    formData['nombreProvinciaPS'] = resultado.DescProvincia
                    formData['AutonomiaTitular'] = resultado.DescAutonomia
                }
            */

            formData[name] = value;
            //if (value === "" || value === null) {
            //    formData[name] = 0;
            //
            //}
            this.setState({
                saveDataCliente: true
            })
            this.setState({ formUsuario: formData });
        }

    };

    //////////////////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    ///////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        const filters = {};
        this.setState({
            inputCifDisabled: false,
            inputNombreProductoDisabled: false,
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            usuarioSeleccionado: false,
            usuarioDetailTotal: [],
            formUsuario: {
                id: 0,
                userId: "",
                nombre: "",
                apellidos: "",
                nif: "",
                email: "",
                password: "",
                user_group_id: 0,
                query_limit: 0,
                profile_photo: "",
                profile_photo_dir: "",
                app_logo: "",
                app_logo_dir: "",
                ayuntamiento: "",
                codigoINE: "",
                userUuid: "",
                telefono: "",
                locked_up: null,
                attempts_login: 0,
                reset_token: "",
                reset_token_expire: "",
                companyId: 0,
                esActiva: false,
                esAdmin: false,
                esComercial: false,
                esConsultor: false,
                esFuncionario: false,
                informes: false,
                detalles: false,
                createdAt: null,
                modifiedAt: null,
                idEmpresa: "1001"
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveUsuarioButtonDisabled: true,
            updateUsuarioButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            saveDataUsuario: false

        });

        this.formRef.current.reset();

    };

    ///////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {

        if (this.state.saveDataUsuario && (this.state.formUsuario.nombre && this.state.formUsuario.email && this.state.formUsuario.password)) {

            this.confirmAlertSave()

        } else {
            this.warningAlertFiltros()
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {

        //const fechaActivacion = this.state.fechaActivacion;
        const uuidUser = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")

        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }


        var municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""

        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        var tasasResumen: any = []
        var iduser = datosUser.userId ? datosUser.userId : ""
        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""




        if (this.state.saveDataUsuario == true) {

            var updatedAt = '';

            var createdAt = this.state.formUsuario.createdAt;

            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var updatedAt = this.state.formUsuario.updatedAt ? this.state.formUsuario.updatedAt : null;

            if (!updatedAt) {
                updatedAt = ''
            };

            var usuarioDataUpdate = {}

            usuarioDataUpdate = {

                'id': this.state.formUsuario.id,
                'userId': this.state.formUsuario.userId,
                'nombre': this.state.formUsuario.nombre ?.toUpperCase() || '',
                'apellidos': this.state.formUsuario.apellidos ?.toUpperCase() || '',
                'nif': this.state.formUsuario.nif,
                'email': this.state.formUsuario.email,
                'password': this.state.formUsuario.password,
                'user_group_id': this.state.formUsuario.user_group_id,
                'query_limit': this.state.formUsuario.query_limit,
                'profile_photo': this.state.formUsuario.profile_photo,
                'profile_photo_dir': this.state.formUsuario.profile_photo_dir,
                'app_logo': this.state.formUsuario.app_logo,
                'app_logo_dir': this.state.formUsuario.app_logo_dir,
                'ayuntamiento': municipio,
                'codigoINE': codigoINE,
                'userUuid': this.state.formUsuario.userUuid,
                'telefono': this.state.formUsuario.telefono,
                'locked_up': this.state.formUsuario.locked_up,
                'attempts_login': this.state.formUsuario.attempts_login,
                'reset_token': this.state.formUsuario.reset_token,
                'reset_token_expire': this.state.formUsuario.reset_token_expire,
                'companyId': this.state.formUsuario.companyId,
                'esActiva': this.state.formUsuario.esActiva,
                'esAdmin': this.state.formUsuario.esAdmin,
                'esComercial': this.state.formUsuario.esComercial,
                'esConsultor': this.state.formUsuario.esConsultor,
                'esFuncionario': this.state.formUsuario.esFuncionario,
                'informes': this.state.formUsuario.informes,
                'detalles': this.state.formUsuario.detalles,
                'createdAt': createdAt,
                'modifiedAt': updatedAt,
                'idEmpresa': this.state.formUsuario.idEmpresa
            };

            var resultados: any = [];
            var resultadoView: any = [];

            const headers = {
                
                'Content-Type': 'application/json',
            };
            let direccionFile = "https://api.nextniro.com/api/v1/gestion/usuariocreate";

            axios.post(direccionFile, usuarioDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    console.log('Actualización correcta', result)
                    console.log('Datpos del registro: ', result.data)
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        usuarioSeleccionado: false,
                        usuarioDetailTotal: [],
                        saveDataUsuario: false,
                        saveUsuarioButtonDisabled: true,
                        updateUsuarioButtonDisabled: true,
                        createdAt: '',
                        updatedAt: '',
                        toggledClearRows: !this.state.toggledClearRows,
                        formUsuario: {
                            id: 0,
                            userId: "",
                            nombre: "",
                            apellidos: "",
                            nif: "",
                            email: "",
                            password: "",
                            user_group_id: 0,
                            query_limit: 0,
                            profile_photo: "",
                            profile_photo_dir: "",
                            app_logo: "",
                            app_logo_dir: "",
                            ayuntamiento: municipio,
                            codigoINE: codigoINE,
                            userUuid: "",
                            telefono: "",
                            locked_up: null,
                            attempts_login: 0,
                            reset_token: "",
                            reset_token_expire: "",
                            companyId: 0,
                            esActiva: false,
                            esAdmin: false,
                            esComercial: false,
                            esConsultor: false,
                            esFuncionario: false,
                            informes: false,
                            detalles: false,
                            createdAt: null,
                            modifiedAt: null,
                            idEmpresa: "1001"
                        }
                    });
                }).catch((error) => {
                    console.log(error);
                    if (error?.response) {
                        // http status code
                        const code = error.response.status
                        if (code == "500") {
                            this.hideAlert()

                            this.warningAlertRepetido()

                            this.setState({
                                isLoading: false,
                                loginError: true,
                                networkError: false,
                                sessionError: false,
                                userBlockedError: false
                            });
                        }
                       
                    }
                });

        }

    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formUsuario };


        const name = id;
        var valueDay = formattedValue

        if (name == 'createdAt') {
            this.setState({ createdAt: value });

        }

        if (name == 'updatedAt') {
            this.setState({ updatedAt: value });
        }

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formData });
    };


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {
        // eslint-disable-next-line no-console

        var producto = '';

        if (state.selectedCount === 1) {
            var existeCliente = ''
            var cliente = state.selectedRows[0].idUser;
            this.setState({ usuarioDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].updatedAt
            existeCliente = state.selectedRows[0].idUser

            if (existeFechaAlta && existeFechaBaja) {
                this.setState({
                    inputDisabled: true,
                    updateUsuarioButtonDisabled: false
                })
            } else if (!existeFechaAlta && !existeFechaBaja) {
                this.setState({
                    inputDisabled: false,
                    updateUsuarioButtonDisabled: true
                })
            }



            if (existeCliente) {
                this.setState({ idUser: this.state.usuarioDetail.idUser });
                this.setState({
                    formUsuario: {

                        id: state.selectedRows[0].id,
                        userId: state.selectedRows[0].userId,
                        nombre: state.selectedRows[0].nombre ?.toUpperCase() || '',
                        apellidos: state.selectedRows[0].apellidos ?.toUpperCase() || '',
                        nif: state.selectedRows[0].nif,
                        email: state.selectedRows[0].email,
                        password: state.selectedRows[0].password,
                        user_group_id: state.selectedRows[0].user_group_id,
                        query_limit: state.selectedRows[0].query_limit,
                        profile_photo: state.selectedRows[0].profile_photo,
                        profile_photo_dir: state.selectedRows[0].profile_photo_dir,
                        app_logo: state.selectedRows[0].app_logo,
                        app_logo_dir: state.selectedRows[0].app_logo_dir,
                        ayuntamiento: state.selectedRows[0].ayuntamiento,
                        codigoINE: state.selectedRows[0].codigoINE,
                        userUuid: state.selectedRows[0].userUuid,
                        telefono: state.selectedRows[0].telefono,
                        locked_up: state.selectedRows[0].locked_up,
                        attempts_login: state.selectedRows[0].attempts_login,
                        reset_token: state.selectedRows[0].reset_token,
                        reset_token_expire: state.selectedRows[0].reset_token_expire,
                        companyId: state.selectedRows[0].companyId,
                        esActiva: state.selectedRows[0].esActiva,
                        esAdmin: state.selectedRows[0].esAdmin,
                        esComercial: state.selectedRows[0].esComercial,
                        esConsultor: state.selectedRows[0].esConsultor,
                        esFuncionario: state.selectedRows[0].esFuncionario,
                        informes: state.selectedRows[0].informes,
                        detalles: state.selectedRows[0].detalles,
                        createdAt: state.selectedRows[0].createdAt,
                        modifiedAt: state.selectedRows[0].updatedAt,
                        idEmpresa: state.selectedRows[0].idEmpresa
                    },
                    usuarioSeleccionado: true,
                    inputDisabled: false
                });

            } else {
                this.setState({
                    usuarioSeleccionado: false,
                    formUsuario: {
                        id: 0,
                        userId: "",
                        nombre: "",
                        apellidos: "",
                        nif: "",
                        email: "",
                        password: "",
                        user_group_id: 0,
                        query_limit: 0,
                        profile_photo: "",
                        profile_photo_dir: "",
                        app_logo: "",
                        app_logo_dir: "",
                        ayuntamiento: "",
                        codigoINE: "",
                        userUuid: "",
                        telefono: "",
                        locked_up: null,
                        attempts_login: 0,
                        reset_token: "",
                        reset_token_expire: "",
                        companyId: 0,
                        esActiva: false,
                        esAdmin: false,
                        esComercial: false,
                        esConsultor: false,
                        esFuncionario: false,
                        informes: false,
                        detalles: false,
                        createdAt: null,
                        modifiedAt: null,
                        idEmpresa: "1001"
                    },
                    createdAt: null,
                    updatedAt: null
                });
            }
        } else {

            this.setState({
                usuarioSeleccionado: false,
                inputDisabled: false,
                updateUsuarioButtonDisabled: true,
                saveUsuarioButtonDisabled: true,
                createdAt: null,
                updatedAt: null,
                formUsuario: {
                    id: 0,
                    userId: "",
                    nombre: "",
                    apellidos: "",
                    nif: "",
                    email: "",
                    password: "",
                    user_group_id: 0,
                    query_limit: 0,
                    profile_photo: "",
                    profile_photo_dir: "",
                    app_logo: "",
                    app_logo_dir: "",
                    ayuntamiento: "",
                    codigoINE: "",
                    userUuid: "",
                    telefono: "",
                    locked_up: null,
                    attempts_login: 0,
                    reset_token: "",
                    reset_token_expire: "",
                    companyId: 0,
                    esActiva: false,
                    esAdmin: false,
                    esComercial: false,
                    esConsultor: false,
                    esFuncionario: false,
                    informes: false,
                    detalles: false,
                    createdAt: null,
                    modifiedAt: null,
                    idEmpresa: "1001"
                }
            });
        };
    };

    /////////////////////////////////////////////////////////////////////////////////

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }



    render() {

        const formUsuario: any = this.state.formUsuario;
        var createResult: any = this.state.createResult;
        const createdAt = this.state.createdAt
        const updatedAt = this.state.updatedAt


        //const listItems = createResult.map((d) => <li key="{d.idUser}">{d.idUser}</li>);

        if (this.state.userGroupId > 3999) {
            return (
                <>
                    <Header title={'Alta Usuario'} />
                    <Container className=" mt--7" fluid>

                        <Card className="mb-4">
                            <CardHeader>
                                <Button
                                    onClick={this.showFiltersToggle}
                                    size="sm"
                                    className="float-right"
                                >
                                    <i className={"ni " + this.getFilterIcon()} />
                                </Button>
                                <h3 className="mb-0">Filtrar</h3>
                            </CardHeader>
                            <Collapse isOpen={this.state.showFilters}>
                                <CardBody>
                                    <Form innerRef={this.formRef}>
                                        <Row>

                                            <Col className="text-right">
                                                <Button onClick={this.clearForm}>
                                                    <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>

                                            </Col>
                                        </Row>

                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>

                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={this.props.history.goBack}
                                                >
                                                    Atrás
                                            </button>
                                            </Col>

                                            <Col className="col-auto d-flex">
                                                <Card className="p-3 bg-light">
                                                    <FormGroup>

                                                        <Button disabled={this.state.saveUsuarioButtonDisabled} id="saveRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                            onClick={() => { this.updateHandler(this.state.saveDataUsuario) }}
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-person-plus-fill" style={{ fontSize: 25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Añadir</span>
                                                            <UncontrolledTooltip delay={0} placement="top" target="saveRepreProptooltip">
                                                                AÑADIR USUARIO AL REGISTRO
                                                    </UncontrolledTooltip>
                                                        </Button>


                                                    </FormGroup>
                                                </Card>
                                            </Col>

                                        </Row>
                                        <div>
                                            <CardTitle>DATOS GENERALES USUARIO</CardTitle>
                                        </div>

                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombre">
                                                        NOMBRE
                                                </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="nombre"
                                                        placeholder=""
                                                        name="nombre"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.nombre}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellidos">
                                                        Apellidos
                                                </Label>
                                                    <Input
                                                        id="apellidos"
                                                        placeholder=""
                                                        name="apellidos"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.apellidos}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="nif">
                                                        NIF
                                                </Label>
                                                    <Input
                                                        id="nif"
                                                        placeholder=""
                                                        name="nif"
                                                        maxLength={16}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.nif}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                    {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF</FormText> : ''}
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>

                                            <Col md="3">
                                                <Label className="form-control-label" htmlFor="ayuntamientoRef">
                                                    Ayuntamiento
                                            </Label>
                                                <Input
                                                        className="text-primary"
                                                        id="ayuntamiento"
                                                        placeholder=""
                                                        name="ayuntamiento"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.ayuntamiento}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="codigoINE">
                                                        INE
                                            </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="codigoINE"
                                                        placeholder=""
                                                        name="codigoINE"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.codigoINE}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>



                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="telefono">
                                                        Teléfono Contacto
                                                </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefono"
                                                        placeholder=""
                                                        name="telefono"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.telefono}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="idEmpresa">
                                                        Empresa
                                                </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="idEmpresa"
                                                        placeholder=""
                                                        name="idEmpresa"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.idEmpresa}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="email">
                                                        Correo Contacto
                                                </Label>
                                                    <Input
                                                        id="email"
                                                        placeholder=""
                                                        name="email"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.email}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="password">
                                                        Password
                                                </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="password"
                                                        placeholder=""
                                                        name="password"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.password}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>



                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="createdAt">
                                                        Fecha Alta Registro
                                                </Label>

                                                    <DatePicker id="createdAt"
                                                        value={createdAt}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "createdAt")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>OTROS DATOS</CardTitle>
                                        </div>

                                        <Row>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Grupo Usuario</Label>
                                                    <Input type="select" name="user_group_id" id="user_group_id"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.user_group_id}>
                                                        <option selected>Selecciona...</option>
                                                        <option value={9999}> Super Admin</option>
                                                        <option value={8888}> Admin</option>
                                                        <option value={5000}> Responsable</option>
                                                        <option value={4000}> Usuario N1</option>
                                                        <option value={3000}> Usuario N2</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Usuario Activo</Label>
                                                    <Input type="select" name="esActiva" id="esActiva"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esActiva}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Administrador</Label>
                                                    <Input type="select" name="esAdmin" id="esAdmin"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esAdmin}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Comercial</Label>
                                                    <Input type="select" name="esComercial" id="esComercial"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esComercial}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Consultor</Label>
                                                    <Input type="select" name="esConsultor" id="esConsultor"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esConsultor}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Funcionario</Label>
                                                    <Input type="select" name="esFuncionario" id="esFuncionario"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esFuncionario}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Informes</Label>
                                                    <Input type="select" name="informes" id="informes"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.informes}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Detalles</Label>
                                                    <Input type="select" name="detalles" id="detalles"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.detalles}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </CardBody>
                                </Card>
                                {this.state.alert}

                            </div>
                        </Row>
                    </Container>

                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"} />
                </>
            );
        }
    }
}

export default withApollo(crearUsuarios);
