
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

interface HeaderInterface{
  title: string
}

class HeaderDash extends React.Component<HeaderInterface> {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 d-flex align-items-center"
          style={{
            minHeight: "200px",
            backgroundImage:
              //"url('+'/assets/img/theme/header-bg.jpg'+')",
              "url(" + require("../../assets/img/theme/Recaudacion-de-Impuestos.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-cyan opacity-6" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="12" xs="12">
                <h1 className="display-2 text-white w-100">{this.props.title}</h1>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default HeaderDash;
