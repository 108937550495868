import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { validateSpanishId } from 'spain-id';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    CardHeader, Collapse, TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import ReactExport from "react-data-export";
import { CSVLink } from "react-csv";
import classnames from "classnames";
import LoadingOverlay from "react-loading-overlay";
// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";

//import { withRouter } from 'react-router-dom';
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';

import { UserStore } from "../../store/UserStore";
import { removeToken } from "../../utils";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const sortIcon = <ArrowDownward />;

const columnsDatosClientes = [

    { selector: row => row.idRemesa, name: 'ID REMESA', sortable: true, width: '140px', format: (row => capitalize(row.idRemesa)) },
    { selector: row => row.municipio, name: 'MUNICIPIO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.municipio)) },
    { selector: row => row.nombreEmpresaEnergia, name: 'DISTRIB/COMERCIALIZ', sortable: true, width: '220px', format: (row => capitalize(row.nombreEmpresaEnergia)) },
    { selector: row => row.numDocumentoEmpresaEnergia, name: 'CIF', sortable: true, width: '120px', center: true, format: (row => capitalize(row.numDocumentoEmpresaEnergia)) },
    { selector: row => row.descripcion, name: 'DESCRIPCION', sortable: true, width: '140px', format: (row => capitalize(row.descripcion)) },
    { selector: row => row.liquidacion, name: 'AÑO LIQUIDACION', sortable: true, width: '120px', center: true },
    { selector: row => row.emisionLiquidacion, name: 'AÑO EMISION', sortable: true, width: '120px', center: true },
    {
        selector: row => row.importe, name: 'IMPORTE', sortable: true, width: '120px', center: true, format: (row => `${Number(row.importe ? row.importe : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })} €`)
    },
    { selector: row => row.expediente, name: 'AÑO EMISION', sortable: true, width: '120px', center: true },
    { selector: row => row.fecFactura, name: 'FECHA FACTURA', sortable: true, width: '140px', center: true, format: (row => moment(row.fecFactura).isValid() ? moment(row.fecFactura).format('DD-MM-YYYY') : "")  },
    { selector: row => row.idFactura, name: 'ID FACTURA', sortable: true, width: '140px', center: true },
];

const columnsDatosClientesAgrupados = [

    { selector: row => row.nombreEmpresaEnergia, name: 'DISTRIB/COMERCIALIZ', sortable: true, width: '220px', format: (row => capitalize(row.nombreEmpresaEnergia)) },
    { selector: row => row.numDocumentoEmpresaEnergia, name: 'CIF', sortable: true, width: '120px', center: true, format: (row => capitalize(row.numDocumentoEmpresaEnergia)) },
    {
        selector: row => row.importe, name: 'IMPORTE', sortable: true, width: '120px', center: true, format: (row => `${Number(row.importe ? row.importe : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })} €`)
    },
    { selector: row => row.fecFactura, name: 'FECHA FACTURA', sortable: true, width: '140px', center: true, format: (row => moment(row.fecFactura).isValid() ? moment(row.fecFactura).format('DD-MM-YYYY') : "") },
    { selector: row => row.idFactura, name: 'ID FACTURA', sortable: true, width: '140px', center: true },
];

const columnsDatosConsumosAnual = [
    {
        selector: row => row.liquidacion, name: 'AÑO', sortable: false, width: '100px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        selector: row => row.importe, name: 'INGRESOS', sortable: false, width: '160px', center: true, format: (row => `${Number(row.importe ? row.importe : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })} €`)
    },
];
const columnsDatosConsumosAnualIngresos = [
    {
        selector: row => row['id'], name: 'AÑO', sortable: false, width: '100px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    {
        selector: row => row['value'], name: 'INGRESOS', sortable: false, width: '160px', center: true, format: (row => `${Number(row['value'] ? row['value'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })} €`)
    }
];


const columnsDatosConsumosAnualComercializadora = [

    { selector: row => row.nombreEmpresaEnergia, name: 'DISTRIB/COMERCIALIZ', sortable: true, width: '220px', format: (row => capitalize(row.nombreEmpresaEnergia)),  style: {
        color: 'rgba(0,0,0,.54)',
    }, },
    { selector: row => row.numDocumentoEmpresaEnergia, name: 'CIF', sortable: true, width: '120px', center: true, format: (row => capitalize(row.numDocumentoEmpresaEnergia)) },
    { selector: row => row.facturas, name: 'Nº FACTURAS', sortable: true, width: '120px', center: true },
    {
        selector: row => row['importe'], name: 'INGRESOS', sortable: true, width: '160px', center: true, format: (row => `${Number(row['importe'] ? row['importe'] : 0).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })} €`)
    },

];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);


class facturasCliente extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            razonSocial: "",
            remesaSearch: "",
            cifClienteSearch: "",
            cifEmisorSearch: "",
            tipoEstadoSearch: "",
            facturaDesde: "",
            facturaHasta: "",
            municipioBuscar: ""
        },
        UserStore: {},
        resumenIngresos: [{
            liquidacion: 2018,
            importe: 0
        }],
        tasasrecaudadasayuntamientoanuales: [{
            liquidacion: 2018,
            data: [{
                codigoIne: "",
                descripcion: "",
                emisionLiquidacion: 0,
                expediente: "",
                fecFactura: "",
                fechAlta: "",
                idFactura: "",
                idRemesa: "",
                importe: 0,
                municipio: "",
                nombreEmpresaEnergia: "",
                numDocumentoEmpresaEnergia: "",
                tipoDocumento: "",
                usuario: ""
            }]
        }],
        tasasrecaudadasporempresa: [{
            liquidacion: 2018,
            data: [{
                facturas: 0,
                importe: 0,
                nombreEmpresaEnergia: "",
                numDocumentoEmpresaEnergia: ""
            }]
        }],
        tabSelected: 2018,
        tabSelectedString: "2018",
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipocliente: "",
        facturaDetailTotal: [],
        facturaSeleccionada: false,
        idCliente: '',
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataCliente: false,
        updateDataCliente: false,
        saveClienteButtonDisabled: true,
        updateClienteButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        CIFClienteError: false,
        show: false,
        imgCollection: [],
        facturaButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        cifErrorEmisor: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        facturaDesde: '',
        facturaHasta: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        emailError: false,
        nombreTitularError: false,
        NIFError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        municipios: [{ id: "", valor: 0 }],
        cnaeAux: "",
        alert: null
    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.container = React.createRef<HTMLDivElement>();
        this.logout = this.logout.bind(this);
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.toggleNavs = this.toggleNavs.bind(this);
    }



    componentDidMount() {

        const user = localStorage.getItem("user");

        if (user) {
            const parsed = JSON.parse(user);
            this.setState({
                user: parsed,
            });
        }

        this.setState({
            ...this.state,
        });
    }



    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALORES DE USUARIO EN componentWillReceiveProps')
        console.log(usuario)
        
        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    }


    //////////////////////////////////////////////////////////////////////////////

    toggleNavs = (tab) => {
        //event.preventDefault();
        //console.log('valor de tab ---> ', tab)

        if (this.state.activeTab !== tab) {
            this.setState({
                tabSelected: tab,
                tabSelectedString: tab.toString(),
            });
        }

    };


    /////////////////////////////////////////////////////////////////////////////////////////////////////

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    warningAlertNoData = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };


    faltaDatos = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos en los campos de municipio y/o distribuidora
                </ReactBSAlert>
            )
        });
    };

    /////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    /////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        var filtersData = { ...this.state.filters };
        filters[name] = value;

        if (event.target.name === 'razonSocial' && event.target.value.length > 0) {
            this.setState({
                buscarButtonDisabled: false
            })
        };

        if (event.target.name === 'municipio') {
            this.setState({
                buscarButtonDisabled: false
            })
        };

        if (event.target.name === 'cifEmisorSearch' && event.target.value.length == 0) {
            this.setState({
                cifErrorEmision: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'cifEmisorSearch' && event.target.value.length > 0) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                this.setState({
                    cifErrorEmisor: false,
                    buscarButtonDisabled: false,
                })

            } else {
                this.setState({
                    cifErrorEmisor: true,
                    buscarButtonDisabled: true,
                })
            }
        };
        filtersData[name] = value;
        this.setState({ filters: filtersData });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;
        this.setState({ showFilters });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////    

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        const filters = {};
        this.setState({
            filters: {
                remesaSearch: '',
                cifClienteSearch: '',
                idSearch: ''
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            facturaSeleccionada: false,
            facturaDetailTotal: [],
            toggledClearRows: !this.state.toggledClearRows,
            saveClienteButtonDisabled: true,
            updateClienteButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            inputDisabledP3456: false,
            inputDisabledE456: false
        });

        this.formRef.current.reset();

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    buscarFacturas = async () => {

        this.setState({
            isLoading: true,
        })

        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }

        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""


        var dataFilters = {
            'nombreEmpresaEnergia': this.state.filters.razonSocial?.toUpperCase() || "",
            'numDocumentoEmpresaEnergia': this.state.filters.cifEmisorSearch?.toUpperCase() || "",
            'codigoIne': codigoINE,
            'facturaDesde': this.state.filters.facturaHasta,
            'facturaHAsta': this.state.filters.facturaDesde
        };

        const headers = {
            //
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/tasas/facturacionayuntamiento";

        axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {

                if (result.data.length > 0) {
                    let data = result.data[0]

                    var xMax = Math.max.apply(null, data.resumenIngresos.map(function (o) { return parseInt(o.liquidacion); }));


                    this.setState({ facturaDetailTotal: data.dataResumen1Ayuntamiento, resumenIngresos: data.resumenIngresos, tasasrecaudadasayuntamientoanuales: data.tasasrecaudadasayuntamientoanuales, tasasrecaudadasporempresa: data.tasasrecaudadasporempresa, tabSelected: xMax, tabSelectedString: xMax.toString(), isLoading: false });

                } else {
                    this.warningAlertNoData()
                };
                //this.clearForm();
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                  });
              });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            console.log('VALORES DE REFTP', reftp)
            reftp?.scrollIntoView({ behavior: "smooth" })

        } else {

            this.setState({
                show: false
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChangeDateFilter = (value, formattedValue, id) => {
        var formData = { ...this.state.filters };
        const name = id;
        var valueDay = formattedValue
        if (name == 'facturaDesde') {
            this.setState({ facturaDesde: value });
            this.setState({
                buscarButtonDisabled: false,
            })
        }

        if (name == 'facturaHasta') {
            this.setState({ facturaHasta: value });
            this.setState({
                buscarButtonDisabled: false,
            })
        }

        formData[name] = valueDay;
        this.setState({ filters: formData });
    }

    ////////////////////////////////////////////////////////////////////////////////////////

     handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }


    renderRates() {


        var indexyear = this.state.resumenIngresos.map(object => object.liquidacion).indexOf(this.state.tabSelected)

        const styleFullWith = { "width": "100%" };

        var datosResumenIngresos: any = []
        if (indexyear !== -1) {
            datosResumenIngresos = this.state.resumenIngresos[indexyear]
            datosResumenIngresos = datosResumenIngresos
        }

        indexyear = this.state.tasasrecaudadasayuntamientoanuales.map(object => object.liquidacion).indexOf(this.state.tabSelected)

        var tasasrecaudadasayuntamientoanuales: any = []
        if (indexyear !== -1) {
            tasasrecaudadasayuntamientoanuales = this.state.tasasrecaudadasayuntamientoanuales[indexyear]
            tasasrecaudadasayuntamientoanuales = tasasrecaudadasayuntamientoanuales.data
        }


        indexyear = this.state.resumenIngresos.map(object => object.liquidacion).indexOf(this.state.tabSelected)
        var resumenIngresos: any = []
        if (indexyear !== -1) {
            //resumenEnergia = this.state.resumenEnergia[indexyear]
            let id = this.state.resumenIngresos[indexyear].liquidacion
            let value = this.state.resumenIngresos[indexyear].importe
            resumenIngresos.push({ id: id, value: value })
        }

        indexyear = this.state.tasasrecaudadasporempresa.map(object => object.liquidacion).indexOf(this.state.tabSelected)

        var tasasrecaudadasporempresa: any = []
        if (indexyear !== -1) {
            tasasrecaudadasporempresa = this.state.tasasrecaudadasporempresa[indexyear]
            tasasrecaudadasporempresa = tasasrecaudadasporempresa.data
        }


        var energia = 0;
        var ingresos = 0;

        for (var index = 0; index < resumenIngresos.length; ++index) {

            energia = resumenIngresos[index].value;
        }

        for (var index = 0; index < resumenIngresos.length; ++index) {

            ingresos = resumenIngresos[index].value;
        }

        var datosvarios: any = []
        datosvarios.push({ energia: energia, ingresos: ingresos })

        var ingresosEnergiaTotal = ingresos

        var energia = energia
        var total = 0




        return (
            <React.Fragment> <TabPane tabId={this.state.tabSelectedString}>

                <Row>
                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Ingresos Anuales Registrados
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnual}
                                        data={this.state.resumenIngresos}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>

                    <Col sm="3">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Resumen Ingresos
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnualIngresos}
                                        data={resumenIngresos}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Facturas Recibidas Ayuntamiento {this.state.tabSelectedString}
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosClientesAgrupados}
                                        data={tasasrecaudadasayuntamientoanuales}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>

                    <Col sm="6">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                <Row className="">
                                    <h2 className="col">
                                        Facturas Recibidas Agrupadas Por Empresas  {this.state.tabSelectedString}
                                    </h2>
                                </Row>
                                <Row>
                                    <DataTable
                                        columns={columnsDatosConsumosAnualComercializadora}
                                        data={tasasrecaudadasporempresa}
                                        fixedHeader={true}
                                        striped
                                        pointerOnHover
                                        customStyles={customStyles}
                                        noDataComponent={"Sin datos para mostrar"}
                                    />
                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
                <Row className="card-deck" style={styleFullWith}>

                    <Card className="mt-3 mb-3 p-3 card-document">
                        <CardTitle className="mt-4 info-title"><h2>Ingresos Registrados Ayuntamiento</h2></CardTitle>
                        <Row className="mt-4">
                            <Col className="col-auto pt-2 pb-2 mr-2">
                                <h2>Ingresos:</h2>
                            </Col>

                            <Col className="col-auto d-flex bg-units radius-pot text-center align-items-center">
                                <h3 className="mt-2 mb-2 justify-content-center align-self-center text-center">{energia.toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })} €</h3>
                            </Col>

                            <Col className="col-auto pt-2 pb-2 ml-4">
                            <ExcelFile filename="Resumen_comercializadoras_ingresos_ayuntamiento" element={<Button color="primary"><i className={"bi bi-search"} /> Bajar Excel</Button>}>
                                        <ExcelSheet data={tasasrecaudadasporempresa} name="Tasas Anual Com">
                                            <ExcelColumn label="COMERCIALIZADORA" value="nombreEmpresaEnergia" />
                                            <ExcelColumn label="CIF" value="numDocumentoEmpresaEnergia" />
                                            <ExcelColumn label="NUMERO FACTURAS" value="facturas" />
                                            <ExcelColumn label="IMPORTE" value="importe" />
                                        </ExcelSheet>
                                    </ExcelFile>
                            </Col>
  
                        </Row>

                    </Card>
                </Row>
            </TabPane>
            </React.Fragment>
        )


        //columnsDatosConsumosAnual

    };




    render() {

        const isLoading = this.state.isLoading;
  
        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)

        const styleFullWith = { "width": "100%" };

        const facturaDesde = this.state.facturaDesde
        const facturaHasta = this.state.facturaHasta

        const ratesHtml = this.renderRates();
        const consumosAnuales = this.state.tasasrecaudadasayuntamientoanuales;

        return (
            <>
                <Header title={'Información Facturas Emitidas - Cliente'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">

                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    APELLIDOS/RAZON SOCIAL
                                                </Label>
                                                <Input
                                                    id="razonSocial"
                                                    placeholder="Ingresar Apellidos/Razón Social del cliente"
                                                    name="razonSocial"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.razonSocial}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="cifEmisorSearch">
                                                    CIF DISTRIBUIDORA
                                                </Label>
                                                <Input
                                                    id="cifEmisorSearch"
                                                    placeholder="Ingresar CIF del emisor"
                                                    name="cifEmisorSearch"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.cifEmisorSearch}
                                                    type="text"

                                                />
                                                {this.state.cifErrorEmisor ? <FormText color="red">Comprobar CIF/DNI</FormText> : ''}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>


                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label">Desde</Label>
                                                <DatePicker id="facturaDesde"
                                                    value={facturaDesde}
                                                    onChange={(v, f) => this.handleChangeDateFilter(v, f, "facturaDesde")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label">Hasta</Label>
                                                <DatePicker id="facturaHasta"
                                                    value={facturaHasta}
                                                    onChange={(v, f) => this.handleChangeDateFilter(v, f, "facturaHasta")}
                                                    instanceCount={2}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarFacturas}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE FACTURAS - EMPRESAS DE SERVICIO"
                                            columns={columnsDatosClientes}
                                            data={this.state.facturaDetailTotal}
                                            //selectableRowsComponent={radio} // Pass the function only
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            subHeader
                                            subHeaderComponent={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    <CSVLink
                                                        data={this.state.facturaDetailTotal}
                                                        filename={"FacturasClientes.csv"}
                                                        className="btn btn-default btn-sm btn-circle"
                                                        color="default"
                                                        target="_blank"
                                                        separator={";"}
                                                    >

                                                        <span className="btn-inner--icon">
                                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Fichero CSV</span>

                                                    </CSVLink>
                                                </div>

                                            }
                                            progressComponent={<Circular />}
                                        />
                                    </Card>

                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>
                </Container>
                <Container>

<Row>
    <div className="col">
        <Card className="shadow">
            <CardBody>
                <Row>
                    <Col>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={this.props.history.goBack}
                        >
                            Atrás
                        </button>
                    </Col>
                    <FormGroup>
                        <Col>

                        </Col>
                    </FormGroup>

                </Row>
                
            </CardBody>
        </Card>

        {this.state.alert}

    </div>
</Row>
</Container>


                <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="section-tabs"
                    tabs
                    role="tablist"
                >
                    {

                        consumosAnuales.map(item => <NavItem key={item.liquidacion}>
                            <NavLink
                                key={item.liquidacion}
                                aria-selected={this.state.tabSelected === item.liquidacion}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabSelected === item.liquidacion,
                                })}
                                onClick={() => this.toggleNavs(item.liquidacion)}
                                href="#"
                                role="tab"
                            >
                                {item.liquidacion}
                            </NavLink>
                        </NavItem>)

                    }
                </Nav>

                <TabContent activeTab={this.state.tabSelectedString}>


                    {ratesHtml}


                </TabContent>


                <div>
                    <LoadingOverlay
                        active={isLoading}
                        spinner
                        text="Cargando...">


                    </LoadingOverlay>
                </div>
                {this.state.alert}
            </>
        );
    }
}


export default withApollo(facturasCliente);
