import Environment from '../../constants/environment';
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import { removeToken } from "../../utils";
import { validateSpanishId, spainIdType } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import { Link, withRouter } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
// core components
import Header from "../../components/Headers/Header";

//import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';

var DatePicker = require("reactstrap-date-picker");

const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const sortIcon = <ArrowDownward />;

const columnsDatosAyuntamiento = [

    { selector: row => row.municipio, name: 'MUNICIPIO', sortable: true, width: '220px', format: (row => capitalize(row.municipio)) },
    { selector: row => row.empresa, name: 'EMPRESA', sortable: true, width: '100px', center: true },
    { selector: row => row.expediente, name: 'EXPEDIENTE', sortable: true, width: '180px', center: true },
    { selector: row => row.nombreRepresentante, name: 'NOMBRE', sortable: true, width: '240px', center: true, format: (row => capitalize(row.nombreRepresentante)) },
    { selector: row => row.apellido1Representante, name: 'APELLIDO', sortable: true, width: '240px', center: true, format: (row => capitalize(row.apellido1Representante)) },
    { selector: row => row.cargo, name: 'CARGO', sortable: true, width: '160px', center: true, format: (row => capitalize(row.cargo)) },
    { selector: row => row.area, name: 'AREA', sortable: true, width: '160px', center: true, format: (row => capitalize(row.area)) },
    { selector: row => row.telefonoFijo, name: 'TELEFONO', sortable: true, width: '180px', center: true, format: (row => capitalize(row.telefonoFijo)) },
    { selector: row => row.telefonoMovil, name: 'MOVIL', sortable: true, width: '180px', center: true, format: (row => capitalize(row.telefonoMovil)) },
    { selector: row => row.email, name: 'CORREO', sortable: true, width: '180px', center: true },


];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

class gestionContestacionEmpresas extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            municipioSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            contestacionDesde: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        TipoCliente: "",
        contestacionDetailTotal: [],
        municipios: [{ id: "Sin valores", valor: 0, email: "" }],
        id: '',
        contestacionDetail: {
            municipio: "",
            codigoINE: "",
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            area: "",
            cargo: "",
            cif: "",
            empresa: "",
            telefonoFijo: "",
            telefonoMovil: "",
            email: "",
            createdAt: "",
            id: "",
            uuidId: "",
            esActiva: false,
            esAprobada: false,
            esEnEstudio: false,
            esFinalizada: false,
            esContestada: false,
            expediente: "",
            fechaContestacionEmpresa: null,
            fechaRevision: null,
            fechaRespuestaAyuntamiento: null,
            fechaFinalizacionExpediente: null,
            idUser: null,
            estado: null
        },
        formContestacion: {
            municipio: "",
            codigoINE: "",
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            area: "",
            cargo: "",
            cif: "",
            empresa: "",
            telefonoFijo: "",
            telefonoMovil: "",
            email: "",
            createdAt: "",
            id: "",
            uuidId: "",
            esActiva: false,
            esAprobada: false,
            esEnEstudio: false,
            esFinalizada: false,
            esContestada: false,
            expediente: "",
            fechaContestacionEmpresa: null,
            fechaRevision: null,
            fechaRespuestaAyuntamiento: null,
            fechaFinalizacionExpediente: null,
            idUser: null,
            estado: null
        },
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        saveDataCliente: false,
        updateDataContestacion: false,
        saveContestacionButtonDisabled: true,
        updateContestacionButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        contestacionDesde: '',
        createdAt: '',
        fechaContestacionEmpresa: null,
        fechaRevision: null,
        fechaRespuestaAyuntamiento: null,
        fechaFinalizacionExpediente: null,
        fechaPresentacion: null,
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        alert: null,
        empresas: [{ id: "Sin valores", valor: 0 }],
        expedientes: null,
        expedientesList: [{ id: "Sin valores", anualidad: 0 }],
        expedienteSeleccionado: [{}],
        inputEmpresaDisabled: true,
        inputExpedientesDisabled: true,
        inputMunicipioDisabled: false

    };


    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);

    }


    componentDidMount() {
        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))
        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
        });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        //this.props.client.cache.reset();
        //this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };
    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea dar de alta la contestación?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar el registro?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Contestacióm guardada correctamente
                </ReactBSAlert>
            )
        });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        var filtersData = { ...this.state.filters };
        filters[name] = value;

        if (event.target.name === 'cifSearch' && event.target.value.length == 0) {

            this.setState({

                NIFError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'cifSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonDisabled: false,
                })

            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonDisabled: true,
                })
            }
        }

        if (event.target.name === 'municipio') {
            this.setState({
                buscarButtonDisabled: false,
            })
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null
        }

        if (event.target.name === 'tipoEstadoSearch') {
            this.setState({
                buscarButtonDisabled: false,
            })
        }

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldContestacion = (event) => {
        event.persist()
        var formData = { ...this.state.formContestacion };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        const nameFilters = ['port', 'host', 'usermail', 'secure', 'pass', 'nombreRepresentante', 'apellido1Representante', 'apellido2Representante', 'area', 'cargo', 'cif', 'tipoVia', 'direccion', 'numFinca', 'portal', 'escalera', 'piso', 'puerta', 'codigoPostal', 'telefonoFijo', 'telefonoMovil', 'email', 'createdAt', 'id', 'uuidId', 'esActiva', 'esAprobada', 'esEnEspera', 'esFinalizada', 'esFirmada', 'esPresentada', 'expediente', 'fechaContestacionEmpresa', 'fechaRevision', 'fechaRespuestaAyuntamiento', 'fechaFinalizacionExpediente', 'fechaPresentacion'];

        const optionsFilters = ['esActiva', 'esAprobada', 'esEnEstudio', 'esFinalizada', 'esContestada'];

        if (this.state.contestacionSeleccionada) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ updateDataContestacion: true });
                this.setState({
                    saveContestacionButtonDisabled: true,
                    updateContestacionButtonDisabled: false
                })
            }
            if (event.target.name == 'nombreContacto' && event.target.value.length > 0) {

                this.setState({ updateDataContestacion: true });
                this.setState({
                    saveContestacionButtonDisabled: true,
                    updateContestacionButtonDisabled: false
                })
            }
            if (event.target.name == 'apellido1Contacto' && event.target.value.length > 0) {

                this.setState({ updateDataContestacion: true });
                this.setState({
                    saveContestacionButtonDisabled: true,
                    updateContestacionButtonDisabled: false
                })
            }

            if (event.target.name === 'cif' && event.target.value.length == 0) {
                this.setState({ NIFClienteError: true })
            } else if (event.target.name === 'cif' && event.target.value.length > 0) {
                const result = validateSpanishId(event.target.value)
                if (result === true) {
                    this.setState({
                        updateDataContestacion: true,
                        NIFClienteError: false
                    });
                    this.setState({
                        saveContestacionButtonDisabled: true,
                        updateContestacionButtonDisabled: false
                    })
                } else {
                    this.setState({
                        NIFClienteError: true,
                        updateDataContestacion: false,
                        updateContestacionButtonDisabled: true
                    })
                }
            }


            if (event.target.name === 'email') {
                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                const result = pattern.test(event.target.value);
                if (result === true) {
                    this.setState({
                        emailError: false,
                    })
                } else {
                    this.setState({
                        emailError: true
                    })
                }
            };


            if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
                value = null
            }

            if (value === "true" || value == "false") {
                value = JSON.parse(value);
            };

        }

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formContestacion: formData });

    };


    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        const filters = {};
        this.setState({
            filters: {
                municipioSearch: '',
                NIFSearch: '',
                idSearch: ''
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            contestacionSeleccionada: false,
            contestacionDetailTotal: [],
            formContestacion: {
                municipio: municipio,
                codigoINE: codigoINE,
                nombreRepresentante: "",
                apellido1Representante: "",
                apellido2Representante: "",
                area: "",
                cargo: "",
                cif: "",
                empresa: "",
                telefonoFijo: "",
                telefonoMovil: "",
                email: "",
                createdAt: "",
                id: "",
                uuidId: "",
                esActiva: false,
                esAprobada: false,
                esEnEstudio: false,
                esFinalizada: false,
                esContestada: false,
                expediente: "",
                fechaContestacionEmpresa: null,
                fechaRevision: null,
                fechaRespuestaAyuntamiento: null,
                fechaFinalizacionExpediente: null,
                idUser: null,
                estado: null
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveContestacionButtonDisabled: true,
            updateContestacionButtonDisabled: true,
            createdAt: '',
            fechaContestacionEmpresa: null,
            fechaRevision: null,
            fechaRespuestaAyuntamiento: null,
            fechaFinalizacionExpediente: null,
            fechaPresentacion: null,
        });

        this.formRef.current.reset();

    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarAyuntamiento = async () => {

        this.setState({
            isLoading: true,
        })

        const uuidUser = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")

        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }

        var municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        var tasasResumen: any = []
        var iduser = datosUser.userId ? datosUser.userId : ""
        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""


        var dataFilters = {
            'codigoINE': codigoINE,
            'cif': this.state.filters.cifSearch?.toUpperCase() || "",
            'esActiva': this.state.filters.tipoEstadoSearch,
        }

        const valores = JSON.stringify(dataFilters)

        const headers = {
            
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/tasas/contestacionlistmunicipio";


        axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {
                //console.log(JSON.stringify(result.data));
                //console.log('resultado del listados')
                if (result.data.rows.length > 0) {
                    let data = result.data.rows

                    this.setState({ contestacionDetailTotal: result.data.rows });

                } else {
                    this.warningAlertNIF()
                };
                //this.clearForm();
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                  });
              });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {
        if (this.state.updateDataContestacion && (this.state.formContestacion.nombreRepresentante && this.state.formContestacion.cif)) {
            this.confirmAlertUpdate()
        } else {
            this.warningAlertFiltros()
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {
        const uuidUser = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")
        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }

        var municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
        const codigoINE = this.state.municipioBuscar ? this.state.municipioBuscar : ""
        var tasasResumen: any = []
        var iduser = datosUser.id ? datosUser.id : ""
        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""

        //const fechaActivacion = this.state.fechaActivacion;
        //const companyId = this.state.companyIdUser.toString();
        //const userId = this.state.userId.toString();

        if (this.state.updateDataContestacion == true) {


            var createdAt = this.state.formContestacion.createdAt;
            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var fechaActivacion = this.state.formContestacion.fechaActivacion;
            if (!fechaActivacion) {

                fechaActivacion = ''
            };

            var fechaAprobacion = this.state.formContestacion.fechaAprobacion;
            if (!fechaAprobacion) {
                fechaAprobacion = ''
            };

            var fechaFirma = this.state.formContestacion.fechaFirma;
            if (!fechaFirma) {
                fechaFirma = ''
            };

            var fechaFinalizacion = this.state.formContestacion.fechaFinalizacion;
            if (!fechaFinalizacion) {
                fechaFinalizacion = ''
            };

            var fechaPresentacion = this.state.formContestacion.fechaPresentacion;
            if (!fechaPresentacion) {
                fechaPresentacion = ''
            };

            var contestacionDataUpdate = {}

            contestacionDataUpdate = {
                'datosContestacion': this.state.formContestacion,
                'idUser': iduser 
            };

            const headers = {
                
                'Content-Type': 'application/json',
            };
            let direccionFile = "https://api.nextniro.com/api/v1/tasas/contestacionupdate";


            axios.post(direccionFile, contestacionDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        contestacionSeleccionada: false,
                        contestacionDetailTotal: [],
                        toggledClearRows: !this.state.toggledClearRows,
                        saveContestacionButtonDisabled: true,
                        updateContestacionButtonDisabled: true,
                        createdAt: '',
                        updatedAt: '',
                        fechaActivacion: null,
                        fechaAprobacion: null,
                        fechaFirma: null,
                        fechaFinalizacion: null,
                        fechaPresentacion: null,
                        formContestacion: {
                            municipio: "",
                            codigoINE: "",
                            nombreContacto: "",
                            apellido1Contacto: "",
                            apellido2Contacto: "",
                            area: "",
                            cargo: "",
                            cif: "",
                            tipoVia: "",
                            direccion: "",
                            numFinca: "",
                            portal: "",
                            escalera: "",
                            piso: "",
                            puerta: "",
                            codigoPostal: "",
                            provincia: "",
                            comunidadAutonoma: "",
                            telefonoFijo: "",
                            telefonoMovil: "",
                            email: "",
                            createdAt: "",
                            id: "",
                            uuidId: "",
                            esActiva: false,
                            esAprobada: false,
                            esEnEspera: false,
                            esFinalizada: false,
                            esFirmada: false,
                            esPresentada: false,
                            expediente: "",
                            fechaActivacion: null,
                            fechaAprobacion: null,
                            fechaFirma: null,
                            fechaFinalizacion: null,
                            fechaPresentacion: null,
                            idUser: null,
                            idEmpresa: null,
                            host: null,
                            port: null,
                            secure: null,
                            usermail: null,
                            pass: null
                        }

                    });

                    //this.clearForm();
                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                      });
                  });

            await this.buscarAyuntamiento();
        }
    };


    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formContestacion };
        const name = id;

        if (name == 'fechaContestacionEmpresa') {
            this.setState({ fechaContestacionEmpresa: value });
        }

        if (name == 'fechaRevision') {
            this.setState({ fechaRevision: value });
        }

        if (name == 'fechaRespuestaAyuntamiento') {
            this.setState({ fechaRespuestaAyuntamiento: value });
        }

        if (name == 'fechaFinalizacionExpediente') {
            this.setState({ fechaFinalizacionExpediente: value });
        }

        if (name == 'createdAt') {
            this.setState({ createdAt: value });
        }

        formData[name] = value
        this.setState({ formContestacion: formData });
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {

        if (state.selectedCount === 1) {
            var existeContestacion = ''
            var cliente = state.selectedRows[0].id;
            this.setState({ contestacionDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt

            existeContestacion = state.selectedRows[0].id

            if (existeFechaAlta) {
                this.setState({
                    inputDisabled: false,
                    updateContestacionButtonDisabled: true
                })
            };

            if (existeContestacion) {
                this.setState({ id: this.state.contestacionDetail.id });
                this.setState({
                    formContestacion: {
                        municipio: state.selectedRows[0].municipio,
                        codigoINE: state.selectedRows[0].codigoINE,
                        nombreRepresentante: state.selectedRows[0].nombreRepresentante,
                        apellido1Representante: state.selectedRows[0].apellido1Representante,
                        apellido2Representante: state.selectedRows[0].apellido2Representante,
                        area: state.selectedRows[0].area,
                        cargo: state.selectedRows[0].cargo,
                        cif: state.selectedRows[0].cif,
                        empresa: state.selectedRows[0].empresa,
                        telefonoFijo: state.selectedRows[0].telefonoFijo,
                        telefonoMovil: state.selectedRows[0].telefonoMovil,
                        email: state.selectedRows[0].email,
                        createdAt: state.selectedRows[0].createdAt,
                        id: state.selectedRows[0].id,
                        uuidId: state.selectedRows[0].uuidId,
                        esActiva: state.selectedRows[0].esActiva,
                        esAprobada: state.selectedRows[0].esAprobada,
                        esEnEstudio: state.selectedRows[0].esEnEspera,
                        esFinalizada: state.selectedRows[0].esFinalizada,
                        esContestada: state.selectedRows[0].esContestada,
                        expediente: state.selectedRows[0].expediente,
                        fechaContestacionEmpresa: state.selectedRows[0].fechaContestacionEmpresa,
                        fechaRevision: state.selectedRows[0].fechaRevision,
                        fechaRespuestaAyuntamiento: state.selectedRows[0].fechaRespuestaAyuntamiento,
                        fechaFinalizacionExpediente: state.selectedRows[0].fechaFinalizacionExpediente,
                        idUser: state.selectedRows[0].idUser,
                        estado: state.selectedRows[0].estado
                    },
                    contestacionSeleccionada: true,
                    inputDisabled: false,
                    esActiva: state.selectedRows[0].esActiva,
                    esAprobada: state.selectedRows[0].esAprobada,
                    esEnEstudio: state.selectedRows[0].esEnEspera,
                    esFinalizada: state.selectedRows[0].esFinalizada,
                    esContestada: state.selectedRows[0].esContestada,
                    expediente: state.selectedRows[0].expediente,
                    createdAt: state.selectedRows[0].createdAt
                });

            } else {
                this.setState({
                    contestacionSeleccionada: false,
                    formContestacion: {
                        municipio: "",
                        codigoINE: "",
                        nombreRepresentante: "",
                        apellido1Representante: "",
                        apellido2Representante: "",
                        area: "",
                        cargo: "",
                        cif: "",
                        empresa: "",
                        telefonoFijo: "",
                        telefonoMovil: "",
                        email: "",
                        createdAt: "",
                        id: "",
                        uuidId: "",
                        esActiva: false,
                        esAprobada: false,
                        esEnEstudio: false,
                        esFinalizada: false,
                        esContestada: false,
                        expediente: "",
                        fechaContestacionEmpresa: null,
                        fechaRevision: null,
                        fechaRespuestaAyuntamiento: null,
                        fechaFinalizacionExpediente: null,
                        idUser: null,
                        estado: null
                    },
                    fechaContestacionEmpresa: null,
                    fechaRevision: null,
                    fechaRespuestaAyuntamiento: null,
                    fechaFinalizacionExpediente: null,
                    fechaPresentacion: null,
                    createdAt: null
                });
            }
        } else {

            this.setState({
                contestacionSeleccionada: false,
                inputDisabled: false,
                updateContestacionButtonDisabled: true,
                fechaContestacionEmpresa: null,
                fechaRevision: null,
                fechaRespuestaAyuntamiento: null,
                fechaFinalizacionExpediente: null,
                fechaPresentacion: null,
                createdAt: null,
                formContestacion: {
                    municipio: "",
                    codigoINE: "",
                    nombreRepresentante: "",
                    apellido1Representante: "",
                    apellido2Representante: "",
                    area: "",
                    cargo: "",
                    cif: "",
                    empresa: "",
                    telefonoFijo: "",
                    telefonoMovil: "",
                    email: "",
                    createdAt: "",
                    id: "",
                    uuidId: "",
                    esActiva: false,
                    esAprobada: false,
                    esEnEstudio: false,
                    esFinalizada: false,
                    esContestada: false,
                    expediente: "",
                    fechaContestacionEmpresa: null,
                    fechaRevision: null,
                    fechaRespuestaAyuntamiento: null,
                    fechaFinalizacionExpediente: null,
                    idUser: null,
                    estado: null
                }
            });
        };
    };

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };

    render() {

        const formContestacion: any = this.state.formContestacion;
        const createdAt = this.state.createdAt

        const fechaContestacionEmpresa = this.state.formContestacion.fechaContestacionEmpresa
        const fechaRevision = this.state.formContestacion.fechaRevision
        const fechaRespuestaAyuntamiento = this.state.formContestacion.fechaRespuestaAyuntamiento
        const fechaFinalizacionExpediente = this.state.formContestacion.fechaFinalizacionExpediente

            return (
                <>
                    <Header title={'Información Contestación a Requerimiento'} />
                    <Container className=" mt--7" fluid>

                        <Card className="mb-4">
                            <CardHeader>
                                <Button
                                    onClick={this.showFiltersToggle}
                                    size="sm"
                                    className="float-right"
                                >
                                    <i className={"ni " + this.getFilterIcon()} />
                                </Button>
                                <h3 className="mb-0">Filtrar</h3>
                            </CardHeader>
                            <Collapse isOpen={this.state.showFilters}>
                                <CardBody>
                                    <Form innerRef={this.formRef}>
                                        <Row>

                                            <Col lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="cifSearch">
                                                        CIF
                                                    </Label>
                                                    <Input
                                                        id="cifSearch"
                                                        placeholder="Ingresar CIF del contacto del ayuntamiento"
                                                        name="cifSearch"
                                                        maxLength={16}
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.cifSearch}
                                                        type="text"

                                                    />
                                                    {this.state.NIFError ? <FormText color="red">Comprobar CIF</FormText> : ''}
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoEstadoAprobada">ESTADO</Label>
                                                    <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.tipoEstadoSearch}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">ACTIVO</option>
                                                        <option value="false">BAJA</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col className="text-right">
                                                <Button onClick={this.clearForm}>
                                                    <i className={"ni ni-fat-remove"} /> Limpiar
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    onClick={this.buscarAyuntamiento}
                                                >
                                                    <i className={"bi bi-search"} /> Buscar
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Card className="shadow">
                                            <DataTable
                                                title="LISTADO DE CONTESTACIONES"
                                                columns={columnsDatosAyuntamiento}
                                                data={this.state.contestacionDetailTotal}
                                                selectableRows
                                                //selectableRowsComponent={radio} // Pass the function only
                                                selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                                selectableRowsSingle
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                noDataComponent={"Sin datos para mostrar"}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                                onSelectedRowsChange={this.handleChange}
                                                clearSelectedRows={this.state.toggledClearRows}
                                                highlightOnHover
                                                striped
                                                pointerOnHover
                                                selectableRowsHighlight
                                                customStyles={customStyles}
                                                contextMessage={{ singular: 'producto', plural: 'productos', message: 'seleccionado' }}
                                                subHeader
                                                subHeaderComponent={

                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <CSVLink
                                                            data={this.state.contestacionDetailTotal}
                                                            filename={"municipiosRegistrados.csv"}
                                                            className="btn btn-default btn-sm btn-circle"
                                                            color="default"
                                                            target="_blank"
                                                            separator={";"}
                                                        >

                                                            <span className="btn-inner--icon">
                                                                <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Fichero CSV</span>

                                                        </CSVLink>
                                                    </div>
                                                }
                                                progressComponent={<Circular />}
                                            />
                                        </Card>

                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={this.props.history.goBack}
                                                >
                                                    Atrás
                                                </button>
                                            </Col>

                                            <Col className="col-auto d-flex">
                                                <Card className="p-3 bg-light">
                                                    <FormGroup>

                                                        <Button disabled={this.state.updateContestacionButtonDisabled} id="updateAytoProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                            onClick={() => { this.updateHandler(this.state.updateDataContestacion) }}
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-file-earmark-text" style={{ fontSize: 30 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Actualizar</span>
                                                            <UncontrolledTooltip delay={0} placement="top" target="updateAytoProptooltip">
                                                                MODIFICAR CONTESTACION EXISTENTE
                                                            </UncontrolledTooltip>
                                                        </Button>

                                                        <Link
                                                            color="info" type="button" className="btn btn-primary btn-sm btn-circle"
                                                            data-toggle="modal"
                                                            to={{ pathname: "nueva-contestacion" }}
                                                            id="nuevaContestacion"
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-file-earmark-text" style={{ fontSize: 30 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Nuevo reg.</span>

                                                            <UncontrolledTooltip delay={0} placement="top" target="nuevaContestacion">
                                                                CREAR CONTESTACION
                                                            </UncontrolledTooltip>
                                                        </Link>

                                                    </FormGroup>

                                                </Card>
                                            </Col>

                                        </Row>
                                        <div>
                                            <CardTitle>DATOS GENERALES CONTESTACION</CardTitle>
                                        </div>

                                        <Row>
                                            <Col md="3">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="municipio">
                                                        MUNICIPIO
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="municipio"
                                                        placeholder=""
                                                        name="municipio"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.municipio}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="codigoINE">
                                                        INE
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="INE"
                                                        placeholder=""
                                                        name="codigoINE"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.codigoINE}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="empresa">
                                                        EMPRESA
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="empresa"
                                                        placeholder=""
                                                        name="empresa"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.empresa|| ''}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="expediente">
                                                        EXPEDIENTE
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="expediente"
                                                        placeholder=""
                                                        name="expediente"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.expediente|| ''}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="createdAt">
                                                        Fecha Alta Registro
                                                    </Label>
                                                    <DatePicker id="createdAt"
                                                        value={createdAt}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "createdAt")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>DATOS DE CONTACTO</CardTitle>
                                        </div>
                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreRepresentante">
                                                        Nombre Contacto
                                                    </Label>
                                                    <Input
                                                        id="nombreRepresentante"
                                                        placeholder=""
                                                        name="nombreRepresentante"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.nombreRepresentante}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido1Representante">
                                                        Primer Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido1Representante"
                                                        placeholder=""
                                                        name="apellido1Representante"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.apellido1Representante}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="apellido2Representante">
                                                        Segundo Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido2Representante"
                                                        placeholder=""
                                                        name="apellido2Representante"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.apellido2Representante}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="area">
                                                        Area
                                                    </Label>
                                                    <Input
                                                        id="area"
                                                        placeholder=""
                                                        name="area"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.area}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="cargo">
                                                        Cargo
                                                    </Label>
                                                    <Input
                                                        id="cargo"
                                                        placeholder=""
                                                        name="cargo"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.cargo}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="telefonoFijo">
                                                        Teléfono Contacto
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoFijo"
                                                        placeholder=""
                                                        name="telefonoFijo"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.telefonoFijo}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="telefonoMovil">
                                                        Teléfono Móvil
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoMovil"
                                                        placeholder=""
                                                        name="telefonoMovil"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.telefonoMovil}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="email">
                                                        Correo Contacto
                                                    </Label>
                                                    <Input
                                                        id="email"
                                                        placeholder=""
                                                        name="email"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.email}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>OTROS DATOS</CardTitle>
                                        </div>
                                        <Row>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esEnEstudio">En Estudio</Label>
                                                    <Input type="select" name="esEnEstudio" id="esEnEstudio"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.esEnEstudio}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="En Respuesta"> Respuesta</Label>
                                                    <Input type="select" name="esContestada" id="esContestada"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.esContestada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esActiva">Activa</Label>
                                                    <Input type="select" name="esActiva" id="esActiva"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.esActiva}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esAprobada">Aprobada</Label>
                                                    <Input type="select" name="esAprobada" id="esAprobada"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.esAprobada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esFinalizada">Finalizada</Label>
                                                    <Input type="select" name="esFinalizada" id="esFinalizada"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldContestacion}
                                                        value={formContestacion.esFinalizada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaContestacionEmpresa">
                                                        Fecha Contestación
                                                    </Label>
                                                    <DatePicker id="fechaContestacionEmpresa"
                                                        value={fechaContestacionEmpresa}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "fechaContestacionEmpresa")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaRevision">
                                                        Fecha Revisión
                                                    </Label>
                                                    <DatePicker id="fechaRevision"
                                                        value={fechaRevision}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "fechaRevision")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaRespuestaAyuntamiento">
                                                        Fecha Resp. Ayto
                                                    </Label>
                                                    <DatePicker id="fechaRespuestaAyuntamiento"
                                                        value={fechaRespuestaAyuntamiento}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "fechaRespuestaAyuntamiento")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaFinalizacionExpediente">
                                                        Fecha Fin Exped.
                                                    </Label>
                                                    <DatePicker id="fechaFinalizacionExpediente"
                                                        value={fechaFinalizacionExpediente}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "fechaFinalizacionExpediente")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                {this.state.alert}
                            </div>
                        </Row>
                    </Container>
                </>
            );

    }
}

export default withRouter(gestionContestacionEmpresas);
