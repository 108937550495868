import Environment from '../../constants/environment';
 import { memoizedRefreshToken } from "../../utils/refreshToken";

import React, { forwardRef } from "react";
import Reflux from "reflux";
//import axios from 'axios';
import axios from "../../utils/axios";
import memoize from 'memoize-one';
import { removeToken } from "../../utils";
import { validateSpanishId, spainIdType } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import { Link, withRouter } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
// core components
import Header from "../../components/Headers/Header";

//import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';

var DatePicker = require("reactstrap-date-picker");

const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const sortIcon = <ArrowDownward />;

const columnsDatosAyuntamiento = [

    { selector: row => row.municipio, name: 'MUNICIPIO', sortable: true, width: '220px', format: (row => capitalize(row.municipio)) },
    { selector: row => row.esActiva, name: 'ACTIVO', sortable: true, width: '100px', center: true },
    { selector: row => row.nombreContacto, name: 'NOMBRE', sortable: true, width: '240px', center: true, format: (row => capitalize(row.nombreContacto)) },
    { selector: row => row.apellido1Contacto, name: 'APELLIDO', sortable: true, width: '240px', center: true, format: (row => capitalize(row.apellido1Contacto)) },
    { selector: row => row.cargo, name: 'CARGO', sortable: true, width: '160px', center: true, format: (row => capitalize(row.cargo)) },
    { selector: row => row.area, name: 'AREA', sortable: true, width: '160px', center: true, format: (row => capitalize(row.area)) },
    { selector: row => row.telefonoFijo, name: 'TELEFONO', sortable: true, width: '180px', center: true, format: (row => capitalize(row.telefonoFijo)) },
    { selector: row => row.telefonoMovil, name: 'MOVIL', sortable: true, width: '180px', center: true, format: (row => capitalize(row.telefonoMovil)) },
    { selector: row => row.email, name: 'CORREO', sortable: true, width: '180px', center: true },
    { selector: row => row.expediente, name: 'EXPEDIENTE', sortable: true, width: '180px', center: true },

];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

  const urlContentToDataUri2 = (url) =>{
    return  fetch(url)
            .then( response => response.blob() )
            .then( blob => new Promise( callback =>{
                let reader = new FileReader() ;
                reader.onload = function(){ callback(this.result) } ;
                reader.readAsDataURL(blob) ;
            }) ) ;
}

class Ayuntamiento extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            municipioSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            ayuntamientoDesde: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        TipoCliente: "",
        ayuntamientoDetailTotal: [],
        ayuntamientoSeleccionado: false,
        id: '',
        municipios: [{ key: "", value: 0 }],
        logoAyuntamiento: "",
        logoPropuesta: "",
        ayuntamientoDetail: {
            municipio: "",
            codigoINE: "",
            nombreContacto: "",
            apellido1Contacto: "",
            apellido2Contacto: "",
            area: "",
            cargo: "",
            cif: "",
            tipoVia: "",
            direccion: "",
            numFinca: "",
            portal: "",
            escalera: "",
            piso: "",
            puerta: "",
            codigoPostal: "",
            provincia: "",
            comunidadAutonoma: "",
            telefonoFijo: "",
            telefonoMovil: "",
            email: "",
            createdAt: "",
            id: "",
            uuidId: "",
            esActiva: false,
            esAprobada: false,
            esEnEspera: false,
            esFinalizada: false,
            esFirmada: false,
            esPresentada: false,
            expediente: "",
            fechaActivacion: null,
            fechaAprobacion: null,
            fechaFirma: null,
            fechaFinalizacion: null,
            fechaPresentacion: null,
            idUser: null,
            idEmpresa: null,
            host: null,
            port: null,
            secure: null,
            usermail: null,
            pass: null,
            logoAyuntamiento:"",
            logoPropuesta: ""

        },
        formAyuntamiento: {
            municipio: "",
            codigoINE: "",
            nombreContacto: "",
            apellido1Contacto: "",
            apellido2Contacto: "",
            area: "",
            cargo: "",
            cif: "",
            tipoVia: "",
            direccion: "",
            numFinca: "",
            portal: "",
            escalera: "",
            piso: "",
            puerta: "",
            codigoPostal: "",
            provincia: "",
            comunidadAutonoma: "",
            telefonoFijo: "",
            telefonoMovil: "",
            email: "",
            createdAt: "",
            id: "",
            uuidId: "",
            esActiva: false,
            esAprobada: false,
            esEnEspera: false,
            esFinalizada: false,
            esFirmada: false,
            esPresentada: false,
            expediente: "",
            fechaActivacion: null,
            fechaAprobacion: null,
            fechaFirma: null,
            fechaFinalizacion: null,
            fechaPresentacion: null,
            idUser: null,
            idEmpresa: null,
            host: null,
            port: null,
            secure: null,
            usermail: null,
            pass: null,
            logoAyuntamiento:"",
            logoPropuesta: ""
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        saveDataCliente: false,
        updateDataAyuntamiento: false,
        saveAyuntamientoButtonDisabled: true,
        updateAyuntamientoButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        ayuntamientoDesde: '',
        createdAt: '',
        fechaActivacion: null,
        fechaAprobacion: null,
        fechaFirma: null,
        fechaFinalizacion: null,
        fechaPresentacion: null,
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        alert: null,
        nombreAyuntamiento: ''
    };


    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const nombreAyuntamiento = usuario.ayuntamiento

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            nombreAyuntamiento
        }, 
         this.buscarAyuntamiento);    
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        //this.props.client.cache.reset();
        //this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    errorDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existe un problema con el servidor"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un problema con el servidor. Si persite este error, por favor, contacte con el área de soporte
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };
    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el ayuntamiento?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos del ayuntamiento?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                    No se podrá volver atrás!
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Ayuntamiento guardado correctamente
                </ReactBSAlert>
            )
        });
    };

    warningFicheroGrande = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado supera el lìmite de tamaño"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El fichero seleccionado supera el límite de tamaño
                </ReactBSAlert>
            )
        });
    };


    warningFicheroPermitido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado no permitido"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Solo se permiten las siguientes extensiones: jpg, jpeg, png
                </ReactBSAlert>
            )
        });
    };

    successAlertLogo = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Logo actualizado correctamente
                </ReactBSAlert>
            )
        });
    };


    ///////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        var filtersData = { ...this.state.filters };
        filters[name] = value;

        if (event.target.name === 'cifSearch' && event.target.value.length == 0) {
            this.setState({
                NIFError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'cifSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonDisabled: false,
                })
            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonDisabled: true,
                })
            }
        }

        if (event.target.name === 'municipio') {

            this.setState({
                buscarButtonDisabled: false,
            })
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null
        }

        if (event.target.name === 'tipoEstadoSearch') {

            this.setState({
                buscarButtonDisabled: false,
            })
        }

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldAyuntamiento = (event) => {
        event.persist()

        var formData = { ...this.state.formAyuntamiento };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        const nameFilters = ['port','host','usermail','secure','pass','nombreContacto', 'apellido1Contacto', 'apellido2Contacto', 'area', 'cargo', 'cif', 'tipoVia', 'direccion', 'numFinca', 'portal', 'escalera', 'piso', 'puerta', 'codigoPostal', 'telefonoFijo', 'telefonoMovil', 'email', 'createdAt', 'id', 'uuidId', 'esActiva', 'esAprobada', 'esEnEspera', 'esFinalizada', 'esFirmada', 'esPresentada', 'expediente', 'fechaActivacion', 'fechaAprobacion', 'fechaFirma', 'fechaFinalizacion', 'fechaPresentacion'];

        const optionsFilters = ['secure', 'esActiva', 'esAprobada', 'esEnEspera', 'esFinalizada', 'esFirmada', 'esPresentada'];

        if (this.state.ayuntamientoSeleccionado) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ updateDataAyuntamiento: true });
                this.setState({
                    saveAyuntamientoButtonDisabled: true,
                    updateAyuntamientoButtonDisabled: false
                })
            }
            if (event.target.name == 'nombreContacto' && event.target.value.length > 0) {

                this.setState({ updateDataAyuntamiento: true });
                this.setState({
                    saveAyuntamientoButtonDisabled: true,
                    updateAyuntamientoButtonDisabled: false
                })
            }
            if (event.target.name == 'apellido1Contacto' && event.target.value.length > 0) {

                this.setState({ updateDataAyuntamiento: true });
                this.setState({
                    saveAyuntamientoButtonDisabled: true,
                    updateAyuntamientoButtonDisabled: false
                })
            }

            if (event.target.name === 'cif' && event.target.value.length == 0) {
                this.setState({ NIFClienteError: true })
            } else if (event.target.name === 'cif' && event.target.value.length > 0) {
                const result = validateSpanishId(event.target.value)
                if (result === true) {
                    this.setState({
                        updateDataAyuntamiento: true,
                        NIFClienteError: false
                    });
                    this.setState({
                        saveAyuntamientoButtonDisabled: true,
                        updateAyuntamientoButtonDisabled: false
                    })
                } else {
                    this.setState({
                        NIFClienteError: true,
                        updateDataAyuntamiento: false,
                        updateAyuntamientoButtonDisabled: true
                    })
                }
            }

            if (event.target.name === 'email') {
                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                const result = pattern.test(event.target.value);
                if (result === true) {
                    this.setState({
                        emailError: false,
                    })
                } else {
                    this.setState({
                        emailError: true
                    })
                }
            }

            if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
                value = null
            }

            if (value === "true" || value == "false") {
                value = JSON.parse(value);
            };

        }

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}clearForm
        this.setState({ formAyuntamiento: formData });

    };


    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        const filters = {};
        this.setState({
            filters: {
                municipioSearch: '',
                NIFSearch: '',
                idSearch: ''
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            ayuntamientoSeleccionado: false,
            ayuntamientoDetailTotal: [],
            formAyuntamiento: {
                municipio: "",
                codigoINE: "",
                nombreContacto: "",
                apellido1Contacto: "",
                apellido2Contacto: "",
                area: "",
                cargo: "",
                cif: "",
                tipoVia: "",
                direccion: "",
                numFinca: "",
                portal: "",
                escalera: "",
                piso: "",
                puerta: "",
                codigoPostal: "",
                provincia: "",
                comunidadAutonoma: "",
                telefonoFijo: "",
                telefonoMovil: "",
                email: "",
                createdAt: "",
                id: "",
                uuidId: "",
                esActiva: false,
                esAprobada: false,
                esEnEspera: false,
                esFinalizada: false,
                esFirmada: false,
                esPresentada: false,
                expediente: "",
                fechaActivacion: null,
                fechaAprobacion: null,
                fechaFirma: null,
                fechaFinalizacion: null,
                fechaPresentacion: null,
                idUser: null,
                idEmpresa: null,
                host: null,
                port: null,
                secure: null,
                usermail: null,
                pass: null

            },
            toggledClearRows: !this.state.toggledClearRows,
            saveAyuntamientoButtonDisabled: true,
            updateAyuntamientoButtonDisabled: true,
            createdAt: '',
            fechaActivacion: null,
            fechaAprobacion: null,
            fechaFirma: null,
            fechaFinalizacion: null,
            fechaPresentacion: null
        });

        this.formRef.current.reset();

    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarAyuntamiento = async () => {

        this.setState({
            isLoading: true,
        })

        const uuidUser = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")

        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }

        var municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""

        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        var tasasResumen: any = []
        var iduser = datosUser.userId ? datosUser.userId : ""
        var userGroupId =   datosUser.user_group_id ? datosUser.user_group_id : ""

        if (userGroupId == 9999){
            iduser = ""
        }

        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""

        var dataFilters = {
            'codigoINE': codigoINE,
            'cif': "",
            'idUser': "",
            'idEmpresa': ""
        }

        const valores = JSON.stringify(dataFilters)

        const headers = {
            
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/gestion/ayuntamientolist";


        await axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {

                if (result.data.rows.length > 0) {
                    let data = result.data.rows

                    var existeAyuntamiento = ''
                    let existeFechaAlta = data[0].createdAt
                          
                    existeAyuntamiento = data[0].id
        

                    if (existeAyuntamiento) {

                        this.setState({ id: data[0].id });
                        this.setState({
                            ayuntamientoDetailTotal: result.data.rows,
                            ayuntamientoDetail: data[0],
                            formAyuntamiento: {
                                municipio: data[0].municipio,
                                codigoINE: data[0].codigoINE,
                                nombreContacto: data[0].nombreContacto?.toUpperCase() || '',
                                apellido1Contacto: data[0].apellido1Contacto?.toUpperCase() || '',
                                apellido2Contacto: data[0].apellido2Contacto?.toUpperCase() || '',
                                area: data[0].area?.toUpperCase() || '',
                                cargo: data[0].cargo?.toUpperCase() || '',
                                cif: data[0].cif,
                                tipoVia: data[0].tipoVia,
                                direccion: data[0].direccion?.toUpperCase() || '',
                                numFinca: data[0].numFinca,
                                portal: data[0].portal,
                                escalera: data[0].escalera,
                                piso: data[0].piso,
                                puerta: data[0].puerta,
                                codigoPostal: data[0].codigoPostal,
                                provincia: data[0].provincia,
                                comunidadAutonoma: data[0].comunidadAutonoma,
                                telefonoFijo: data[0].telefonoFijo,
                                telefonoMovil: data[0].telefonoMovil,
                                email: data[0].email,
                                createdAt: data[0].createdAt,
                                id: data[0].id,
                                uuidId: data[0].uuidId,
                                esActiva: data[0].esActiva,
                                esAprobada: data[0].esAprobada,
                                esEnEspera: data[0].esEnEspera,
                                esFinalizada: data[0].esFinalizada,
                                esFirmada: data[0].esFirmada,
                                esPresentada: data[0].esPresentada,
                                expediente: data[0].expediente,
                                fechaActivacion: data[0].fechaActivacion,
                                fechaAprobacion: data[0].fechaAprobacion,
                                fechaFirma: data[0].fechaFirma,
                                fechaFinalizacion: data[0].fechaFinalizacion,
                                fechaPresentacion: data[0].fechaPresentacion,
                                idUser: data[0].idUser,
                                idEmpresa: data[0].idEmpresa,
                                host: data[0].host,
                                port: data[0].port,
                                secure: data[0].secure,
                                usermail: data[0].usermail,
                                pass: data[0].pass,
                                logoAyuntamiento: data[0].logoAyuntamiento,
                                logoPropuesta: data[0].logoPropuesta
                            },
                            ayuntamientoSeleccionado: true,
                            inputDisabled: false,
                            fechaActivacion: data[0].fechaActivacion,
                            fechaAprobacion: data[0].fechaAprobacion,
                            fechaFirma: data[0].fechaFirma,
                            fechaFinalizacion: data[0].fechaFinalizacion,
                            fechaPresentacion: data[0].fechaPresentacion,
                            createdAt: data[0].createdAt,
                            updateAyuntamientoButtonDisabled: true
                        });

                    if (existeFechaAlta) {
                        this.setState({
                            inputDisabled: false,
                            updateAyuntamientoButtonDisabled: true
                        })
                    };

                    if(data[0].logoAyuntamiento){
                        urlContentToDataUri2(data[0].logoAyuntamiento).then( logo3 => this.setState({logoAyuntamiento: logo3}) ) ;
                        }
    
                        if(data[0].logoPropuesta){
                          urlContentToDataUri2(data[0].logoPropuesta).then( logo3 => this.setState({logoPropuesta: logo3}) ) ;
                        }
        
                    } else {

                        console.log('entra en no existe ayuntamiento')
                    }
                           

                } else {
                    this.warningAlertNIF()
                };



                //this.clearForm();
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                  });
              });
              
    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    updateHandler(props) {

        if (this.state.updateDataAyuntamiento && (this.state.formAyuntamiento.nombreContacto && this.state.formAyuntamiento.cif)) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }
    };


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {
        const uuidUser = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")

        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }

        var municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        var tasasResumen: any = []
        var iduser = datosUser.id ? datosUser.id : ""
        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""

        //const fechaActivacion = this.state.fechaActivacion;
        //const companyId = this.state.companyIdUser.toString();
        //const userId = this.state.userId.toString();

        if (this.state.updateDataAyuntamiento == true) {

            var createdAt = this.state.formAyuntamiento.createdAt;
            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var fechaActivacion = this.state.formAyuntamiento.fechaActivacion;
            if (!fechaActivacion) {

                fechaActivacion = ''
            };

            var fechaAprobacion = this.state.formAyuntamiento.fechaAprobacion;
            if (!fechaAprobacion) {
                fechaAprobacion = ''
            };

            var fechaFirma = this.state.formAyuntamiento.fechaFirma;
            if (!fechaFirma) {
                fechaFirma = ''
            };

            var fechaFinalizacion = this.state.formAyuntamiento.fechaFinalizacion;
            if (!fechaFinalizacion) {
                fechaFinalizacion = ''
            };

            var fechaPresentacion = this.state.formAyuntamiento.fechaPresentacion;
            if (!fechaPresentacion) {
                fechaPresentacion = ''
            };

            var ayuntamientoDataUpdate = {}

            ayuntamientoDataUpdate = {
                'municipio': this.state.formAyuntamiento.municipio,
                'codigoINE': this.state.formAyuntamiento.codigoINE,
                'nombreContacto': this.state.formAyuntamiento.nombreContacto?.toUpperCase() || '',
                'apellido1Contacto': this.state.formAyuntamiento.apellido1Contacto?.toUpperCase() || '',
                'apellido2Contacto': this.state.formAyuntamiento.apellido2Contacto?.toUpperCase() || '',
                'area': this.state.formAyuntamiento.area?.toUpperCase() || '',
                'cargo': this.state.formAyuntamiento.cargo?.toUpperCase() || '',
                'cif': this.state.formAyuntamiento.cif,
                'tipoVia': this.state.formAyuntamiento.tipoVia?.toUpperCase() || '',
                'direccion': this.state.formAyuntamiento.direccion?.toUpperCase() || '',
                'numFinca': this.state.formAyuntamiento.numFinca,
                'portal': this.state.formAyuntamiento.portal,
                'escalera': this.state.formAyuntamiento.escalera,
                'piso': this.state.formAyuntamiento.piso,
                'puerta': this.state.formAyuntamiento.puerta,
                'codigoPostal': this.state.formAyuntamiento.codigoPostal,
                'provincia': this.state.formAyuntamiento.provincia,
                'comunidadAutonoma': this.state.formAyuntamiento.comunidadAutonoma,
                'telefonoFijo': this.state.formAyuntamiento.telefonoFijo,
                'telefonoMovil': this.state.formAyuntamiento.telefonoMovil,
                'email': this.state.formAyuntamiento.email,
                'createdAt': createdAt,
                'id': this.state.formAyuntamiento.id,
                'uuidId': this.state.formAyuntamiento.uuidId,
                'esActiva': this.state.formAyuntamiento.esActiva,
                'esAprobada': this.state.formAyuntamiento.esAprobada,
                'esEnEspera': this.state.formAyuntamiento.esEnEspera,
                'esFinalizada': this.state.formAyuntamiento.esFinalizada,
                'esFirmada': this.state.formAyuntamiento.esFirmada,
                'esPresentada': this.state.formAyuntamiento.esPresentada,
                'expediente': this.state.formAyuntamiento.expediente,
                'fechaActivacion': fechaActivacion,
                'fechaAprobacion': fechaAprobacion,
                'fechaFirma': fechaFirma,
                'fechaFinalizacion': fechaFinalizacion,
                'fechaPresentacion': fechaPresentacion,
                'idUser': this.state.formAyuntamiento.idUser,
                'idEmpresa': this.state.formAyuntamiento.idEmpresa,
                'host': this.state.formAyuntamiento.host ? this.state.formAyuntamiento.host : null,
                'port': this.state.formAyuntamiento.port ? this.state.formAyuntamiento.port : null,
                'secure': this.state.formAyuntamiento.secure ? this.state.formAyuntamiento.secure : false,
                'usermail': this.state.formAyuntamiento.usermail ? this.state.formAyuntamiento.usermail : null,
                'pass': this.state.formAyuntamiento.pass ? this.state.formAyuntamiento.pass : null,
                'logoAyuntamiento':this.state.formAyuntamiento.logoAyuntamiento ? this.state.formAyuntamiento.logoAyuntamiento : "",
                'logoPropuesta': this.state.formAyuntamiento.logoPropuesta ? this.state.formAyuntamiento.logoPropuesta : ""
            };

            const headers = {
                
                'Content-Type': 'application/json',
            };
            let direccionFile = "https://api.nextniro.com/api/v1/gestion/ayuntamientoupdate";


            axios.post(direccionFile, ayuntamientoDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        ayuntamientoSeleccionado: false,
                        toggledClearRows: !this.state.toggledClearRows,
                        saveAyuntamientoButtonDisabled: true,
                        updateAyuntamientoButtonDisabled: true,
                    });

                    //this.clearForm();
                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                      });
                  });

            this.buscarAyuntamiento();

        }

    };

    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formAyuntamiento };

        const name = id;
        var valueDay = formattedValue

        if (name == 'ayuntamientoDesde') {
            this.setState({ ayuntamientoDesde: value });
        }

        if (name == 'fechaActivacion') {
            this.setState({ fechaActivacion: value });
        }

        if (name == 'fechaAprobacion') {
            this.setState({ fechaAprobacion: value });
        }

        if (name == 'fechaFirma') {
            this.setState({ fechaFirma: value });
        }

        if (name == 'fechaFinalizacion') {
            this.setState({ fechaFinalizacion: value });
        }

        if (name == 'fechaPresentacion') {
            this.setState({ fechaPresentacion: value });
        }

        formData[name] = value
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formData });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    validateFile(file) {
        const extension = file.name.split(".").pop() || "";
        const fileSizeInBytes = file.size;
        const fileSizeInKB = fileSizeInBytes / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;
        if (!this.validFileExtensions.includes(extension)) {
            const extensionsAllowed = this.validFileExtensions.join(", ");
            this.warningFicheroPermitido()
            return false;
        }
        if (fileSizeInMB > this.maxFileSize) {
            this.warningFicheroGrande()
            return false;
        }
        return true;
    };

   refresh = () => window.location.reload(false)


    // On App Logo Change

    onAppLogoChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        if (this.validateFile(file)) {

            var formData = new FormData();

            //formData.append('file', file)

            this.setState({
                isLoading: true,
            });

            const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
            
            const user: any = localStorage.getItem("user");

            var datosUser: any = []

            if (user) {
                datosUser = JSON.parse(user);
            }

            var municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
            const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
            const id = datosUser.id ? datosUser.id : ""
    
            const url = "documentosBase"
    
            formData.append('municipio', municipio);
            formData.append('codigoIne', codigoINE)
            formData.append('usuario', userID);
            formData.append('url', url);
            formData.append('id', id);
            formData.append('file', file)

            const headers = {

                'Content-Type': 'multipart/form-data'
            };
            
            let direccionFile = "https://api.nextniro.com/api/v1/files/upload/logo/ayuntamiento";

            axios.post(direccionFile, formData, { headers }
            ).then(result => {
                let direccionImagen = result.data.resultado

                var formDataAyuntamiento = { ...this.state.formAyuntamiento };
                formDataAyuntamiento['logoAyuntamiento'] = direccionImagen
                this.setState({
                    formAyuntamiento: formDataAyuntamiento
                })
                this.setState({
                    isLoading: false
                })
                var logo1 = ""

                    urlContentToDataUri2(direccionImagen).then( logo1 => this.setState({logoAyuntamiento: logo1}) ) ;
                this.successAlertLogo()
            }).catch((error) => {
                console.log('Failure: ', error)
                //this.errorDB()
                this.setState({
                    isLoading: false,
                });
            });
            //reader.readAsDataURL(file);
        }
    };

    onAppLogoChangePropuesta = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        if (this.validateFile(file)) {

            var formData = new FormData();

            this.setState({
                isLoading: true,
            });

            const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
            
            const user: any = localStorage.getItem("user");

            var datosUser: any = []

            if (user) {
                datosUser = JSON.parse(user);
            }

            var municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
            const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
            const id = datosUser.id ? datosUser.id : ""
    
            const url = "documentosBase"
    
            formData.append('municipio', municipio);
            formData.append('codigoIne', codigoINE)
            formData.append('usuario', userID);
            formData.append('url', url);
            formData.append('id', id);
            formData.append('file', file)

            const headers = {
                'Content-Type': 'multipart/form-data'
            };

            let direccionFile = "https://api.nextniro.com/api/v1/files/upload/logo/documento";

            axios.post(direccionFile, formData, { headers }
            ).then(result => {
                let direccionImagen = result.data.resultado
 
                var formDataAyuntamiento = { ...this.state.formAyuntamiento };
                formDataAyuntamiento['logoPropuesta'] = direccionImagen
                this.setState({
                    formAyuntamiento: formDataAyuntamiento
                })
                this.setState({
                    isLoading: false,
                })
                var logo1 = ""

                    urlContentToDataUri2(direccionImagen).then( logo1 => this.setState({logoPropuesta: logo1}) ) ;

                this.successAlertLogo()
            }).catch((error) => {
                console.log('Failure: ', error)
                //this.errorDB()
                this.setState({
                    isLoading: false,
                });
            });

            // reader.readAsDataURL(file);
        }
    };

    eventFire = (el, etype) => {
        if (el.fireEvent) {
            el.fireEvent("on" + etype);
        } else {
            var evObj = document.createEvent("Events");
            evObj.initEvent(etype, true, false);
            el.dispatchEvent(evObj);
        }
    };

    onAppLogoButtonClick = () => {
        const input = document.getElementById("label-upload-button");
        this.eventFire(input, "click");
    };

    onAppLogoPropuestaClick= () => {
        const input = document.getElementById("label-upload-propuesta-button");
        this.eventFire(input, "click");
    };
   
    ///////////////////////////////////////////////////////////////////////////////////////

    render() {

        const formAyuntamiento: any = this.state.formAyuntamiento;
        const createdAt = this.state.createdAt
        const fechaActivacion = this.state.fechaActivacion
        const fechaAprobacion = this.state.fechaAprobacion
        const fechaFirma = this.state.fechaFirma
        const fechaFinalizacion = this.state.fechaFinalizacion
        const fechaPresentacion = this.state.fechaPresentacion

        const ayuntamientoDesde = this.state.ayuntamientoDesde
        const nombreAyuntamiento= this.state.nombreAyuntamiento

        //var previewImage = this.state.formAyuntamiento.logoAyuntamiento ? this.state.formAyuntamiento.logoAyuntamiento : ""
        //var previewImagePropuesta = this.state.formAyuntamiento.logoPropuesta ? this.state.formAyuntamiento.logoPropuesta : ""

        var previewImage = this.state.logoAyuntamiento ? this.state.logoAyuntamiento : ""
     
        var previewImagePropuesta = this.state.logoPropuesta ? this.state.logoPropuesta : ""
 
        if (this.state.userGroupId < 5000 && this.state.userGroupId > 2000) {
            return (
                <>
                    <Header title={'Información ayuntamiento de '+nombreAyuntamiento} />
                    <Container className=" mt--7" fluid>

                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={this.props.history.goBack}
                                                >
                                                    Atrás
                                                </button>
                                            </Col>

                                            <Col className="col-auto d-flex">
                                                <Card className="p-3 bg-light">
                                                    <FormGroup>

                                                        <Button disabled={this.state.updateAyuntamientoButtonDisabled} id="updateAytoProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                            onClick={() => { this.updateHandler(this.state.updateDataAyuntamiento) }}
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-person-lines-fill" style={{ fontSize: 30 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Actualizar</span>
                                                            <UncontrolledTooltip delay={0} placement="top" target="updateAytoProptooltip">
                                                                MODIFICAR DATOS AYUNTAMIENTO
                                                            </UncontrolledTooltip>
                                                        </Button>

                                                    </FormGroup>

                                                </Card>
                                            </Col>

                                        </Row>
                                        <div>
                                            <CardTitle>DATOS GENERALES AYUNTAMIENTO</CardTitle>
                                        </div>

                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="municipio">
                                                        MUNICIPIO
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="municipio"
                                                        placeholder=""
                                                        name="municipio"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.municipio}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="codigoINE">
                                                        INE
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="INE"
                                                        placeholder=""
                                                        name="codigoINE"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.codigoINE}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>



                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="cif">
                                                        CIF AYTO
                                                    </Label>
                                                    <Input
                                                        id="cif"
                                                        placeholder=""
                                                        name="cif"
                                                        maxLength={16}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.cif}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                    {this.state.NIFClienteError ? <FormText color="red">Comprobar CIF</FormText> : ''}
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="tipoVia">
                                                        Tipo Via
                                                    </Label>
                                                    <Input
                                                        id="tipoViar"
                                                        placeholder=""
                                                        name="tipoVia"
                                                        maxLength={12}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.tipoVia}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="direccion">
                                                        Dirección
                                                    </Label>
                                                    <Input
                                                        id="direccion"
                                                        placeholder=""
                                                        name="direccion"
                                                        maxLength={60}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.direccion}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="numFinca">
                                                        Nº Finca
                                                    </Label>
                                                    <Input
                                                        id="numFinca"
                                                        placeholder=""
                                                        name="numFinca"
                                                        maxLength={10}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.numFinca}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>



                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="portal">
                                                        Portal
                                                    </Label>
                                                    <Input
                                                        id="portal"
                                                        placeholder=""
                                                        name="portal"
                                                        maxLength={10}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.portal}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="escalera">
                                                        ESC.
                                                    </Label>
                                                    <Input
                                                        id="escalera"
                                                        placeholder=""
                                                        name="escalera"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.escalera}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="piso">
                                                        Piso
                                                    </Label>
                                                    <Input
                                                        id="piso"
                                                        placeholder=""
                                                        name="piso"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.piso}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="puerta">
                                                        Puerta
                                                    </Label>
                                                    <Input
                                                        id="puerta"
                                                        placeholder=""
                                                        name="puerta"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.puerta}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="codigoPostal">
                                                        C.P.
                                                    </Label>
                                                    <Input
                                                        id="codigoPostal"
                                                        placeholder=""
                                                        name="codigoPostal"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.codigoPostal}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                    {this.state.CodigoPostalTitularError ? <FormText color="red">Por favor compruebe el código postal del titular</FormText> : ''}
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="provincia">
                                                        Provincia
                                                    </Label>
                                                    <Input
                                                        id="provincia"
                                                        placeholder=""
                                                        name="provincia"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.provincia}
                                                        type="text"
                                                        disabled={true}

                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="comunidadAutonoma">
                                                        C. Autónoma
                                                    </Label>
                                                    <Input
                                                        id="comunidadAutonoma"
                                                        placeholder=""
                                                        name="comunidadAutonoma"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.comunidadAutonoma}
                                                        type="text"
                                                        disabled={true}

                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>



                                        <Row>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="createdAt">
                                                        Fecha Alta Registro
                                                    </Label>

                                                    <DatePicker id="createdAt"
                                                        value={createdAt}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "createdAt")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>DATOS DE CONTACTO</CardTitle>
                                        </div>
                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreContacto">
                                                        Nombre Contacto
                                                    </Label>
                                                    <Input
                                                        id="nombreContacto"
                                                        placeholder=""
                                                        name="nombreContacto"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.nombreContacto}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido1Contacto">
                                                        Primer Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido1Contacto"
                                                        placeholder=""
                                                        name="apellido1Contacto"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.apellido1Contacto}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellido2Contacto">
                                                        Segundo Apellido
                                                    </Label>
                                                    <Input
                                                        id="apellido2Contacto"
                                                        placeholder=""
                                                        name="apellido2Contacto"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.apellido2Contacto}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="area">
                                                        Area
                                                    </Label>
                                                    <Input
                                                        id="area"
                                                        placeholder=""
                                                        name="area"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.area}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="cargo">
                                                        Cargo
                                                    </Label>
                                                    <Input
                                                        id="cargo"
                                                        placeholder=""
                                                        name="cargo"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.cargo}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="telefonoFijo">
                                                        Teléfono Contacto
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoFijo"
                                                        placeholder=""
                                                        name="telefonoFijo"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.telefonoFijo}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="telefonoMovil">
                                                        Teléfono Móvil
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoMovil"
                                                        placeholder=""
                                                        name="telefonoMovil"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.telefonoMovil}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="email">
                                                        Correo Contacto
                                                    </Label>
                                                    <Input
                                                        id="email"
                                                        placeholder=""
                                                        name="email"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.email}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>DATOS SERVIDOR CORREO</CardTitle>
                                        </div>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="host">
                                                        Servidor Correo
                                                    </Label>
                                                    <Input
                                                        id="host"
                                                        placeholder=""
                                                        name="host"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.host}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="port">
                                                        Puerto
                                                    </Label>
                                                    <Input
                                                        id="port"
                                                        placeholder=""
                                                        name="port"
                                                        maxLength={5}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.port}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="secure">Seguro</Label>
                                                    <Input type="select" name="secure" id="secure"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.secure}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="usermail">
                                                        Usuario Correo
                                                    </Label>
                                                    <Input
                                                        id="usermail"
                                                        placeholder=""
                                                        name="usermail"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.usermail}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="pass">
                                                        Clave
                                                    </Label>
                                                    <Input
                                                        id="pass"
                                                        placeholder=""
                                                        name="pass"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.pass}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>OTROS DATOS</CardTitle>
                                        </div>
                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="expediente">
                                                        Expediente
                                                    </Label>
                                                    <Input
                                                        id="expediente"
                                                        placeholder=""
                                                        name="expediente"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.expediente}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esPresentada">Presentada</Label>
                                                    <Input type="select" name="esPresentada" id="esPresentada"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esPresentada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esEnEspera">En Espera</Label>
                                                    <Input type="select" name="esEnEspera" id="esEnEspera"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esEnEspera}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esActiva">Activo</Label>
                                                    <Input type="select" name="esActiva" id="esActiva"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esActiva}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esAprobada">Aprobada</Label>
                                                    <Input type="select" name="esAprobada" id="esAprobada"
                                                        disabled={this.state.inputDisabled}

                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esAprobada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esFinalizada">Finalizada</Label>
                                                    <Input type="select" name="esFinalizada" id="esFinalizada"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esFinalizada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="esFirmada">Firmada</Label>
                                                    <Input type="select" name="esFirmada" id="esFirmada"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldAyuntamiento}
                                                        value={formAyuntamiento.esFirmada}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaPresentacion">
                                                        Fecha Presentacion
                                                    </Label>
                                                    <DatePicker id="fechaPresentacion"
                                                        value={fechaPresentacion}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "fechaPresentacion")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaFirma">
                                                        Fecha Firma
                                                    </Label>
                                                    <DatePicker id="fechaFirma"
                                                        value={fechaFirma}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "fechaFirma")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                </div>
                    </Row>
                   
                    <Row>
                                <Col sm="4">
                        <Card className="mt-3 mb-3">

                                <CardBody>
                                    <div>
                                        <CardTitle>LOGO AYUNTAMIENTO</CardTitle>
                                    </div>

                                  

                                        <Col>
                                           
                                                {previewImage && (
                                                    <div>
                                                        <img className="img-fluid" src={this.state.logoAyuntamiento} alt="" />
                                                    </div>
                                                )}
                                                <FormGroup>
                                                    <label htmlFor="upload-button" id="label-upload-button">
                                                        <span className="btn btn-sm btn-primary mt-3">
                                                            Subir logo
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="upload-button"
                                                        style={{ display: "none" }}
                                                        onChange={this.onAppLogoChange}
                                                        accept="image/*"
                                                    />
                                                </FormGroup>

                                        </Col>
                                
                            </CardBody>
                            </Card>
                            </Col>
                            <Col sm="4">
                        <Card className="mt-3 mb-3">
                            <CardBody>
                                    <div>
                                        <CardTitle>LOGO DOCUMENTOS</CardTitle>
                                    </div>

                                    <Row>

                                        <Col>
                                           
                                                {previewImagePropuesta && (
                                                    <div>
                                                        <img className="img-fluid" src={this.state.logoPropuesta}  alt="" />
                                                    </div>
                                                )}
                                                <FormGroup>
                                                    <label htmlFor="upload-button-propuesta" id="label-upload-propuesta-button">
                                                        <span className="btn btn-sm btn-primary mt-3">
                                                            Subir logo
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="upload-button-propuesta"
                                                        style={{ display: "none" }}
                                                        onChange={this.onAppLogoChangePropuesta}
                                                        accept="image/*"
                                                    />
                                                </FormGroup>
                                            
                                        </Col>
                                </Row>
                            </CardBody>
                       
                          
                        </Card>
                        </Col>
                        </Row>
                                {this.state.alert}
                            
                       
                    </Container>
                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"} />
                </>
            );
        }
    }
}

export default withRouter(Ayuntamiento);
