import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import Checkbox from '@material-ui/core/Checkbox';
import { CSVLink } from "react-csv";
// core components
import Header from "../../components/Headers/Header";
import { withRouter } from "react-router-dom"
import moment from "moment";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";

import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';



var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const sortIcon = <ArrowDownward />;
const estado = (s) => {
    if (s !== true) return <i className="fas fa-exclamation-circle fa-2x" style={{ color: "#ff1900" }} ></i>
    return <i className="far fa-check-circle fa-2x" style={{ color: "#7ee06e" }} ></i>
}

const columnsDatosEmpresas = [
    { selector: row => row.nif, name: 'NIF', sortable: true, width: '150px', format: (row => capitalize(row.nif)) },
    { selector: row => row.empresa, name: 'EMPRESA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.empresa)) },
    { selector: row => row.tipo, name: 'TIPO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipo)) },
    { selector: row => row.tipoServicio, name: 'SERVICIO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipoServicio)) },
    { selector: row => row.numOrden, name: 'ORDEN', sortable: true, width: '100px', center: true, format: (row => capitalize(row.numOrden)) },
    { selector: row => row.correoContacto, name: 'CORREO', sortable: true, width: '150px', center: true, format: (row => capitalize(row.correoContacto)) },
    { selector: row => row.nombreContacto, name: 'CONTACTO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.nombreContacto)) },
    { selector: row => row.telefonoContacto, name: 'TELEFONO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.telefonoContacto)) }
];

//backgroundImage:
//"url(" + require("../../assets/img/theme/header-bg.jpg") + ")",

const columnsDatosEmpresasSeleccionadas = [
    { selector: row => row.nif, name: 'NIF', sortable: true, width: '150px', format: (row => capitalize(row.nif)) },
    { selector: row => row.empresa, name: 'EMPRESA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.empresa)) },
    { selector: row => row.tipo, name: 'TIPO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipo)) },
    { selector: row => row.anualidades, name: 'ANUALIDADES RECLAMADAS', sortable: true, width: '200px', center: true, format: (row => String(row.anualidades)) }
];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);


class gestionComunicacionEmpresas extends Reflux.Component<any, any> {
    public formRef: any = null;
    lastFilters = {};
    state = {
        filters: {
            empresasSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            municipio: "",
            codigoINE: "",
            email: "",
            tipoEmpresa:"DIS",
            tipoEmpresaServicio:"ELEC"
        },
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        empresaDetailTotal: [],
        empresaSeleccionado: false,
        passwordModificado: false,
        idUser: '',
        municipios: [{ id: "", valor: 0, email: "" }],
        empresasSeleccionadas: [],
        cantidadEmpresasSeleccionadas: 0,
        empresasGeneracionDocumentos: [],
        numeroSin1Notificacion: 0,
        numeroSin1EnvioAyto: 0,
        numeroSin1EnvioEmpresa: 0,
        numeroSin2Notificacion: 0,
        numeroSin2EnvioAyto: 0,
        numeroSin2EnvioEmpresa: 0,
        numero1EnvioEmpresa: 0,
        numero2EnvioEmpresa: 0,
        selectEmpresasButtonDisabled: false,
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataEmpresa: false,
        updateDataEmpresa: false,
        updateEmpresaButtonDisabled: true,
        saveEmpreButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        usuariosDesde: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        IBANError: false,
        alert: null,
        anualidad: [],
        tipoAño: ""

    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);
    }


    ///////////////////////////////////////////////////////////////

    componentDidMount() {
        
        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))
        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;
        const userGroupId = usuario.user_group_id
        const userId = usuario.id

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };
        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId
        });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        //this.props.client.cache.reset();
        //this.props.client.resetStore();
        this.props.history.push(`/auth/login`);
    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos. El campo MUNICIPIO es obligatorio
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltrosAnualidad = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos. El campo Anualidad es obligatorio y debe estar seleccionada una empresa como mínimo
                </ReactBSAlert>
            )
        });
    };


    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el usuario?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea generar los documentos de la primera notificación de tasas?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onGeneracionDoc1()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Generación de la primera notificación realizada correctamente
                </ReactBSAlert>
            )
        });
    };

    successAlertSinFicheros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No se han generado notificaciones"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Las empresas seleccionadas no tienen anualidades pendientes para la generación de las notificaciones.
                </ReactBSAlert>
            )
        });
    };



    //////////////////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;

        var municipio = ""
        var email = ""

        if (event.target.name === 'NIFSearch' && event.target.value.length == 0) {

            this.setState({

                NIFError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'NIFSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonDisabled: false,
                })

            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonDisabled: true,
                })
            }
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null
            this.setState({
                buscarButtonDisabled: true
            })

        } else if (event.target.name === 'tipoEstadoSearch' && event.target.value !== 'Selecciona...') {
            this.setState({
                buscarButtonDisabled: false
            })

        };

        if (event.target.name === 'municipio') {
            let indexMunicipio = this.state.municipios.findIndex(object =>
                object.id === value
            ); // 👉️ 1

            if (indexMunicipio !== -1) {
                municipio = this.state.municipios[indexMunicipio].valor
                email = this.state.municipios[indexMunicipio].email
            };
            filtersData['codigoINE'] = value
            filtersData['municipio'] = municipio
            filtersData['email'] = email

            this.setState({
                buscarButtonDisabled: false,
            })

            this.setState({ filters: filtersData });
        } else {
            filtersData[name] = value;
            this.setState({
                buscarButtonDisabled: false,
            })
            this.setState({ filters: filtersData });
        };
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldUsuario = (event) => {
        event.persist()

        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        //<option value="ACT"> Año en curso </option>
        //<option value="CIC"> Últimos 4 años</option>


        var max = new Date().getFullYear() - 1
        var minShort = max
        var min = max - 3
        var año = []

        if (event.target.value == 'Selecciona...') {
            value = null
            this.setState({
                buscarButtonDisabled: true,
                anualidad: []
            })

        } else if (event.target.value !== 'Selecciona...') {

            if (event.target.value == "ACT") {

                for (var i = max; i >= minShort; i--) {
                    var añosString = i.toString()
                    año.push(añosString)
                }
                this.setState({
                    buscarButtonDisabled: false

                })
            } else if (event.target.value == "CIC") {

                for (var i = max; i >= min; i--) {
                    var añosString = i.toString()
                    año.push(añosString)
                }

                this.setState({
                    buscarButtonDisabled: false
                })
            }

        };




        this.setState({
            saveDataEmpresa: true
        })
        this.setState({
            anualidad: año,
            tipoAño: value
        });


    };


    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        this.setState({
            filters: {
                empresasSearch: "",
                NIFSearch: "",
                tipoEstadoSearch: "",
                municipio: "",
                codigoINE: "",
                email: "",
                tipoEmpresa:"DIS",
                tipoEmpresaServicio:"ELEC"
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            empresaSeleccionado: false,
            empresaDetailTotal: [],
            passwordModificado: false,
            toggledClearRows: !this.state.toggledClearRows,
            updateEmpresaButtonDisabled: true,
            saveEmpreButtonDisabled: true,
            saveDataEmpresa: false,
            anualidad: [],
            tipoAño: ""
        });

        this.formRef.current.reset();

    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarEmpresa = async () => {


            this.setState({
                isLoading: true,
            })

            const token = localStorage.getItem("token")
            const user: any = localStorage.getItem("user");

            var datosUser: any = []
            if (user) {
                datosUser = JSON.parse(user);
                //this.setState({ user: parsed });
            }

            var municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
      

            var dataFilters = {
                'empresa': this.state.filters.empresasSearch?.toUpperCase() || "",
                'nif': this.state.filters.NIFSearch?.toUpperCase() || "",
                'municipioIne': codigoINE,
                'tipo': this.state.filters.tipoEmpresa?.toUpperCase() || "",
                'tipoServicio': this.state.filters.tipoEmpresaServicio?.toUpperCase() || ""
            };

            const headers = {
                
                'Content-Type': 'application/json',
            };
            let direccionFile = "https://api.nextniro.com/api/v1/tasas/empresalistmunicipio";

            axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
                .then((result) => {
                    if (result.data.rows.length > 0) {
                        let data = result.data.rows
                        this.setState({ empresaDetailTotal: result.data.rows });
                    } else {
                        this.warningAlertNIF()
                    };
                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                      });
                  });


    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    updateHandler(props) {

        if (this.state.anualidad && this.state.empresasGeneracionDocumentos.length > 0) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltrosAnualidad()
        }
    };



    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {
        // eslint-disable-next-line no-console

        var empresasSeleccionadas: any = state.selectedRows
        var cantidadEmpresasSeleccionadas = state.selectedRows.length || 0


        var numeroComercializadora = state.selectedRows.reduce(function (n, empresas) {
            return n + (empresas.tipo == "COM");
        }, 0);

        var numeroDistribuidora = state.selectedRows.reduce(function (n, empresas) {
            return n + (empresas.tipo == "DIS");
        }, 0);

        this.setState({
            empresasSeleccionadas: empresasSeleccionadas,
            cantidadEmpresasSeleccionadas: cantidadEmpresasSeleccionadas,
            numeroComercializadora: numeroComercializadora,
            numeroDistribuidora: numeroDistribuidora

        })
    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////

    handleChangeSelected = state => {
        // eslint-disable-next-line no-console

        var empresasGeneracionDocumentos: any = state.selectedRows

        this.setState({
            empresasGeneracionDocumentos: empresasGeneracionDocumentos
        })
    };

    //////////////////////////////////////////////////////////////////////////////////////

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };

    //////////////////////////////////////////////////////////////////////////////////////

    approvedHandler() {
        this.onGeneracionDoc1()
    };

    ///////////////////////////////////////////////////////////////////////////////////////

    onGeneracionDoc1 = async () => {

        var empresasenviargeneracion: any = this.state.empresasGeneracionDocumentos

        //const idOferta = uuid();

        this.setState({
            isLoading: true,
        })

        const token = localStorage.getItem("token")
        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        };

        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""

        const ayuntamiento =  datosUser.ayuntamiento ? datosUser.ayuntamiento : ""

        var dataFilters = {
            'empresa': this.state.filters.empresasSearch?.toUpperCase() || "",
            'nif': this.state.filters.NIFSearch?.toUpperCase() || "",
            'tipoServicio': this.state.filters.tipoEstadoSearch.toUpperCase() || "",
            'municipioIne': codigoINE,
            'municipio': ayuntamiento,
            'listacomercializadoras': empresasenviargeneracion,
            'anualidad': this.state.anualidad,
            'tipoAño': this.state.tipoAño,
            'email': this.state.filters.email || ""
        };

        const headers = {
            
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/tasas/generacionprimeradoc";

        axios.post(direccionFile, dataFilters, { headers, responseType: 'blob' })
            .then((result) => {
                var fecha = new Date().toISOString()

                const blob = result.data
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = ayuntamiento + "_1notificaciones_" + fecha + ".zip";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);

                this.successAlert();

                this.setState({
                    inputDisabled: false,
                    empresaSeleccionado: false,
                    empresaDetailTotal: [],
                    saveDataEmpresa: false,
                    saveEmpreButtonDisabled: true,
                    updateEmpresaButtonDisabled: true,
                    passwordModificado: false,
                    loading: false,
                    toggledClearRows: !this.state.toggledClearRows,
                    empresasGeneracionDocumentos: [],
                    empresasSeleccionadas: [],
                    cantidadEmpresasSeleccionadas: 0,
                    numeroComercializadora: 0,
                    numeroDistribuidora: 0
                })
                this.buscarEmpresa()
            }).catch((error) => {
                if (error?.response) {
                   // http status code
                    const code = error.response.status
                    // response data
                    if (code == "404") {
                        this.successAlertSinFicheros();
                        this.setState({
                            inputDisabled: false,
                            empresaSeleccionado: false,
                            empresaDetailTotal: [],
                            saveDataEmpresa: false,
                            saveEmpreButtonDisabled: true,
                            updateEmpresaButtonDisabled: true,
                            passwordModificado: false,
                            loading: false,
                            loginError: true,
                            networkError: false,
                            sessionError: false,
                            userBlockedError: false,
                            toggledClearRows: !this.state.toggledClearRows,
                            empresasGeneracionDocumentos: [],
                            empresasSeleccionadas: [],
                            cantidadEmpresasSeleccionadas: 0,
                            numeroComercializadora: 0,
                            numeroDistribuidora: 0
                        })
                        this.buscarEmpresa()

                    }
                }
            });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    render() {

        const cantidadEmpresasSeleccionadas = this.state.cantidadEmpresasSeleccionadas || 0
        const numeroComercializadora = this.state.numeroComercializadora
        const numeroDistribuidora = this.state.numeroDistribuidora

        return (
            <>
                <Header title={'Inicio proceso reclamación'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    RAZON SOCIAL/NOMBRE
                                                </Label>
                                                <Input
                                                    id="empresasSearch"
                                                    placeholder="Ingresar Razón Social de la Empresa"
                                                    name="empresasSearch"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.empresasSearch}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NIFSearch">
                                                    NIF
                                                </Label>
                                                <Input
                                                    id="NIFSearch"
                                                    placeholder="Ingresar CIF empresa"
                                                    name="NIFSearch"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.NIFSearch}
                                                    type="text"

                                                />
                                                {this.state.NIFError ? <FormText color="red">Comprobar CIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoEmpresa">Tipo</Label>
                                                    <Input type="select" name="tipoEmpresa" id="tipoEmpresa"
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.tipoEmpresa}>
                                                        <option value="COM">COMERCIALIZADORA</option>
                                                        <option value="DIS">DISTRIBUIDORA</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                        <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoEmpresaServicio">Servicio</Label>
                                                    <Input type="select" name="tipoEmpresaServicio" id="tipoEmpresaServicio"
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.tipoEmpresaServicio}>
                                                        <option value="ELEC">ELECTRICIDAD</option>
                                                        <option value="GAS">GAS</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                  {/*      <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoEstadoProducto">ESTADO</Label>
                                                <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.tipoEstadoSearch}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="ELEC">ELECTRICIDAD</option>
                                                    <option value="GAS">GAS</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                            */}
                                    </Row>

                                    <div className="text-right">
                                        <Button onClick={this.clearForm}
                                            className="float-right ml-3 ">
                                            <i className={"ni ni-fat-remove"} /> Limpiar
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={this.buscarEmpresa}
                                        >
                                            <i className={"bi bi-search"} /> Buscar
                                        </Button>
                                    </div>
                                </Form>

                            </CardBody>
                        </Collapse>
                    </Card>

                    <Card className="shadow">
                        <DataTable
                            title="EMPRESAS DE SERVICIOS"
                            columns={columnsDatosEmpresas}
                            data={this.state.empresaDetailTotal}
                            selectableRows
                            selectableRowsComponent={Checkbox} // Pass the function only
                            //selectableRowsComponentProps={{ inkDisabled: true }}
                            //selectableRowsComponentProps={{ type: 'Checkbox' }}// optionally, pass Material Ui supported props down to our custom checkbox
                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                            noDataComponent={"Sin datos para mostrar"}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[20, 25, 30, 40]}
                            onSelectedRowsChange={this.handleChange}
                            clearSelectedRows={this.state.toggledClearRows}
                            highlightOnHover
                            striped
                            pointerOnHover
                            selectableRowsHighlight
                            customStyles={customStyles}
                            contextMessage={{ singular: 'empresa', plural: 'empresas', message: '' }}
                            subHeader
                            subHeaderComponent={

                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <CSVLink
                                        data={this.state.empresaDetailTotal}
                                        filename={"empresasRegistradas.csv"}
                                        className="btn btn-default btn-sm btn-circle"
                                        color="default"
                                        target="_blank"
                                        separator={";"}
                                    >

                                        <span className="btn-inner--icon">
                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                        </span>
                                        <span className="btn-inner--text">Fichero CSV</span>

                                    </CSVLink>
                                </div>
                            }
                            progressComponent={<Circular />}
                        />
                    </Card>



                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>

                                    </Row>
                                    <div>
                                        <CardTitle>RESUMEN</CardTitle>
                                    </div>

                                    <Row>


                                        <Col md="6" xl="3">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <div className="col">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="text-uppercase text-muted mb-0"
                                                            >
                                                                EMPRESAS
                                                            </CardTitle>
                                                            <span className="h2 font-weight-bold mb-0">
                                                                {cantidadEmpresasSeleccionadas}

                                                            </span>
                                                        </div>
                                                        <Col className="col-auto">
                                                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                                <i className="bi bi-info-square" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className="mt-3 mb-0 text-sm">
                                                        <span className="text-nowrap">Empresas seleccionadas</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col md="6" xl="3">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <div className="col">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="text-uppercase text-muted mb-0"
                                                            >
                                                                COMERCIALIZ.
                                                            </CardTitle>
                                                            <span className="h2 font-weight-bold mb-0">
                                                                {numeroComercializadora}

                                                            </span>
                                                        </div>
                                                        <Col className="col-auto">
                                                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                                                <i className="bi bi-briefcase" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className="mt-3 mb-0 text-sm">
                                                        <span className="text-truncate">Comercializadoras seleccionadas</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col md="6" xl="3">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <div className="col">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="text-uppercase text-muted mb-0"
                                                            >
                                                                Distribuidoras
                                                            </CardTitle>
                                                            <span className="h2 font-weight-bold mb-0">
                                                                {numeroDistribuidora}

                                                            </span>
                                                        </div>
                                                        <Col className="col-auto">
                                                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                                <i className="bi bi-briefcase" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className="mt-3 mb-0 text-sm">
                                                        <span className="text-truncate">Distribuidoras seleccionadas</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className="shadow">
                                <DataTable
                                    title="EMPRESAS A GESTIONAR INCIO TASAS"
                                    columns={columnsDatosEmpresasSeleccionadas}
                                    data={this.state.empresasSeleccionadas}
                                    selectableRows
                                    selectableRowsComponent={Checkbox} // Pass the function only
                                    //selectableRowsComponentProps={{ inkDisabled: true }}
                                    noDataComponent={"Sin datos para mostrar"}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[20, 25, 30, 40]}
                                    onSelectedRowsChange={this.handleChangeSelected}
                                    clearSelectedRows={this.state.toggledClearRows}
                                    highlightOnHover
                                    striped
                                    pointerOnHover
                                    selectableRowsHighlight
                                    customStyles={customStyles}
                                    contextMessage={{ singular: 'empresa', plural: 'empresas', message: '' }}
                                    subHeader
                                    subHeaderComponent={

                                        <div style={{ display: 'flex', alignItems: 'center' }}>


                                            <FormGroup className="mr-2">
                                                <Label className="form-control-label" for="tipo">Anualidad</Label>
                                                <Input type="select" name="tipo" id="tipoAño" className="mr-2"
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={this.state.tipoAño}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="ACT"> Año en curso </option>
                                                    <option value="CIC"> Últimos 4 años</option>
                                                </Input>
                                            </FormGroup>


                                            <Button disabled={this.state.selectEmpresasButtonDisabled} color="#fd7f6f" size="sm" className="btn btn-sm btn-circle"
                                                onClick={() => { this.updateHandler(this.state.empresasGeneracionDocumentos) }}

                                            >
                                                <i className={"bi bi-file-earmark-text"} style={{ fontSize: 25 }} />
                                                <span className="btn-inner--text">1ª NOTIF.</span>
                                            </Button>

                                            <CSVLink
                                                data={this.state.empresasSeleccionadas}
                                                filename={"empresasSeleccionadas.csv"}
                                                className="btn btn-default btn-sm btn-circle"
                                                color="default"
                                                target="_blank"
                                                separator={";"}
                                            >

                                                <span className="btn-inner--icon">
                                                    <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                </span>
                                                <span className="btn-inner--text">Fichero CSV</span>

                                            </CSVLink>

                                        </div>
                                    }
                                    progressComponent={<Circular />}
                                />
                            </Card>

                            {this.state.alert}

                        </div>
                    </Row>
                </Container>

            </>
        );
    }
};
export default withRouter(gestionComunicacionEmpresas);
