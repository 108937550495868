//import Password from './Password'
//import { Mailer } from 'nodemailer-react'
import React from "react";
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import Reflux from "reflux";
import { withApollo } from "react-apollo";
import { Card, Container, FormGroup, CardTitle, CardBody, Form, ListGroup, ListGroupItem, Input, Row, FormText, Col, Label, Button, UncontrolledTooltip } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { UserStore } from "../../store/UserStore";
import { removeToken } from "../../utils";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../assets/scss/documento.scss";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Header from "../../components/Headers/Header";
import ReactBSAlert from "react-bootstrap-sweetalert";
//const nodemailer = require("nodemailer");
//import { Mailer } from 'nodemailer-react'

//import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

const columnsDatosAnalisis = [
    {
        selector: row => row['tarifa'], name: 'TARIFA', sortable: false, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['cups'], name: 'CUPS', sortable: false, width: '100px', center: true },
    { selector: row => row['energiatarifa'], name: 'TOTAL ENERGIA', sortable: false, width: '130px', center: true },
    { selector: row => row['menor5mw'], name: 'CUPS < 5Mw', width: '130px', sortable: false, center: true },
    { selector: row => row['energiamenor5mw'], name: 'ENERGIA < 5Mw', width: '130px', sortable: false, center: true },
    { selector: row => row['menor10mw'], name: 'CUPS > 5Mw < 10Mw', width: '130px', sortable: false, center: true },
    { selector: row => row['energiamenor10mw'], name: 'ENERGIA> 5Mw < 10Mw', width: '130px', sortable: false, center: true },
    { selector: row => row['menor20mw'], name: 'CUPS > 10Mw < 20Mw', width: '130px', sortable: false, center: true },
    { selector: row => row['energiamenor20mw'], name: 'ENERGIA > 10Mw < 20Mw', width: '130px', sortable: false, center: true },
    { selector: row => row['menor9999MW'], name: 'CUPS > 20Mw', width: '130px', sortable: false, center: true },
    { selector: row => row['energiamenor9999MW'], name: 'ENERGIA > 20Mw', width: '130px', sortable: false, center: true },
    { selector: row => row['sindatos'], name: 'CUPS SIN DATOS', width: '100px', sortable: false, center: true },
];



const columnsDatosPerfil = [
    {
        selector: row => row['id'], name: 'TIPO', sortable: false, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['cups'], name: 'CUPS', sortable: false, width: '100px', center: true },
    { selector: row => row['consumoTotalPerfil'], name: 'CONSUMO PERFIL', width: '170px', sortable: false, center: true },
    { selector: row => row['porcentaje'], name: 'PORCENTAJE', width: '100px', sortable: false, center: true }
];

const columnsDatosTarifa = [
    {
        selector: row => row['id'], name: 'TARIFA', sortable: false, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['cups'], name: 'CUPS', sortable: false, width: '100px', center: true },
    { selector: row => row['consumoTotalTarifa'], name: 'CONSUMO TARIFA', width: '170px', sortable: false, center: true },
    { selector: row => row['porcentaje'], name: 'PORCENTAJE', width: '100px', sortable: false, center: true }
];


const notify = () => toast.success(
    "Correo enviado correctamente",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyError = () => toast.error(
    "Existe un problema con el servicio de correos",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyEnvio = () => toast.info(
    "Preparando el fichero para su envío por correo",
    {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


class informePreliminarMunicipioConsultor extends Reflux.Component {
    public container: any = null;
    public fileInput: any = null;
    public pdfExportComponent: any = null;

    public formProductoRef: any = null;
    public state: any = {
        id: "",
        compania: "",
        municipioBuscar: "00000",
        correoContacto: "",
        nombreFichero: '',
        emailError: true,
        alert: null,
        ingresosPrevistosGas: 0,
        tasasResumenMunicipio:
        {
            distribuidora: "",
            municipio: "",
            provincia: "",
            autonomia: "",
            consumoenergiatotal: 0,
            consumoEnergiaTotalGas: 0,
            cups: 0,
            cupsGas: 0,

            datosPerfil: [
                {
                    id: "",
                    consumoTotalPerfil: 0,
                    cups: 0,
                    porcentaje: 0
                }
            ],
            datosTarifa: [
                {
                    id: "",
                    consumoTotalTarifa: 0,
                    cups: 0,
                    porcentaje: 0
                }
            ],
            datosAnalisis: [
                {
                    tarifa: "",
                    cups: "0",
                    energiatarifa: "",
                    menor5mw: "",
                    energiamenor5mw: "",
                    menor10mw: "",
                    energiamenor10mw: "",
                    menor20mw: "",
                    energiamenor20mw: "",
                    menor9999MW: "",
                    energiamenor9999MW: "",
                    sindatos: "",
                    energiasindatos: ""
                }
            ]
        },
        outputDatosAnalisis: [{ id: "", value: 0 }],
        outputDatosAnalisisGas : [{ id: "", value: 0 }],
        outputDatosTarifa: [{ id: "", value: 0 }],
        outputDatosTarifaGas: [{ id: "", value: 0 }],

        municipios: [{ key: "", value: 0 }],
        // --- Data
        isLoading: false,
        isDisabled: true,
        datafile: "",
        uploadedFileContents: null,
        control: 0,
    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.container = React.createRef<HTMLDivElement>();
        this.pdfExportComponent = React.createRef<PDFExport>();
        this.fileInput = React.createRef();
        this.logout = this.logout.bind(this);
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
    }


    componentDidMount() {

        this.formProductoRef = React.createRef();


        const user = localStorage.getItem("user");

        if (user) {
            const parsed = JSON.parse(user);
            this.setState({
                user: parsed,
            });
        }

        this.setState({
            ...this.state,
        },
            this.fetchDataMunicipio
        );

        this.setState({

            control: 1
        }
        );

    };


    /////////////////////////////////// ON Change Field para correo //////////////////////////////////////////////////////

    onChangeFieldCorreo = (event) => {
        event.persist()

        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;

        if (event.target.name === 'correoContacto') {


            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);

            if (result === true) {
                this.setState({
                    emailError: false,
                    isDisabled: false
                })
            } else {
                this.setState({
                    emailError: true,
                    isDisabled: true
                })
            }
        }

        this.setState({ correoContacto: value });
    };


    /////////////////////////////////////////////////////////////////////////////////////////////////////

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };


    /////////////////////////////////////////////////////////////////////////////////////////////////

    exportPDFWithMethod = () => {
        let element = this.container.current || document.body;
        savePDF(element, {
            paperSize: "auto",
            margin: 40,
            fileName: `Report for ${new Date().getFullYear()}`,
        });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    exportPDFWithComponentclick = () => {
        if (this.pdfExportComponent.current) {
            this.pdfExportComponent.current.save();
        }
    };


    ////////////////////////////////////////////////////////////////////////////////////////////////
    //SendEmail = async (e: React.ChangeEvent<HTMLInputElement>)  => {


    SendEmail = async (event) => {
        event.persist()
        var selectedFile: any = []
        var evento: any = []
        if (event !== null && event.target.files !== null) {
            selectedFile = event.target.files;
            evento = event
        }


        if (selectedFile.length > 0) {
            let fileToLoad = selectedFile[0];
            try {
                const fileContents = await this.readUploadedFileAsText(fileToLoad)
                this.setState({
                    uploadedFileContents: fileContents
                });
            } catch (e) {
                console.warn(e.message)
            }
        };

        var templateParams = {
            from_name: "nextNiro Tasas Tool",
            municipio: this.state.tasasResumenMunicipio.municipio,
            correoContacto: this.state.correoContacto,
            nombrePropuesta: this.state.nombreFichero + ".pdf",
            variable_tasaspdf: this.state.uploadedFileContents
        };


        notifyEnvio();


        emailjs.send("service_nextniroTasas", "template_i8jo8c3", templateParams, "user_9fdpYny52CeShK5yj0B3u")
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                this.setState({
                    uploadedFileContents: null,
                    nombreFichero: "",
                    correoContacto: ""
                });

                notify();
            }).catch((error) => {
                console.log('FAILED...', error);
                this.setState({
                    uploadedFileContents: null,
                    nombreFichero: "",
                    correoContacto: "",
                    isDisabled: true

                });

                notifyError();
            });



    };

    readUploadedFileAsText = (inputFile) => {

        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            temporaryFileReader.readAsDataURL(inputFile);
        });
    };



    fetchDataMunicipio = async () => {

        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        var municipioPDF = ""

        var municipio = datosUser.ayuntamiento ? datosUser.ayuntamiento : ""
        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""
        var iduser = datosUser.id ? datosUser.id : ""

        let indexMunicipio = this.state.municipios.findIndex(object =>
            object.key === codigoINE
        ); // 👉️ 1

        if (indexMunicipio !== -1) {
            municipio = this.state.municipios[indexMunicipio].value
        };


        var dataFilters = {
            'codigoINE': codigoINE,
            'municipio': municipio,
            'id': iduser

        }

        const headers = {

            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/tasas/createinfobaseLg";


        axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {
                var tasasResumenMunicipio = result.data.dataAyuntamientoPre;
                var auxDatosAnalisis = result.data.dataAyuntamientoPre.datosAnalisis
                var auxDatosTarifa = result.data.dataAyuntamientoPre.datosTarifa
                var auxDatosAnalisisGas = result.data.dataAyuntamientoPre.datosAnalisisGas
                var auxDatosTarifaGas = result.data.dataAyuntamientoPre.datosTarifaGas
                var outputDatosAnalisis: any = [];
                var outputDatosTarifa: any = [];
                var outputDatosAnalisisGas: any = [];
                var outputDatosTarifaGas: any = [];
                var ingresosPrevistosGas = 0
                for (var i = 0; i < auxDatosAnalisis.length; ++i) {
                    outputDatosAnalisis.push({ id: auxDatosAnalisis[i].tarifa, value: Number(auxDatosAnalisis[i].cups) })
                }
                for (var i = 0; i < auxDatosTarifa.length; ++i) {
                    outputDatosTarifa.push({ id: auxDatosTarifa[i].id, value: auxDatosTarifa[i].porcentaje })

                }

                for (var i = 0; i < auxDatosAnalisisGas.length; ++i) {
                    outputDatosAnalisisGas.push({ id: auxDatosAnalisisGas[i].tarifa, value: Number(auxDatosAnalisisGas[i].cups) })
                }
                for (var i = 0; i < auxDatosTarifaGas.length; ++i) {
                    outputDatosTarifaGas.push({ id: auxDatosTarifaGas[i].id, value: auxDatosTarifaGas[i].porcentaje })

                }

                for (var i = 0; i < auxDatosTarifaGas.length; ++i) {
                    ingresosPrevistosGas= ingresosPrevistosGas + auxDatosTarifaGas[i].costesEnergiaGas + auxDatosTarifaGas[i].costesFijos  + auxDatosTarifaGas[i].costesVariables 
                }


                let indexMunicipio = this.state.municipios.findIndex(object =>
                    object.key === codigoINE
                ); // 👉️ 1

                if (indexMunicipio !== -1) {
                    municipioPDF = this.state.municipios[indexMunicipio].value
                };
                let nombreFichero = 'estudio_preliminar_tasas_' + municipioPDF + (new Date().getFullYear()).toString();
                this.setState({
                    tasasResumenMunicipio: tasasResumenMunicipio,
                    outputDatosAnalisis: outputDatosAnalisis,
                    outputDatosTarifa: outputDatosTarifa,
                    outputDatosAnalisisGas: outputDatosAnalisisGas,
                    outputDatosTarifaGas: outputDatosTarifaGas,
                    ingresosPrevistosGas: ingresosPrevistosGas,
                    nombreFichero: nombreFichero,
                    isLoading: false,
                });
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                });
            });

    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    /////////////////////////////////////////////////////////////////////////////////

    render() {
        const isLoading = this.state.isLoading;
        const styleFullWith = { "width": "100%" };
        const outputDatosAnalisis = this.state.outputDatosAnalisis;
        const outputDatosTarifa = this.state.outputDatosTarifa;
        const outputDatosAnalisisGas = this.state.outputDatosAnalisisGas;
        const outputDatosTarifaGas = this.state.outputDatosTarifaGas;
        const nombreFichero = this.state.nombreFichero
        const correoContacto = this.state.correoContacto

        return (
            <>

                <Header title={'Información Preliminar Municipio'} />
                <Container className=" mt--7" fluid>
                    <Card className="mb-4">
                        <CardBody>
                            <Row>

                                <Col md="2">


                                    <Button className="btn-icon" color="primary" type="button" id="imprimirProptooltip" onClick={this.exportPDFWithComponentclick} >
                                        <span className="btn-inner--icon mr-1">
                                            <i className="fas fa-file-pdf fa-2x" />
                                        </span>
                                        <span className="btn-inner--text">Exportar PDF</span>
                                        <UncontrolledTooltip delay={0} placement="top" target="imprimirProptooltip">
                                            EXPORTAR A PDF
                                        </UncontrolledTooltip>
                                    </Button>


                                </Col>
                                <Col md="6">

                                    <Form>
                                        <input
                                            className="custom-file-input lg"
                                            id="customFileLang"
                                            lang="es"
                                            type="file"
                                            ref={this.fileInput}
                                            onChange={this.SendEmail}
                                            disabled={this.state.isDisabled}
                                        />
                                        <FormText color="muted">
                                            Seleccionar estudio para su envío por correo electrónico
                                        </FormText>
                                    </Form>

                                </Col>

                                <Col md="3">
                                    <FormGroup>
                                        <Label
                                            className="form-control-label"
                                            htmlFor="correoContacto"
                                        >
                                            Correo de contacto
                                        </Label>
                                        <Input
                                            id="correoContacto"
                                            placeholder=""
                                            name="correoContacto"
                                            onChange={this.onChangeFieldCorreo}
                                            value={correoContacto || ""}
                                            type="text"
                                            required
                                        >  </Input>
                                        {this.state.emailError ? <FormText color="red">Por favor introduzca una dirección de correo electrónico correcto</FormText> : ''}
                                    </FormGroup>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Container>
                <PDFExport
                    ref={this.pdfExportComponent}
                    paperSize="auto"
                    margin={20}
                    fileName={`${nombreFichero}`}
                    author="aytos tasas"
                >
                    <div id="document-page" ref={this.container} className="border card-deck  p-2 mb-2 shadow font-face-open">
                        <LoadingOverlay
                            active={isLoading}
                            spinner
                            text="Cargando...">

                            <h1 className="mt-2 ml-2 mr-2 mb-1">INFORME PRELIMINAR. Ayuntamiento {this.state.tasasResumenMunicipio.municipio}</h1>

                            <Row className="card-deck" style={styleFullWith}>
                                <Card className="mt-3 mb-3 p-3 card-document">
                                    <CardBody>
                                        <Row>
                                            <Col md="6">
                                                <CardTitle className="info-title font-face-open"><h2>Información del municipio</h2></CardTitle>
                                                <ListGroup>
                                                    <ListGroupItem className="list-item-documento font-face-open-bold">Municipio: {this.state.tasasResumenMunicipio.municipio}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento font-face-open-bold">Provincia: {this.state.tasasResumenMunicipio.provincia}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento font-face-open-bold">C. Autónoma: {this.state.tasasResumenMunicipio.autonomia}</ListGroupItem>
                                                </ListGroup>
                                            </Col>
                                            <Col md="6">
                                                <CardTitle className="info-title font-face-open"><h2>Información Energía</h2></CardTitle>
                                                <ListGroup>
                                                    <ListGroupItem className="list-item-documento font-face-open-bold">Número Cups: {this.state.tasasResumenMunicipio.cups.toLocaleString('es-ES', {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            })}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento font-face-open-bold">Total energía Elect: {(this.state.tasasResumenMunicipio.consumoenergiatotal / 1000000000).toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })} GWh</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento font-face-open-bold">Número Cups Gas: {this.state.tasasResumenMunicipio.cupsGas.toLocaleString('es-ES', {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            })}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento font-face-open-bold">Total energía Gas: {(this.state.tasasResumenMunicipio.consumoEnergiaTotalGas / 1000000000).toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })} GWh</ListGroupItem>
                                                </ListGroup>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Row>

                            <Row className="card-deck" style={styleFullWith}>
                                <Card className="mt-3 mb-3 p-3 card-document">
                                <CardTitle className="info-title"><h2>Informe Técnico</h2></CardTitle>
                                <Row>
                                    <Col md="6">
                                        <p>
                                            Puntos de suministro electricidad por tarifa de acceso                                                <br></br>
                                            <br></br>
                                        </p>

                                        <Card className="card-stats" style={{ height: 400 }}>

                                            <ResponsiveBar
                                                layout="horizontal"
                                                margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                                                data={outputDatosAnalisis}
                                                indexBy="id"
                                                keys={['value']}
                                                colors={{ scheme: 'nivo' }}
                                                colorBy="indexValue"
                                                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                                                enableGridX
                                                enableGridY={false}
                                                axisTop={{
                                                    format: '~s',
                                                }}

                                                axisBottom={null}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                padding={0.3}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}

                                            />

                                            <Col className="col-auto mr-2 d-flex bg-energia radius-pot text-center align-items-center">
                                                <h3 className="mt-2 mb-2 justify-content-center align-self-center text-center"> Cups electricidad:  {this.state.tasasResumenMunicipio.cups}</h3>
                                            </Col>

                                        </Card>

                                        <p>
                                            Distribución consumo electricidad por tarifa de acceso
                                            <br></br>
                                        </p>

                                        <Card className="card-stats" style={{ height: 400 }}>

                                            <ResponsivePie
                                                data={outputDatosTarifa}
                                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                colors={{ scheme: 'nivo' }}
                                                innerRadius={0.5}
                                                padAngle={0.7}
                                                cornerRadius={3}
                                                activeOuterRadiusOffset={8}
                                                borderWidth={1}
                                                borderColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            0.2
                                                        ]
                                                    ]
                                                }}
                                                arcLinkLabelsSkipAngle={10}
                                                arcLinkLabelsTextColor="#333333"
                                                arcLinkLabelsThickness={2}
                                                arcLinkLabelsColor={{ from: 'color' }}
                                                arcLabelsSkipAngle={10}
                                                arcLabelsTextColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            2
                                                        ]
                                                    ]
                                                }}
                                                valueFormat={value =>
                                                    `${Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 2,
                                                    })} %`
                                                }
                                                legends={[
                                                    {
                                                        anchor: 'bottom',
                                                        direction: 'row',
                                                        justify: false,
                                                        translateX: 0,
                                                        translateY: 56,
                                                        itemsSpacing: 0,
                                                        itemWidth: 100,
                                                        itemHeight: 18,
                                                        itemTextColor: '#999',
                                                        itemDirection: 'left-to-right',
                                                        itemOpacity: 1,
                                                        symbolSize: 18,
                                                        symbolShape: 'circle',
                                                        effects: [
                                                            {
                                                                on: 'hover',
                                                                style: {
                                                                    itemTextColor: '#000'
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]}
                                            />
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                          <p>
                                        Puntos de suministro gas por tarifa de acceso                                                 <br></br>
                                            <br></br>
                                        </p>

                                        <Card className="card-stats" style={{ height: 400 }}>

                                            <ResponsiveBar
                                                layout="horizontal"
                                                margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
                                                data={outputDatosAnalisisGas}
                                                indexBy="id"
                                                keys={['value']}
                                                colors={{ scheme: 'nivo' }}
                                                colorBy="indexValue"
                                                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                                                enableGridX
                                                enableGridY={false}
                                                axisTop={{
                                                    format: '~s',
                                                }}

                                                axisBottom={null}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: '',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                padding={0.3}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}

                                            />

                                            <Col className="col-auto mr-2 d-flex bg-energia radius-pot text-center align-items-center">
                                                <h3 className="mt-2 mb-2 justify-content-center align-self-center text-center"> Cups gas:  {this.state.tasasResumenMunicipio.cupsGas}</h3>
                                            </Col>

                                        </Card>

                                        <p>
                                            Distribución consumo gas por tarifa de acceso
                                            <br></br>
                                        </p>

                                        <Card className="card-stats" style={{ height: 400 }}>

                                            <ResponsivePie
                                                data={outputDatosTarifaGas}
                                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                colors={{ scheme: 'nivo' }}
                                                innerRadius={0.5}
                                                padAngle={0.7}
                                                cornerRadius={3}
                                                activeOuterRadiusOffset={8}
                                                borderWidth={1}
                                                borderColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            0.2
                                                        ]
                                                    ]
                                                }}
                                                arcLinkLabelsSkipAngle={10}
                                                arcLinkLabelsTextColor="#333333"
                                                arcLinkLabelsThickness={2}
                                                arcLinkLabelsColor={{ from: 'color' }}
                                                arcLabelsSkipAngle={10}
                                                arcLabelsTextColor={{
                                                    from: 'color',
                                                    modifiers: [
                                                        [
                                                            'darker',
                                                            2
                                                        ]
                                                    ]
                                                }}
                                                valueFormat={value =>
                                                    `${Number(value).toLocaleString('es-ES', {
                                                        minimumFractionDigits: 2,
                                                    })} %`
                                                }
                                                legends={[
                                                    {
                                                        anchor: 'bottom',
                                                        direction: 'row',
                                                        justify: false,
                                                        translateX: 0,
                                                        translateY: 56,
                                                        itemsSpacing: 0,
                                                        itemWidth: 100,
                                                        itemHeight: 18,
                                                        itemTextColor: '#999',
                                                        itemDirection: 'left-to-right',
                                                        itemOpacity: 1,
                                                        symbolSize: 18,
                                                        symbolShape: 'circle',
                                                        effects: [
                                                            {
                                                                on: 'hover',
                                                                style: {
                                                                    itemTextColor: '#000'
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                                </Card>

                            </Row>



                            <Row className="card-deck" style={styleFullWith}>

                                <Card className="mt-3 mb-3 p-3 card-document">
                                    <CardTitle className="mt-4 info-title"><h2>Resumen conclusiones</h2></CardTitle>
                                    <Row className="mt-4">
                                        <Col className="col-auto pt-2 pb-2 mr-2">
                                            <h2>Total energía anual electricidad (GWh):</h2>
                                        </Col>

                                        <Col className="col-auto d-flex bg-units radius-pot text-center align-items-center">
                                            <h3 className="mt-2 mb-2 justify-content-center align-self-center text-center">{(this.state.tasasResumenMunicipio.consumoenergiatotal / 1000000000).toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}</h3>
                                        </Col>

                                        <Col className="col-auto pt-2 pb-2 ml-6">
                                            <h2>Recaudación Prevista: </h2>
                                        </Col>

                                        <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                                            <h3 className="mt-2 mb-2 justify-content-center align-self-center">{((this.state.tasasResumenMunicipio.consumoenergiatotal / 1000000000) * 3000).toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })} €</h3>
                                        </Col>

                                    </Row>

                                    <Row className="mt-4">
                                        <Col className="col-auto pt-2 pb-2 mr-2">
                                            <h2>Total energía anual gas (GWh):</h2>
                                        </Col>

                                        <Col className="col-auto d-flex bg-units radius-pot text-center align-items-center">
                                            <h3 className="mt-2 mb-2 justify-content-center align-self-center text-center">{(this.state.tasasResumenMunicipio.consumoEnergiaTotalGas / 1000000000).toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}</h3>
                                        </Col>

                                        <Col className="col-auto pt-2 pb-2 ml-6">
                                            <h2>Recaudación Prevista: </h2>
                                        </Col>

                                        <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                                            <h3 className="mt-2 mb-2 justify-content-center align-self-center">{(this.state.ingresosPrevistosGas * 0.015).toLocaleString('es-ES', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })} €</h3>
                                        </Col>

                                    </Row>


                                </Card>
                                
                            </Row>

                            <Row className="m-3 border-top">
                                <p className="float-right text-right mt-2"></p>
                                <p>
                                    <small>
                                        Los estudios preliminares no son documentos de carácter contractual. Los datos sólo se consolidaran con el estudio pormenorizado y la auditoría actualizada completa, una vez aportados los datos facilitados por la/s distribuidora/s previa petición del ayuntamiento.
                                        Los datos indicados en el estudio preliminar son estimaciones y sufrirán variaciones al alza o a la baja y son fruto de la experiencia en auditorias previas ya ejecutadas por Next Niro Energy Group, S.L.
                                        Estos datos son de elaboración propia con datos extraídos de múltiples fuentes de carácter público o privadas, entre los cuales se encuentran los datos suministrados desde el sitio web del INE: www.ine.es, a fecha de 01/10/2022, los datos suministrados por el servicio REData API y esios de Red Eléctrica de España S.A.U., los datos web el Operador de Mercado Ibérico de Electricidad (OMIE): www.omie.es y la información expuesta en los informes emitidos por la Comisión Nacional de Mercados y Competencia que se pueden consultar en: www.cnmc.es, así como de los datos obtenidos a partir de la experiencia en proyectos previos ejecutados por Next Niro Energy Group, S.L.
                                        Las fuentes de datos externas utilizadas no son responsable de los daños materiales o sobre datos, ni de posibles perjuicios económicos provocados por el uso de la información reutilizada.
                                        Las licencias de uso de los datos se rigen por las leyes españolas independientemente del entorno legal del usuario. Cualquier disputa que pueda surgir en la interpretación de este acuerdo se resolverá en los tribunales españoles.
                                    </small>
                                </p>
                            </Row>

                        </LoadingOverlay>
                    </div>
                </PDFExport>

                <div>
                    <Container className=" mt-3" fluid>
                        <Row className="card-deck" style={styleFullWith}>

                            <Card className="mt-3 mb-3">
                                <CardBody>
                                    <Row className="">
                                        <h2 className="col">
                                            Datos análisis por tarifa
                                        </h2>
                                    </Row>
                                    <Row>
                                        <DataTable
                                            columns={columnsDatosAnalisis}
                                            data={this.state.tasasResumenMunicipio.datosAnalisis}
                                            fixedHeader={true}
                                            striped
                                            pointerOnHover
                                            customStyles={customStyles}
                                            noDataComponent={"Sin datos para mostrar"}
                                        />
                                    </Row>

                                </CardBody>

                            </Card>



                        </Row>
                        <Row className="card-deck" style={styleFullWith}>

                            <Card className="mt-3 mb-3">
                                <CardBody>
                                    <Row className="">
                                        <h2 className="col">
                                            Datos análisis por tipo jurídico
                                        </h2>
                                    </Row>
                                    <Row>
                                        <DataTable
                                            columns={columnsDatosPerfil}
                                            data={this.state.tasasResumenMunicipio.datosPerfil}
                                            fixedHeader={true}
                                            striped
                                            pointerOnHover
                                            customStyles={customStyles}
                                            noDataComponent={"Sin datos para mostrar"}
                                        />
                                    </Row>

                                </CardBody>

                            </Card>

                            <Card className="mt-3 mb-3">
                                <CardBody>
                                    <Row className="">
                                        <h2 className="col">
                                            Datos análisis por tarifa
                                        </h2>
                                    </Row>
                                    <Row>
                                        <DataTable
                                            columns={columnsDatosTarifa}
                                            data={this.state.tasasResumenMunicipio.datosTarifa}
                                            fixedHeader={true}
                                            striped
                                            pointerOnHover
                                            customStyles={customStyles}
                                            noDataComponent={"Sin datos para mostrar"}
                                        />
                                    </Row>

                                </CardBody>

                            </Card>

                        </Row>
                    </Container>
                    {this.state.alert}
                </div>


                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

export default withApollo(informePreliminarMunicipioConsultor);
