/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Col, Nav } from "reactstrap";
import logo from "../../assets/img/theme/logonextnirosmall.png";

class Footer extends React.Component {
  
  render() {

    return (
      <footer className="footer">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-center text-muted">
              © {new Date().getFullYear()}{" "}
              <a
                className="font-weight-bold ml-1"
                href="http://www.nextniro.com"
                target="_blank"
              >
                <img src={logo} style={{height:'12px', marginBottom:'3px'}}/>
              </a>
            </div>
          </Col>


          <Col xl="6">
            <Nav style={{ fontSize: '0.7rem'}} className="nav-footer justify-content-center justify-content-xl-end">
              ver. 1.89_a_20231112
            </Nav>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
