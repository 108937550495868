import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link    
} from 'react-router-dom';
import { CSVLink } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";

import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';



var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const sortIcon = <ArrowDownward />;
const estado = (s) => {
    if (s !== true) return <i className="fas fa-exclamation-circle fa-2x" style={{ color: "#ff1900" }} ></i>
    return <i className="far fa-check-circle fa-2x" style={{ color: "#7ee06e" }} ></i>
}

const columnsDatosUsuarios = [

    { selector: row => row.nif, name: 'NIF', sortable: true, width: '220px', format: (row => capitalize(row.nif)) },
    {
        name: 'ACTIVO',
        center: true, width: '100px',
        cell: row => estado(row.esActiva,)

    },
    { selector: row => row.apellidos, name: 'APELLIDOS', sortable: true, width: '240px', center: true, format: (row => capitalize(row.apellidos)) },
    { selector: row => row.nombre, name: 'NOMBRE', sortable: true, width: '240px', center: true, format: (row => capitalize(row.nombre)) },
    { selector: row => row.telefono, name: 'TELEFONO', sortable: true, width: '240px', center: true, format: (row => capitalize(row.telefono)) },
    { selector: row => row.email, name: 'CORREO', sortable: true, width: '240px', center: true, format: (row => capitalize(row.email)) },
    { selector: row => row.ayuntamiento, name: 'AYUNTAMIENTO', sortable: true, width: '240px', center: true, format: (row => capitalize(row.ayuntamiento)) },
    {
        name: 'ADMIN',
        center: true, width: '100px',
        cell: row => estado(row.esAdmin,)

    },
    {
        name: 'COMERCIAL',
        center: true, width: '100px',
        cell: row => estado(row.esComercial)

    },
    {
        name: 'FUNCIONARIO',
        center: true, width: '100px',
        cell: row => estado(row.esFuncionario)

    }

];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);





class Usuarios extends Reflux.Component<any, any> {
    public formRef: any = null;
    lastFilters = {};
    state = {
        filters: {
            apellidosUsuariosSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            usuariosDesde: ""
        },
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        TipoCliente: "",
        usuarioDetailTotal: [],
        usuarioSeleccionado: false,
        passwordModificado: false,
        idUser: '',
        municipios: [{ key: "", value: 0 }],
        usuarioDetail: {
            id: 0,
            userId: "",
            nombre: "",
            apellidos: "",
            nif: "",
            email: "",
            password: "",
            user_group_id: 0,
            query_limit: 0,
            profile_photo: "",
            profile_photo_dir: "",
            app_logo: "",
            app_logo_dir: "",
            ayuntamiento: "",
            codigoINE: "",
            userUuid: "",
            telefono: "",
            locked_up: null,
            attempts_login: 0,
            reset_token: "",
            reset_token_expire: "",
            companyId: 0,
            esActiva: false,
            esAdmin: false,
            esComercial: false,
            esConsultor: false,
            esFuncionario: false,
            informes: false,
            detalles: false,
            createdAt: null,
            modifiedAt: null,
            idEmpresa: "1001"

        },
        formUsuario: {
            id: 0,
            userId: "",
            nombre: "",
            apellidos: "",
            nif: "",
            email: "",
            password: "",
            user_group_id: 0,
            query_limit: 0,
            profile_photo: "",
            profile_photo_dir: "",
            app_logo: "",
            app_logo_dir: "",
            ayuntamiento: "",
            codigoINE: "",
            userUuid: "",
            telefono: "",
            locked_up: null,
            attempts_login: 0,
            reset_token: "",
            reset_token_expire: "",
            companyId: 0,
            esActiva: false,
            esAdmin: false,
            esComercial: false,
            esConsultor: false,
            esFuncionario: false,
            informes: false,
            detalles: false,
            createdAt: null,
            modifiedAt: null,
            idEmpresa: "1001"
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataUsuario: false,
        updateDataUsuario: false,
        updateUsuarioButtonDisabled: true,
        saveUsuarioButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        usuariosDesde: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        IBANError: false,
        alert: null

    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);

    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    componentDidMount() {
        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = Number(usuario.user_group_id)
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });

    };

    //////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
     </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
     </ReactBSAlert>
            )
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
            </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el usuario?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos del usuario?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Cliente actualizado correctamente
        </ReactBSAlert>
            )
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////



    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        var filtersData = { ...this.state.filters };
        filters[name] = value;
        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/

        if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputIdDisabled: false
            })
        } else if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length > 0) {

            this.setState({
                inputIdDisabled: true,
                buscarButtonDisabled: false

            })

        }


        if (event.target.name === 'NIFSearch' && event.target.value.length == 0) {

            this.setState({

                NIFError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'NIFSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonDisabled: false,
                })

            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonDisabled: true,
                })
            }
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null
            this.setState({
                buscarButtonDisabled: true
            })

        } else if (event.target.name === 'tipoEstadoSearch' && event.target.value !== 'Selecciona...'){
            this.setState({
                buscarButtonDisabled: false
            })

        };

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldUsuario = (event) => {
        event.persist()

        var formData = { ...this.state.formUsuario };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        const nameFilters = ['id', 'userId', 'nombre', 'apellidos', 'nif', 'email', 'password', 'user_group_id', 'query_limit', 'profile_photo', 'profile_photo_dir', 'app_logo', 'app_logo_dir', 'ayuntamiento', 'codigoINE', 'userUuid', 'telefono', 'locked_up', 'attempts_login', 'reset_token', 'reset_token_expire', 'companyId', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles', 'createdAt', 'modifiedAt', 'idEmpresa'];

        const optionsFilters = ['user_group_id', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles'];

        var municipio = ""

        if (this.state.usuarioSeleccionado) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ saveDataUsuario: true });
                this.setState({
                    saveUsuarioButtonDisabled: false,
                    updateUsuarioButtonDisabled: false
                })
            }
            if (event.target.name == 'nombre' && event.target.value.length > 0) {

                this.setState({ saveDataUsuario: true });
                this.setState({
                    saveUsuarioButtonDisabled: false,
                    updateUsuarioButtonDisabled: false
                })
            }
            if (event.target.name == 'apellidos' && event.target.value.length > 0) {

                this.setState({ saveDataUsuario: true });
                this.setState({
                    saveUsuarioButtonDisabled: false,
                    updateUsuarioButtonDisabled: false
                })
            }

            if (event.target.name == 'password' && event.target.value.length > 0) {

                this.setState({
                    saveDataUsuario: true,
                    passwordModificado: true
                });
                this.setState({
                    saveUsuarioButtonDisabled: false,
                    updateUsuarioButtonDisabled: false
                })
            }


            if (event.target.name === 'nif' && event.target.value.length == 0) {
                this.setState({ NIFClienteError: true })
            } else if (event.target.name === 'nif' && event.target.value.length > 0) {
                const result = validateSpanishId(event.target.value)
                if (result === true) {
                    this.setState({
                        saveDataUsuario: true,
                        NIFClienteError: false
                    });
                    this.setState({
                        saveUsuarioButtonDisabled: true,
                        updateUsuarioButtonDisabled: false
                    })
                } else {
                    this.setState({
                        NIFClienteError: true,
                        saveDataUsuario: false,
                        updateUsuarioButtonDisabled: true
                    })
                }
            }


            if (event.target.name === 'email') {

                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                const result = pattern.test(event.target.value);

                if (result === true) {
                    this.setState({
                        emailError: false,
                    })
                } else {
                    this.setState({
                        emailError: true
                    })
                }
            };

            if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
                value = null
            }

            if (value === "true" || value == "false") {
                value = JSON.parse(value);
            };

            if (name == 'ayuntamiento') {

                let indexMunicipio = this.state.municipios.findIndex(object =>
                    object.key === value
                ); // 👉️ 1

                if (indexMunicipio !== -1) {
                    municipio = this.state.municipios[indexMunicipio].value
                };

                formData['codigoINE'] = value
                formData['ayuntamiento'] = municipio
                this.setState({
                    saveDataCliente: true
                })

                this.setState({ formUsuario: formData });

            } else {


                /*    if (name == 'nombreMunicipioPS') {
                        const municipio: any = this.state.dicMunicipios
                        const resultado = municipio.find(municipio => municipio ?.Nombre === value);
                        const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
                        formData['nombreProvinciaPS'] = resultado.DescProvincia
                        formData['AutonomiaTitular'] = resultado.DescAutonomia
                    }
                */

                formData[name] = value;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //
                //}
                this.setState({
                    saveDataCliente: true
                })
                this.setState({ formUsuario: formData });
            }

        }


    };


    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        this.setState({
            filters: {
                apellidosUsuariosSearch: "",
                NIFSearch: "",
                tipoEstadoSearch: "",
                usuariosDesde: ""
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            usuarioSeleccionado: false,
            usuarioDetailTotal: [],
            passwordModificado: false,
            formUsuario: {
                id: 0,
                userId: "",
                nombre: "",
                apellidos: "",
                nif: "",
                email: "",
                password: "",
                user_group_id: 0,
                query_limit: 0,
                profile_photo: "",
                profile_photo_dir: "",
                app_logo: "",
                app_logo_dir: "",
                ayuntamiento: "",
                codigoINE: "",
                userUuid: "",
                telefono: "",
                locked_up: null,
                attempts_login: 0,
                reset_token: "",
                reset_token_expire: "",
                companyId: 0,
                esActiva: false,
                esAdmin: false,
                esComercial: false,
                esConsultor: false,
                esFuncionario: false,
                informes: false,
                detalles: false,
                createdAt: null,
                modifiedAt: null,
                idEmpresa: "1001"
            },
            toggledClearRows: !this.state.toggledClearRows,
            updateUsuarioButtonDisabled: true,
            saveUsuarioButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            saveDataUsuario: false
        });

        this.formRef.current.reset();

    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarUsuario = async () => {

        this.setState({
            isLoading: true,
        })

        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }

        const codigoINE = datosUser.codigoINE ? datosUser.codigoINE : ""


        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""

        var dataFilters = {
            'idEmpresa': idEmpresa,
            'apellidos': this.state.filters.apellidosUsuariosSearch ?.toUpperCase() || "",
            'nif': this.state.filters.NIFSearch ?.toUpperCase() || "",
            'createdAt': this.state.filters.usuariosDesde,
            'esActiva': this.state.filters.tipoEstadoSearch,
            'codigoINE': codigoINE
        };

        const headers = {
            
            'Content-Type': 'application/json',
        };
        let direccionFile = "https://api.nextniro.com/api/v1/gestion/usuariolist";

        axios.post(direccionFile, dataFilters, { headers, responseType: 'json' })
            .then((result) => {
                //console.log(JSON.stringify(result.data));
                //console.log('resultado del listados')
                if (result.data.rows.length > 0) {
                    let data = result.data.rows

                    this.setState({ usuarioDetailTotal: result.data.rows });

                } else {
                    this.warningAlertNIF()
                };
                //this.clearForm();
            }).catch((error) => {
                this.setState({
                    isLoading: false,
                  });
              });
    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    updateHandler(props) {

        if (this.state.saveDataCliente && (this.state.formUsuario.nombre && this.state.formUsuario.email)) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {

        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }

        if (this.state.saveDataUsuario == true) {

            var updatedAt = this.state.formUsuario.updatedAt;
            if (!updatedAt) {
                updatedAt = moment().format("YYYY-MM-DDTHH:mm:ss");

            }
            var createdAt = this.state.formUsuario.createdAt;

            if (!createdAt) {

                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var usuarioDataUpdate = {}



            usuarioDataUpdate = {
                'passwordModificado': this.state.passwordModificado,
                'datosModificadosUsuario': {
                    'id': this.state.formUsuario.id,
                    'userId': this.state.formUsuario.userId,
                    'nombre': this.state.formUsuario.nombre ?.toUpperCase() || '',
                    'apellidos': this.state.formUsuario.apellidos ?.toUpperCase() || '',
                    'nif': this.state.formUsuario.nif,
                    'email': this.state.formUsuario.email,
                    'password': this.state.formUsuario.password,
                    'user_group_id': this.state.formUsuario.user_group_id,
                    'query_limit': this.state.formUsuario.query_limit,
                    'profile_photo': this.state.formUsuario.profile_photo,
                    'profile_photo_dir': this.state.formUsuario.profile_photo_dir,
                    'app_logo': this.state.formUsuario.app_logo,
                    'app_logo_dir': this.state.formUsuario.app_logo_dir,
                    'ayuntamiento': this.state.formUsuario.ayuntamiento,
                    'codigoINE': this.state.formUsuario.codigoINE,
                    'userUuid': this.state.formUsuario.userUuid,
                    'telefono': this.state.formUsuario.telefono,
                    'locked_up': this.state.formUsuario.locked_up,
                    'attempts_login': this.state.formUsuario.attempts_login,
                    'reset_token': this.state.formUsuario.reset_token,
                    'reset_token_expire': this.state.formUsuario.reset_token_expire,
                    'companyId': this.state.formUsuario.companyId,
                    'esActiva': this.state.formUsuario.esActiva,
                    'esAdmin': this.state.formUsuario.esAdmin,
                    'esComercial': this.state.formUsuario.esComercial,
                    'esConsultor': this.state.formUsuario.esConsultor,
                    'esFuncionario': this.state.formUsuario.esFuncionario,
                    'informes': this.state.formUsuario.informes,
                    'detalles': this.state.formUsuario.detalles,
                    'createdAt': createdAt,
                    'modifiedAt': updatedAt,
                    'idEmpresa': this.state.formUsuario.idEmpresa
                }
            };

            const headers = {
                
                'Content-Type': 'application/json',
            };
            let direccionFile = "https://api.nextniro.com/api/v1/gestion/usuarioupdate";


            axios.post(direccionFile, usuarioDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    console.log('Actualización correcta', result)
                    console.log('Datpos del registro: ', result.data)
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        usuarioSeleccionado: false,
                        usuarioDetailTotal: [],
                        saveDataUsuario: false,
                        saveUsuarioButtonDisabled: true,
                        updateUsuarioButtonDisabled: true,
                        passwordModificado: false,
                        createdAt: '',
                        updatedAt: '',
                        toggledClearRows: !this.state.toggledClearRows,
                        formUsuario: {
                            id: 0,
                            userId: "",
                            nombre: "",
                            apellidos: "",
                            nif: "",
                            email: "",
                            password: "",
                            user_group_id: 0,
                            query_limit: 0,
                            profile_photo: "",
                            profile_photo_dir: "",
                            app_logo: "",
                            app_logo_dir: "",
                            ayuntamiento: "",
                            codigoINE: "",
                            userUuid: "",
                            telefono: "",
                            locked_up: null,
                            attempts_login: 0,
                            reset_token: "",
                            reset_token_expire: "",
                            companyId: 0,
                            esActiva: false,
                            esAdmin: false,
                            esComercial: false,
                            esConsultor: false,
                            esFuncionario: false,
                            informes: false,
                            detalles: false,
                            createdAt: null,
                            modifiedAt: null,
                            idEmpresa: "1001"
                        }
                    });

                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                      });
                  });

            await this.buscarUsuario();
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formUsuario };


        const name = id;
        var valueDay = formattedValue

        if (name == 'createdAt') {
            this.setState({ createdAt: value });

        }

        if (name == 'updatedAt') {
            this.setState({ updatedAt: value });
        }

        formData[name] = value

        this.setState({ formData });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    handleChangeDateFilter = (value, formattedValue, id) => {

        var formData = { ...this.state.filters };

        const name = id;
        var valueDay = formattedValue

        if (name == 'usuariosDesde') {
            this.setState({ usuariosDesde: value });

        }

        formData[name] = valueDay;

        this.setState({ filters: formData });
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {
        // eslint-disable-next-line no-console

        if (state.selectedCount === 1) {
            var existeCliente = ''
            var cliente = state.selectedRows[0].id;
            this.setState({ usuarioDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].updatedAt
            existeCliente = state.selectedRows[0].id

            if (existeFechaAlta && existeFechaBaja) {

                this.setState({
                    inputDisabled: true,
                    updateUsuarioButtonDisabled: false
                })
            } else if (!existeFechaAlta && !existeFechaBaja) {
                this.setState({
                    inputDisabled: false,
                    updateUsuarioButtonDisabled: true
                })
            }



            if (existeCliente) {
                this.setState({ id: this.state.usuarioDetail.id });
                this.setState({
                    formUsuario: {
                        id: state.selectedRows[0].id,
                        userId: state.selectedRows[0].userId,
                        nombre: state.selectedRows[0].nombre ?.toUpperCase() || '',
                        apellidos: state.selectedRows[0].apellidos ?.toUpperCase() || '',
                        nif: state.selectedRows[0].nif,
                        email: state.selectedRows[0].email,
                        password: state.selectedRows[0].password,
                        user_group_id: state.selectedRows[0].user_group_id,
                        query_limit: state.selectedRows[0].query_limit,
                        profile_photo: state.selectedRows[0].profile_photo,
                        profile_photo_dir: state.selectedRows[0].profile_photo_dir,
                        app_logo: state.selectedRows[0].app_logo,
                        app_logo_dir: state.selectedRows[0].app_logo_dir,
                        ayuntamiento: state.selectedRows[0].ayuntamiento,
                        codigoINE: state.selectedRows[0].codigoINE,
                        userUuid: state.selectedRows[0].userUuid,
                        telefono: state.selectedRows[0].telefono,
                        locked_up: state.selectedRows[0].locked_up,
                        attempts_login: state.selectedRows[0].attempts_login,
                        reset_token: state.selectedRows[0].reset_token,
                        reset_token_expire: state.selectedRows[0].reset_token_expire,
                        companyId: state.selectedRows[0].companyId,
                        esActiva: state.selectedRows[0].esActiva,
                        esAdmin: state.selectedRows[0].esAdmin,
                        esComercial: state.selectedRows[0].esComercial,
                        esConsultor: state.selectedRows[0].esConsultor,
                        esFuncionario: state.selectedRows[0].esFuncionario,
                        informes: state.selectedRows[0].informes,
                        detalles: state.selectedRows[0].detalles,
                        createdAt: state.selectedRows[0].createdAt,
                        modifiedAt: state.selectedRows[0].updatedAt,
                        idEmpresa: state.selectedRows[0].idEmpresa

                    },
                    usuarioSeleccionado: true,
                    inputDisabled: false,
                    createdAt: state.selectedRows[0].createdAt
                });

            } else {
                this.setState({
                    usuarioSeleccionado: false,
                    formUsuario: {
                        id: 0,
                        userId: "",
                        nombre: "",
                        apellidos: "",
                        nif: "",
                        email: "",
                        password: "",
                        user_group_id: 0,
                        query_limit: 0,
                        profile_photo: "",
                        profile_photo_dir: "",
                        app_logo: "",
                        app_logo_dir: "",
                        ayuntamiento: "",
                        codigoINE: "",
                        userUuid: "",
                        telefono: "",
                        locked_up: null,
                        attempts_login: 0,
                        reset_token: "",
                        reset_token_expire: "",
                        companyId: 0,
                        esActiva: false,
                        esAdmin: false,
                        esComercial: false,
                        esConsultor: false,
                        esFuncionario: false,
                        informes: false,
                        detalles: false,
                        createdAt: null,
                        modifiedAt: null,
                        idEmpresa: "1001"
                    },
                    createdAt: null,
                    updatedAt: null,
                    passwordModificado: false,
                });
            }
        } else {

            this.setState({
                usuarioSeleccionado: false,
                inputDisabled: false,
                updateUsuarioButtonDisabled: true,
                saveUsuarioButtonDisabled: true,
                passwordModificado: false,
                createdAt: null,
                updatedAt: null,
                formUsuario: {
                    id: 0,
                    userId: "",
                    nombre: "",
                    apellidos: "",
                    nif: "",
                    email: "",
                    password: "",
                    user_group_id: 0,
                    query_limit: 0,
                    profile_photo: "",
                    profile_photo_dir: "",
                    app_logo: "",
                    app_logo_dir: "",
                    ayuntamiento: "",
                    codigoINE: "",
                    userUuid: "",
                    telefono: "",
                    locked_up: null,
                    attempts_login: 0,
                    reset_token: "",
                    reset_token_expire: "",
                    companyId: 0,
                    esActiva: false,
                    esAdmin: false,
                    esComercial: false,
                    esConsultor: false,
                    esFuncionario: false,
                    informes: false,
                    detalles: false,
                    createdAt: null,
                    modifiedAt: null,
                    idEmpresa: "1001"
                }
            });



        };

    }



    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }



    render() {

        const formUsuario: any = this.state.formUsuario;
        const createdAt = this.state.createdAt
        const updatedAt = this.state.updatedAt

        const usuariosDesde = this.state.usuariosDesde


        if (this.state.userGroupId < 4001 && this.state.userGroupId > 2000) {
            return (
                <>
                    <Header title={'Información Usuarios'} />
                    <Container className=" mt--7" fluid>

                        <Card className="mb-4">
                            <CardHeader>
                                <Button
                                    onClick={this.showFiltersToggle}
                                    size="sm"
                                    className="float-right"
                                >
                                    <i className={"ni " + this.getFilterIcon()} />
                                </Button>
                                <h3 className="mb-0">Filtrar</h3>
                            </CardHeader>
                            <Collapse isOpen={this.state.showFilters}>
                                <CardBody>
                                    <Form innerRef={this.formRef}>
                                        <Row>
                                            <Col md="12" lg="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="clienteRef">
                                                        APELLIDOS
                                  </Label>
                                                    <Input
                                                        id="apellidosUsuariosSearch"
                                                        placeholder="Ingresar Apellidos/Razón Social del cliente"
                                                        name="apellidosUsuariosSearch"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.apellidosUsuariosSearch}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="NIFSearch">
                                                        NIF
                                            </Label>
                                                    <Input
                                                        id="NIFSearch"
                                                        placeholder="Ingresar NIF del representante"
                                                        name="NIFSearch"
                                                        maxLength={16}
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.NIFSearch}
                                                        type="text"

                                                    />
                                                    {this.state.NIFError ? <FormText color="red">Comprobar NIF/DNI</FormText> : ''}
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>
                                                    <Label className="form-control-label">DESDE</Label>
                                                    <DatePicker id="usuariosDesde"
                                                        value={usuariosDesde}
                                                        onChange={(v, f) => this.handleChangeDateFilter(v, f, "usuariosDesde")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoEstadoProducto">ESTADO</Label>
                                                    <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                        onChange={this.onChangeFieldSearch}
                                                        value={this.state.filters.tipoEstadoSearch}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">ACTIVO</option>
                                                        <option value="false">BAJA</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col className="text-right">
                                                <Button onClick={this.clearForm}>
                                                    <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>
                                                <Button
                                                    color="primary"
                                                    onClick={this.buscarUsuario}
                                                    disabled={this.state.buscarButtonDisabled}
                                                >
                                                    <i className={"bi bi-search"} /> Buscar
                                    </Button>
                                            </Col>
                                        </Row>

                                        <Card className="shadow">
                                            <DataTable
                                                title="LISTADO DE USUARIOS"
                                                columns={columnsDatosUsuarios}
                                                data={this.state.usuarioDetailTotal}
                                                selectableRows
                                                //selectableRowsComponent={radio} // Pass the function only
                                                selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                                selectableRowsSingle
                                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                noDataComponent={"Sin datos para mostrar"}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                                onSelectedRowsChange={this.handleChange}
                                                clearSelectedRows={this.state.toggledClearRows}
                                                highlightOnHover
                                                striped
                                                pointerOnHover
                                                selectableRowsHighlight
                                                customStyles={customStyles}
                                                contextMessage={{ singular: 'producto', plural: 'productos', message: 'seleccionado' }}
                                                subHeader
                                                subHeaderComponent={

                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <CSVLink
                                                            data={this.state.usuarioDetailTotal}
                                                            filename={"ClientesRegistrados.csv"}
                                                            className="btn btn-default btn-sm btn-circle"
                                                            color="default"
                                                            target="_blank"
                                                            separator={";"}
                                                        >

                                                            <span className="btn-inner--icon">
                                                                <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Fichero CSV</span>

                                                        </CSVLink>
                                                    </div>
                                                }
                                                progressComponent={<Circular />}
                                            />
                                        </Card>

                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>




                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={this.props.history.goBack}
                                                >
                                                    Atrás
                                            </button>
                                            </Col>

                                            <Col className="col-auto d-flex">
                                                <Card className="p-3 bg-light">
                                                    <FormGroup>

                                                        <Button disabled={this.state.updateUsuarioButtonDisabled} id="UpdateRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                            onClick={() => { this.updateHandler(this.state.saveDataUsuario) }}
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-person-lines-fill" style={{ fontSize: 30 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Actualizar</span>
                                                            <UncontrolledTooltip delay={0} placement="top" target="UpdateRepreProptooltip">
                                                                MODIFICAR USUARIO EXISTENTE
                                                    </UncontrolledTooltip>
                                                        </Button>

                                                        <Link
                                                            color="info" type="button" className="btn btn-primary btn-sm btn-circle"
                                                            data-toggle="modal"
                                                            to={{ pathname: "nuevo-usuario" }}
                                                            id="newElecProduct"
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-person-plus-fill" style={{ fontSize: 30 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Nuevo</span>

                                                            <UncontrolledTooltip delay={0} placement="top" target="newElecProduct">
                                                                CREAR NUEVO USUARIO
                                                </UncontrolledTooltip>
                                                        </Link>

                                                    </FormGroup>

                                                </Card>
                                            </Col>

                                        </Row>
                                        <div>
                                            <CardTitle>DATOS GENERALES USUARIO</CardTitle>
                                        </div>

                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombre">
                                                        NOMBRE
                                            </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="nombre"
                                                        placeholder=""
                                                        name="nombre"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.nombre}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="apellidos">
                                                        Apellidos
                                            </Label>
                                                    <Input
                                                        id="apellidos"
                                                        placeholder=""
                                                        name="apellidos"
                                                        maxLength={50}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.apellidos}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="nif">
                                                        NIF
                                            </Label>
                                                    <Input
                                                        id="nif"
                                                        placeholder=""
                                                        name="nif"
                                                        maxLength={16}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.nif}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                    {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF</FormText> : ''}
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>

                                            <Col md="3">
                                                <Label className="form-control-label" htmlFor="ayuntamientoRef">
                                                    Ayuntamiento
                                        </Label>

                                                <Input
                                                        className="text-primary"
                                                        id="ayuntamiento"
                                                        placeholder=""
                                                        name="ayuntamiento"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.ayuntamiento}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="codigoINE">
                                                        INE
                                        </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="codigoINE"
                                                        placeholder=""
                                                        name="codigoINE"
                                                        maxLength={40}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.codigoINE}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>



                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="telefono">
                                                        Teléfono Contacto
                                            </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefono"
                                                        placeholder=""
                                                        name="telefono"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.telefono}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="idEmpresa">
                                                        Empresa
                                            </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="idEmpresa"
                                                        placeholder=""
                                                        name="idEmpresa"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.idEmpresa}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="email">
                                                        Correo Contacto
                                            </Label>
                                                    <Input
                                                        id="email"
                                                        placeholder=""
                                                        name="email"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.email}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="password">
                                                        Password
                                            </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="password"
                                                        placeholder=""
                                                        name="password"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.password}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="createdAt">
                                                        Fecha Alta Registro
                                            </Label>

                                                    <DatePicker id="createdAt"
                                                        value={createdAt}
                                                        onChange={(v, f) => this.handleChangeDate(v, f, "createdAt")}
                                                        instanceCount={1}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        dayLabels={spanishDayLabels}
                                                        monthLabels={spanishMonthLabels}
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>OTROS DATOS</CardTitle>
                                        </div>

                                        <Row>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Grupo Usuario</Label>
                                                    <Input type="select" name="user_group_id" id="user_group_id"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.user_group_id}>
                                                        <option selected>Selecciona...</option>
                                                        <option value={5000}> Responsable</option>
                                                        <option value={4000}> Usuario N1</option>
                                                        <option value={3000}> Usuario N2</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Usuario Activo</Label>
                                                    <Input type="select" name="esActiva" id="esActiva"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esActiva}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>




                                        </Row>


                                        <Row>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Administrador</Label>
                                                    <Input type="select" name="esAdmin" id="esAdmin"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esAdmin}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Comercial</Label>
                                                    <Input type="select" name="esComercial" id="esComercial"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esComercial}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Consultor</Label>
                                                    <Input type="select" name="esConsultor" id="esConsultor"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esConsultor}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Funcionario</Label>
                                                    <Input type="select" name="esFuncionario" id="esFuncionario"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.esFuncionario}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Informes</Label>
                                                    <Input type="select" name="informes" id="informes"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.informes}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Detalles</Label>
                                                    <Input type="select" name="detalles" id="detalles"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formUsuario.detalles}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="true">SI</option>
                                                        <option value="false">NO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </CardBody>
                                </Card>
                                {this.state.alert}

                            </div>
                        </Row>
                    </Container>

                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"} />
                </>
            );
        }
    }
}


export default withApollo(Usuarios);
