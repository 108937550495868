import React from "react";
import Reflux from "reflux";
import { Modal, Button } from "reactstrap";
import { APIErrorService } from "../../services";
import { Observable, Subscription } from "rxjs";
import { LOGOUT } from "../../queries/users/users.queries";
import { removeToken } from "../../utils";

class LogoutModal extends Reflux.Component {
  public state = {
    logoutModal: false,
  };

  private errorObservable: Observable<any>;
  private errorSubscription: Subscription;

  constructor(props) {
    super(props);
    this.errorObservable = APIErrorService.getErrors();
    this.errorSubscription = this.errorObservable.subscribe(this.onNextError);
  }

  public componentWillUnmount() {
    this.errorSubscription.unsubscribe();
  }

  private toggleModal = (isOpen: boolean) => {
    this.setState({ logoutModal: isOpen });
  };

  private onNextError = (error) => {
    this.toggleModal(true);
  };

  private onClickInLogoutButton = async () => {
    //const client = this.props.client;
    //const resp = await client.mutate({
      //mutation: LOGOUT,
    //});
    //const session = resp.data.logout;
    //if (session.session_id && !session.active) {
      removeToken();
      localStorage.removeItem("lastFilters");
      localStorage.removeItem("lastFiltersEmpre");
      localStorage.removeItem("lastFiltersMulti");
      localStorage.removeItem('lastFiltersMultiGas');
      localStorage.removeItem('lastFiltersGas');
      localStorage.removeItem("user");
      this.props.client.resetStore();
      this.props.history.push(`/auth/login`);
    //} else {
     // alert("Problema al cerrar sesión");
    //}
  };

  render() {
    return (
      <Modal className="modal-dialog-centered" isOpen={this.state.logoutModal}>
        <div className="modal-header">
          <h2 className="modal-title">Ups!</h2>
        </div>
        <div className="modal-body">
          <p>
            se ha cerrado su sesión por tiempo de espera. Por favor reinicie su sesión
          </p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            data-dismiss="modal"
            onClick={this.onClickInLogoutButton}
          >
            Cerrar sesión
          </Button>
        </div>
      </Modal>
    );
  }
}

export default LogoutModal;
